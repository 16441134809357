import { useState, useEffect } from "react";
import { Link, useSearchParams } from 'react-router-dom';
import { FaFilePdf, FaLinkedin, FaGithub, FaFileArchive } from "react-icons/fa";
import { Helmet } from "react-helmet";
import API from '../../../middleware/API';

// ================================|| Recruitment ||================================ //

function Recruitment() {
    const [params] = useSearchParams();
    const [Careerdata, setCareerData] = useState();

    useEffect(() => {
        getCareerDetailById();
    }, []);

    const getCareerDetailById = async () => {
        try {
            var GetCareerDetailByIdAPI = API.getCareerDetailById;
            GetCareerDetailByIdAPI = GetCareerDetailByIdAPI + "?id=" + params.get("Id");
            const response = await fetch(GetCareerDetailByIdAPI);
            const jsonData = await response.json();
            setCareerData(jsonData.recordsets[0]);
        }
        catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <Helmet>
                <title>Recruitment</title>
            </Helmet>
            <div className="container-fluid">
                <div className="container d-flex pt-5">
                    <div className="col">
                        <h3 className="card-header px-3 pb-3">Recruitment Detail (Career)</h3>
                    </div>
                    <div className="col" style={{ textAlign: "end" }}>
                        <Link to='/admin/recruitments' className="btn btn-info cl-button -primary">Back to List</Link>
                    </div>
                </div>

                <div className="container mx-auto ">
                    <div className="px-3 pb-3">
                        <div className="table-responsive text-nowrap shadow p-3 mb-5 bg-body rounded">
                            <div className="container">
                                <div className="col-md-8 mx-auto">
                                    <div className="border rounded-3 p-2">
                                        <table className="row">
                                            <div className="col-md-3">
                                                <thead>
                                                    <tr>
                                                        <div className="col pb-2 fs-6 fw-bold">Index :- </div>
                                                        <div className="col pb-2 fs-6 fw-bold">FirstName :- </div>
                                                        <div className="col pb-2 fs-6 fw-bold">LastName :- </div>
                                                        <div className="col pb-2 fs-6 fw-bold">AppliedForRole :- </div>
                                                        <div className="col pb-2 fs-6 fw-bold">RoleName :- </div>
                                                        <div className="col pb-2 fs-6 fw-bold">Email :- </div>
                                                        <div className="col pb-2 fs-6 fw-bold">MobileNo :- </div>
                                                        <div className="col pb-2 fs-6 fw-bold">LastCompany :- </div>
                                                        <div className="col pb-2 fs-6 fw-bold">CV :- </div>
                                                        <div className="col pb-2 fs-6 fw-bold">linkedin :- </div>
                                                        <div className="col pb-2 fs-6 fw-bold">GitHub :- </div>
                                                        <div className="col pb-2 fs-6 fw-bold">Portfolio :- </div>
                                                    </tr>
                                                </thead>
                                            </div>
                                            <div className="col-md-9">
                                                <tbody className="pb-4">
                                                    {Careerdata &&
                                                        Careerdata.map(({ Id, FirstName, LastName, AppliedForRole, OtherRole, Email, MobileNo, LastCompany, CV, LinkedIn, GitHub, PortFolio }) => (
                                                            <tr key={Id}>
                                                                <div className='col table-data pb-2'>{Id}</div>
                                                                <div className='col table-data pb-2'>{FirstName}</div>
                                                                <div className='col table-data pb-2'>{LastName}</div>
                                                                <div className='col table-data pb-2'>{AppliedForRole}</div>
                                                                <div className='col table-data pb-2'>{OtherRole ? <span>{OtherRole}</span> : "N/A"}</div>
                                                                <div className='col table-data pb-2'><a className="" href='mailto:{Email}' style={{ cursor: "pointer" }}>{Email}</a></div>
                                                                <div className='col table-data pb-2'>{MobileNo}</div>
                                                                <div className='col table-data pb-2'>{LastCompany}</div>
                                                                <div className='col table-data pb-2'><a href={CV} target="_blank"><FaFilePdf size={'1.2rem'} /></a></div>
                                                                <div className='col table-data pb-2'><a href={LinkedIn} target="_blank"><FaLinkedin size={'1.2rem'} /></a></div>
                                                                <div className='col table-data pb-2'><a href={GitHub} target="_blank"><FaGithub size={'1.2rem'} /></a></div>
                                                                <div className='col table-data pb-2'><a href={PortFolio} target="_blank"><FaFileArchive size={'1.2rem'} /></a></div>
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </div>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Recruitment;