import React from 'react';
import { Helmet } from "react-helmet";

// ================================|| JabelAliOffshore ||================================ //

const JabelAliOffshore = () => {

    return (
        <>
            <Helmet>
                <title>Business Setup in Jebel Ali Offshore | Jebel Ali Offshore Company Registration</title>
            </Helmet>
            <div class="chairman-message">
                <div class="chairman-img">
                    <div class="pos-message-box">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-7 aos-init" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2 className='alignment'>Jabel Ali Offshore</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img class="d-lg-block d-none" style={{ width: "-webkit-fill-available" }} src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001997/images/Contact-us.png" alt="jabel-ali-banner" title="Jabel Ali Banner" />
            </div>
            <div className="container">
                <div className="row pt-5 pb-5">
                    <h1><span style={{ color: "#aa8038" }}>Business Setup in Jabel Ali Offshore Freezone</span></h1>
                    <div className='col-12'>
                        <p>
                            The Jebel Ali Free Zone Authority is the most settled and by and large respected of the free zones that have been allowed to work in the emirate of Dubai.
                            This kind of joining is allowed to trade all around and is allowed to have a property in the emirate of Dubai. Corporate financial backers are permitted.
                            Should the incorporator lease an office space inside JAFZA, they will then, at that point, be equipped for one boss home visa.
                            True to form with all UAE wires, this substance is similarly allowed to have and work a Dubai record.
                            The JAFZA offshore association allows the incorporator to see the value in 100% bringing back of capital and assets, unbounded on the new exchange or capital advancements inside or outside the country.
                        </p>
                    </div>
                    <div className='col-md-7'>
                        <h5 style={{ color: "#aa8038" }}>Approving And Permitted Activities</h5>
                        <p>
                            Not the least bit like a functioning associated with a license to work that is combined in the UAE, whether solidified in JAFZ, the UAE focal region district, or other free zones, the JAFZ toward the ocean association isn't given with a grant to work at the hour of the circuit.
                            Rather, the toward the ocean association simply gets a confirmation of wire. Other corporate records for the toward the ocean association will consolidate a notification and articles of connection. Since a license to work isn't given, the offshore association can't immediate any business development with individuals inside the UAE.
                            Nevertheless, as business practices coordinated outer the UAE are not open to UAE guidelines, the offshore association could lead business practices outside the UAE.
                        </p>
                        <p>
                            While the JAFZ offshore association isn't permitted to lead any sort of business or development in the UAE, it does, subject to JAFZ Authority's support and the Dubai Land Departments' stream technique, hold the choice to get freehold property inside unambiguous relegated areas in Dubai.
                        </p>
                        <p>
                            A JAFZ toward the ocean can moreover go probably as a holding association through acquiring partakes in UAE focal region associations and other free zone associations, as well as, reliant upon the material bank's technique, open a record, promise its segments, and secure credits and other cash workplaces.
                        </p>
                    </div>
                    <div className='col-md-5'>
                        <img class="card-img-top" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002041/images/jabel-ali-offshore-free-zone.jpg" alt="jabel-ali" title="Jabel Ali" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default JabelAliOffshore;