import React from 'react';
import { Helmet } from "react-helmet";

// ================================|| ExciseCompliance ||================================ //

const ExciseCompliance = () => {

    return (
        <>
            <Helmet>
                <title>Excise Compliance Services in Dubai and the UAE</title>
            </Helmet>
            <div class="chairman-message">
                <div class="chairman-img">
                    <div class="pos-message-box">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-7 aos-init" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2 className='alignment'>Excise Compliance</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img class="d-lg-block d-none" style={{ width: "-webkit-fill-available" }} src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001997/images/Contact-us.png" alt="excise-compliance-banner" title="Excise Compliance Banner" />
            </div>
            <div className="container">
                <div className="row pt-5 pb-5">
                    <h1><span style={{ color: "#aa8038" }}>Excise Compliance Services in UAE</span></h1>
                    <div className='col-12'>
                        <p>
                            Each firm in UAE which is overseeing separate products (carbonated drinks, jazzed drinks, tobacco and tobacco things, e-cigarettes and sugar-further developed rewards) ought to be enrolled with Federal Tax Authority (FTA).
                            They are supposed to stay aware of records of all their accessible trades as per the FTA's principles on extricating. The endless records of concentrate stock should be stayed aware of unequivocally and as soon a potential to avoid disciplines or fines.
                        </p>
                    </div>
                    <div className='col-md-7'>
                        <h5 style={{ color: "#aa8038" }}>Excise tax is the responsibility of any person engaged in :-</h5>
                        <ul>
                            <li style={{ listStyle: "unset" }}>
                                The Import Of Excise Goods Into The UAE;
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                The Production Of Excise Goods In The UAE;
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                The Release Of Excise Goods From A Designated Zone In The UAE;
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                The Stockpiling Of Excise Goods In The UAE In Certain Cases, Where Excise Tax Has Not Previously Been Paid On The Goods In The UAE And Stockpiling Is Undertaken For Business Purposes.
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                If You Have A Responsibility For Excise Tax, As Outlined Above, Then You Must Register With The FTA To Submit Tax Returns And Pay Excise Tax. There Are Some Exceptions For The Registration As Well.
                            </li>
                        </ul>
                    </div>
                    <div className='col-md-5'>
                        <img class="card-img-top" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002018/images/excise-compliance.jpg" alt="excise-compliance" title="Excise Compliance" />
                    </div>
                </div>
            </div>
            <div className="three-coloum-content pt-4" style={{ backgroundColor: "#fbf2de" }}>
                <div className="container">
                    <div className="row">
                        <h5 class="text-center">In summary, you must register for excise tax if you are:</h5>
                        <div class="row pb-5">
                            <div class="col-lg-6 col-md-6 col-sm-6 float-left mt-2">
                                <div class="pl-4 pr-4 pb-1 pt-1" style={{ background: "#aa8038", borderRadius: "25px" }}>
                                    <h5 class="text-center text-white">an importer of excise goods;</h5>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 float-left mt-2">
                                <div class="pl-4 pr-4 pb-1 pt-1" style={{ background: "#aa8038", borderRadius: "25px" }}>
                                    <h5 class="text-center text-white">a person releasing excise goods from a designated zone;</h5>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 float-left mt-2">
                                <div class="pl-4 pr-4 pb-1 pt-1" style={{ background: "#aa8038", borderRadius: "25px" }}>
                                    <h5 class="text-center text-white">a producer of excise goods;</h5>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 float-left mt-2">
                                <div class="pl-4 pr-4 pb-1 pt-1" style={{ background: "#aa8038", borderRadius: "25px" }}>
                                    <h5 class="text-center text-white">a stockpiler of excise goods.</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row pt-5 pb-5">
                    <p>
                        Excise tax declarations are needed to be filed in order to state the excise goods and pay the excise tax. Eligible entities are required to register for excise tax via the FTA website.
                        The tax period for excise tax return filing is one calendar month and the return must be filed via the FTA online portal within 15 days following the termination of the pertinent tax period.
                        A payment of the due tax must be done to FTA no later than the 15th day following the end of a calendar month.
                    </p>
                    <p>
                        It is extremely necessary for any business operating in the UAE to register for excise tax purposes and take direct steps to become compliant with the Excise Tax Law and its Executive Regulations.
                    </p>
                    <p>
                        We provide full-fledged assistance to businesses comply to Excise tax regulations perfectly.
                    </p>
                    <h5 style={{ color: "#aa8038" }}>Excise tax is the responsibility of any person engaged in :-</h5>
                    <p>
                        <ul>
                            <li style={{ listStyle: "unset" }}>
                                Ensure Proper Submission Of Excise Returns
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Assessment Of Excise Records, Returns & Reconciliation
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Give Advice On Client’s Queries And FTA Notifications
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Represent Before The FTA For Excise Tax Concerns
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Examine And Customize Source Documents
                            </li>
                        </ul>
                    </p>
                    <p>
                        Our dedicated team helps clients manage Excise Tax and adhere to the regulatory guidelines.
                        We aim to prevent compliance failures and ensure that our clients are always protected from any potential non-compliance risks.
                    </p>
                </div>
            </div>
        </>
    );
};

export default ExciseCompliance;