import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { FaFile } from "react-icons/fa";
import { Link, useSearchParams } from 'react-router-dom';
import API from '../../../middleware/API';

// ================================|| Contact ||================================ //

function Contact() {
    const [params] = useSearchParams();
    const [Contactdata, setContactData] = useState();

    useEffect(() => {
        getContactDetailById();
    }, []);

    const getContactDetailById = async () => {
        try {
            var GetContactDetailByIdAPI = API.getContactDetailById;
            GetContactDetailByIdAPI = GetContactDetailByIdAPI + "?id=" + params.get("Id");
            const response = await fetch(GetContactDetailByIdAPI);
            const jsonData = await response.json();
            setContactData(jsonData.recordsets[0]);
        }
        catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <Helmet>
                <title>Contact</title>
            </Helmet>
            <div className="container-fluid">
                <div className="container d-flex pt-5">
                    <div className="col">
                        <h3 className="card-header px-3 pb-3">Contact Detail</h3>
                    </div>
                    <div className="col" style={{ textAlign: "end" }}>
                        <Link to='/admin/contact-details' className="btn -primary text-white" style={{ background: "#aa8038" }}>Back to List</Link>
                    </div>
                </div>

                <div className="container mx-auto ">
                    <div className="px-3 pb-3">
                        <div className="table-responsive text-nowrap shadow p-3 mb-5 bg-body rounded">
                            <div className="container">
                                <div className="col-md-8 mx-auto">
                                    <div className="border rounded-3 p-2">
                                        <table className="row">
                                            <div className="col-md-3">
                                                <thead>
                                                    <tr>
                                                        <div className="col pb-2 fs-6 fw-bold">Index :- </div>
                                                        <div className="col pb-2 fs-6 fw-bold">Name :- </div>
                                                        <div className="col pb-2 fs-6 fw-bold">Email :- </div>
                                                        <div className="col pb-2 fs-6 fw-bold">MobileNo :- </div>
                                                        <div className="col pb-2 fs-6 fw-bold">Service :- </div>
                                                        <div className="col pb-2 fs-6 fw-bold">Description :- </div>
                                                    </tr>
                                                </thead>
                                            </div>
                                            <div className="col-md-9">
                                                <tbody className="pb-4">
                                                    {Contactdata &&
                                                        Contactdata.map(({ Id, Name, Email, MobileNo, Service, Description }) => (
                                                            <tr key={Id}>
                                                                <div className='col table-data pb-2'>{Id}</div>
                                                                <div className='col table-data pb-2'>{Name}</div>
                                                                <div className='col table-data pb-2'><a className="" href='mailto:{Email}' style={{ cursor: "pointer" }}>{Email}</a></div>
                                                                <div className='col table-data pb-2'>{MobileNo}</div>
                                                                <div className='col table-data pb-2'>{Service}</div>
                                                                <div className='col table-data pb-2' style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}>{Description}</div>
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </div>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Contact;