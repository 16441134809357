import React from 'react';
import { Helmet } from "react-helmet";

// ================================|| VATRegistration ||================================ //

const VATRegistration = () => {

    return (
        <>
            <Helmet>
                <title>VAT Registration Services in Dubai and the UAE</title>
            </Helmet>
            <div class="chairman-message">
                <div class="chairman-img">
                    <div class="pos-message-box">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-7 aos-init" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2 className='alignment'>VAT Registration</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img class="d-lg-block d-none" style={{ width: "-webkit-fill-available" }} src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001997/images/Contact-us.png" alt="vat-registration-banner" title="Vat Registration Banner" />
            </div>
            <div className="container">
                <div className="row pt-5 pb-5">
                    <h1><span style={{ color: "#aa8038" }}>VAT Registration Services in UAE</span></h1>
                    <div className='col-12'>
                        <p>
                            Regard Added Tax or VAT is a cost on the use or usage of work and items expected at each brick-and-mortar store. VAT is a kind of underhanded charge and is expected in more than 180 countries all around the planet.
                            The end-buyer ultimately bears the cost. Associations assemble and record the obligation to serve public power. Regard Added Tax (VAT) was introduced in the UAE on 1 January 2018.
                            The speed of VAT is 5%. All associations with a yearly pay of Dh no less than 375,000 are supposed to introduce their VAT selection online applications somewhere near 30 days of being supposed to enlist.
                        </p>
                    </div>
                    <div className='col-md-7'>
                        <h2 className='pt-3'><span style={{ color: "#aa8038" }}>Kind Of Vat Registration: </span></h2>
                        <p>
                            <b>-> Necessary Registration:- </b>The associations must enrollthemselves if: -<br /><br />
                            1.) Accessible supplies beyond a year is more than AED 375000 or<br />
                            2.) Accessible supplies in the next 30 days going to outperform AED 375000
                        </p>
                        <p>
                            <b>-> Purposeful Registration:- </b>Any business that doesn't fulfill the mandatory selection guidelines and falls into the going with portrayals could choose a conscious enlistment if:-<br /><br />
                            1.) Accessible supplies or accessible costs beyond a year are more than AED 187500 or<br />
                            2.) Accessible supplies or accessible costs in the next 30 days going to outperform AED 187500
                        </p>
                    </div>
                    <div className='col-md-5'>
                        <img class="card-img-top" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002084/images/vat-registration.jpg" alt="vat-registration" title="Vat Registration" />
                    </div>
                    <h2 style={{ color: "#aa8038" }}>Our VAT Advisory Services Includes :-</h2>
                    <p>
                        <ul>
                            <li style={{ listStyle: "unset" }}>
                                A valid email address & UAE contact number
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Copy of valid Trade License
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Certificate of Incorporation/ Registration Certificate (if applicable)
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Copy of emirates ID and passport of the owner/partners & manager
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Contact number and email of Authorized signatory /manager
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Memorandum of Association (MOA)
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Contact Details of company (complete address & P.O Box with emirate)
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Primary bank account details including IBAN
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Details of sales & purchase in previous 12 months along with proof
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Expected revenue and expense for the next 30 days
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Supporting documents for customs registration in each Emirates (if applicable)
                            </li>
                        </ul>
                    </p>
                </div>
            </div>
        </>
    );
};

export default VATRegistration;