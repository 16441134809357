import React from 'react';
import Faq from "react-faq-component";
import { Helmet } from "react-helmet";

// ================================|| FAQ ||================================ //

const FAQ = () => {

	const mainland = {
		title: "",
		rows: [
			{
				title: "Can a business setup in Dubai mainland operate without a sponsor?",
				content: `Businesses in Dubai mainland can now operate without a local 
				sponsor, allowing 100% foreign ownership as per the updated UAE Commercial 
				Companies Law. This move is aimed at boosting foreign investment and offering 
				entrepreneurs greater control and flexibility in their operations.`,
			},
			{
				title: "Can I have 100% ownership in my business setup in Dubai mainland?",
				content: `Yes, you can now fully own your business for certain business activities. You don’t need an Emirati 
				partner to have most of the ownership. But some activities still need a local sponsor.`,
			},
			{
				title: "Are there any restrictions on the number of visas for a mainland business setup in Dubai?",
				content: `In Dubai’s mainland, businesses have no visa limits. They can get visas based on their office space. 
				This helps firms grow and hire diverse staff in Dubai.`,
			},
			{
				title: "Can I set up a mainland company without a physical office space?",
				content: `In Dubai, setting up a mainland company usually requires leasing a physical office 
				space, but professional activities may offer some exemptions. Detailed guidance, including options like virtual 
				offices, is best sought through business setup advisory services.`,
			},
		],
	};

	const freezone = {
		title: "",
		rows: [
			{
				title: "What is a Free Zone Company Setup?",
				content: `The UAE has special economic zones. These zones give perks like full ownership, no taxes, and 
				simplified processes. A company set in a free zone is called a freezone company setup.`,
			},
			{
				title: "Can I own a free zone company alone?",
				content: `Yes, you can own a Free Zone company in Dubai alone as a Free Zone Establishment 
				(FZE), which is a business entity with a single shareholder. This allows for 100% foreign ownership without 
				requiring a local sponsor, offering benefits like tax exemptions and complete repatriation of profits and capital.`,
			},
			{
				title: "How long does the Dubai freezone company setup process take?",
				content: `The Dubai freezone company setup can take a few weeks. It can change based on the specific free 
				zone, how complicated your business is, and whether your paperwork is complete.`,
			},
		],
	};

	const accounting = {
		title: "",
		rows: [
			{
				title: "What are accounts outsourcing bookkeeping?",
				content: `We provide specialized bookkeeping and accounting services; we meet all your accounting 
				requirements. Outsourcing is a very cost-effective way of reducing the accountants’ hiring and training 
				expenses in an organization.`,
			},
			{
				title: "Do I lose control if my accounts are handled by an external agency?",
				content: `No, you do not. But you will have more time to make informed decisions on all the financial aspects 
				once accounting and bookkeeping are completed. As an investor, you can focus on growing your 
				business instead of reacting to business events.`,
			},
			{
				title: "What’s included in accounting services by an external agency?",
				content: `The services offered by us can be tailored to include almost everything an in-house accounting 
				department would do or as little as you would want and provide useful financial information that can 
				benefit your company’s growth.`,
			},
			{
				title: "How many years company have to keep their book of account?",
				content: `Books of Accounts should be maintained for a period of 5 Years from the end of the financial year.`,
			},
			{
				title: "What is VAT in Dubai?",
				content: `VAT = Value Added Tax levied on the consumption or use of goods and services at each point of sale. 
				It is in existence in more than 180 countries around the world. The end-consumer ultimately bears the 
				cost.`,
			},
			{
				title: "How can I get VAT Certificate in UAE?",
				content: `In-order to obtain VAT Certificate from the Federal Tax Authority the applicant must consider various 
				aspects such as, whether to register as 
				<ul>
					<li style="list-style: unset">
						Voluntary Applicant 
					</li>
					<li style="list-style: unset">
						Mandatory Registrant
					</li>
					<li style="list-style: unset">
						Tax Group
					</li>
					<li style="list-style: unset">
						Standalone
					</li>
				</ul>`,
			},
			{
				title: "What is VAT Certificate in UAE?",
				content: `VAT Certificate is a Statutory Document issued by the Federal tax Authority confirming the 
				registration of the applicant mentioning the Tax Registration Number (TRN) & the period for which 
				the Tax Returns need to be filed.`,
			},
			{
				title: "What is the percentage of VAT in Dubai?",
				content: `In terms of Standard Rated Supplies, the Percentage of VAT is 5%. However, there are Exempt & Zero
				Rated Supplies as well.`,
			},
		],
	};

	const styles = {
		titleTextColor: "black",
		bgColor: "#fbf2de"
	};

	const config = {
		bgColor: "#fbf2de",
	};

	return (
		<>

			<script type="application/ld+json"
				dangerouslySetInnerHTML={{
					__html:
						`{
							  "@context": "https://schema.org",
							  "@type": "FAQPage",
							  "mainEntity": [{
								"@type": "Question",
								"name": "Can a business setup in Dubai mainland operate without a sponsor?",
								"acceptedAnswer": {
								  "@type": "Answer",
								  "text": "Businesses in Dubai mainland can now operate without a local sponsor, allowing 100% foreign ownership as per the updated UAE Commercial Companies Law. This move is aimed at boosting foreign investment and offering entrepreneurs greater control and flexibility in their operations."
								}
							  },{
								"@type": "Question",
								"name": "Can I have 100% ownership in my business setup in Dubai mainland?",
								"acceptedAnswer": {
								  "@type": "Answer",
								  "text": "Yes, you can now fully own your business for certain business activities. You don’t need an Emirati partner to have most of the ownership. However, some activities still need a local sponsor."
								}
							  },{
								"@type": "Question",
								"name": "Are there any restrictions on the number of visas for a mainland business setup in Dubai?",
								"acceptedAnswer": {
								  "@type": "Answer",
								  "text": "In Dubai’s mainland, businesses have no visa limits. They can get visas based on their office space. This helps firms grow and hire diverse staff in Dubai."
								}
							  },{
								"@type": "Question",
								"name": "Can I set up a mainland company without a physical office space?",
								"acceptedAnswer": {
								  "@type": "Answer",
								  "text": "In Dubai, setting up a mainland company usually requires leasing a physical office space, but professional activities may offer some exemptions. Detailed guidance, including options like virtual offices, is best sought through business setup advisory services."
								}
							  },{
								"@type": "Question",
								"name": "What is a Free Zone Company Setup?",
								"acceptedAnswer": {
								  "@type": "Answer",
								  "text": "The UAE has special economic zones. These zones give perks like full ownership, no taxes, and simplified processes. A company set in a free zone is called a free zone company setup."
								}
							  },{
								"@type": "Question",
								"name": "Can I own a free zone company alone?",
								"acceptedAnswer": {
								  "@type": "Answer",
								  "text": "Yes, you can own a Free Zone company in Dubai alone as a Free Zone Establishment (FZE), which is a business entity with a single shareholder. This allows for 100% foreign ownership without requiring a local sponsor, offering benefits like tax exemptions and complete repatriation of profits and capital."
								}
							  },{
								"@type": "Question",
								"name": "How long does the Dubai freezone company setup process take?",
								"acceptedAnswer": {
								  "@type": "Answer",
								  "text": "The Dubai Free Zone company setup can take a few weeks. It can change based on the specific free zone, how complicated your business is, and whether your paperwork is complete."
								}
							  },{
								"@type": "Question",
								"name": "What is licensing in international business?",
								"acceptedAnswer": {
								  "@type": "Answer",
								  "text": "Licensing in international business involves a company (licensor) granting another company (licensee) the right to use its intellectual property, like trademarks or technology, in exchange for fees or royalties. This allows the licensee to use and benefit from the intellectual property without developing it themselves."
								}
							  },{
								"@type": "Question",
								"name": "What are the benefits of an international business license?",
								"acceptedAnswer": {
								  "@type": "Answer",
								  "text": "Benefits of an international business license include:
							
							Market Expansion: Allows companies to enter new markets without significant upfront investment.
							
							Cost Savings: Licensees avoid the high costs of research, development, and marketing for intellectual property.
							
							Risk Mitigation: Reduces risks associated with entering new markets by leveraging the local knowledge and expertise of the licensee.
							
							Revenue Generation: Provides an additional income stream through licensing fees or royalties.
							
							Brand Exposure: Expands brand presence globally through the licensee's activities.
							
							Flexibility: Allows licensors to focus on core competencies while licensees handle local operations.
							
							Global Presence: Enables rapid international expansion without the need for substantial infrastructure investment."
								}
							  },{
								"@type": "Question",
								"name": "How to acquire an international business license?",
								"acceptedAnswer": {
								  "@type": "Answer",
								  "text": "To acquire an international business license:
							
							Identify Intellectual Property: Determine the intellectual property to license, such as trademarks, patents, or technology.
							
							Legal Consultation: Seek legal advice to understand licensing regulations and requirements in target markets.
							
							Due Diligence: Research potential licensees, considering their reputation, capabilities, and market presence.
							
							Negotiate Terms: Discuss and negotiate terms, including fees, royalties, duration, and quality control, with the chosen licensee.
							
							Draft Agreement: Develop a comprehensive licensing agreement detailing rights, obligations, and conditions.
							
							Legal Approval: Ensure legal compliance and seek approval from relevant authorities, if required.
							
							Sign Agreement: Finalize the agreement with signatures from both parties.
							
							Quality Control: Implement measures to maintain and monitor the quality of licensed products or services.
							
							Monitor and Enforce: Regularly monitor licensee activities and enforce terms to protect intellectual property rights."
								}
							  },{
								"@type": "Question",
								"name": "What are accounts outsourcing bookkeeping?",
								"acceptedAnswer": {
								  "@type": "Answer",
								  "text": "We provide specialized bookkeeping and accounting services; we meet all your accounting requirements. Outsourcing is a very cost-effective way of reducing the accountants’ hiring and training expenses in an organization."
								}
							  },{
								"@type": "Question",
								"name": "Do I lose control if my accounts are handled by an external agency?",
								"acceptedAnswer": {
								  "@type": "Answer",
								  "text": "No, you do not. But you will have more time to make informed decisions on all the financial aspects once accounting and bookkeeping are completed. As an investor, you can focus on growing your business instead of reacting to business events."
								}
							  },{
								"@type": "Question",
								"name": "What’s included in accounting services by an external agency?",
								"acceptedAnswer": {
								  "@type": "Answer",
								  "text": "The services offered by us can be tailored to include almost everything an in-house accounting department would do or as little as you would want and provide useful financial information that can benefit your company’s growth."
								}
							  },{
								"@type": "Question",
								"name": "How many years company have to keep their book of account?",
								"acceptedAnswer": {
								  "@type": "Answer",
								  "text": "Books of Accounts should be maintained for a period of 5 Years from the end of the financial year."
								}
							  },{
								"@type": "Question",
								"name": "What is VAT in Dubai?",
								"acceptedAnswer": {
								  "@type": "Answer",
								  "text": "VAT = Value Added Tax levied on the consumption or use of goods and services at each point of sale. It is in existence in more than 180 countries around the world. The end-consumer ultimately bears the cost."
								}
							  },{
								"@type": "Question",
								"name": "How can I get VAT Certificate in UAE?",
								"acceptedAnswer": {
								  "@type": "Answer",
								  "text": "In-order to obtain VAT Certificate from the Federal Tax Authority the applicant must consider various aspects such as, whether to register as
							Voluntary Applicant
							Mandatory Registrant
							Tax Group
							Standalone"
								}
							  },{
								"@type": "Question",
								"name": "What is VAT Certificate in UAE?",
								"acceptedAnswer": {
								  "@type": "Answer",
								  "text": "A VAT Certificate is a Statutory Document issued by the Federal tax Authority confirming the registration of the applicant mentioning the Tax Registration Number (TRN) & the period for which the Tax Returns need to be filed."
								}
							  },{
								"@type": "Question",
								"name": "What is the percentage of VAT in Dubai?",
								"acceptedAnswer": {
								  "@type": "Answer",
								  "text": "In terms of Standard Rated Supplies, the Percentage of VAT is 5%. However, there are Exempt & Zero Rated Supplies as well."
								}
							  }]
							}`
				}}
			/>
			<Helmet>
				<title>FAQ - Devotion Business</title>
            </Helmet>
			<div class="chairman-message">
				<div class="chairman-img">
					<div class="pos-message-box">
						<div class="container">
							<div class="row">
								<div class="col-lg-7 aos-init" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
									<h1 className='alignment-contact'>FAQ</h1>
								</div>
							</div>
						</div>
					</div>
				</div>
				<img class="d-lg-block d-none" style={{ width: "-webkit-fill-available" }} src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001997/images/Contact-us.png" alt="faq-banner" title="FAQ Banner" />
			</div>
			<div className="container">
				<div className="row pt-5 pb-5">
					<h3 className='fw-bold mt-3 mb-3'><span style={{ color: "#aa8038" }}>Mainland</span></h3>
					<Faq
						data={mainland}
						styles={styles}
						config={config}
					/>
				</div>
				<div className="row pt-5 pb-5">
					<h3 className='fw-bold mb-3'><span style={{ color: "#aa8038" }}>FreeZone</span></h3>
					<Faq
						data={freezone}
						styles={styles}
						config={config}
					/>
				</div>
				<div className="row pt-5 pb-5">
					<h3 className='fw-bold mb-3'><span style={{ color: "#aa8038" }}>Accounting & VAT</span></h3>
					<Faq
						data={accounting}
						styles={styles}
						config={config}
					/>
				</div>
			</div>
		</>
	);
};

export default FAQ;