import React from 'react';
import { Helmet } from "react-helmet";

// ================================|| RasAlKhaimahOffshore ||================================ //

const RasAlKhaimahOffshore = () => {

    return (
        <>
            <Helmet>
                <title>Business Setup in RAK Offshore | Ras Al Khaimah Offshore Company Formation</title>
            </Helmet>
            <div class="chairman-message">
                <div class="chairman-img">
                    <div class="pos-message-box">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-7 aos-init" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2 className='alignment'>RAS Al Khaimah Offshore</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img class="d-lg-block d-none" style={{ width: "-webkit-fill-available" }} src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001997/images/Contact-us.png" alt="ras-al-banner" title="Ras Al Banner" />
            </div>
            <div className="container">
                <div className="row pt-5 pb-5">
                    <h1><span style={{ color: "#aa8038" }}>Business Setup in RAK Offshore Freezone</span></h1>
                    <div className='col-12'>
                        <p>
                            Ras al Khaimah is an Emirate and sheikdom in the Persian Gulf, overseen by Emir Saud Bis Saqr Al Qasimi.
                            It approaches a piece of the United Arab Emirates (UAE) - an association of seven emirates. Various emirates are: Abu Dhabi, Ajman, Dubai, Fujairah, Sharjah, Ras al Khaimah (RAK) and Umm al-Quwain.
                        </p>
                        <p>
                            Ras al Khaimah grants new liability regarding the ocean association. Subsequently, no UAE public as a financial backer or backing is imperative.
                            Offshore associations in Ras al Khaimah are barred from all corporate and individual costs. The full bringing back of the ocean association advantages and capital is allowed. AED 2,000 yearly evaluation (550 USD)
                        </p>
                        <p>
                            RAK toward the ocean association improvement outfits a money chief with full-scale monetary security and insurance. RAK toward the ocean is the speediest strategy for placing assets into offshore associations in the UAE.
                            Ras Al Khaimah Investment Authority (RAKIA) is the administering body, and RAK toward the ocean associations are coordinated under the RAK Offshore Regulations 2006.
                        </p>
                        <p>
                            The upsides of the ocean association in the United Arab Emirates are that the state is white-recorded, sovereign, and an overall stage for trading, guiding, and business.
                        </p>
                    </div>
                    <div className='col-md-7'>
                        <h5 style={{ color: "#aa8038" }}>Documents Required To Open A Company In RAK Offshore</h5>
                        <ul>
                            <li style={{ listStyle: "unset" }}>
                                Passport copy
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                A bank reference letter addressed to RAK authority
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                6 months bank statement
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Residence proof or any utility bill
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Curriculum vitae of investor
                            </li>
                        </ul>
                    </div>
                    <div className='col-md-5'>
                        <img class="card-img-top" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002060/images/ras-al-khaimah-offshore.jpg" alt="ras-al" title="Ras Al" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default RasAlKhaimahOffshore;