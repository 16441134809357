import React from 'react';
import { Helmet } from "react-helmet";

// ================================|| VATAdvisoryServices ||================================ //

const VATAdvisoryServices = () => {

    return (
        <>
            <Helmet>
                <title>VAT Advisory Services in Dubai and the UAE</title>
            </Helmet>
            <div class="chairman-message">
                <div class="chairman-img">
                    <div class="pos-message-box">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-7 aos-init" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2 className='alignment'>VAT Advisory Services</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img class="d-lg-block d-none" style={{ width: "-webkit-fill-available" }} src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001997/images/Contact-us.png" alt="vat-advisory-banner" title="Vat Advisory Banner" />
            </div>
            <div className="container">
                <div className="row pt-5 pb-5">
                    <h1><span style={{ color: "#aa8038" }}>VAT Advisory Services in UAE</span></h1>
                    <div className='col-12'>
                        <p>
                            VAT is a usage-based obligation and more than 160 countries gathered this thought in their country.
                            UAE executed VAT for the first time in 2018 and guidelines in emerging on ordinary reasons.
                            It's more brilliant to be charge pleasant to handily keep up with your business as resistance's disciplines are very high in UAE.
                            Being one of the Leading VAT Consultants in UAE, we have a gathering of qualified TAX Experts in UAE who can help you with all VAT-related questions and you can fundamentally focus on your business.
                        </p>
                    </div>
                    <div className='col-md-7'>
                        <h5 style={{ color: "#aa8038" }}>Our VAT Advisory Services Includes :-</h5>
                        <ul>
                            <li style={{ listStyle: "unset" }}>
                                VAT Registration & related compliances.
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Initial Consultancy to setup process for Invoicing.
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Preparing & Filling of VAT Return online as per periodicity specified by FTA.
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Suggestion on avenues and transaction where tax burden can reduce.
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Clarification on special transaction from Designated Zones.
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Clarification on VAT applicability and treatment of specific transaction.
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Clarification on Transaction with other GCC countries.
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Verification of Input Tax Credit claims to offset and reduce the final liability.
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Evaluation and Identification of transactions exempt from tax.
                            </li><li style={{ listStyle: "unset" }}>
                                Working of tax payable under reverse charge mechanism and its claim.
                            </li><li style={{ listStyle: "unset" }}>
                                Assistance in tax deposited to FTA.
                            </li><li style={{ listStyle: "unset" }}>
                                Assistance in dealing with FTA for VAT refund & assessment.
                            </li><li style={{ listStyle: "unset" }}>
                                Filling of Voluntary Disclosure.
                            </li>
                        </ul>
                    </div>
                    <div className='col-md-5'>
                        <img class="card-img-top" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002081/images/vat-advisory-services.jpg" alt="vat-advisory" title="Vat Advisory" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default VATAdvisoryServices;