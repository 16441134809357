import React from "react";
import API from '../../middleware/API';
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { Cookies, useCookies } from "react-cookie";
import { NavLink, Bars, NavMenu } from "./NavbarElement";
import { FaHome, FaEnvelopeOpenText, FaProjectDiagram, FaUserFriends, FaBlenderPhone, FaUserAlt, FaUserCog, FaPaste } from "react-icons/fa";

// ================================|| Navbar ||================================ //

const Navbar = () => {
  const [RolePermissiondata, setRolePermissionData] = useState();
  const [cookie, SetCookie] = useCookies(['username']);
  const cookies = new Cookies();
  const roleid = cookies.get('roleid');

  useEffect(() => {
    getRolePermissionByRoleId();
  }, []);

  const getRolePermissionByRoleId = async () => {
    try {
      var GetRolePermissionByRoleIdAPI = API.getRolePermissionByRoleId + "?roleId=" + roleid;
      const response = await fetch(GetRolePermissionByRoleIdAPI);
      const jsonData = await response.json();
      setRolePermissionData(jsonData.recordsets[0]);

      for (var i = 0; i <= jsonData.recordsets[0].length; i++) {
        for (var j = 1; j <= jsonData.recordsets[0].length; j++) {
          if (jsonData.recordsets[0][i].Module_Id === j && jsonData.recordsets[0][i].IsAllowed === true)
            document.getElementById(jsonData.recordsets[0][i].ModuleKeyword).hidden = false;
        }
      }
    }
    catch (error) {
      console.log(error);
    }
  };

  return (
    <aside id="layout-menu" className="layout-menu menu-vertical menu bg-menu-theme">
      <div className="app-brand mt-3 mb-4">
        <Link to='/admin/dashboard' className="app-brand-link">
          <span className="app-brand-logo demo"></span>
          <div className="d-flex">
            <img src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002044/images/logo.png" height={55} width={200} className="img-fluid" alt="logo" title="Logo" />
          </div>
        </Link>
        <a href="javascript:void(0);" class="layout-menu-toggle menu-link text-large ms-auto d-block d-xl-none">
          <i class="bx bx-chevron-left bx-sm align-middle"></i>
        </a>
      </div>

      <div className="menu-inner-shadow"></div>

      <Bars />
      <NavMenu>
        < ul className="menu-inner py-2 pt-2">
          <li className="menu-item" id="dashboard" hidden>
            <NavLink to='/admin/dashboard' activeStyle>
              <FaHome className="me-2 fs-4" />
              <div data-i18n="Analytics">Dashboard</div>
            </NavLink>
          </li>

          <li className="menu-item" id="role" hidden>
            <NavLink to='/admin/roles' activeStyle>
              <FaUserCog className="me-2 fs-4" />
              <div data-i18n="Analytics">Role</div>
            </NavLink>
          </li>

          <li className="menu-item" id="role-permission" hidden>
            <NavLink to='/#' activeStyle>
              <FaUserCog className="me-2 fs-4" />
              <div data-i18n="Analytics">Role Permission</div>
            </NavLink>
          </li>

          <li className="menu-item" id="user" hidden>
            <NavLink to='/admin/users' activeStyle>
              <FaUserAlt className="me-2 fs-4" />
              <div data-i18n="Analytics">User</div>
            </NavLink>
          </li>

          <li className="menu-item" id="blog">
            <NavLink to='/admin/blogs' activeStyle>
              <FaEnvelopeOpenText className="me-2 fs-4" />
              <div data-i18n="Analytics">Blog</div>
            </NavLink>
          </li>

          <li className="menu-item" id="enquiry">
            <NavLink to='/admin/contact-details' activeStyle>
              <FaBlenderPhone className="me-2 fs-4" />
              <div data-i18n="Analytics">Contact</div>
            </NavLink>
          </li>

          <li className="menu-item" id="demo-project" hidden>
            <NavLink to='/admin/request-a-quotes' activeStyle>
              <FaPaste className="me-2 fs-4" />
              <div data-i18n="Analytics">Request A Quote</div>
            </NavLink>
          </li>

          {/* <li className="menu-item" id="project-detail" hidden>
            <NavLink to='' activeStyle>
              <FaProjectDiagram className="me-2 fs-4" />
              <div data-i18n="Analytics">Project Leads(Home)</div>
            </NavLink>
          </li>

          <li className="menu-item" id="recruitment" hidden>
            <NavLink to='' activeStyle>
              <FaUserFriends className="me-2 fs-4" />
              <div data-i18n="Analytics">Recruitment</div>
            </NavLink>
          </li>

          <li className="menu-item" id="enquiry" hidden>
            <NavLink to='' activeStyle>
              <FaBlenderPhone className="me-2 fs-4" />
              <div data-i18n="Analytics">Contact</div>
            </NavLink>
          </li>

          <li className="menu-item" id="demo-project" hidden>
            <NavLink to='' activeStyle>
              <FaPaste className="me-2 fs-4" />
              <div data-i18n="Analytics">Demo Projects</div>
            </NavLink>
          </li>

          <li className="menu-item" id="current-opening" hidden>
            <NavLink to='' activeStyle>
              <FaPaste className="me-2 fs-4" />
              <div data-i18n="Analytics">Current Opening</div>
            </NavLink>
          </li> */}
        </ul>
      </NavMenu>
    </aside>
  );
};

export default Navbar;