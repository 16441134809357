import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import API from '../../../middleware/API';
import { Link, useNavigate } from 'react-router-dom';

// ================================|| RequestAQuote ||================================ //

const RequestAQuote = () => {
	const navigate = useNavigate();
    const MainLandCompany = ["Main Land Company Setup"];
    const FreeZoneCompany = ["Free Zone Company Setup"];
    const OffshoreCompany = ["Offshore Company Setup"];
    const CommercialLicense = ["Commercial License"];
    const ProfessionalLicense = ["Professional License"];
    const IndustrialLicense = ["Industrial License"];
    const OfficeSpaceYes = ["Yes"];
    const WarehouseYes = ["Yes"];
    const InvestorVisaYes = ["Yes"];
    const [BusinessIn, setBusinessIn] = useState("");
    const [LegalStatus, setLegalStatus] = useState("");
    const [TypeOfLicense, setTypeOfLicense] = useState("");
    const [LicenseValue, setLicenseValue] = useState("");
    const [Nationality, setNationality] = useState("");
    const [Description, setDescription] = useState("");
    const [OfficeSpace, setOfficeSpace] = useState("");
    const [OfficeSpaceValue, setOfficeSpaceValue] = useState("");
    const [Warehouse, setWarehouse] = useState("");
    const [WarehouseValue, setWarehouseValue] = useState("");
    const [InvestorVisa, setInvestorVisa] = useState("");
    const [InvestorVisaValue, setInvestorVisaValue] = useState("");
    const [NoOfShareHolders, setNoOfShareHolders] = useState("");
    const [Bank, setBank] = useState("");
    const [Name, setName] = useState("");
    const [Email, setEmail] = useState("");
    const [MobileNo, setMobileNo] = useState();
    const [Service, setService] = useState("");
    const [EmailError, setEmailError] = useState("");

    let submitHandler = async (e) => {
        e.preventDefault();
        try {
            let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            if (!re.test(Email)) {
                setEmailError("please enter valid email address");
            }
            else {
                var SendRequestAQuoteEmailAPI = API.sendRequestAQuoteEmail;
				let result = await fetch(SendRequestAQuoteEmailAPI, {
					method: "POST",
					headers: {
						'Accept': 'application/json',
						'Content-type': 'application/json',
					},
					json: true,
					body: JSON.stringify({
						name: Name,
                        email: Email,
                        mobileNo: MobileNo,
                        nationality: Nationality,
                        businessIn: BusinessIn,
                        service: Service,
                        noOfShareHolders: NoOfShareHolders,
                        legalStatus: LegalStatus,
                        typeOfLicense: TypeOfLicense,
                        licenseValue: LicenseValue,
                        description: Description,
                        officeSpace: OfficeSpace,
                        officeSpaceValue: OfficeSpaceValue,
                        warehouse: Warehouse,
                        warehouseValue: WarehouseValue,
                        investorVisa: InvestorVisa,
                        investorVisaValue: InvestorVisaValue,
                        bank: Bank,
					}),
				});
                let resJsons = await result.json();
				if (resJsons.status === "SUCCESS") {
                    var SaveRequestAQuoteDetailAPI = API.saveRequestAQuoteDetail;
                    let res = await fetch(SaveRequestAQuoteDetailAPI, {
                        method: "POST",
                        headers: {
                            'Accept': 'application/json',
                            'Content-type': 'application/json',
                        },
                        json: true,
                        body: JSON.stringify({
                            name: Name,
                            email: Email,
                            mobileNo: MobileNo,
                            nationality: Nationality,
                            businessIn: BusinessIn,
                            service: Service,
                            noOfShareHolders: NoOfShareHolders,
                            legalStatus: LegalStatus,
                            typeOfLicense: TypeOfLicense,
                            licenseValue: LicenseValue,
                            description: Description,
                            officeSpace: OfficeSpace,
                            officeSpaceValue: OfficeSpaceValue,
                            warehouse: Warehouse,
                            warehouseValue: WarehouseValue,
                            investorVisa: InvestorVisa,
                            investorVisaValue: InvestorVisaValue,
                            bank: Bank,
                        }),
                    });
                    
                    setBusinessIn("");
                    setLegalStatus("");
                    setTypeOfLicense("");
                    setLicenseValue("");
                    setNationality("");
                    setDescription("");
                    setOfficeSpace("");
                    setOfficeSpaceValue("");
                    setWarehouse("");
                    setWarehouseValue("");
                    setInvestorVisa("");
                    setInvestorVisaValue("");
                    setNoOfShareHolders("");
                    setBank("");
                    setName("");
                    setEmail("");
                    setMobileNo("");
                    setService("");
                    setEmailError('');
					navigate('/request-a-quote/thank-you');
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <Helmet>
                <title>Request A Quote - Devotion Business</title>
            </Helmet>

            <div class="chairman-message">
                <div class="chairman-img">
                    <div class="pos-message-box">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-7 aos-init" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2 className='alignment'>Request A Quote</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img class="d-lg-block d-none" style={{ width: "-webkit-fill-available" }} src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001997/images/Contact-us.png" alt="request-a-quote-banner" title="Request A Quote Banner" />
            </div>
            <div class="container three-coloum-content mt-5 mb-5">
                <div className='container'>
                    <div class="container">
                        <div class="container row">
                            <h3 class="text-center mt-3" style={{ color: "rgb(170, 128, 56)" }}> Request A Quote Form</h3>
                            <div class="col-lg-1 col-md-1 col-sm-1"></div>
                            <div class="col-lg-10 col-md-10 col-sm-10" style={{ border: "8px solid rgb(170, 128, 56" }}>
                                <form class="mt-4 mb-5" id="inquiry-form" onSubmit={submitHandler}>
                                    <div class="row">
                                        <div class="col-md-12 form-group py-2">
                                            <span class="text-center fw-bold" style={{ fontSize: "18px", color: "rgb(170, 128, 56)" }}>Where Do You Want To Set Up Your Business? * </span>
                                            <hr />
                                            <div class="row">
                                                <div class="col-md-6 my-2">Setup a Business In *</div>
                                                <div class="col-md-6">
                                                    <select class="form-select my-2" id="select" onChange={(e) => setBusinessIn(e.target.value)} value={BusinessIn} required>
                                                        <option value="">Select</option>
                                                        <option value="Main Land Company Setup">Main Land Company Setup</option>
                                                        <option value="Free Zone Company Setup">Free Zone Company Setup</option>
                                                        <option value="Offshore Company Setup">Offshore Company Setup</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12 my-2">{BusinessIn ? <span>Licensing Authority</span> : null}</div>
                                                <div class="col-md-12">
                                                    {MainLandCompany.find((e) => e === BusinessIn) && (
                                                        <select class="form-select my-2" id="select" onChange={(e) => setService(e.target.value)} value={Service}>
                                                            <option value="">Select</option>
                                                            <option value="Dubai">Dubai</option>
                                                            <option value="Abu Dhabi">Abu Dhabi</option>
                                                            <option value="Sharjah">Sharjah</option>
                                                            <option value="Ras Al-Khaimah">Ras Al-Khaimah</option>
                                                            <option value="Fujairah">Fujairah</option>
                                                            <option value="Ajman">Ajman</option>
                                                            <option value="Umm Al-Quwain">Umm Al-Quwain</option>
                                                        </select>
                                                    )}
                                                    {FreeZoneCompany.find((e) => e === BusinessIn) && (
                                                        <select class="form-select my-2" id="select" onChange={(e) => setService(e.target.value)} value={Service}>
                                                            <option value="">Select</option>
                                                            <option value="Dubai Free Zone">Dubai Free Zone</option>
                                                            <option value="Abu Dhabi Free Zone">Abu Dhabi Free Zone</option>
                                                            <option value="Sharjah Free Zone">Sharjah Free Zone</option>
                                                            <option value="Ras Al-Khaimah Free Zone">Ras Al-Khaimah Free Zone</option>
                                                            <option value="Fujairah Free Zone">Fujairah Free Zone</option>
                                                            <option value="Ajman Free Zone">Ajman Free Zone</option>
                                                            <option value="Umm Al-Quwain Free Zone">Umm Al-Quwain Free Zone</option>
                                                        </select>
                                                    )}
                                                    {OffshoreCompany.find((e) => e === BusinessIn) && (
                                                        <select class="form-select my-2" id="select" onChange={(e) => setService(e.target.value)} value={Service}>
                                                            <option value="">Select</option>
                                                            <option value="Offshore Company Setup">Offshore Company Setup</option>
                                                            <option value="Ras Al-Khaimah Offshore">Ras Al-Khaimah Offshore</option>
                                                            <option value="Jebel Ali Offshore Free zone">Jebel Ali Offshore Free zone</option>
                                                        </select>
                                                    )}
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12 my-2">Type of Share Holders *</div>
                                                <div class="col-md-12">
                                                    <select class="form-select my-2" id="select" onChange={(e) => setNoOfShareHolders(e.target.value)} value={NoOfShareHolders} required>
                                                        <option value="">Select</option>
                                                        <option value="Corporate ShareHolders">Corporate ShareHolders</option>
                                                        <option value="Individual ShareHolders">Individual ShareHolders</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12 my-2">Legal Status For The New Set Up *</div>
                                                <div class="col-md-12">
                                                    <select class="form-select my-2" id="select" onChange={(e) => setLegalStatus(e.target.value)} value={LegalStatus} required>
                                                        <option value="">Select</option>
                                                        <option value="Branch">Branch</option>
                                                        <option value="Sole Establishment">Sole Establishment</option>
                                                        <option value="Civil Company">Civil Company</option>
                                                        <option value="L.L.C">L.L.C</option>
                                                        <option value="Others">Others</option>
                                                        <option value="I Didn’t Know Please advise">I Didn’t Know Please advise</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12 my-2">Type Of License *</div>
                                                <div class="col-md-12">
                                                    <select class="form-select my-2" id="select" onChange={(e) => setTypeOfLicense(e.target.value)} value={TypeOfLicense} required>
                                                        <option value="">Select</option>
                                                        <option value="Commercial License">Commercial License</option>
                                                        <option value="Professional License">Professional License</option>
                                                        <option value="Industrial License">Industrial License</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12 my-2">{TypeOfLicense ? <span>Business Activity For The New Setup</span> : null}</div>
                                                <div class="col-md-12">
                                                    {CommercialLicense.find((e) => e === TypeOfLicense) && (
                                                        <select class="form-select my-2" id="select" onChange={(e) => setLicenseValue(e.target.value)} value={LicenseValue}>
                                                            <option value="">Select</option>
                                                            <option value="Trading Activities">Trading Activities</option>
                                                            <option value="Sports and Equipment Trading">Sports and Equipment Trading</option>
                                                            <option value="Electronics and furniture trading">Electronics and furniture trading</option>
                                                            <option value="Paper Trading">Paper Trading</option>
                                                            <option value="Building Maintenance & Building Materials Trading">Building Maintenance & Building Materials Trading</option>
                                                            <option value="Chemical Trading">Chemical Trading</option>
                                                            <option value="Theatres">Theatres</option>
                                                            <option value="Cleaning and Equipment Trading">Cleaning and Equipment Trading</option>
                                                            <option value="Domestic Gas Distribution">Domestic Gas Distribution</option>
                                                            <option value="Animal Food Trading">Animal Food Trading</option>
                                                            <option value="Fish Trading">Fish Trading</option>
                                                            <option value="Jewellery Trading">Jewellery Trading</option>
                                                            <option value="Medicine Trading">Medicine Trading</option>
                                                            <option value="Supermarket">Supermarket</option>
                                                            <option value="Waste Trading">Waste Trading</option>
                                                            <option value="Trading in cars and Vehicles">Trading in cars and Vehicles</option>
                                                            <option value="Other Activities">Other Activities</option>
                                                        </select>
                                                    )}
                                                    {ProfessionalLicense.find((e) => e === TypeOfLicense) && (
                                                        <select class="form-select my-2" id="select" onChange={(e) => setLicenseValue(e.target.value)} value={LicenseValue}>
                                                            <option value="">Select</option>
                                                            <option value="Business and professional organizations">Business and professional organizations</option>
                                                            <option value="Accounts">Accounts</option>
                                                            <option value="Agriculture">Agriculture</option>
                                                            <option value="Animal Welfare Organisations">Animal Welfare Organisations</option>
                                                            <option value="Business Councils">Business Councils</option>
                                                            <option value="Car registration and transfer services">Car registration and transfer services</option>
                                                            <option value="Businessmen Forums, Charity societies">Businessmen Forums, Charity societies</option>
                                                            <option value="Cleaning Services, Commercial Library">Cleaning Services, Commercial Library</option>
                                                            <option value="Consultancy Management, Education">Consultancy Management, Education</option>
                                                            <option value="Exhibition Organisations">Exhibition Organisations</option>
                                                            <option value="Saloons">Saloons</option>
                                                            <option value="Laboratories">Laboratories</option>
                                                            <option value="Printing and Publishing Services">Printing and Publishing Services</option>
                                                            <option value="Representative Office">Representative Office</option>
                                                            <option value="Technical and Professional workshop">Technical and Professional workshop</option>
                                                            <option value="Typing and Translation">Typing and Translation</option>
                                                            <option value="Veterinary clinic and Women club">Veterinary clinic and Women club</option>
                                                            <option value="Event Management">Event Management</option>
                                                            <option value="Charity Society">Charity Society</option>
                                                            <option value="Other Services">Other Services</option>
                                                        </select>
                                                    )}
                                                    {IndustrialLicense.find((e) => e === TypeOfLicense) && (
                                                        <select class="form-select my-2" id="select" onChange={(e) => setLicenseValue(e.target.value)} value={LicenseValue}>
                                                            <option value="">Select</option>
                                                            <option value="Food industries">Food industries</option>
                                                            <option value="Candles manufacturin">Candles manufacturin</option>
                                                            <option value="Locks & keys manufacturing">Locks & keys manufacturing</option>
                                                            <option value="Hand tools manufacturing">Hand tools manufacturing</option>
                                                            <option value="Cargo container manufacturing">Cargo container manufacturing</option>
                                                            <option value="Architectural manufacturing">Architectural manufacturing</option>
                                                            <option value="Air conditioning machine manufacturing">Air conditioning machine manufacturing</option>
                                                            <option value="Sandals manufacturing">Sandals manufacturing</option>
                                                            <option value="Arabic gown manufacturing">Arabic gown manufacturing</option>
                                                            <option value="Sportswear manufacturing">Sportswear manufacturing</option>
                                                            <option value="Uniforms manufacturing">Uniforms manufacturing</option>
                                                            <option value="Handbags manufacturing">Handbags manufacturing</option>
                                                            <option value="Wooden door manufacturing">Wooden door manufacturing</option>
                                                            <option value="Other Services">Other Services</option>
                                                        </select>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 form-group py-2">
                                            <span class="text-center fw-bold" style={{ fontSize: "18px", color: "rgb(170, 128, 56)" }}> Business Activity for the new setup </span>
                                            <hr />
                                            <div class="row">
                                                <div class="col-md-12 my-2">Brief Description Of The New Business Activity</div>
                                                <div class="col-md-12">
                                                    <textarea class="my-2" name="message" id="message" placeholder="Brief Description Of The New Business Activity" value={Description} onChange={(e) => setDescription(e.target.value)} />
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12 my-2"> Office Space Required ? *</div>
                                                <div class="col-md-12">
                                                    <select class="form-select my-2" id="select" onChange={(e) => setOfficeSpace(e.target.value)} value={OfficeSpace} required>
                                                        <option value="">Select</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">No</option>
                                                    </select>
                                                </div>
                                                {OfficeSpaceYes.find((e) => e === OfficeSpace) && (
                                                    <div class="col-md-12">
                                                        <select class="form-select my-2" id="select" onChange={(e) => setOfficeSpaceValue(e.target.value)} value={OfficeSpaceValue}>
                                                            <option value="">Select</option>
                                                            <option value="Flexi Desk">Flexi Desk</option>
                                                            <option value="1 – 3 People">1 – 3 People</option>
                                                            <option value="4 – 6 People">4 – 6 People</option>
                                                            <option value="7 – 10 People">7 – 10 People</option>
                                                            <option value="More Than 10 People">More Than 10 People</option>
                                                        </select>
                                                    </div>
                                                )}
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12 my-2"> Warehouse Space Required ? * </div>
                                                <div class="col-md-12">
                                                    <select class="form-select my-2" id="select" onChange={(e) => setWarehouse(e.target.value)} value={Warehouse} required>
                                                        <option value="">Select</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">No</option>
                                                    </select>
                                                </div>
                                                {WarehouseYes.find((e) => e === Warehouse) && (
                                                    <div class="col-md-12">
                                                        <select class="form-select my-2" id="select" onChange={(e) => setWarehouseValue(e.target.value)} value={WarehouseValue}>
                                                            <option value="">Select</option>
                                                            <option value="100 – 250 Sqm">100 – 250 Sqm</option>
                                                            <option value="500 – 1000 Sqm">500 – 1000 Sqm</option>
                                                            <option value="1000 – 1500 Sqm">1000 – 1500 Sqm</option>
                                                            <option value="More Than 1500 Sqm">More Than 1500 Sqm</option>
                                                        </select>
                                                    </div>
                                                )}
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12 my-2"> Investor Visa Required ? * </div>
                                                <div class="col-md-12">
                                                    <select class="form-select my-2" id="select" onChange={(e) => setInvestorVisa(e.target.value)} value={InvestorVisa} required>
                                                        <option value="">Select</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">No</option>
                                                    </select>
                                                </div>
                                                <div class="col-md-12 my-2">How Many Employment Visa/s Require ?</div>
                                                <div class="col-md-12">
                                                    <input type="number" class="form-control bg-light my-2" name="investorVisa" id="investorVisa" placeholder="How Many Employment Visa/s Require" value={InvestorVisaValue} onChange={(e) => setInvestorVisaValue(e.target.value)} />
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12 my-2"> Bank A/C Require ? * </div>
                                                <div class="col-md-12">
                                                    <select class="form-select my-2" id="select" onChange={(e) => setBank(e.target.value)} value={Bank} required>
                                                        <option value="">Select</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">No</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 form-group py-2">
                                            <span class="text-center fw-bold" style={{ fontSize: "18px", color: "rgb(170, 128, 56)" }}> Your Information </span>
                                            <hr />
                                            <div class="row">
                                                <div class="col-md-6 my-2">Name *</div>
                                                <div class="col-md-6">
                                                    <input type="text" class="form-control bg-light my-2" name="name" id="name" placeholder="Your Name" value={Name} onChange={(e) => setName(e.target.value)} required />
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6 my-2">Email Id *</div>
                                                <div class="col-md-6">
                                                    <input type="email" class="form-control bg-light my-2" name="email" id="email" placeholder="Your Email" value={Email} onChange={(e) => setEmail(e.target.value)} required />
                                                    <div class="error-message">{EmailError ? <p>{EmailError}</p> : null}</div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6 my-2">Mobile No *</div>
                                                <div class="col-md-6">
                                                    <PhoneInput
                                                        defaultCountry="AE"
                                                        placeholder="Your Mobile No *"
                                                        value={MobileNo}
                                                        onChange={setMobileNo} required />
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6 my-2">Nationality</div>
                                                <div class="col-md-6">
                                                    <select class="form-select my-2" id="select" onChange={(e) => setNationality(e.target.value)} value={Nationality}>
                                                        <option value="">Select</option>
                                                        <option value="afghan">Afghan</option>
                                                        <option value="albanian">Albanian</option>
                                                        <option value="algerian">Algerian</option>
                                                        <option value="american">American</option>
                                                        <option value="andorran">Andorran</option>
                                                        <option value="angolan">Angolan</option>
                                                        <option value="antiguans">Antiguans</option>
                                                        <option value="argentinean">Argentinean</option>
                                                        <option value="armenian">Armenian</option>
                                                        <option value="australian">Australian</option>
                                                        <option value="austrian">Austrian</option>
                                                        <option value="azerbaijani">Azerbaijani</option>
                                                        <option value="bahamian">Bahamian</option>
                                                        <option value="bahraini">Bahraini</option>
                                                        <option value="bangladeshi">Bangladeshi</option>
                                                        <option value="barbadian">Barbadian</option>
                                                        <option value="barbudans">Barbudans</option>
                                                        <option value="batswana">Batswana</option>
                                                        <option value="belarusian">Belarusian</option>
                                                        <option value="belgian">Belgian</option>
                                                        <option value="belizean">Belizean</option>
                                                        <option value="beninese">Beninese</option>
                                                        <option value="bhutanese">Bhutanese</option>
                                                        <option value="bolivian">Bolivian</option>
                                                        <option value="bosnian">Bosnian</option>
                                                        <option value="brazilian">Brazilian</option>
                                                        <option value="british">British</option>
                                                        <option value="bruneian">Bruneian</option>
                                                        <option value="bulgarian">Bulgarian</option>
                                                        <option value="burkinabe">Burkinabe</option>
                                                        <option value="burmese">Burmese</option>
                                                        <option value="burundian">Burundian</option>
                                                        <option value="cambodian">Cambodian</option>
                                                        <option value="cameroonian">Cameroonian</option>
                                                        <option value="canadian">Canadian</option>
                                                        <option value="cape verdean">Cape Verdean</option>
                                                        <option value="central african">Central African</option>
                                                        <option value="chadian">Chadian</option>
                                                        <option value="chilean">Chilean</option>
                                                        <option value="chinese">Chinese</option>
                                                        <option value="colombian">Colombian</option>
                                                        <option value="comoran">Comoran</option>
                                                        <option value="congolese">Congolese</option>
                                                        <option value="costa rican">Costa Rican</option>
                                                        <option value="croatian">Croatian</option>
                                                        <option value="cuban">Cuban</option>
                                                        <option value="cypriot">Cypriot</option>
                                                        <option value="czech">Czech</option>
                                                        <option value="danish">Danish</option>
                                                        <option value="djibouti">Djibouti</option>
                                                        <option value="dominican">Dominican</option>
                                                        <option value="dutch">Dutch</option>
                                                        <option value="east timorese">East Timorese</option>
                                                        <option value="ecuadorean">Ecuadorean</option>
                                                        <option value="egyptian">Egyptian</option>
                                                        <option value="emirian">Emirian</option>
                                                        <option value="equatorial guinean">Equatorial Guinean</option>
                                                        <option value="eritrean">Eritrean</option>
                                                        <option value="estonian">Estonian</option>
                                                        <option value="ethiopian">Ethiopian</option>
                                                        <option value="fijian">Fijian</option>
                                                        <option value="filipino">Filipino</option>
                                                        <option value="finnish">Finnish</option>
                                                        <option value="french">French</option>
                                                        <option value="gabonese">Gabonese</option>
                                                        <option value="gambian">Gambian</option>
                                                        <option value="georgian">Georgian</option>
                                                        <option value="german">German</option>
                                                        <option value="ghanaian">Ghanaian</option>
                                                        <option value="greek">Greek</option>
                                                        <option value="grenadian">Grenadian</option>
                                                        <option value="guatemalan">Guatemalan</option>
                                                        <option value="guinea-bissauan">Guinea-Bissauan</option>
                                                        <option value="guinean">Guinean</option>
                                                        <option value="guyanese">Guyanese</option>
                                                        <option value="haitian">Haitian</option>
                                                        <option value="herzegovinian">Herzegovinian</option>
                                                        <option value="honduran">Honduran</option>
                                                        <option value="hungarian">Hungarian</option>
                                                        <option value="icelander">Icelander</option>
                                                        <option value="indian">Indian</option>
                                                        <option value="indonesian">Indonesian</option>
                                                        <option value="iranian">Iranian</option>
                                                        <option value="iraqi">Iraqi</option>
                                                        <option value="irish">Irish</option>
                                                        <option value="israeli">Israeli</option>
                                                        <option value="italian">Italian</option>
                                                        <option value="ivorian">Ivorian</option>
                                                        <option value="jamaican">Jamaican</option>
                                                        <option value="japanese">Japanese</option>
                                                        <option value="jordanian">Jordanian</option>
                                                        <option value="kazakhstani">Kazakhstani</option>
                                                        <option value="kenyan">Kenyan</option>
                                                        <option value="kittian and nevisian">Kittian and Nevisian</option>
                                                        <option value="kuwaiti">Kuwaiti</option>
                                                        <option value="kyrgyz">Kyrgyz</option>
                                                        <option value="laotian">Laotian</option>
                                                        <option value="latvian">Latvian</option>
                                                        <option value="lebanese">Lebanese</option>
                                                        <option value="liberian">Liberian</option>
                                                        <option value="libyan">Libyan</option>
                                                        <option value="liechtensteiner">Liechtensteiner</option>
                                                        <option value="lithuanian">Lithuanian</option>
                                                        <option value="luxembourger">Luxembourger</option>
                                                        <option value="macedonian">Macedonian</option>
                                                        <option value="malagasy">Malagasy</option>
                                                        <option value="malawian">Malawian</option>
                                                        <option value="malaysian">Malaysian</option>
                                                        <option value="maldivan">Maldivan</option>
                                                        <option value="malian">Malian</option>
                                                        <option value="maltese">Maltese</option>
                                                        <option value="marshallese">Marshallese</option>
                                                        <option value="mauritanian">Mauritanian</option>
                                                        <option value="mauritian">Mauritian</option>
                                                        <option value="mexican">Mexican</option>
                                                        <option value="micronesian">Micronesian</option>
                                                        <option value="moldovan">Moldovan</option>
                                                        <option value="monacan">Monacan</option>
                                                        <option value="mongolian">Mongolian</option>
                                                        <option value="moroccan">Moroccan</option>
                                                        <option value="mosotho">Mosotho</option>
                                                        <option value="motswana">Motswana</option>
                                                        <option value="mozambican">Mozambican</option>
                                                        <option value="namibian">Namibian</option>
                                                        <option value="nauruan">Nauruan</option>
                                                        <option value="nepalese">Nepalese</option>
                                                        <option value="new zealander">New Zealander</option>
                                                        <option value="ni-vanuatu">Ni-Vanuatu</option>
                                                        <option value="nicaraguan">Nicaraguan</option>
                                                        <option value="nigerien">Nigerien</option>
                                                        <option value="north korean">North Korean</option>
                                                        <option value="northern irish">Northern Irish</option>
                                                        <option value="norwegian">Norwegian</option>
                                                        <option value="omani">Omani</option>
                                                        <option value="pakistani">Pakistani</option>
                                                        <option value="palauan">Palauan</option>
                                                        <option value="panamanian">Panamanian</option>
                                                        <option value="papua new guinean">Papua New Guinean</option>
                                                        <option value="paraguayan">Paraguayan</option>
                                                        <option value="peruvian">Peruvian</option>
                                                        <option value="polish">Polish</option>
                                                        <option value="portuguese">Portuguese</option>
                                                        <option value="qatari">Qatari</option>
                                                        <option value="romanian">Romanian</option>
                                                        <option value="russian">Russian</option>
                                                        <option value="rwandan">Rwandan</option>
                                                        <option value="saint lucian">Saint Lucian</option>
                                                        <option value="salvadoran">Salvadoran</option>
                                                        <option value="samoan">Samoan</option>
                                                        <option value="san marinese">San Marinese</option>
                                                        <option value="sao tomean">Sao Tomean</option>
                                                        <option value="saudi">Saudi</option>
                                                        <option value="scottish">Scottish</option>
                                                        <option value="senegalese">Senegalese</option>
                                                        <option value="serbian">Serbian</option>
                                                        <option value="seychellois">Seychellois</option>
                                                        <option value="sierra leonean">Sierra Leonean</option>
                                                        <option value="singaporean">Singaporean</option>
                                                        <option value="slovakian">Slovakian</option>
                                                        <option value="slovenian">Slovenian</option>
                                                        <option value="solomon islander">Solomon Islander</option>
                                                        <option value="somali">Somali</option>
                                                        <option value="south african">South African</option>
                                                        <option value="south korean">South Korean</option>
                                                        <option value="spanish">Spanish</option>
                                                        <option value="sri lankan">Sri Lankan</option>
                                                        <option value="sudanese">Sudanese</option>
                                                        <option value="surinamer">Surinamer</option>
                                                        <option value="swazi">Swazi</option>
                                                        <option value="swedish">Swedish</option>
                                                        <option value="swiss">Swiss</option>
                                                        <option value="syrian">Syrian</option>
                                                        <option value="taiwanese">Taiwanese</option>
                                                        <option value="tajik">Tajik</option>
                                                        <option value="tanzanian">Tanzanian</option>
                                                        <option value="thai">Thai</option>
                                                        <option value="togolese">Togolese</option>
                                                        <option value="tongan">Tongan</option>
                                                        <option value="trinidadian or tobagonian">Trinidadian or Tobagonian</option>
                                                        <option value="tunisian">Tunisian</option>
                                                        <option value="turkish">Turkish</option>
                                                        <option value="tuvaluan">Tuvaluan</option>
                                                        <option value="ugandan">Ugandan</option>
                                                        <option value="ukrainian">Ukrainian</option>
                                                        <option value="uruguayan">Uruguayan</option>
                                                        <option value="uzbekistani">Uzbekistani</option>
                                                        <option value="venezuelan">Venezuelan</option>
                                                        <option value="vietnamese">Vietnamese</option>
                                                        <option value="welsh">Welsh</option>
                                                        <option value="yemenite">Yemenite</option>
                                                        <option value="zambian">Zambian</option>
                                                        <option value="zimbabwean">Zimbabwean</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-3 col-md-6 col-sm-6 py-1 d-block">
                                            <input type="submit" class="cl-button -primary -small py-2" value="Submit Request" style={{ background: "#aa8038" }} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="col-lg-1 col-md-1 col-sm-1"></div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
};

export default RequestAQuote;