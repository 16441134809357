import React, { useState, useEffect } from "react";
import { Link, useSearchParams } from 'react-router-dom';
import { FaClock, FaUser } from "react-icons/fa";
import { Helmet } from "react-helmet";
import moment from 'moment';
import API from '../../../middleware/API';

// ================================|| Blog ||================================ //

const Blog = () => {
    const [params] = useSearchParams();
    const [Blogdata, setBlogData] = useState();

    useEffect(() => {
        getBlogById();
    }, []);

    const getBlogById = async () => {
        try {
            var GetBlogByIdAPI = API.getBlogById;
            GetBlogByIdAPI = GetBlogByIdAPI + "?id=" + params.get("Id");
            const response = await fetch(GetBlogByIdAPI);
            const jsonData = await response.json();
            setBlogData(jsonData.recordsets[0]);
        }
        catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <Helmet>
                <title>Blog Description</title>
            </Helmet>
            <div className='container-fluid p-0'>
                <div className="container d-flex pt-5">
                    <div className="col">
                        <h3 className="card-header px-3 pb-3">Blog Detail</h3>
                    </div>
                    <div className="col" style={{ textAlign: "end" }}>
                        <Link to='/admin/blogs' className="btn -primary text-white" style={{ background: "#aa8038" }}>Back to List</Link>
                    </div>
                </div>
                <div className="container">
                    <div className='container py-2 blog_dec'>
                        {Blogdata &&
                            Blogdata.map(({ Id, BlogTitle, Category, BlogTages, BlogImage, BlogDescription, CreatedOn, CreatedBy, Profile }) => (
                                <div className="row">
                                    <h1 className="fw-bold pt-5">{BlogTitle}</h1>
                                    <p className="text-secondary"><FaClock /> {moment(CreatedOn).format('DD-MM-YYYY')} &nbsp;&nbsp;&nbsp; <FaUser /> {CreatedBy}</p>
                                    <div className="col-md-9 mb-5">
                                        <div className="text-center"><img src={BlogImage} className="img-fluid" alt="blog-image" title="Blog Image" /></div>
                                        <div className="text-decoration-none py-3 img-fluid" dangerouslySetInnerHTML={{ __html: BlogDescription }} ></div>
                                        <h6 className="fw-bold pb-4">Tags: {BlogTages}</h6>
                                    </div>
                                    <div className="col-md-3 py-2 mb-5">
                                        <div className="mb-4">
                                            <label htmlFor="search" style={{ width: "-webkit-fill-available" }}>
                                                <input id="search" type="text" placeholder="Search" />
                                            </label>
                                        </div>
                                        <div>
                                            <h5>Follow Us</h5>
                                            <div className="mb-4">
                                                <a href="https://www.instagram.com/devotioncorp/" target={'_blank'}><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1704114953/website/footer_p/icons8-instagram.png' className='rounded-2' alt="instagram" title="Instagram" /></a>
                                                <a href="https://www.linkedin.com/company/dibn-business-servicess/" target={'_blank'}><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1704114953/website/footer_p/icons8-linkedin.png' className='rounded-2' alt="linked-in" title="Linked In" /></a>
                                                <a href="https://wa.me/message/FKVORBGCTZW3I1" target={'_blank'}><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1704114953/website/footer_p/icons8-whatsapp.png' className='rounded-2' alt="whatsapp" title="Whatsapp" /></a>
                                                <a href="https://www.facebook.com/devotioncorp" target={'_blank'}><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1704114953/website/footer_p/icons8-facebook.png' className='rounded-2' alt="facebook" title="Facebook" /></a>
                                                <a href="https://twitter.com/devotioncorp" target={'_blank'}><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1704114953/website/footer_p/icons8-twitterx.png' className='rounded-2' alt="twitter-x" title="Twitter X" /></a>
                                            </div>
                                            <hr />
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Blog;