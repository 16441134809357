import React from 'react';
import { Helmet } from "react-helmet";

// ================================|| ExciseAdvisory ||================================ //

const ExciseAdvisory = () => {

    return (
        <>
            <Helmet>
                <title>Excise Advisory Services in Dubai and the UAE</title>
            </Helmet>
            <div class="chairman-message">
                <div class="chairman-img">
                    <div class="pos-message-box">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-7 aos-init" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2 className='alignment'>Excise Advisory</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img class="d-lg-block d-none" style={{ width: "-webkit-fill-available" }} src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001997/images/Contact-us.png" alt="excise-banner" title="Excise Banner" />
            </div>
            <div className="container">
                <div className="row pt-5 pb-5">
                    <h1><span style={{ color: "#aa8038" }}>Excise Advisory Services in UAE</span></h1>
                    <div className='col-12'>
                        <p>
                            It is crucial for any business working in the UAE to enroll for extricate charge purposes and track down direct ways of becoming predictable with the Excise Tax Law and its Executive Regulation.
                            It's more brilliant to be charge pleasant to effortlessly keep up with your business as disobedience's disciplines are uncommonly high in UAE.
                            Being one of the Leading Tax Consultants in UAE, we have a gathering of qualified TAX Experts in UAE who can help you with all concentrate-related questions and you can only focus in on your business.
                        </p>
                    </div>
                    <div className='col-md-7'>
                        <h5 style={{ color: "#aa8038" }}>Our Excise Advisory Function Includes Following Services :-</h5>
                        <ul>
                            <li style={{ listStyle: "unset" }}>
                                Excise Registration & Related Compliances.
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Excise Registration & Related Compliances.
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Preparing & Filling Of Excise Return Online As Per Periodicity Specified By FTA.
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Suggestion On Avenues And Transaction Where Tax Burden Can Reduce.
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Clarification On Special Transaction From Designated Zones.
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Clarification On Excise Applicability And Treatment Of Specific Transaction.
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Clarification On Transaction With Other GCC Countries.
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Evaluation And Identification Of Transactions Exempt From Tax.
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Assistance In Tax Deposited To FTA.
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Assistance In Dealing With FTA For VAT Refund & Assessment.
                            </li>
                        </ul>
                    </div>
                    <div className='col-md-5'>
                        <img class="card-img-top" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002017/images/excise-advisory.jpg" alt="excise-advisory" title="Excise Advisory" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ExciseAdvisory;