import React from 'react';
import ModalDialog from './RasDialogEnquiry';
import ModalDownloadDialog from './RasDialogDownload';
import { Helmet } from "react-helmet";

// ================================|| RasAlKhaimahFreeZones ||================================ //

const RasAlKhaimahFreeZones = () => {

    return (
        <>
            <Helmet>
                <title>Business Setup in RAK Free Zone | RAK Free Zone Company Formation</title>
            </Helmet>
            <div class="chairman-message">
                <div class="chairman-img">
                    <div class="pos-message-freezone-box">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-7 aos-init" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2>Ras Al Khaimah Free Zone</h2>
                                    <p>
                                        Business arrangement in Ras Al Khaimah Streamlined commerce Zone - RAK FTZ was laid out in the year 2000.
                                        It is a productive venture objective for your business with different open doors, adaptabilities, and furthermore benefits!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img class="d-lg-block chairman-desktop-ras-img d-none" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001991/images/Chairmans-Message_desktop.jpg" alt="ras-al-khaimah-banner" title="Ras Al Khaimah Banner" />
                <img class="d-lg-none d-block" style={{ width: "-webkit-fill-available" }} src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001991/images/Chairmans-Message_mobile.jpg" alt="ras-al-khaimah-banner" title="Ras Al Khaimah Banner" />
            </div>
            <div class="pt-5 pb-5">
                <div class="container">
                    <div class="row">
                        <h1><span style={{ color: "#aa8038" }}>Business Setup in RAK Freezone</span></h1>
                        <div class="col-lg-3 col-md-4 col-sm-6 mt-2 float-left allowHover">
                            <div class="card border-brown business-broucher-blocks busniess-block-shadow">
                                <div class="card-body">
                                    <span>
                                        <img class="img-fluid mx-auto d-block busniess-img-shadow" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002055/images/ras-al-khaimah-freezones_1.jpg" alt="ras-al-khaimah" title="Ras Al Khaimah" />
                                    </span>
                                    <h5 class="text-brown text-break text-center mt-4">
                                        Ras Al Khaimah Economic Zone (RAKEZ)
                                    </h5>
                                    <p id='Ras Al Khaimah Economic Zone (RAKEZ)' class="text-center mt-4 freezoneblock-enquiry">
                                        <ModalDialog />
                                    </p>
                                    <p id='Ras Al Khaimah Economic Zone (RAKEZ)' class="text-center mt-3 freezoneblock-download">
                                        <ModalDownloadDialog />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 mt-2 float-left allowHover">
                            <div class="card border-brown business-broucher-blocks busniess-block-shadow">
                                <div class="card-body">
                                    <span>
                                        <img class="img-fluid mx-auto d-block busniess-img-shadow" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002056/images/ras-al-khaimah-freezones_2.jpg" alt="ras-al-khaimah" title="Ras Al Khaimah" />
                                    </span>
                                    <h5 class="text-brown text-break text-center mt-4">
                                        RAK Maritime City Free Zone Authority (RMCFZA)
                                    </h5>
                                    <p id='RAK Maritime City Free Zone Authority (RMCFZA)' class="text-center mt-4 freezoneblock-enquiry">
                                        <ModalDialog />
                                    </p>
                                    <p id='RAK Maritime City Free Zone Authority (RMCFZA)' class="text-center mt-3 freezoneblock-download">
                                        <ModalDownloadDialog />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 mt-2 float-left allowHover">
                            <div class="card border-brown business-broucher-blocks busniess-block-shadow">
                                <div class="card-body">
                                    <span>
                                        <img class="img-fluid mx-auto d-block busniess-img-shadow" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002058/images/ras-al-khaimah-freezones_3.jpg" alt="ras-al-khaimah" title="Ras Al Khaimah" />
                                    </span>
                                    <h5 class="text-brown text-break text-center mt-4">
                                        Ras Al Khaimah Investment Authority
                                    </h5>
                                    <p id='Ras Al Khaimah Investment Authority' class="text-center mt-4 freezoneblock-enquiry">
                                        <ModalDialog />
                                    </p>
                                    <p id='Ras Al Khaimah Investment Authority' class="text-center mt-3 freezoneblock-download">
                                        <ModalDownloadDialog />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 mt-2 float-left allowHover">
                            <div class="card border-brown business-broucher-blocks busniess-block-shadow">
                                <div class="card-body">
                                    <span>
                                        <img class="img-fluid mx-auto d-block busniess-img-shadow" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002058/images/ras-al-khaimah-freezones_4.jpg" alt="ras-al-khaimah" title="Ras Al Khaimah" />
                                    </span>
                                    <h5 class="text-brown text-break text-center mt-4">
                                        Ras Al Khaimah Free Trade Zone
                                    </h5>
                                    <p id='Ras Al Khaimah Free Trade Zone' class="text-center mt-4 freezoneblock-enquiry">
                                        <ModalDialog />
                                    </p>
                                    <p id='Ras Al Khaimah Free Trade Zone' class="text-center mt-3 freezoneblock-download">
                                        <ModalDownloadDialog />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 mt-2 float-left allowHover">
                            <div class="card border-brown business-broucher-blocks busniess-block-shadow">
                                <div class="card-body">
                                    <span>
                                        <img class="img-fluid mx-auto d-block busniess-img-shadow" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002059/images/ras-al-khaimah-freezones_5.jpg" alt="ras-al-khaimah" title="Ras Al Khaimah" />
                                    </span>
                                    <h5 class="text-brown text-break text-center mt-4">
                                        Ras Al Khaimah Media Free Zone
                                    </h5>
                                    <p id='Ras Al Khaimah Media Free Zone' class="text-center mt-4 freezoneblock-enquiry">
                                        <ModalDialog />
                                    </p>
                                    <p id='Ras Al Khaimah Media Free Zone' class="text-center mt-3 freezoneblock-download">
                                        <ModalDownloadDialog />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RasAlKhaimahFreeZones;