import React from 'react';
import { Helmet } from "react-helmet";

// ================================|| TAXResidencyCertificate ||================================ //

const TAXResidencyCertificate = () => {

    return (
        <>
            <Helmet>
                <title>Tax Residency Certificate Services in Dubai and the UAE</title>
            </Helmet>
            <div class="chairman-message">
                <div class="chairman-img">
                    <div class="pos-message-box">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-7 aos-init" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2 className='alignment'>TAX Residency Certificate</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img class="d-lg-block d-none" style={{ width: "-webkit-fill-available" }} src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001997/images/Contact-us.png" alt="tax-residency-banner" title="TAX Residency Banner" />
            </div>
            <div className="container">
                <div className="row pt-5 pb-5">
                    <h1><span style={{ color: "#aa8038" }}>Tax Residency Certificate Services in UAE</span></h1>
                    <div className='col-12'>
                        <p>
                            A Tax Residency Certificate (TRC) generally called Tax Domicile confirmation is a report given for qualified government components, associations, and individuals to take advantage of plans of twofold duty assortment avoidance on pay embraced by the UAE.
                        </p>
                    </div>
                    <div className='col-md-6'>
                        <h5><span style={{ color: "#aa8038" }}>Following Are The Requirement For Individual:-</span></h5>
                        <p>
                            <ul>
                                <li style={{ listStyle: "unset" }}>
                                    Passport Copy And Valid Visa Copy Issued At Least 180 Days Earlier.
                                </li>
                                <li style={{ listStyle: "unset" }}>
                                    Emirates ID Copy.
                                </li>
                                <li style={{ listStyle: "unset" }}>
                                    6 Months Personal UAE Bank Statements, Stamped By The Bank.
                                </li>
                                <li style={{ listStyle: "unset" }}>
                                    Proof Of Income In UAE, E.G., Employment Agreement, Share Certificate, Salary Certificate.
                                </li>
                                <li style={{ listStyle: "unset" }}>
                                    Immigration (GDRF) Report (Report Evidencing All Entries Into And Exists Out Of The UAE).
                                </li>
                                <li style={{ listStyle: "unset" }}>
                                    Certified Tenancy Contract Copy Or Title Deed; Valid For At Least Three Months Prior To Application.
                                </li>
                            </ul>
                        </p>
                    </div>
                    <div className='col-md-6'>
                        <h5><span style={{ color: "#aa8038" }}>Following Are The Requirement For Corporate:-</span></h5>
                        <p>
                            <ul>
                                <li style={{ listStyle: "unset" }}>
                                    Valid Trade License Copy.
                                </li>
                                <li style={{ listStyle: "unset" }}>
                                    Certified Tenancy Contract Copy Or Title Deed; Valid For At Least Three Months Prior To Application.
                                </li>
                                <li style={{ listStyle: "unset" }}>
                                    Passport, Valid Visa Copy And Emirates ID Of The Company Director/ Manager.
                                </li>
                                <li style={{ listStyle: "unset" }}>
                                    Latest Certified Audited Financial Statement.
                                </li>
                                <li style={{ listStyle: "unset" }}>
                                    Last 6 Months Company UAE Bank Statements.
                                </li>
                            </ul>
                        </p>
                    </div>
                    <div className='col-md-3'></div>
                    <div className='col-md-6'>
                        <img class="col-md-6 card-img-top" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002077/images/tax-residency-certificate.jpg" alt="tax-residency-certificate" title="Tax Residency Certificate" />
                    </div>
                    <div className='col-md-3'></div>
                </div>
            </div>
        </>
    );
};

export default TAXResidencyCertificate;