import React, { useState, useEffect } from "react";
import API from '../../../middleware/API';
import { FaClock, FaUser, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import moment from 'moment';
import { Helmet } from "react-helmet";
import { useLocation } from 'react-router-dom';

// ================================|| Blog ||================================ //

const BlogDetail = () => {
	var slug = '';
	const location = useLocation();
	const [Blogdata, setBlogData] = useState();
	const [PrevBlogdata, setPrevBlogData] = useState();
	const [NextBlogdata, setNextBlogData] = useState();
	const [BlogCommentdata, setBlogCommentData] = useState();
	const [BlogCommentCount, setBlogCommentCount] = useState();
	const [fname, setFName] = useState("");
	const [lname, setLName] = useState("");
	const [email, setEmail] = useState("");
	const [message, setMessage] = useState("");
	const [textmessage, setTextmessage] = useState("");

	const [isFirstLoad, setIsFirstLoad] = useState(true)
	const [isVisible, setVisible] = useState(false)


	useEffect(() => {
		slug = location.pathname.replace('/blog/', '');
		getBlogByBlogSlug();
		getBlogCountByBlogSlug();

		if (!isFirstLoad) {
			window.location.reload();
		} else {
			setIsFirstLoad(false)
		}
	}, [isVisible]);

	let submitHandler = async (e) => {
		e.preventDefault();
		try {
			var SaveBlogCommentsAPI = API.saveBlogComments;
			let res = await fetch(SaveBlogCommentsAPI, {
				method: "POST",
				headers: {
					'Accept': 'application/json',
					'Content-type': 'application/json',
				},
				json: true,
				body: JSON.stringify({
					blogId: Blogdata[0].Id,
					firstName: fname,
					lastName: lname,
					email: email,
					description: textmessage
				}),
			});
			let resJson = await res.json();
			if (res.status === 200) {
				setFName("");
				setLName("");
				setEmail("");
				setTextmessage("");
				setMessage("Your response has been submitted sucessfully.");
			}
		} catch (error) {
			console.log(error);
		}
	};

	const getBlogByBlogSlug = async () => {
		try {
			var GetBlogByBlogSlugAPI = API.getBlogByBlogSlug;
			GetBlogByBlogSlugAPI = GetBlogByBlogSlugAPI + "?blogSlug=" + slug;
			const response = await fetch(GetBlogByBlogSlugAPI);
			const jsonData = await response.json();
			setBlogData(jsonData.recordsets[0]);
			if (jsonData.recordsets[1][0].PrevSlug != "")
				setPrevBlogData(jsonData.recordsets[1][0].PrevSlug);
			if (jsonData.recordsets[1][0].NextSlug != "")
				setNextBlogData(jsonData.recordsets[1][0].NextSlug);

			var GetBlogCommentsByBlogIdAPI = API.getBlogCommentsByBlogId;
			GetBlogCommentsByBlogIdAPI = GetBlogCommentsByBlogIdAPI + "?blogId=" + jsonData.recordsets[0][0].Id;
			const response1 = await fetch(GetBlogCommentsByBlogIdAPI);
			const jsonData1 = await response1.json();
			setBlogCommentData(jsonData1.recordsets[0]);
			setBlogCommentCount(jsonData1.recordsets[0].length);
		}
		catch (error) {
			console.log(error);
		}
	};

	const getBlogCountByBlogSlug = async () => {
		try {
			var GetBlogCountByBlogSlugAPI = API.getBlogCountByBlogSlug;
			GetBlogCountByBlogSlugAPI = GetBlogCountByBlogSlugAPI + "?blogSlug='" + slug + "'";
			const response = await fetch(GetBlogCountByBlogSlugAPI);
			const jsonData = await response.json();

			const count = jsonData.recordset[0].BlogVisitCount + 1;
			var UpdateBlogCountByBlogSlugAPI = API.updateBlogCountByBlogSlug;
			UpdateBlogCountByBlogSlugAPI = UpdateBlogCountByBlogSlugAPI + "?count=" + count + "&blogSlug='" + slug + "'";
			const response1 = await fetch(UpdateBlogCountByBlogSlugAPI);
			const jsonData1 = await response.json();
		}
		catch (error) {
			console.log(error);
		}
	};

	return (
		<>
			{Blogdata &&
				Blogdata.map(({ SeoTitle, BlogSlug, Meta_Description }) => (
					<Helmet>
                        {document.title = SeoTitle}
                        {document.head.querySelector('meta[name="title"]').content = SeoTitle}
                        {document.head.querySelector('meta[name="description"]').content = Meta_Description}
                        {document.head.querySelector('link[rel="canonical"]').content = "https://www.devotionbusiness.com/blog/" + BlogSlug}
                        {document.head.querySelector('meta[property="og:title"]').content = SeoTitle}
                        {document.head.querySelector('meta[property="og:url"]').content = "https://www.devotionbusiness.com/blog/" + BlogSlug}
                        {document.head.querySelector('meta[property="og:description"]').content = Meta_Description}
                        {document.head.querySelector('meta[property="og:image"]').content = "https://res.cloudinary.com/dm1muiikf/image/upload/v1702367024/website/TBC_LOGO.png"}
                    </Helmet>
				))}
			<div className='container'>
				{Blogdata &&
					Blogdata.map(({ Id, BlogTitle, Category, BlogTages, BlogImage, BlogDescription, CreatedOn, CreatedBy, Profile }) => (
						<div className="row">
							<h1 className="fw-bold pt-5">{BlogTitle}</h1>
							<p className="text-secondary"><FaClock /> {moment(CreatedOn).format('DD-MM-YYYY')} &nbsp;&nbsp;&nbsp; <FaUser /> {CreatedBy}</p>
							<div className="col-md-9 mb-5">
								<div className="text-center"><img src={BlogImage} className="img-fluid" alt="blog-image" title="Blog Image" /></div>
								<div className="text-decoration-none py-3 img-fluid" dangerouslySetInnerHTML={{ __html: BlogDescription }} ></div>
								<h6 className="fw-bold pb-4">Tags: {BlogTages}</h6>
								<h5 className='fw-bold'>This Post Has {BlogCommentCount} Comments</h5>
								<hr />
								<div className="mb-5">
									{BlogCommentdata &&
										BlogCommentdata.map(({ FirstName, LastName, Description, CreatedOn }) => (
											<div>
												<p className="text-left"><span className="fw-bold">{FirstName} {LastName}</span><span className="float-end">{moment(CreatedOn).format('DD-MMMM-YYYY')}</span></p>
												<p><span className="fw-bolder fs-5">“ </span> {Description} <span className="fw-bolder fs-5"> ”</span></p>
											</div>
										))}
								</div>
								<hr />
								<div className="mt-3 mb-3">
									<h5 className='fw-bold'>Leave a Comment</h5>
									<p className="text-secondary">Your email address will not be published. Required fields are marked *</p>
									<form id="inquiry-form" onSubmit={submitHandler}>
										<div className='row'>
											<div className=" col-md-6 form-item pb-2">
												<input type="text" name="fname" placeholder="First Name *" value={fname} onChange={(e) => setFName(e.target.value)} required />
											</div>
											<div className=" col-md-6 form-item pb-2">
												<input type="text" name="lname" placeholder="Last Name *" value={lname} onChange={(e) => setLName(e.target.value)} required />
											</div>
										</div>
										<div className="row">
											<div className="col-md-12 form-group py-2">
												<input type="email" name="email" id="email" placeholder="Email *" value={email} onChange={(e) => setEmail(e.target.value)} required />
											</div>
										</div>
										<div className="row">
											<div className="col-md-12 form-group py-2">
												<textarea name="message" id="message" cols="30" rows="5" placeholder="Comment *" value={textmessage} onChange={(e) => setTextmessage(e.target.value)} required ></textarea>
											</div>
										</div>
										<div class="row">
											<div class="col-lg-3 col-md-6 col-sm-6 py-1 d-block">
												<input type="submit" class="cl-button -primary -small py-2" value="Post Comment" style={{ background: "#aa8038" }} />
											</div>
										</div>
										<div class="message" style={{ textAlign: "left" }}>{message ? <p>{message}</p> : null}</div>
									</form>
								</div>
								<div className="blog-post-header-info">
									<div className="blog-post-header-authors p-0" style={{ display: "flex" }}>
										<div className="col-6">{PrevBlogdata ? <a href={"https://www.devotionbusiness.com/blog/" + PrevBlogdata} className="w-50 fs-6 fw-bold text-decoration-none text-dark"><FaChevronLeft /> Previous</a> : null}</div>
										<div className="col-6 text-end">{NextBlogdata ? <a href={"https://www.devotionbusiness.com/blog/" + NextBlogdata} className="w-50 fs-6 fw-bold text-decoration-none text-dark">Next <FaChevronRight /></a> : null}</div>
									</div>
								</div>
							</div>
							<div className="col-md-3 py-2 mb-5">
								<div className="mb-4">
									<label htmlFor="search" style={{ width: "-webkit-fill-available" }}>
										<input id="search" type="text" placeholder="Search" />
									</label>
								</div>
								<div>
									<h5>Follow Us</h5>
									<div className="mb-4">
										<a href="https://www.instagram.com/devotioncorp/" target={'_blank'}><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1704114953/website/footer_p/icons8-instagram.png' className='rounded-2' alt="instagram" title="Instagram" /></a>
										<a href="https://www.linkedin.com/company/dibn-business-servicess/" target={'_blank'}><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1704114953/website/footer_p/icons8-linkedin.png' className='rounded-2' alt="linked-in" title="Linked In" /></a>
										<a href="https://wa.me/message/FKVORBGCTZW3I1" target={'_blank'}><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1704114953/website/footer_p/icons8-whatsapp.png' className='rounded-2' alt="whatsapp" title="Whatsapp" /></a>
										<a href="https://www.facebook.com/devotioncorp" target={'_blank'}><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1704114953/website/footer_p/icons8-facebook.png' className='rounded-2' alt="facebook" title="Facebook" /></a>
										<a href="https://twitter.com/devotioncorp" target={'_blank'}><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1704114953/website/footer_p/icons8-twitterx.png' className='rounded-2' alt="twitter-x" title="Twitter X" /></a>
									</div>
									<hr />
								</div>
							</div>
						</div>
					))}
			</div>
		</>
	);
};

export default BlogDetail;