import React from 'react';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { Helmet } from "react-helmet";

// ================================|| AccountingBookkeeping ||================================ //

const AccountingBookkeeping = () => {

    return (
        <>
            <Helmet>
                <title>Accounting & Bookkeeping Services in Dubai and the UAE</title>
            </Helmet>
            <div class="chairman-message">
                <div class="chairman-img">
                    <div class="pos-message-box">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-7 aos-init" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2 className='alignment'>Accounting & Bookkeeping</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img class="d-lg-block d-none" style={{ width: "-webkit-fill-available" }} src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001997/images/Contact-us.png" alt="accounting-banner" title="Accounting Banner" />
            </div>
            <div className="container">
                <div className="row pt-5 pb-5">
                    <h1><span style={{ color: "#aa8038" }}>Accounting & Bookkeeping Services in UAE</span></h1>
                    <p>
                        Bookkeeping and accounting are two capacities that are basic for every business affiliation.
                        In the most straightforward of terms, bookkeeping is responsible for the recording of money related trades however accounting is at risk for unraveling, requesting, separating, uncovering, and summarizing the financial data.
                        Accounting and bookkeeping organizations in UAE are normal by all of the associations regardless of the size of the association as the public power of UAE makes it expected to maintain a purposeful and broad recording of the general huge number of financial trades of the association for a base time frame of 5 years.
                        Accounting and Bookkeeping help the organization of the association to fathom and screen the improvement of the money preceding seeking after any financial decision.
                    </p>
                    <p>
                        We are an apparent and enrolled accounting organization provider to associations across UAE.
                        We offer a broad extent of money-related courses of action that are highlighted helping associations with chipping away at their pay and raising their essential concern. We have a gathering of ensured clerks, specialists, and money specialists who can cultivate an all the more impressive system for managing and taking a look at the financial strength of your business.
                    </p>
                </div>
                <div className="three-coloum-content pt-4" style={{ backgroundColor: "#fbf2de" }}>
                    <div className="container">
                        <div className="row">
                            <h5 style={{ color: "#aa8038" }}>Our Range Of Specialized Services Includes :-</h5>
                            <div className='col-md-6'>
                                <p>
                                    <ul>
                                        <li style={{ listStyle: "unset" }}>
                                            Posting of Sales, Purchases & expenses
                                        </li>
                                        <li style={{ listStyle: "unset" }}>
                                            Posting of Payments & receipts
                                        </li>
                                        <li style={{ listStyle: "unset" }}>
                                            Petty Cash Accounting
                                        </li>
                                        <li style={{ listStyle: "unset" }}>
                                            Posting of Journal Entries
                                        </li>
                                        <li style={{ listStyle: "unset" }}>
                                            Payroll Accounting
                                        </li>
                                        <li style={{ listStyle: "unset" }}>
                                            Accounts Receivable & payable Reporting
                                        </li>
                                        <li style={{ listStyle: "unset" }}>
                                            Bank Reconciliation
                                        </li>
                                    </ul>
                                </p>
                            </div>
                            <div className='col-md-6'>
                                <p>
                                    <ul>
                                        <li style={{ listStyle: "unset" }}>
                                            Profit and Loss Account
                                        </li>
                                        <li style={{ listStyle: "unset" }}>
                                            Balance Sheet
                                        </li>
                                        <li style={{ listStyle: "unset" }}>
                                            Cash Flow Statement
                                        </li>
                                        <li style={{ listStyle: "unset" }}>
                                            Auditing of Financial Statement
                                        </li>
                                        <li style={{ listStyle: "unset" }}>
                                            Evaluation of financial statements
                                        </li>
                                        <li style={{ listStyle: "unset" }}>
                                            MIS reports as per management request (aging report, cost centre analysis, revenue and cost trend analysis)
                                        </li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row pt-5 pb-5">
                    <div class="col-md-12">
                        <h2 class="text-center" style={{ color: "#aa8038" }}>
                            Pricing Plans
                        </h2>
                    </div>
                    <div class="col-md-3 mt-5">
                        <div class="business-packages">
                            <div class="" style={{ backgroundColor: "#aa8038" }}>
                                <h5 class="text-white p-2 text-center">PACKAGE 1</h5>
                            </div>
                            <div class="bg-white">
                                <h3 class="text-center mb-0" style={{ color: "#aa8038" }}>
                                    AED 500
                                </h3>
                                <p class="text-center text-break text-grey">
                                    Per Month
                                </p>
                            </div>
                            <div style={{ background: "#F1F1F1" }} class="p-3">
                                <h4 class="text-dark text-center mb-0">
                                    Upto 100
                                </h4>
                                <p class="text-center text-break text-grey">
                                    Transaction Per month
                                </p>
                            </div>
                            <p class="text-grey pl-3 pr-3 pt-3 font-size-17 font-weight-6 fw-bold">Scope of Services</p>
                            <p class="text-justify">
                            </p><div class="d-flex align-content-between">
                                <div class="tick-icon">
                                    <img class="tick-svg" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001992/images/Check-icon.svg" alt="check-image" title="Check" />
                                </div>
                                <div class="text-grey font-weight-4">
                                    Accounting of Financial
                                    <br />
                                    Transaction:- Monthly
                                </div>
                            </div>
                            <div class="d-flex align-content-between mt-3">
                                <div class="tick-icon">
                                    <img class="tick-svg" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001992/images/Check-icon.svg" alt="check-image" title="Check" />
                                </div>
                                <div class="text-grey font-weight-4">
                                    Bank Reconciliation:- Monthly
                                </div>
                            </div>
                            <div class="d-flex align-content-between mt-3">
                                <div class="tick-icon">
                                    <img class="tick-svg" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001992/images/Check-icon.svg" alt="check-image" title="Check" />
                                </div>
                                <div class="text-grey font-weight-4">
                                    Trial Balance:- Monthly
                                </div>
                            </div>
                            <div class="d-flex align-content-between mt-3">
                                <div class="tick-icon">
                                    <img class="tick-svg" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001992/images/Check-icon.svg" alt="check-image" title="Check" />
                                </div>
                                <div class="text-grey font-weight-4">
                                    Payable &amp; Receivable Reconciliation:- Monthly
                                </div>
                            </div>
                            <div class="d-flex align-content-between mt-3">
                                <div class="tick-icon">
                                    <img class="tick-svg" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001992/images/Check-icon.svg" alt="check-image" title="Check" />
                                </div>
                                <div class="text-grey font-weight-4">
                                    VAT Return Filling:- Quarterly
                                </div>
                            </div>
                            <div class="d-flex align-content-between mt-3">
                                <div class="tick-icon">
                                    <img class="tick-svg" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001992/images/Check-icon.svg" alt="check-image" title="Check" />
                                </div>
                                <div class="text-grey font-weight-4">
                                    VAT Advisory:- Quarterly
                                </div>
                            </div>
                            <div class="d-flex align-content-between mt-3">
                                <div class="tick-icon">
                                    <img class="tick-svg" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001992/images/Check-icon.svg" alt="check-image" title="Check" />
                                </div>
                                <div class="text-grey font-weight-4">
                                    Balance Sheet:- Annual
                                </div>
                            </div>
                            <div class="d-flex align-content-between mt-3">
                                <div class="tick-icon">
                                    <img class="tick-svg" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001992/images/Check-icon.svg" alt="check-image" title="Check" />
                                </div>
                                <div class="text-grey font-weight-4">
                                    Profit &amp; Loss:- Annual
                                </div>
                            </div>
                            <div class="d-flex align-content-between mt-3">
                                <div class="tick-icon">
                                    <img class="tick-svg" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001992/images/Check-icon.svg" alt="check-image" title="Check" />
                                </div>
                                <div class="text-grey font-weight-4">
                                    Cash Flow:- Annual
                                </div>
                            </div>
                            <p></p>
                        </div>
                    </div>
                    <div class="col-md-3 mt-5">
                        <div class="business-packages">
                            <div class="" style={{ backgroundColor: "#aa8038" }}>
                                <h5 class="text-white p-2 text-center">PACKAGE 2</h5>
                            </div>
                            <div class="bg-white">
                                <h3 class="text-center mb-0" style={{ color: "#aa8038" }}>
                                    AED 1000
                                </h3>
                                <p class="text-center text-break text-grey">
                                    Per Month
                                </p>
                            </div>
                            <div style={{ background: "#F1F1F1" }} class="p-3">
                                <h4 class="text-dark text-center mb-0">
                                    From 101 to 500
                                </h4>
                                <p class="text-center text-break text-grey">
                                    Transaction Per month
                                </p>
                            </div>
                            <p class="text-grey pl-3 pr-3 pt-3 font-size-17 font-weight-6 fw-bold">Scope of Services</p>
                            <p class="text-justify">
                            </p><div class="d-flex align-content-between">
                                <div class="tick-icon">
                                    <img class="tick-svg" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001992/images/Check-icon.svg" alt="check-image" title="Check" />
                                </div>
                                <div class="text-grey font-weight-4">
                                    Accounting of Financial
                                    <br />
                                    Transaction:- Monthly
                                </div>
                            </div>
                            <div class="d-flex align-content-between mt-3">
                                <div class="tick-icon">
                                    <img class="tick-svg" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001992/images/Check-icon.svg" alt="check-image" title="Check" />
                                </div>
                                <div class="text-grey font-weight-4">
                                    Bank Reconciliation:- Monthly
                                </div>
                            </div>
                            <div class="d-flex align-content-between mt-3">
                                <div class="tick-icon">
                                    <img class="tick-svg" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001992/images/Check-icon.svg" alt="check-image" title="Check" />
                                </div>
                                <div class="text-grey font-weight-4">
                                    Trial Balance:- Monthly
                                </div>
                            </div>
                            <div class="d-flex align-content-between mt-3">
                                <div class="tick-icon">
                                    <img class="tick-svg" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001992/images/Check-icon.svg" alt="check-image" title="Check" />
                                </div>
                                <div class="text-grey font-weight-4">
                                    Payable &amp; Receivable Reconciliation:- Monthly
                                </div>
                            </div>
                            <div class="d-flex align-content-between mt-3">
                                <div class="tick-icon">
                                    <img class="tick-svg" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001992/images/Check-icon.svg" alt="check-image" title="Check" />
                                </div>
                                <div class="text-grey font-weight-4">
                                    VAT Return Filling:- Quarterly
                                </div>
                            </div>
                            <div class="d-flex align-content-between mt-3">
                                <div class="tick-icon">
                                    <img class="tick-svg" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001992/images/Check-icon.svg" alt="check-image" title="Check" />
                                </div>
                                <div class="text-grey font-weight-4">
                                    VAT Advisory:- Quarterly
                                </div>
                            </div>
                            <div class="d-flex align-content-between mt-3">
                                <div class="tick-icon">
                                    <img class="tick-svg" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001992/images/Check-icon.svg" alt="check-image" title="Check" />
                                </div>
                                <div class="text-grey font-weight-4">
                                    Balance Sheet:- Annual
                                </div>
                            </div>
                            <div class="d-flex align-content-between mt-3">
                                <div class="tick-icon">
                                    <img class="tick-svg" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001992/images/Check-icon.svg" alt="check-image" title="Check" />
                                </div>
                                <div class="text-grey font-weight-4">
                                    Profit &amp; Loss:- Annual
                                </div>
                            </div>
                            <div class="d-flex align-content-between mt-3">
                                <div class="tick-icon">
                                    <img class="tick-svg" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001992/images/Check-icon.svg" alt="check-image" title="Check" />
                                </div>
                                <div class="text-grey font-weight-4">
                                    Cash Flow:- Annual
                                </div>
                            </div>
                            <p></p>
                        </div>
                    </div>
                    <div class="col-md-3 mt-5">
                        <div class="business-packages">
                            <div class="" style={{ backgroundColor: "#aa8038" }}>
                                <h5 class="text-white p-2 text-center">PACKAGE 3</h5>
                            </div>
                            <div class="bg-white">
                                <h3 class="text-center mb-0" style={{ color: "#aa8038" }}>
                                    AED 1500
                                </h3>
                                <p class="text-center text-break text-grey">
                                    Per Month
                                </p>
                            </div>
                            <div style={{ background: "#F1F1F1" }} class="p-3">
                                <h4 class="text-dark text-center mb-0">
                                    From 501 to 1000
                                </h4>
                                <p class="text-center text-break text-grey">
                                    Transaction Per month
                                </p>
                            </div>
                            <p class="text-grey pl-3 pr-3 pt-3 font-size-17 font-weight-6 fw-bold">Scope of Services</p>
                            <p class="text-justify">
                            </p><div class="d-flex align-content-between">
                                <div class="tick-icon">
                                    <img class="tick-svg" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001992/images/Check-icon.svg" alt="check-image" title="Check" />
                                </div>
                                <div class="text-grey font-weight-4">
                                    Accounting of Financial
                                    <br />
                                    Transaction:- Monthly
                                </div>
                            </div>
                            <div class="d-flex align-content-between mt-3">
                                <div class="tick-icon">
                                    <img class="tick-svg" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001992/images/Check-icon.svg" alt="check-image" title="Check" />
                                </div>
                                <div class="text-grey font-weight-4">
                                    Bank Reconciliation:- Monthly
                                </div>
                            </div>
                            <div class="d-flex align-content-between mt-3">
                                <div class="tick-icon">
                                    <img class="tick-svg" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001992/images/Check-icon.svg" alt="check-image" title="Check" />
                                </div>
                                <div class="text-grey font-weight-4">
                                    Trial Balance:- Monthly
                                </div>
                            </div>
                            <div class="d-flex align-content-between mt-3">
                                <div class="tick-icon">
                                    <img class="tick-svg" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001992/images/Check-icon.svg" alt="check-image" title="Check" />
                                </div>
                                <div class="text-grey font-weight-4">
                                    Payable &amp; Receivable Reconciliation:- Monthly
                                </div>
                            </div>
                            <div class="d-flex align-content-between mt-3">
                                <div class="tick-icon">
                                    <img class="tick-svg" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001992/images/Check-icon.svg" alt="check-image" title="Check" />
                                </div>
                                <div class="text-grey font-weight-4">
                                    VAT Return Filling:- Quarterly
                                </div>
                            </div>
                            <div class="d-flex align-content-between mt-3">
                                <div class="tick-icon">
                                    <img class="tick-svg" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001992/images/Check-icon.svg" alt="check-image" title="Check" />
                                </div>
                                <div class="text-grey font-weight-4">
                                    VAT Advisory:- Quarterly
                                </div>
                            </div>
                            <div class="d-flex align-content-between mt-3">
                                <div class="tick-icon">
                                    <img class="tick-svg" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001992/images/Check-icon.svg" alt="check-image" title="Check" />
                                </div>
                                <div class="text-grey font-weight-4">
                                    Balance Sheet:- Annual
                                </div>
                            </div>
                            <div class="d-flex align-content-between mt-3">
                                <div class="tick-icon">
                                    <img class="tick-svg" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001992/images/Check-icon.svg" alt="check-image" title="Check" />
                                </div>
                                <div class="text-grey font-weight-4">
                                    Profit &amp; Loss:- Annual
                                </div>
                            </div>
                            <div class="d-flex align-content-between mt-3">
                                <div class="tick-icon">
                                    <img class="tick-svg" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001992/images/Check-icon.svg" alt="check-image" title="Check" />
                                </div>
                                <div class="text-grey font-weight-4">
                                    Cash Flow:- Annual
                                </div>
                            </div>
                            <p></p>
                        </div>
                    </div>
                    <div class="col-md-3 mt-5">
                        <div class="business-packages">
                            <div class="" style={{ backgroundColor: "#aa8038" }}>
                                <h5 class="text-white p-2 text-center">PACKAGE 4</h5>
                            </div>
                            <div class="bg-white">
                                <h3 class="text-center mb-0" style={{ color: "#aa8038" }}>
                                    AED 2250
                                </h3>
                                <p class="text-center text-break text-grey">
                                    Per Month
                                </p>
                            </div>
                            <div style={{ background: "#F1F1F1" }} class="p-3">
                                <h4 class="text-dark text-center mb-0">
                                    From 1001 to 2000
                                </h4>
                                <p class="text-center text-break text-grey">
                                    Transaction Per month
                                </p>
                            </div>
                            <p class="text-grey pl-3 pr-3 pt-3 font-size-17 font-weight-6 fw-bold">Scope of Services</p>
                            <p class="text-justify">
                            </p><div class="d-flex align-content-between">
                                <div class="tick-icon">
                                    <img class="tick-svg" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001992/images/Check-icon.svg" alt="check-image" title="Check" />
                                </div>
                                <div class="text-grey font-weight-4">
                                    Accounting of Financial
                                    <br />
                                    Transaction:- Monthly
                                </div>
                            </div>
                            <div class="d-flex align-content-between mt-3">
                                <div class="tick-icon">
                                    <img class="tick-svg" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001992/images/Check-icon.svg" alt="check-image" title="Check" />
                                </div>
                                <div class="text-grey font-weight-4">
                                    Bank Reconciliation:- Monthly
                                </div>
                            </div>
                            <div class="d-flex align-content-between mt-3">
                                <div class="tick-icon">
                                    <img class="tick-svg" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001992/images/Check-icon.svg" alt="check-image" title="Check" />
                                </div>
                                <div class="text-grey font-weight-4">
                                    Trial Balance:- Monthly
                                </div>
                            </div>
                            <div class="d-flex align-content-between mt-3">
                                <div class="tick-icon">
                                    <img class="tick-svg" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001992/images/Check-icon.svg" alt="check-image" title="Check" />
                                </div>
                                <div class="text-grey font-weight-4">
                                    Payable &amp; Receivable Reconciliation:- Monthly
                                </div>
                            </div>
                            <div class="d-flex align-content-between mt-3">
                                <div class="tick-icon">
                                    <img class="tick-svg" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001992/images/Check-icon.svg" alt="check-image" title="Check" />
                                </div>
                                <div class="text-grey font-weight-4">
                                    VAT Return Filling:- Quarterly
                                </div>
                            </div>
                            <div class="d-flex align-content-between mt-3">
                                <div class="tick-icon">
                                    <img class="tick-svg" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001992/images/Check-icon.svg" alt="check-image" title="Check" />
                                </div>
                                <div class="text-grey font-weight-4">
                                    VAT Advisory:- Quarterly
                                </div>
                            </div>
                            <div class="d-flex align-content-between mt-3">
                                <div class="tick-icon">
                                    <img class="tick-svg" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001992/images/Check-icon.svg" alt="check-image" title="Check" />
                                </div>
                                <div class="text-grey font-weight-4">
                                    Balance Sheet:- Annual
                                </div>
                            </div>
                            <div class="d-flex align-content-between mt-3">
                                <div class="tick-icon">
                                    <img class="tick-svg" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001992/images/Check-icon.svg" alt="check-image" title="Check" />
                                </div>
                                <div class="text-grey font-weight-4">
                                    Profit &amp; Loss:- Annual
                                </div>
                            </div>
                            <div class="d-flex align-content-between mt-3">
                                <div class="tick-icon">
                                    <img class="tick-svg" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001992/images/Check-icon.svg" alt="check-image" title="Check" />
                                </div>
                                <div class="text-grey font-weight-4">
                                    Cash Flow:- Annual
                                </div>
                            </div>
                            <p></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="three-coloum-content mt-4 mb-1">
                <div className="container faqsection">
                    <div className="row">
                        <Tab.Container id="left-tabs-example" defaultActiveKey={1}>
                            <Row>
                                <Col sm={8}>
                                    <Nav variant="pills" className="flex-column">
                                        <Nav.Item className='p-2 fs-5 d-flex'>
                                            <Nav.Link eventKey={1} className='text-black tab-link-variation-2'>Package 1 Details</Nav.Link>
                                            <Nav.Link eventKey={2} className='text-black tab-link-variation-2'>Package 2 Details</Nav.Link>
                                            <Nav.Link eventKey={3} className='text-black tab-link-variation-2'>Package 3 Details</Nav.Link>
                                            <Nav.Link eventKey={4} className='text-black tab-link-variation-2'>Package 4 Details</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>
                            </Row>
                            <Row>
                                <Tab.Content>
                                    <Tab.Pane eventKey={1}>
                                        <div className='container' style={{ background: "#F8F8F8" }}>
                                            <div class="col-md-12">
                                                <p class="mt-3 font-size-15 font-weight-6 fw-bold">
                                                    Scope of Services
                                                </p>
                                                <table class="table table-hover table-responsive table-striped table-bordered">
                                                    <tbody>
                                                        <tr>
                                                            <td class="font-size-15" width="50%">
                                                                Transaction Per Month
                                                            </td>
                                                            <td class="font-size-15" width="50%">
                                                                Upto 100 Transactions Per Month
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="font-size-15" width="50%">
                                                                Fees Per Year / Month
                                                            </td>
                                                            <td class="font-size-15" width="50%">
                                                                AED 500 per month
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table><br />
                                                <table class="table table-bordered table-striped">
                                                    <thead class="bg-blue">
                                                        <tr>
                                                            <th class="text-white font-size-16" style={{ backgroundColor: "#aa8038" }}>
                                                                Sr. No.
                                                            </th>
                                                            <th class="text-white font-size-16" style={{ backgroundColor: "#aa8038" }}>
                                                                Particulars
                                                            </th>
                                                            <th class="text-white font-size-16" style={{ backgroundColor: "#aa8038" }}>
                                                                Periodicity
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td class="description font-size-16">1</td>
                                                            <td class="description font-size-16">Posting of Revenue Invoice includes Output VAT</td>
                                                            <td class="description font-size-16">Monthly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">2</td>
                                                            <td class="description font-size-16">Posting of Purchase includes Input VAT</td>
                                                            <td class="description font-size-16">Monthly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">3</td>
                                                            <td class="description font-size-16">Posting of Payments:- Cash, Cheques, Bank Transfer</td>
                                                            <td class="description font-size-16">Monthly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">4</td>
                                                            <td class="description font-size-16">Petty Cash Accounting</td>
                                                            <td class="description font-size-16">Monthly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">5</td>
                                                            <td class="description font-size-16">Posting of Journal Entries</td>
                                                            <td class="description font-size-16">Monthly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">6</td>
                                                            <td class="description font-size-16">Administration and General Expenses</td>
                                                            <td class="description font-size-16">Monthly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">7</td>
                                                            <td class="description font-size-16">Payroll Accounting</td>
                                                            <td class="description font-size-16">Monthly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">8</td>
                                                            <td class="description font-size-16">Fixed Assets &amp; depreciation</td>
                                                            <td class="description font-size-16">Monthly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">9</td>
                                                            <td class="description font-size-16">Accounts Receivable Reporting</td>
                                                            <td class="description font-size-16">Monthly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">10</td>
                                                            <td class="description font-size-16">Accounts Payable Reporting</td>
                                                            <td class="description font-size-16">Monthly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">11</td>
                                                            <td class="description font-size-16">Bank Reconciliation</td>
                                                            <td class="description font-size-16">Monthly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">12</td>
                                                            <td class="description font-size-16">Trial Balance Reporting</td>
                                                            <td class="description font-size-16">Monthly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">13</td>
                                                            <td class="description font-size-16">Profit and Loss Account</td>
                                                            <td class="description font-size-16">Monthly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">14</td>
                                                            <td class="description font-size-16">Balance Sheet</td>
                                                            <td class="description font-size-16">Monthly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">15</td>
                                                            <td class="description font-size-16">Reconciliation of Accounts</td>
                                                            <td class="description font-size-16">Quarterly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">16</td>
                                                            <td class="description font-size-16">VAT Return Filing</td>
                                                            <td class="description font-size-16">Quarterly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">17</td>
                                                            <td class="description font-size-16">VAT Advisory</td>
                                                            <td class="description font-size-16">Quarterly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">18</td>
                                                            <td class="description font-size-16">Cash Flow Statement</td>
                                                            <td class="description font-size-16">Annually</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey={2}>
                                        <div className='container' style={{ background: "#F8F8F8" }}>
                                            <div class="col-md-12">
                                                <p class="mt-3 font-size-15 font-weight-6 fw-bold">
                                                    Scope of Services
                                                </p>
                                                <table class="table table-hover table-responsive table-striped table-bordered">
                                                    <tbody>
                                                        <tr>
                                                            <td class="font-size-15" width="50%">
                                                                Transaction Per Month
                                                            </td>
                                                            <td class="font-size-15" width="50%">
                                                                From 101 to 500 Transactions Per Month
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="font-size-15" width="50%">
                                                                Fees Per Year / Month
                                                            </td>
                                                            <td class="font-size-15" width="50%">
                                                                AED 1000 per month
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table><br />
                                                <table class="table table-bordered table-striped">
                                                    <thead class="bg-blue">
                                                        <tr>
                                                            <th class="text-white font-size-16" style={{ backgroundColor: "#aa8038" }}>
                                                                Sr. No.
                                                            </th>
                                                            <th class="text-white font-size-16" style={{ backgroundColor: "#aa8038" }}>
                                                                Particulars
                                                            </th>
                                                            <th class="text-white font-size-16" style={{ backgroundColor: "#aa8038" }}>
                                                                Periodicity
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td class="description font-size-16">1</td>
                                                            <td class="description font-size-16">Posting of Revenue Invoice includes Output VAT</td>
                                                            <td class="description font-size-16">Monthly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">2</td>
                                                            <td class="description font-size-16">Posting of Purchase includes Input VAT</td>
                                                            <td class="description font-size-16">Monthly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">3</td>
                                                            <td class="description font-size-16">Posting of Payments:- Cash, Cheques, Bank Transfer</td>
                                                            <td class="description font-size-16">Monthly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">4</td>
                                                            <td class="description font-size-16">Petty Cash Accounting</td>
                                                            <td class="description font-size-16">Monthly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">5</td>
                                                            <td class="description font-size-16">Posting of Journal Entries</td>
                                                            <td class="description font-size-16">Monthly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">6</td>
                                                            <td class="description font-size-16">Administration and General Expenses</td>
                                                            <td class="description font-size-16">Monthly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">7</td>
                                                            <td class="description font-size-16">Payroll Accounting</td>
                                                            <td class="description font-size-16">Monthly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">8</td>
                                                            <td class="description font-size-16">Fixed Assets &amp; depreciation</td>
                                                            <td class="description font-size-16">Monthly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">9</td>
                                                            <td class="description font-size-16">Accounts Receivable Reporting</td>
                                                            <td class="description font-size-16">Monthly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">10</td>
                                                            <td class="description font-size-16">Accounts Payable Reporting</td>
                                                            <td class="description font-size-16">Monthly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">11</td>
                                                            <td class="description font-size-16">Bank Reconciliation</td>
                                                            <td class="description font-size-16">Monthly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">12</td>
                                                            <td class="description font-size-16">Trial Balance Reporting</td>
                                                            <td class="description font-size-16">Monthly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">13</td>
                                                            <td class="description font-size-16">Profit and Loss Account</td>
                                                            <td class="description font-size-16">Monthly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">14</td>
                                                            <td class="description font-size-16">Balance Sheet</td>
                                                            <td class="description font-size-16">Monthly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">15</td>
                                                            <td class="description font-size-16">Reconciliation of Accounts</td>
                                                            <td class="description font-size-16">Quarterly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">16</td>
                                                            <td class="description font-size-16">VAT Return Filing</td>
                                                            <td class="description font-size-16">Quarterly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">17</td>
                                                            <td class="description font-size-16">VAT Advisory</td>
                                                            <td class="description font-size-16">Quarterly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">18</td>
                                                            <td class="description font-size-16">Cash Flow Statement</td>
                                                            <td class="description font-size-16">Annually</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey={3}>
                                        <div className='container' style={{ background: "#F8F8F8" }}>
                                            <div class="col-md-12">
                                                <p class="mt-3 font-size-15 font-weight-6 fw-bold">
                                                    Scope of Services
                                                </p>
                                                <table class="table table-hover table-responsive table-striped table-bordered">
                                                    <tbody>
                                                        <tr>
                                                            <td class="font-size-15" width="50%">
                                                                Transaction Per Month
                                                            </td>
                                                            <td class="font-size-15" width="50%">
                                                                From 501 to 1000 Transactions Per Month
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="font-size-15" width="50%">
                                                                Fees Per Year / Month
                                                            </td>
                                                            <td class="font-size-15" width="50%">
                                                                AED 1500 per month
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table><br />
                                                <table class="table table-bordered table-striped">
                                                    <thead class="bg-blue">
                                                        <tr>
                                                            <th class="text-white font-size-16" style={{ backgroundColor: "#aa8038" }}>
                                                                Sr. No.
                                                            </th>
                                                            <th class="text-white font-size-16" style={{ backgroundColor: "#aa8038" }}>
                                                                Particulars
                                                            </th>
                                                            <th class="text-white font-size-16" style={{ backgroundColor: "#aa8038" }}>
                                                                Periodicity
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td class="description font-size-16">1</td>
                                                            <td class="description font-size-16">Posting of Revenue Invoice includes Output VAT</td>
                                                            <td class="description font-size-16">Monthly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">2</td>
                                                            <td class="description font-size-16">Posting of Purchase includes Input VAT</td>
                                                            <td class="description font-size-16">Monthly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">3</td>
                                                            <td class="description font-size-16">Posting of Payments:- Cash, Cheques, Bank Transfer</td>
                                                            <td class="description font-size-16">Monthly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">4</td>
                                                            <td class="description font-size-16">Petty Cash Accounting</td>
                                                            <td class="description font-size-16">Monthly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">5</td>
                                                            <td class="description font-size-16">Posting of Journal Entries</td>
                                                            <td class="description font-size-16">Monthly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">6</td>
                                                            <td class="description font-size-16">Administration and General Expenses</td>
                                                            <td class="description font-size-16">Monthly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">7</td>
                                                            <td class="description font-size-16">Payroll Accounting</td>
                                                            <td class="description font-size-16">Monthly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">8</td>
                                                            <td class="description font-size-16">Fixed Assets &amp; depreciation</td>
                                                            <td class="description font-size-16">Monthly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">9</td>
                                                            <td class="description font-size-16">Accounts Receivable Reporting</td>
                                                            <td class="description font-size-16">Monthly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">10</td>
                                                            <td class="description font-size-16">Accounts Payable Reporting</td>
                                                            <td class="description font-size-16">Monthly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">11</td>
                                                            <td class="description font-size-16">Bank Reconciliation</td>
                                                            <td class="description font-size-16">Monthly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">12</td>
                                                            <td class="description font-size-16">Trial Balance Reporting</td>
                                                            <td class="description font-size-16">Monthly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">13</td>
                                                            <td class="description font-size-16">Profit and Loss Account</td>
                                                            <td class="description font-size-16">Monthly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">14</td>
                                                            <td class="description font-size-16">Balance Sheet</td>
                                                            <td class="description font-size-16">Monthly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">15</td>
                                                            <td class="description font-size-16">Reconciliation of Accounts</td>
                                                            <td class="description font-size-16">Quarterly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">16</td>
                                                            <td class="description font-size-16">VAT Return Filing</td>
                                                            <td class="description font-size-16">Quarterly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">17</td>
                                                            <td class="description font-size-16">VAT Advisory</td>
                                                            <td class="description font-size-16">Quarterly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">18</td>
                                                            <td class="description font-size-16">Cash Flow Statement</td>
                                                            <td class="description font-size-16">Annually</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey={4}>
                                        <div className='container' style={{ background: "#F8F8F8" }}>
                                            <div class="col-md-12">
                                                <p class="mt-3 font-size-15 font-weight-6 fw-bold">
                                                    Scope of Services
                                                </p>
                                                <table class="table table-hover table-responsive table-striped table-bordered">
                                                    <tbody>
                                                        <tr>
                                                            <td class="font-size-15" width="50%">
                                                                Transaction Per Month
                                                            </td>
                                                            <td class="font-size-15" width="50%">
                                                                From 1001 to 2000 Transactions Per Month
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="font-size-15" width="50%">
                                                                Fees Per Year / Month
                                                            </td>
                                                            <td class="font-size-15" width="50%">
                                                                AED 2250 per month
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table><br />
                                                <table class="table table-bordered table-striped">
                                                    <thead class="bg-blue">
                                                        <tr>
                                                            <th class="text-white font-size-16" style={{ backgroundColor: "#aa8038" }}>
                                                                Sr. No.
                                                            </th>
                                                            <th class="text-white font-size-16" style={{ backgroundColor: "#aa8038" }}>
                                                                Particulars
                                                            </th>
                                                            <th class="text-white font-size-16" style={{ backgroundColor: "#aa8038" }}>
                                                                Periodicity
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td class="description font-size-16">1</td>
                                                            <td class="description font-size-16">Posting of Revenue Invoice includes Output VAT</td>
                                                            <td class="description font-size-16">Monthly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">2</td>
                                                            <td class="description font-size-16">Posting of Purchase includes Input VAT</td>
                                                            <td class="description font-size-16">Monthly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">3</td>
                                                            <td class="description font-size-16">Posting of Payments:- Cash, Cheques, Bank Transfer</td>
                                                            <td class="description font-size-16">Monthly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">4</td>
                                                            <td class="description font-size-16">Petty Cash Accounting</td>
                                                            <td class="description font-size-16">Monthly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">5</td>
                                                            <td class="description font-size-16">Posting of Journal Entries</td>
                                                            <td class="description font-size-16">Monthly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">6</td>
                                                            <td class="description font-size-16">Administration and General Expenses</td>
                                                            <td class="description font-size-16">Monthly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">7</td>
                                                            <td class="description font-size-16">Payroll Accounting</td>
                                                            <td class="description font-size-16">Monthly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">8</td>
                                                            <td class="description font-size-16">Fixed Assets &amp; depreciation</td>
                                                            <td class="description font-size-16">Monthly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">9</td>
                                                            <td class="description font-size-16">Accounts Receivable Reporting</td>
                                                            <td class="description font-size-16">Monthly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">10</td>
                                                            <td class="description font-size-16">Accounts Payable Reporting</td>
                                                            <td class="description font-size-16">Monthly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">11</td>
                                                            <td class="description font-size-16">Bank Reconciliation</td>
                                                            <td class="description font-size-16">Monthly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">12</td>
                                                            <td class="description font-size-16">Trial Balance Reporting</td>
                                                            <td class="description font-size-16">Monthly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">13</td>
                                                            <td class="description font-size-16">Profit and Loss Account</td>
                                                            <td class="description font-size-16">Monthly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">14</td>
                                                            <td class="description font-size-16">Balance Sheet</td>
                                                            <td class="description font-size-16">Monthly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">15</td>
                                                            <td class="description font-size-16">Reconciliation of Accounts</td>
                                                            <td class="description font-size-16">Quarterly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">16</td>
                                                            <td class="description font-size-16">VAT Return Filing</td>
                                                            <td class="description font-size-16">Quarterly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">17</td>
                                                            <td class="description font-size-16">VAT Advisory</td>
                                                            <td class="description font-size-16">Quarterly</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="description font-size-16">18</td>
                                                            <td class="description font-size-16">Cash Flow Statement</td>
                                                            <td class="description font-size-16">Annually</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </Tab.Pane>

                                </Tab.Content>
                            </Row>
                        </Tab.Container>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AccountingBookkeeping;