import React from 'react';
import Faq from "react-faq-component";
import { Helmet } from "react-helmet";

// ================================|| VATCompliance ||================================ //

const VATCompliance = () => {

    const data = {
        title: "",
        rows: [
            {
                title: "1) Registration",
                content: `Companies meeting the minimum turnover criteria will be required to get it registered for VAT. A Unique Tax Payer Registration Number will be provided to all the companies registered under VAT.<br/><br/>
                <table class="table table-hover table-responsive table-striped table-bordered">
                    <thead>
                        <tr>
                            <th class="text-white font-size-16 font-weight-6 bg-blue p-2" style="background-color: #aa8038" width="50%">
                                Type of Registration
                            </th>
                            <th class="text-white font-size-16 font-weight-6 bg-blue p-2" style="background-color: #aa8038" width="50%">
                                Turnover in Last 12 months
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="font-size-15">
                                Compulsory
                            </td>
                            <td class="font-size-15">
                                AED 375,000 and above
                            </td>
                        </tr>
                        <tr>
                            <td class="font-size-15">
                                Voluntary
                            </td>
                            <td class="font-size-15">
                                Between AED 375,000 and 187,500
                            </td>
                        </tr>
                        <tr>
                            <td class="font-size-15">
                                Not Required
                            </td>
                            <td class="font-size-15">
                                Less than AED 187,500
                            </td>
                        </tr>
                    </tbody>
                </table><br/>
                Excludes the value of Exempt invoices.<br/><br/>
                A group of companies may register under a single VAT registration entity if they meet the eligibility requirements. Such a group is considered as a single taxable entity and allowed to balance the tax credit of member companies. Trade within group companies also does not attract tax.<br/><br/>
                Taxable supplies are all supplies of goods and services made by a person that are not exempt supplies (mentioned below). Taxable supplies comprise of standard-rated supplies and zero-rated supplies.<br/><br/>
                <h5 className='pt-5' style="color: #aa8038">Zero-Rated Supplies</h5>
                Under the Article 45 of the Federal Decree-Law no. (8) of 2017 on Value Added Tax, the following supplies are subject to VAT at zero-rate (0%):
                <ul>
                    <li style="list-style: unset">
                        Export of Goods and Services to Outside the GCC
                    </li>
                    <li style="list-style: unset">
                        International transportation services for Passengers and Goods
                    </li>
                    <li style="list-style: unset">
                        Supply of certain Sea, Air and Land means of transport
                    </li>
                    <li style="list-style: unset">
                        Buildings Specifically Designed to be Used by Charities
                    </li>
                    <li style="list-style: unset">
                        Precious metals
                    </li>
                    <li style="list-style: unset">
                        Converted Residential Building
                    </li>
                    <li style="list-style: unset">
                        Education Services, and Supplies Relevant Goods and Services
                    </li>
                    <li style="list-style: unset">
                        Healthcare Services, and Supplies Relevant Goods and Services
                    </li>
                </ul>
                <b>DO: Since the supplies as mentioned above are still taxable, you need to secure a record of these zero-rated supplies in your VAT account, which will need to reflect on your tax return.</b><br/><br/>
                <h5 className='pt-5' style="color: #aa8038">Exempt Supplies</h5>
                The following supplies under Article 46 of the Federal Decree-Law no. (8) of 2017 on Value Added Tax are non-taxable; hence, they are qualified for VAT exemptions:
                <ul>
                    <li style="list-style: unset">
                        The supply of some financial services (clarified in VAT legislation)
                    </li>
                    <li style="list-style: unset">
                        Local Passenger Transport
                    </li>
                    <li style="list-style: unset">
                        Bare Land
                    </li>
                    <li style="list-style: unset">
                        Residential Properties
                    </li>
                </ul>
                <b>DON'T: You do not have to register for VAT if all your supplies are exempted.</b>`,
            },
            {
                title: "2) VAT Invoice",
                content: `VAT Invoice is an issued document by a VAT-registered supplier, which serve as a proof and a record that VAT is paid for all taxable goods and services. It contains the following invoicing details as prescribed by the UAE VAT law:
                <ul>
                    <li style="list-style: unset">
                        Company Name
                    </li>
                    <li style="list-style: unset">
                        Company Address
                    </li>
                    <li style="list-style: unset">
                        Tax Registration Number
                    </li>
                    <li style="list-style: unset">
                        Sequential/Unique Numbering
                    </li>
                    <li style="list-style: unset">
                        Issue Date
                    </li>
                    <li style="list-style: unset">
                        Description of Goods and Services
                    </li>
                    <li style="list-style: unset">
                        VAT Rate
                    </li>
                    <li style="list-style: unset">
                        VAT Amount (AED)
                    </li>
                    <li style="list-style: unset">
                        Unit Price of Good and Services
                    </li>
                    <li style="list-style: unset">
                        Customer Name and Address
                    </li>
                </ul>
                <b>DO: You must issue a VAT Invoice within 14 days once goods and services are supplied.</b>`,
            },
            {
                title: "3) VAT Return",
                content: `VAT Return is the document submitted to and as required by the FTA before the due date, which is filed on a quarterly and monthly basis. VAT Return contains the output tax due and input tax recoverable along with other information needed to complete the VAT Return format (VAT201- VAT Return). The due date of filing the VAT Return is the 28th day after the tax period ends. 
                Payment should also be received by the FTA on or before the 28th day (FTA will not be held responsible if for any technical reason, they do not receive the payment even if it was affected from your end on time).<br/><br/>
                e.g. For instance, if your company has VAT Return for January to March quarter, you have to file the document before the 28th of April of the same year.<br/><br/>
                <b>DON'T: Do not skip the process of filing your VAT Return as this will automatically result in incurring penalties imposed by the Federal Tax Authority (FTA).</b>`,
            },
            {
                title: "4) Financial Records",
                content: `Under UAE VAT Law, VAT registered businesses are mandated to maintain several books of accounts and records for at least five years, which includes the following:<br/><br/>
                <ul>
                    <li style="list-style: unset">
                        Records of all supplies and Imports of Goods and Services
                    </li>
                    <li style="list-style: unset">
                        All Tax Invoices & Tax Credit Notes issued and received
                    </li>
                    <li style="list-style: unset">
                        Records of Goods and Services disposed
                    </li>
                    <li style="list-style: unset">
                        Records of adjustments or corrections made to accounts or Tax Invoices
                    </li>
                </ul>
                <b>DO: Always keep well-maintained financial records of your business as this will be required and inspected by the FTA.</b>`,
            },
            {
                title: "5) Audit Of Records",
                content: `Government may directly or through its appointed agents demand to audit the tax returns of the companies. Companies are required to maintain physical records of all purchases and supplies as also maintain complete registration details of suppliers and customers.`,
            },
        ],
    };

    const styles = {
        titleTextColor: "black",
        rowTitleColor: "#aa8038"
    };

    const config = {
        rowTitleColor: "#aa8038",
    };

    return (
        <>
            <Helmet>
                <title>VAT Compliance Services in Dubai and the UAE</title>
            </Helmet>
            <div class="chairman-message">
                <div class="chairman-img">
                    <div class="pos-message-box">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-7 aos-init" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2 className='alignment'>VAT Compliance</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img class="d-lg-block d-none" style={{ width: "-webkit-fill-available" }} src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001997/images/Contact-us.png" alt="vat-compliance-banner" title="Vat Compliance Banner" />
            </div>
            <div className="container">
                <div className="row pt-5 pb-5">
                    <div className='col-md-7'>
                        <h1><span style={{ color: "#aa8038" }}>VAT Compliance Services in UAE</span></h1>
                        <p>
                            Esteem Added Tax or VAT is a duty on the utilization of labor and products demanded at each retail location.
                            VAT is a type of circuitous expense and is exacted by more than 180 nations all over the planet. The end buyer eventually bears the expense.
                            Organizations gather and record the duty of the public authority. Esteem Added Tax (VAT) was presented in the UAE on 1 January 2018.
                            The pace of VAT is 5%. The enrolled organizations will be expected to keep up with the records for a predetermined period and present the records for examination.
                            This period will be at first set for quite a long time in UAE.
                        </p>
                    </div>
                    <div className='col-md-5'>
                        <img class="card-img-top" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002083/images/vat-compliance.jpg" alt="vat-compliance" title="Vat Compliance" />
                    </div>
                </div>
                <Faq
                    data={data}
                    styles={styles}
                    config={config}
                />
            </div>
            <div className="three-coloum-content pt-4" style={{ backgroundColor: "#fbf2de" }}>
                <div className="container">
                    <div className="row">
                        <p>Below are some of the Violations and the related Penalties.</p>
                        <h5>Violations Related to VAT Registration and Financial Records:</h5>
                        <p>
                            <ul>
                                <li style={{ listStyle: "unset" }}>
                                    Violation: Failure to keep the required records specified by the tax procedures law and the tax law. Penalty: AED10,000 for the first time. AED50,000 for each repeat violation.
                                </li>
                                <li style={{ listStyle: "unset" }}>
                                    Violation: Failure to submit the required records in Arabic when requested by the Authority Penalty: AED20,000′
                                </li>
                                <li style={{ listStyle: "unset" }}>
                                    Violation: Failure to inform the Authority of an amendment to tax records that needs to be submitted. Penalty: AED5,000 for the first time. AED15,000 in case of repetition.
                                </li>
                                <li style={{ listStyle: "unset" }}>
                                    Violation: Failure to submit a registration application within the timeframe specified by the tax law. Penalty: AED20,000
                                </li>
                                <li style={{ listStyle: "unset" }}>
                                    Violation: Failure to submit for de-registration within the timeframe specified by the tax law. Penalty: AED 10,000
                                </li>
                            </ul>
                        </p>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row pt-4">
                    <h3>Violations Related To VAT:</h3>
                    <p>
                        <ul>
                            <li style={{ listStyle: "unset" }}>
                                Failure by the taxable person/business to display prices inclusive of tax. Penalty: AED15,000
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Failure by the taxable person/business to notify the Authority of applying tax based on the margin. Penalty: AED2,500
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Failure to comply with conditions and procedures related to the transfer of goods in designated zones. Penalty: The penalty will be the higher of AED 50,000 or 50% of the tax if any, unpaid on the goods as the result of the violation.
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Failure by the taxable person/business to issue a tax invoice or alternative document when making any supply. Penalty: AED5,000 for each missing tax invoice or alternative document.
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Failure by the taxable person/business to issue a tax credit note or alternative document. Penalty: AED5,000 for each missing tax credit note or alternative document.
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Failure by the taxable person to comply with the conditions and procedures regarding the issuance of electronic tax invoices and electronic tax credit notes. enalty: AED5,000 for each incorrect document.
                            </li>
                        </ul>
                    </p>
                </div>
            </div>
        </>
    );
};

export default VATCompliance;