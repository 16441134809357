import React, { useState } from 'react';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { Helmet } from "react-helmet";
import API from '../../middleware/API';

// ================================|| Career ||================================ //

const Career = () => {

    const [FirstName, setFirstName] = useState("");
    const [LastName, setLastName] = useState("");
    const [file, setFile] = useState("");
    const [Email, setEmail] = useState("");
    const [MobileNo, setMobileNo] = useState();
    const [Qualification, setQualification] = useState("");
    const [Experience, setExperience] = useState("");
    const [Country, setCountry] = useState("");
    const [City, setCity] = useState("");
    const [message, setMessage] = useState("");
    const [pdfErrorMessage, setPDFErrorMessage] = useState("");
    const [EmailError, setEmailError] = useState("");
    const [Address, setAddress] = useState("");

    let submitHandler = async (e) => {
        e.preventDefault();
        try {
            let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            if (!re.test(Email)) {
                setPDFErrorMessage('');
                setEmailError("please enter valid email address");
            }
            else {
                var res = /(?:\.([^.]+))?$/;
                var ext = res.exec(file);
                if (ext[0] === ".pdf") {
                    const url = "https://api.cloudinary.com/v1_1/ddnekoeii/auto/upload";
                    const files = document.querySelector("[type=file]").files;
                    let file = files[0];
                    const formData = new FormData();

                    formData.append("file", file);
                    formData.append("upload_preset", "ml_default");
                    formData.append("api_key", "881256775413327");

                    const fileData = await fetch(url, {
                        method: "POST",
                        body: formData
                    })
                    const fileJson = await fileData.json();
                    var SendCareerDetailEmailAPI = API.sendCareerDetailEmail;
                    let result = await fetch(SendCareerDetailEmailAPI, {
                        method: "POST",
                        headers: {
                            'Accept': 'application/json',
                            'Content-type': 'application/json',
                        },
                        json: true,
                        body: JSON.stringify({
                            firstName: FirstName,
                            lastName: LastName,
                            mobileNo: MobileNo,
                            email: Email,
                            qualification: Qualification,
                            experience: Experience,
                            country: Country,
                            city: City,
                            address: Address,
                            filePath: fileJson.secure_url,
                        }),
                    });
                    setFirstName('');
                    setLastName('');
                    setEmail('');
                    setMobileNo('');
                    setQualification('');
                    setExperience('');
                    setCountry('');
                    setCity('');
                    setFile('');
                    setAddress('');
                    setEmailError('');
                    setPDFErrorMessage('');
                    setMessage("Your response has been submitted sucessfully.");
                }
                else {
                    setEmailError("");                    
                    setPDFErrorMessage("Please upload only Pdf Format");
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <Helmet>
				<title>Devotion Careers: Grow with Us in Dubai</title>
            </Helmet>
            <div class="chairman-message">
                <div class="chairman-img">
                    <div class="pos-message-box">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-7 aos-init" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h1 className='alignment-contact'>Career</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img class="d-lg-block d-none" style={{ width: "-webkit-fill-available" }} src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001997/images/Contact-us.png" alt="career-banner" title="Career Banner" />
            </div>
            <div class="container three-coloum-content mt-5 mb-5">
                <div class="container">
                    <div class="container row">
                        <div class="container col-md-12">
                            <div class="container">
                                <h2 style={{ color: "#aa8038" }}>Apply Now</h2>
                                <form class="mt-4 mb-5" id="inquiry-form" onSubmit={submitHandler}>
                                    <div class="row">
                                        <div class="col-md-4 form-group py-2">
                                            <input type="text" class="form-control bg-light" name="fname" id="fname" placeholder="First Name *" value={FirstName} onChange={(e) => setFirstName(e.target.value)} required />
                                        </div>
                                        <div class="col-md-4 form-group py-2">
                                            <input type="text" class="form-control bg-light" name="lname" id="lname" placeholder="Last Name *" value={LastName} onChange={(e) => setLastName(e.target.value)} required />
                                        </div>
                                        <div class="col-md-4 form-group py-2">
                                            <input type="email" class="form-control bg-light" name="email" id="email" placeholder="Email *" value={Email} onChange={(e) => setEmail(e.target.value)} required />
                                            <div class="error-message">{EmailError ? <p>{EmailError}</p> : null}</div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-4 form-group py-2">
                                            <PhoneInput
                                                defaultCountry="AE"
                                                placeholder="Phone *"
                                                value={MobileNo}
                                                onChange={setMobileNo} required />
                                        </div>
                                        <div class="col-md-4 form-group py-2">
                                            <input type="text" class="form-control bg-light" name="qualification" id="qualification" placeholder="Qualification *" value={Qualification} onChange={(e) => setQualification(e.target.value)} required />
                                        </div>
                                        <div class="col-md-4 form-group py-2">
                                            <input type="text" class="form-control bg-light" name="experience" id="experience" placeholder="Experience *" value={Experience} onChange={(e) => setExperience(e.target.value)} required />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 form-group py-2">
                                            <input type="text" class="form-control bg-light" name="country" id="country" placeholder="Country *" value={Country} onChange={(e) => setCountry(e.target.value)} required />
                                        </div>
                                        <div class="col-md-6 form-group py-2">
                                            <input type="text" class="form-control bg-light" name="city" id="city" placeholder="City *" value={City} onChange={(e) => setCity(e.target.value)} required />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 form-group py-2">
                                            <textarea class="" name="message" id="message" placeholder="Address" value={Address} onChange={(e) => setAddress(e.target.value)}></textarea>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 form-group py-2">
                                            <p style={{ color: "#6c757d" }}>Please upload file your Resume: </p>
                                        </div>
                                        <div class="col-md-6 form-group py-2">
                                            <input type="file" accept='application/pdf' name="file" id="files" onChange={(e) => setFile(e.target.value)} value={file} required />
                                            <div class="error-message">{pdfErrorMessage ? <p>{pdfErrorMessage}</p> : null}</div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-3 col-md-6 col-sm-6 py-1 d-block">
                                            <input type="submit" class="cl-button -primary -small py-2" value="Send Application" style={{ background: "#aa8038" }} />
                                        </div>
                                    </div>
                                    <div class="message" style={{ textAlign: "left" }}>{message ? <p>{message}</p> : null}</div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Career;