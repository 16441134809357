import React from 'react';
import Faq from "react-faq-component";
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";

// ================================|| DubaiMainland ||================================ //

const DubaiMainland = () => {

    const data = {
        title: "",
        rows: [
            {
                title: "+ Select Business Activity",
                content: `Select the business activity types from Trading/commercial, Consultancy, Professional or industrial. You can choose several activities within a one group.`,
            },
            {
                title: "+ Select Trade Name",
                content: `It is advisable to suggest three tradename options in order to preferences and name Should be clear. Trade Names that cannot be reserved: Name of country, Name of Continent, Famous regions and Towns, Color (blue, red etc.). Foreign words and short words will be charged extra fee of Dh2000 per year. At this stage it is required to submit all the documents of all partners (passport Visa / EID / NOC of UAE / Resident / Residential address).`,
            },
            {
                title: "+ Apply for Initial Approval in DED",
                content: `Once the trade name confirms then the company can be formed and the company name is approved.`,
            },
            {
                title: "+ Prepare MOA & LSA agreement",
                content: `DED will prepare the Memorandum of Association and Local Service agreement based on the information provided on the above stages.`,
            },
            {
                title: "+ Business Location",
                content: `To complete the registration, process a Ejari (Lease contract) is required in main land. This refers to either business center/warehouse/office/showroom. It may be rented or a purchase.`,
            },
            {
                title: "+ Get Approvals",
                content: `Some business activities require an additional / external approval from the other Government authorities such as a Civil Defense, Police, SIRA, MOH etc.`,
            },
            {
                title: "+ Map Structure",
                content: `After the LSA and MOA are signed, all the payments and approval are made. License will be issued.`,
            },
        ],
    };

    const styles = {
        titleTextColor: "black",
    };

    const config = {
        rowTitleColor: "#aa8038",
    };

    return (
        <>
            <Helmet>
                <title>Business Setup in Dubai Mainland | Setup Company in Dubai Mainland</title>
            </Helmet>
            <div class="chairman-message">
                <div class="chairman-img">
                    <div class="pos-message-box">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-7 aos-init" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2 className='alignment'>Dubai Mainland</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img class="d-lg-block d-none" style={{ width: "-webkit-fill-available" }} src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001997/images/Contact-us.png" alt="dubai-banner" title="Dubai Banner" />
            </div>
            <div className="container">
                <div className="row pt-5 pb-5">
                    <h1><span style={{ color: "#aa8038" }}>Business Setup in Dubai Mainland</span></h1>
                    <div className='col-12'>
                        <p>
                            A Mainland association is an association signed up for one of the seven emirates of the UAE Department of Economic Development (DED).
                            This plans that expecting that you choose to coordinate business in Dubai Mainland, you ought to enrol a business component with the DED, which will then, give you a license to work to start continuing with work in Dubai.
                            The focal region offers easier induction to clients, organization, versatility, and incredible groundwork.
                        </p>
                        <p>
                            Dubai Mainland associations can practice any Professional, Commercial, present-day, trading, or movement business development. A couple of master practices are dismissed by DED's business rules and others require the support of experts that deal with these activities.
                        </p>
                        <p>
                            <b>100% Foreign Ownership Is Available for More Than 1,000 Commercial And Industrial Activities.</b><br />
                            <b>Portions Of Foreign Companies Do Not Require an Emirati Agent.</b>
                        </p>
                        <p>
                            <Link to="/dubai-mainland"><u style={{ color: "#aa8038" }}>Click Here To View A List Of Activities Open For 100% Foreign Ownership</u></Link>
                        </p>
                    </div>
                </div>
            </div>
            <div className="three-coloum-content pt-4" style={{ backgroundColor: "#EDEDED" }}>
                <div className="container">
                    <div className="row pt-3 pb-3">
                        <h3 class="text-center pb-4" style={{ color: "#aa8038" }}>Different Types Of Licenses Issued By Mainland</h3>
                        <div className='col-md-7'>
                            <h4 style={{ color: "#aa8038" }}>Commercial License:</h4>
                            <p>
                                Where the purpose of the entity is to trade within the whole region. Kindly pay attention that 100% foreign ownership of this company type in the UAE is permitted.
                            </p>
                            <ul>
                                <li style={{ listStyle: "unset" }}>
                                    Now foreign investors are allowed to hold 100% equity ownership in UAE companies.
                                </li>
                                <li style={{ listStyle: "unset" }}>
                                    The minimum recommended share capital is AED 300,000.
                                </li>
                                <li style={{ listStyle: "unset" }}>
                                    Multiple business-related activities under 1 trade licence can be provided.
                                </li>
                                <li style={{ listStyle: "unset" }}>
                                    No corporate tax and no income tax.
                                </li>
                                <li style={{ listStyle: "unset" }}>
                                    Location flexibility and zero trade restrictions.
                                </li>
                            </ul>
                        </div>
                        <div className='col-md-5'>
                            <img class="card-img-top" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002014/images/dubai-mainland.jpg" alt="dubai" title="Dubai" />
                        </div>
                    </div>
                </div>
            </div >
            <div class="container">
                <div class="row pt-5 pb-5">
                    <div class="col-md-12">
                        <h2 style={{ color: "#aa8038" }}>Professional License:</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-5">
                        <h5 style={{ color: "#aa8038" }}>
                            1. Sole Establishment
                        </h5>
                        <p class="text-break text-wrap description text-justify" style={{ lineHeight: "30px" }}>
                            This type of entity is applicable for professional, service activities and consultancy.
                            Setting up such type of a company is the best way a foreign investor can get 100% ownership in
                            a UAE company registered outside Free Zones.
                        </p>
                        <p class="text-justify">
                        </p><ul class="list-unstyled list-icon mb-5">
                            <li class="text-justify mb-3">
                                <div class="d-flex justify-content-start p-2" style={{ backgroundColor: "#EDEDED" }}>
                                    <div class="ml-2">
                                        <span class="font-size-15 font-weight-4 text-break">No Corporate tax and No Income Tax.</span>
                                    </div>
                                </div>
                            </li>
                            <li class="text-justify mb-3">
                                <div class="d-flex justify-content-start p-2" style={{ backgroundColor: "#EDEDED" }}>
                                    <div class="ml-2">
                                        <span class="font-size-15 font-weight-4 text-break">No requirement for minimum capital.</span>
                                    </div>
                                </div>
                            </li>
                            <li class="text-justify mb-3">
                                <div class="d-flex justify-content-start p-2" style={{ backgroundColor: "#EDEDED" }}>
                                    <div class="ml-2">
                                        <span class="font-size-15 font-weight-4 text-break">The UAE National will act as an agent with 0% ownership.</span>
                                    </div>
                                </div>
                            </li>
                            <li class="text-justify mb-3">
                                <div class="d-flex justify-content-start p-2" style={{ backgroundColor: "#EDEDED" }}>
                                    <div class="ml-2">
                                        <span class="font-size-15 font-weight-4 text-break">One shareholder only.</span>
                                    </div>
                                </div>
                            </li>
                            <li class="text-justify mb-3">
                                <div class="d-flex justify-content-start p-2" style={{ backgroundColor: "#EDEDED" }}>
                                    <div class="ml-2">
                                        <span class="font-size-15 font-weight-4 text-break">No corporate taxes, no income taxes.</span>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <p></p>
                    </div>
                    <div class="col-md-7">
                        <h5 style={{ color: "#aa8038" }}>
                            2. Civil Company
                        </h5>
                        <p class="text-break text-wrap description text-justify" style={{ lineHeight: "30px" }}>
                            Civil company is a company owned by 100% foreign
                            ownership with up to 3 shareholders and local service agent.
                            This is applicable for professional and service activities/license.
                        </p>
                        <p class="text-justify">
                        </p><ul class="list-unstyled list-icon mb-5">
                            <li class="text-justify mb-3">
                                <div class="d-flex justify-content-start p-2" style={{ backgroundColor: "#EDEDED" }}>
                                    <div class="ml-2">
                                        <span class="font-size-15 font-weight-4 text-break">
                                            Local service agent owns 0% of business shares and profit,
                                            whereas foreign partner or a company owns 100% of business shares.
                                        </span>
                                    </div>
                                </div>
                            </li>
                            <li class="text-justify mb-3">
                                <div class="d-flex justify-content-start p-2" style={{ backgroundColor: "#EDEDED" }}>
                                    <div class="ml-2">
                                        <span class="font-size-15 font-weight-4 text-break">
                                            Local service agent main task is to perform a role of a signatory for fulfillment of all government related tasks.
                                        </span>
                                    </div>
                                </div>
                            </li>
                            <li class="text-justify mb-3">
                                <div class="d-flex justify-content-start p-2" style={{ backgroundColor: "#EDEDED" }}>
                                    <div class="ml-2">
                                        <span class="font-size-15 font-weight-4 text-break">
                                            Local service agent has no administrative powers and is only entitled to annual payment for government services.
                                        </span>
                                    </div>
                                </div>
                            </li>
                            <li class="text-justify mb-3">
                                <div class="d-flex justify-content-start p-2" style={{ backgroundColor: "#EDEDED" }}>
                                    <div class="ml-2">
                                        <span class="font-size-15 font-weight-4 text-break">
                                            Local service agent can be a professional, a UAE Government employee or a company owned by a UAE National.
                                        </span>
                                    </div>
                                </div>
                            </li>
                            <li class="text-justify mb-3">
                                <div class="d-flex justify-content-start p-2" style={{ backgroundColor: "#EDEDED" }}>
                                    <div class="ml-2">
                                        <span class="font-size-15 font-weight-4 text-break">
                                            For certain activities professional qualification of local service agent is required.
                                        </span>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="three-coloum-content pt-4" style={{ backgroundColor: "#aa8038" }}>
                <div className="container">
                    <div className="row pt-3 pb-3">
                        <h3 class="text-white pb-4">Industrial License:</h3>
                        <p class="text-white">
                            Industrial licenses are required for establishing industrial or manufacturing activity in the UAE. With the industrial license, businessmen can assemble and process goods using raw materials that are local or imported.
                            Economy Department states to be the main authority to issue industrial license. There might be necessary additional approvals from specific departments, ministries or Municipality. Which depends on the activity.
                            Health Ministry is an authority that can provide authorization of medical business and institutions within the pharmaceutical segment. Such as operating in financial segment, require an approval from central bank of UAE.
                        </p>
                    </div>
                    <div class="row pb-5">
                        <div class="col-lg-6 col-md-6 col-sm-6 float-left mt-2">
                            <div class="pl-4 pr-4 pb-1 pt-1" style={{ background: "#fff", borderRadius: "25px" }}>
                                <h5 class="text-center">Safe and Legal revenue transactions.</h5>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 float-left mt-2">
                            <div class="pl-4 pr-4 pb-1 pt-1" style={{ background: "#fff", borderRadius: "25px" }}>
                                <h5 class="text-center">Modern Industrial & Manufacturing Infrastructure.</h5>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 float-left mt-2">
                            <div class="pl-4 pr-4 pb-1 pt-1" style={{ background: "#fff", borderRadius: "25px" }}>
                                <h5 class="text-center">Generate more business opportunities.</h5>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 float-left mt-2">
                            <div class="pl-4 pr-4 pb-1 pt-1" style={{ background: "#fff", borderRadius: "25px" }}>
                                <h5 class="text-center">Renew Industrial trade license at affordable cost.</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <div class="container pt-5 pb-5">
                <h3 class="pb-4" style={{ color: "#aa8038" }}>How To Register Dubai Mainland Company In UAE?</h3>
                <Faq
                    data={data}
                    styles={styles}
                    config={config}
                />
            </div>
            <div className="three-coloum-content pt-4" style={{ backgroundColor: "#EDEDED" }}>
                <div className="container">
                    <div className="row pt-3 pb-3">
                        <h3 class="text-center pb-4" style={{ color: "#aa8038" }}>Instant License In Dubai</h3>
                        <p>
                            You can get a commercial and professional license in Dubai in just 5 minutes. Introduced with aim to boost the economy of Dubai , the instance license makes the easiest and fastest to the entrepreneurs to obtain trade license to start their business in Dubai.
                        </p>
                        <h3 class="text-center pb-4" style={{ color: "#aa8038" }}>Benefits of Instant License</h3>
                        <div className='col-md-6'>
                            <ul>
                                <li style={{ listStyle: "unset" }}>
                                    Issue in 5 to 10 minutes of time
                                </li>
                                <li style={{ listStyle: "unset" }}>
                                    Same day trade license
                                </li>
                            </ul>
                        </div>
                        <div className='col-md-5'>
                            <ul>
                                <li style={{ listStyle: "unset" }}>
                                    Trade name is not required for first year
                                </li>
                                <li style={{ listStyle: "unset" }}>
                                    Not required physical office for first year
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div >
            <div class="container pt-5 pb-5">
                <h3 class="" style={{ color: "#aa8038" }}>Establishment By GCC Citizen</h3>
                <h6 class="" style={{ color: "#aa8038" }}>Department of economic development (DED) License</h6>
                <p>
                    The states of the Gulf Cooperation Council (the UAE, Saudi Arabia, Sultanate of Oman, Qatar, Kuwait and Bahrain) signed the United Economic Agreement in Riyadh on 7th June 1981, with a view to coordinate and unify economic, financial, monetary, commercial and industrial legislations and UAE endorsed this agreement in 1982.
                </p>
                <p>
                    It is conditional as per the Federal Law No. 2 of 1989 concerning permitting the GCC citizen to conduct a business operation in UAE that the investor should be a natural person residing in UAE and practice the required activity by himself and have a license to practice the activity in his country of origin. Procedure overview of LLC establishment.
                </p>
                <p class="fw-bold">Devotion Support</p>
                <ul>
                    <li style={{ listStyle: "unset" }}>
                        Referral to commercial Real Estate Broker Agency.
                    </li>
                    <li style={{ listStyle: "unset" }}>
                        Introduction to the Web Developing & Advertising Agency.
                    </li>
                    <li style={{ listStyle: "unset" }}>
                        Company bank account Set-up advice.
                    </li>
                </ul>
            </div>
        </>
    );
};

export default DubaiMainland;