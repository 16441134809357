import { useState, useEffect } from "react";
import { Link, useSearchParams } from 'react-router-dom';
import { Helmet } from "react-helmet";
import moment from 'moment';
import API from '../../../middleware/API';

// ================================|| RequestAQuote ||================================ //

function RequestAQuote() {
    const [params] = useSearchParams();
    const [RequestAQuotedata, setRequestAQuoteData] = useState();

    useEffect(() => {
        getRequestAQuoteDetailById();
    }, []);

    const getRequestAQuoteDetailById = async () => {
        try {
            var GetRequestAQuoteDetailByIdAPI = API.getRequestAQuoteDetailById;
            GetRequestAQuoteDetailByIdAPI = GetRequestAQuoteDetailByIdAPI + "?id=" + params.get("Id");
            const response = await fetch(GetRequestAQuoteDetailByIdAPI);
            const jsonData = await response.json();
            setRequestAQuoteData(jsonData.recordsets[0]);
        }
        catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <Helmet>
                <title>Demo Project</title>
            </Helmet>
            <div className="container-fluid">
                <div className="container d-flex pt-5">
                    <div className="col">
                        <h3 className="card-header px-3 pb-3">Request A Quote Detail</h3>
                    </div>
                    <div className="col" style={{ textAlign: "end" }}>
                        <Link to='/admin/request-a-quotes' className="btn -primary text-white" style={{ background: "#aa8038" }}>Back to List</Link>
                    </div>
                </div>
                <div className="container mx-auto ">
                    <div className="px-3 pb-3">
                        <div className="table-responsive text-nowrap shadow p-3 mb-5 bg-body rounded">
                            <div className="container">
                                <div className="col-md-8 mx-auto">
                                    <div className="border rounded-3 p-2">
                                        <table className="row">
                                            <div className="col-md-3">
                                                <thead>
                                                    <tr>
                                                        <div className="col pb-2 fs-6 fw-bold">Index :- </div>
                                                        <div className="col pb-2 fs-6 fw-bold">Name :- </div>
                                                        <div className="col pb-2 fs-6 fw-bold">Email :- </div>
                                                        <div className="col pb-2 fs-6 fw-bold">Mobile No :- </div>
                                                        <div className="col pb-2 fs-6 fw-bold">Nationality :- </div>
                                                        <b>Where Do You Want To Set Up Your Business?</b>
                                                        <div className="col pb-2 fs-6 fw-bold">Setup a Business In :- </div>
                                                        <div className="col pb-2 fs-6 fw-bold">Licensing Authority :- </div>
                                                        <div className="col pb-2 fs-6 fw-bold">Number Of Share Holders :- </div>
                                                        <div className="col pb-2 fs-6 fw-bold">Legal Status For The New Set Up :- </div>
                                                        <div className="col pb-2 fs-6 fw-bold">Type Of License :- </div>
                                                        <div className="col pb-2 fs-6 fw-bold">Business Activity For The New Setup :- </div>
                                                        <b>Business Activity for the new setup</b>
                                                        <div className="col pb-2 fs-6 fw-bold">Brief Description Of The New Business Activity :- </div>
                                                        <div className="col pb-2 fs-6 fw-bold">Office Space Required :- </div>
                                                        <div className="col pb-2 fs-6 fw-bold">Warehouse Space Required :- </div>
                                                        <div className="col pb-2 fs-6 fw-bold">Investor Visa Required :- </div>
                                                        <div className="col pb-2 fs-6 fw-bold">Bank A/C Require :- </div>
                                                    </tr>
                                                </thead>
                                            </div>
                                            <div className="col-md-9">
                                                <tbody className="pb-4">
                                                    {RequestAQuotedata &&
                                                        RequestAQuotedata.map(({ Id, Name, Email, MobileNo, Nationality, BusinessIn, Service, NoOfShareHolders, LegalStatus, TypeOfLicense, LicenseValue, Description, OfficeSpace, OfficeSpaceValue, Warehouse, WarehouseValue, InvestorVisa, InvestorVisaValue, Bank }) => (
                                                            <tr key={Id}>
                                                                <div className='col table-data pb-2'>{Id}</div>
                                                                <div className='col table-data pb-2'>{Name}</div>
                                                                <div className='col table-data pb-2'><a className="" href='mailto:{Email}' style={{ cursor: "pointer" }}>{Email}</a></div>
                                                                <div className='col table-data pb-2'>{MobileNo}</div>
                                                                <div className='col table-data pb-2'>{Nationality}</div>
                                                                <b className="text-white">B</b>
                                                                <div className='col table-data pb-2'>{BusinessIn}</div>
                                                                <div className='col table-data pb-2'>{Service}</div>
                                                                <div className='col table-data pb-2'>{NoOfShareHolders}</div>
                                                                <div className='col table-data pb-2'>{LegalStatus}</div>
                                                                <div className='col table-data pb-2'>{TypeOfLicense}</div>
                                                                <div className='col table-data pb-2'>{LicenseValue}</div>
                                                                <b className="text-white">B</b>
                                                                <div className='col table-data pb-2'>{Description}</div>
                                                                <div className='col table-data pb-2'>{OfficeSpace} {OfficeSpaceValue}</div>
                                                                <div className='col table-data pb-2'>{Warehouse} {WarehouseValue}</div>
                                                                <div className='col table-data pb-2'>{InvestorVisa} {InvestorVisaValue}</div>
                                                                <div className='col table-data pb-2'>{Bank}</div>
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </div>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RequestAQuote;