import React from 'react';
import { Helmet } from "react-helmet";

// ================================|| ExciseReturnFiling ||================================ //

const ExciseReturnFiling = () => {

    return (
        <>
            <Helmet>
                <title>Excise Return Filing Services in Dubai and the UAE</title>
            </Helmet>
            <div class="chairman-message">
                <div class="chairman-img">
                    <div class="pos-message-box">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-7 aos-init" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2 className='alignment'>Excise Return Filing</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img class="d-lg-block d-none" style={{ width: "-webkit-fill-available" }} src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001997/images/Contact-us.png" alt="excise-return-filing-banner" title="Excise Return Filing Banner" />
            </div>
            <div className="container">
                <div className="row pt-5 pb-5">
                    <h1><span style={{ color: "#aa8038" }}>Excise Return Filing Services in UAE</span></h1>
                    <div className='col-12'>
                        <p>
                            Remove return is a power record to be introduced by each enrolled person to the Federal Tax Authority ("FTA") month-tomonth premise.
                            All VAT Returns should be submitted electronically using the FTA entrance before the fifteenth of the next month.
                            In case there is no arrangement explicitly period, furthermore enlisted individual requirements to record NIL return before the due date.
                        </p>
                        <h4><span style={{ color: "#aa8038" }}></span>Penalty For Noncompliance</h4>
                        <h6><span style={{ color: "#aa8038" }}></span>Penalty for Non-Submission or Non-payment of Excise</h6>
                    </div>
                    <table class="table table-hover table-responsive table-striped table-bordered">
                        <thead>
                            <tr>
                                <th class="text-white font-size-16 font-weight-6 bg-blue p-2" style={{ backgroundColor: "#aa8038" }} width="40%">
                                    Non Submission of return on time
                                </th>
                                <th class="text-white font-size-16 font-weight-6 bg-blue p-2" style={{ backgroundColor: "#aa8038" }} width="60%">
                                    Nonpayment of Excise on time
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="font-size-15">
                                    AED 1000 for the first time delay
                                </td>
                                <td class="font-size-15">
                                    2% of the unpaid tax is due immediately once the payment of Payable Tax is late
                                </td>
                            </tr>
                            <tr>
                                <td class="font-size-15">
                                    AED 2000 in case of repetative non-compliance with 24 months
                                </td>
                                <td class="font-size-15">
                                    4% of the unpaid tax is due on the seventh day following the deadline for payment, on the amount of tax which is still unpaid
                                </td>
                            </tr>
                            <tr>
                                <td class="font-size-15">
                                </td>
                                <td class="font-size-15">
                                    1% of the unpaid tax daily penalty charged on any amount that is still unpaid one calendar month following the deadline for payment with upper ceiling of (300%)
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className='col-md-7'>
                        <h3 className='pt-3' style={{ color: "#aa8038" }}>How Devotion Can Help:-</h3>
                        <p>
                            We have team of tax consultants and experts & their professional advices helps businesses in complying with the rules and regulations specified by federal tax authority.
                            Our dedicated expert team for excise return filing constantly keeps track of the time-frame specified by the FTA and files the return filing within the stipulated time, thereby, ensuring smooth compliance of the law, and related rules and regulations.
                        </p>
                    </div>
                    <div className='col-md-5'>
                        <img class="card-img-top" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002018/images/excise-compliance.jpg" alt="excise-return-filing" title="Excise Return Filing" />
                    </div>
                </div>
            </div>
            <div className="three-coloum-content pt-4" style={{ backgroundColor: "#fbf2de" }}>
                <div className="container">
                    <div className="row">
                        <h5 class="text-center">In summary, you must register for excise tax if you are:</h5>
                        <div class="row pb-5">
                            <div class="col-lg-6 col-md-6 col-sm-6 float-left mt-2">
                                <div class="pl-4 pr-4 pb-1 pt-1" style={{ background: "#aa8038", borderRadius: "25px" }}>
                                    <h5 class="text-center text-white">an importer of excise goods;</h5>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 float-left mt-2">
                                <div class="pl-4 pr-4 pb-1 pt-1" style={{ background: "#aa8038", borderRadius: "25px" }}>
                                    <h5 class="text-center text-white">a person releasing excise goods from a designated zone;</h5>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 float-left mt-2">
                                <div class="pl-4 pr-4 pb-1 pt-1" style={{ background: "#aa8038", borderRadius: "25px" }}>
                                    <h5 class="text-center text-white">a producer of excise goods;</h5>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 float-left mt-2">
                                <div class="pl-4 pr-4 pb-1 pt-1" style={{ background: "#aa8038", borderRadius: "25px" }}>
                                    <h5 class="text-center text-white">a stockpiler of excise goods.</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row pt-5 pb-5">
                    <p>
                        Excise tax declarations are needed to be filed in order to state the excise goods and pay the excise tax. Eligible entities are required to register for excise tax via the FTA website.
                        The tax period for excise tax return filing is one calendar month and the return must be filed via the FTA online portal within 15 days following the termination of the pertinent tax period.
                        A payment of the due tax must be done to FTA no later than the 15th day following the end of a calendar month.
                    </p>
                    <p>
                        It is extremely necessary for any business operating in the UAE to register for excise tax purposes and take direct steps to become compliant with the Excise Tax Law and its Executive Regulations.
                    </p>
                    <p>
                        We provide full-fledged assistance to businesses comply to Excise tax regulations perfectly.
                    </p>
                    <h5 style={{ color: "#aa8038" }}>Excise tax is the responsibility of any person engaged in :-</h5>
                    <p>
                        <ul>
                            <li style={{ listStyle: "unset" }}>
                                Ensure Proper Submission Of Excise Returns
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Assessment Of Excise Records, Returns & Reconciliation
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Give Advice On Client’s Queries And FTA Notifications
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Represent Before The FTA For Excise Tax Concerns
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Examine And Customize Source Documents
                            </li>
                        </ul>
                    </p>
                    <p>
                        Our dedicated team helps clients manage Excise Tax and adhere to the regulatory guidelines.
                        We aim to prevent compliance failures and ensure that our clients are always protected from any potential non-compliance risks.
                    </p>
                </div>
            </div>
        </>
    );
};

export default ExciseReturnFiling;