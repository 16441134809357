import React from 'react';
import { Helmet } from "react-helmet";

// ================================|| SharjahMainland ||================================ //

const SharjahMainland = () => {

    return (
        <>
            <Helmet>
                <title>Business Setup in Sharjah Mainland | Setup Company in Sharjah Mainland</title>
            </Helmet>
            <div class="chairman-message">
                <div class="chairman-img">
                    <div class="pos-message-box">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-7 aos-init" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2 className='alignment'>Sharjah Mainland</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img class="d-lg-block d-none" style={{ width: "-webkit-fill-available" }} src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001997/images/Contact-us.png" alt="sharjah-banner" title="Sharjah Banner" />
            </div>
            <div className="container">
                <div className="row pt-5 pb-5">
                    <h1><span style={{ color: "#aa8038" }}>Business Setup in Sharjah Mainland</span></h1>
                    <div className='col-12'>
                        <p>
                            Sharjah is viewed as one of the quickest developing Emirates in the UAE. Business Setup in Sharjah LLC can be one of the most outstanding choices appropriate for money managers and business people.
                            It is a permanent place to stay for a ton of organizations from various enterprises. Sharjah Mainland is an esteemed area that interfaces significant exchanging zones and is one of the most secure urban communities with an inordinate monetary stockpile.
                        </p>
                        <p>
                            DBIN helps their client for setting the Sharjah Mainland Company Setup with all the necessary procedure and documents.
                        </p>
                    </div>
                </div>
            </div>
            <div className="three-coloum-content pt-4" style={{ backgroundColor: "#fbf2de" }}>
                <div className="container">
                    <div className="row">
                        <div className='col-md-7'>
                            <h5>Reasons for Mainland business setup in Sharjah</h5>
                            <p>
                                <ul>
                                    <li style={{ listStyle: "unset" }}>
                                        The business nature in Sharjah enlightens the importance of service, trade, and manufacturing sector which encourages foreign investment
                                    </li>
                                    <li style={{ listStyle: "unset" }}>
                                        The business nature in Sharjah enlightens the importance of service, trade, and manufacturing sector which encourages foreign investment
                                    </li>
                                    <li style={{ listStyle: "unset" }}>
                                        Presence of international bank and modern communication system
                                    </li>
                                    <li style={{ listStyle: "unset" }}>
                                        The government policies are favorable towards entrepreneurs
                                    </li>
                                </ul>
                            </p>
                        </div>
                        <div className='col-md-5'>
                            <img class="card-img-top" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002071/images/sharjah-mainland.jpg" alt="sharjah" title="Sharjah" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row pt-5 pb-5">
                    <div className='col-md-7'>
                        <h3 style={{ color: "#aa8038" }}>Procedure For Mainland Company Formation In Sharjah</h3>
                        <div class="row pt-3 pb-3">
                            <div className='col-md-6'>
                                <p>
                                    <ul>
                                        <li style={{ listStyle: "unset" }}>
                                            Obtain the MOA and company’s lease agreement signed by a public notary
                                        </li>
                                        <li style={{ listStyle: "unset" }}>
                                            Furnish the necessary documents
                                        </li>
                                        <li style={{ listStyle: "unset" }}>
                                            Pay the license fees to obtain a trade license
                                        </li>
                                        <li style={{ listStyle: "unset" }}>
                                            Obtain approval from Name board
                                        </li>
                                        <li style={{ listStyle: "unset" }}>
                                            Apply for an establishment card
                                        </li>
                                        <li style={{ listStyle: "unset" }}>
                                            Get approval from the Ministry of Labor for hiring an employee
                                        </li>
                                        <li style={{ listStyle: "unset" }}>
                                            Get the stamp on the visa
                                        </li>
                                    </ul>
                                </p>
                            </div>
                            <div className='col-md-6'>
                                <p>
                                    <ul>
                                        <li style={{ listStyle: "unset" }}>
                                            Determine the type of business activity that you will be conducting
                                        </li>
                                        <li style={{ listStyle: "unset" }}>
                                            Find a local sponsor
                                        </li>
                                        <li style={{ listStyle: "unset" }}>
                                            Submit the company registration form with the name to the Department of economic development (DED)
                                        </li>
                                        <li style={{ listStyle: "unset" }}>
                                            File the trade license with DED
                                        </li>
                                        <li style={{ listStyle: "unset" }}>
                                            Get the trade name registered
                                        </li>
                                        <li style={{ listStyle: "unset" }}>
                                            Obtain approval from the municipality
                                        </li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-5'>
                        <img class="card-img-top" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002069/images/sharjah-mainland_1.jpg" alt="sharjah" title="Sharjah" />
                    </div>
                </div>
            </div>
            <div className="three-coloum-content pt-4" style={{ backgroundColor: "#EDEDED" }}>
                <div className="container">
                    <div className="row pt-5 pb-5">
                        <div className='col-md-6'>
                            <img class="card-img-top" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002070/images/sharjah-mainland_2.jpg" alt="sharjah" title="Sharjah" />
                        </div>
                        <div className='col-md-6'>
                            <h3 style={{ color: "#aa8038" }}>Documents Required</h3>
                            <ul>
                                <li style={{ listStyle: "unset" }}>
                                    Certified copy of tenancy contract
                                </li>
                                <li style={{ listStyle: "unset" }}>
                                    NOC letter
                                </li>
                                <li style={{ listStyle: "unset" }}>
                                    Visa and passport copies
                                </li>
                                <li style={{ listStyle: "unset" }}>
                                    Proof of residence
                                </li>
                                <li style={{ listStyle: "unset" }}>
                                    Approval from legal affairs and Ministry of economy
                                </li>
                                <li style={{ listStyle: "unset" }}>
                                    Trade name certificate
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div >
            <div className="container">
                <div className="row pt-5 pb-5">
                    <div className='col-md-7'>
                        <h3 style={{ color: "#aa8038" }}>Advantages For Business Setup In Sharjah LLC</h3>
                        <ul>
                            <li style={{ listStyle: "unset" }}>
                                Taxation benefits
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                100% foreign ownership
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Flexible government regulations
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Security of investment
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Presence of local, and foreign banks
                            </li>
                        </ul>
                    </div>
                    <div className='col-md-5'>
                        <img class="card-img-top" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002071/images/sharjah-mainland_3.jpg" alt="sharjah" title="Sharjah" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default SharjahMainland;