import React from 'react';
import ModalDialog from './FujairahDialogEnquiry';
import ModalDownloadDialog from './FujairahDialogDownload';
import { Helmet } from "react-helmet";

// ================================|| FujairahFreeZones ||================================ //

const FujairahFreeZones = () => {

    return (
        <>
            <Helmet>
                <title>Fujairah Free Zone: Business Setup and License Cost | Company Registration in Fujairah</title>
            </Helmet>
            <div class="chairman-message">
                <div class="chairman-img">
                    <div class="pos-message-freezone-box">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-7 aos-init" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2>Fujairah Free Zone</h2>
                                    <p>
                                        Fujairah Free Zone exploits its essential area in the main emirate disregarding the east shoreline of the UAE.
                                        Situated close to the Fujairah port, it works with bringing in from and providing merchandise across the Indian Sea.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img class="d-lg-block chairman-desktop-fujairah-img d-none" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001991/images/Chairmans-Message_desktop.jpg" alt="fujairah-banner" title="Fujairah Banner" />
                <img class="d-lg-none d-block" style={{ width: "-webkit-fill-available" }} src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001991/images/Chairmans-Message_mobile.jpg" alt="fujairah-banner" title="Fujairah Banner" />
            </div>
            <div class="pt-5 pb-5">
                <div class="container">
                    <div class="row">
                        <h1><span style={{ color: "#aa8038" }}>Business Setup in Fujairah Freezone</span></h1>
                        <div class="col-lg-3 col-md-4 col-sm-6 mt-2 float-left allowHover">
                            <div class="card border-brown business-broucher-blocks busniess-block-shadow">
                                <div class="card-body">
                                    <span>
                                        <img class="img-fluid mx-auto d-block busniess-img-shadow" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002023/images/fujairah-freezones_1.jpg" alt="fujairah" title="Fujairah" />
                                    </span>
                                    <h5 class="text-brown text-break text-center mt-4">
                                        Fujairah Free Zone
                                    </h5>
                                    <p id='Fujairah Free Zone' class="text-center mt-4 freezoneblock-enquiry">
                                        <ModalDialog />
                                    </p>
                                    <p id='Fujairah Free Zone' class="text-center mt-3 freezoneblock-download">
                                        <ModalDownloadDialog />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 mt-2 float-left allowHover">
                            <div class="card border-brown business-broucher-blocks busniess-block-shadow">
                                <div class="card-body">
                                    <span>
                                        <img class="img-fluid mx-auto d-block busniess-img-shadow" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002023/images/fujairah-freezones_1.jpg" alt="fujairah" title="Fujairah" />
                                    </span>
                                    <h5 class="text-brown text-break text-center mt-4">
                                        Fujairah Creative City
                                    </h5>
                                    <p id='Fujairah Creative City' class="text-center mt-4 freezoneblock-enquiry">
                                        <ModalDialog />
                                    </p>
                                    <p id='Fujairah Creative City' class="text-center mt-3 freezoneblock-download">
                                        <ModalDownloadDialog />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FujairahFreeZones;