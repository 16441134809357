import React from 'react';
import { Helmet } from "react-helmet";

// ================================|| TermsConditions ||================================ //

const TermsConditions = () => {

    return (
        <>
            <Helmet>
				<title>Terms Conditions - Devotion Business</title>
            </Helmet>
            <div class="chairman-message">
                <div class="chairman-img">
                    <div class="pos-message-box">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-7 aos-init" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h1 className='alignment'>Terms Conditions</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img class="d-lg-block d-none" style={{ width: "-webkit-fill-available" }} src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001997/images/Contact-us.png" alt="termscondition-banner" title="Terms Condition Banner" />
            </div>
            <div class="container three-coloum-content mt-5 mb-5">
                <div className='container'>
                    <div class="container">
                        <div class="container row">
                            <p>
                                This Terms and conditions, as well as any other additional terms, conditions, notice and displayed elsewhere on this website (www.devotionbusiness.com) (Terms) apply to use of this Website.
                                Please read these terms carefully before accessing or using the information and services available through this website. This terms modified from time to time without notice to you and you should there for regularly review this terms for any such modifications.
                            </p>
                            <p>
                                Devotion Corporate Services is one of the Leading Document Processing Centre in Dubai-United Arab Emirates under License number 757215. With its registered Office No : 101-10, Bay Square, Business Bay, Dubai.
                            </p>
                        </div>
                        <div class="container row">
                            <h4 class="mt-3 mb-3" style={{ color: "#aa8038" }}>Information Contained On The Website</h4>
                            <p>
                                <ul>
                                    <li style={{ listStyle: "unset" }}>
                                        While we take all reasonable care to ensure that the information contained on the Website is a accurate and up to date, we make no representations, warranties or undertaking about any of the information, content or materials provided on the Website (including, without limitation, any as to quality, accuracy, completeness or reliability).
                                    </li>
                                    <li style={{ listStyle: "unset" }}>
                                        All material on the website is provided for information purposes only and does not constitute legal, accounting or other professional advice, and it must therefore note be relied upon as such. You should arrange your own advice from a qualified party before acting on any of the information, or purchasing any of the services, available on or from the Web site.
                                    </li>
                                </ul>
                            </p>
                        </div>
                        <div class="container row">
                            <h4 class="mt-3 mb-3" style={{ color: "#aa8038" }}>Updates And Changes</h4>
                            <p>
                                <ul>
                                    <li style={{ listStyle: "unset" }}>
                                        The Website is being updated and improved on an ongoing basis. We reserve the right to change or remove (temporarily or permanently) the Website or any part of it without notice and you confirm that we shall not be liable to you for any such change or removal; changes to these General Terms and Conditions or to the Specific Terms and Conditions may be made at any time, and your use of the Website, or the purchase of services, are subject to any such changes.
                                        You agree to check to see if any changes have been made to the General or the relevant Specific terms each time you visit the website or purchase products or services from it.
                                    </li>
                                </ul>
                            </p>
                        </div>
                        <div class="container row">
                            <h4 class="mt-3 mb-3" style={{ color: "#aa8038" }}>Price Of Services</h4>
                            <p>
                                <ul>
                                    <li style={{ listStyle: "unset" }}>
                                        We reserve the right periodically to update the prices on the website and to add to, amend, or withdraw the products and services that we offer, without prior notice. Every effort is made to ensure that the prices are correct, but in the event of serious error, any transaction shall be voidable by us.
                                    </li>
                                    <li style={{ listStyle: "unset" }}>
                                        We shall not be liable to anyone for withdrawing or amending any of the services we sell, or for refusing of failing to process an order.
                                    </li>
                                </ul>
                            </p>
                        </div>
                        <div class="container row">
                            <h4 class="mt-3 mb-3" style={{ color: "#aa8038" }}>Devotion Corporate Services Does Not Guarantee</h4>
                            <p>
                                <ul>
                                    <li style={{ listStyle: "unset" }}>
                                        Devotion Corporate Services does not guarantee that the relevant regulatory authorities in the UAE shall accept the documents prepared and/or submitted by Devotion Corporate Services on behalf of the client.
                                        The regulatory authorities in the UAE enjoy considerable discretion and they may reject any application without assigning a reason. The client acknowledges and accepts the risk of such rejection.
                                    </li>
                                    <li style={{ listStyle: "unset" }}>
                                        Furthermore, the regulatory authorities in the UAE are entitled to request additional documents from the client at any time and the client agrees to furnish the same at the earliest.
                                        Devotion Corporate Services shall not be responsible for any delay or other complication arising under any application submitted on behalf of the client, except where the same may arise due to Devotion Corporate Services gross negligence or willful misconduct.
                                    </li>
                                </ul>
                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
};

export default TermsConditions;