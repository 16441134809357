import React from 'react';
import { Helmet } from "react-helmet";

// ================================|| ExciseRegistration ||================================ //

const ExciseRegistration = () => {

    return (
        <>
            <Helmet>
                <title>Excise Registration Services in Dubai and the UAE</title>
            </Helmet>
            <div class="chairman-message">
                <div class="chairman-img">
                    <div class="pos-message-box">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-7 aos-init" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2 className='alignment'>Excise Registration</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img class="d-lg-block d-none" style={{ width: "-webkit-fill-available" }} src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001997/images/Contact-us.png" alt="excise-registration-banner" title="Excise Registration Banner" />
            </div>
            <div className="container">
                <div className="row pt-5 pb-5">
                    <h1><span style={{ color: "#aa8038" }}>Excise Registration Services in UAE</span></h1>
                    <div className='col-12'>
                        <p>
                            A separate charge has been done in UAE on tobacco things, juice drinks, and carbonated drinks in 2017.
                            The Federal Tax Authority (FTA) has proclaimed new rates for separate charges in the UAE and has even broadened the once-over of concentrate stock from first December 2019.
                            This development in extricating rates in the UAE was begun to assist the public power's undertakings with updating general prosperity and thwart progressing afflictions directly associated with the use of sugar and tobacco.
                        </p>
                    </div>
                    <div className='col-md-7'>
                        <p>
                            Associations that import, make, scatter, or hold the going with Excise items should be selected for Excise Tax:-
                        </p>
                        <ul>
                            <li style={{ listStyle: "unset" }}>
                                Sweetened beverages
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Energy drinks or any other any concentrations, powder, gel, or extracts intended to be made into an energy beverage
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Carbonated drinks or any other any concentrations, powder, gel, or extracts intended to be made into an aerated beverage
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Cigarettes
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Electronic smoking devices aka e-cigarettes
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                E-liquids used in electronic smoking devices, whether or not they contain nicotine or tobacco
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Tobacco products
                            </li>
                        </ul>
                    </div>
                    <div className='col-md-5'>
                        <img class="card-img-top" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002020/images/excise-registration.jpg" alt="excise-registration-banner" title="Excise Registration Banner" />
                    </div>
                    <h4 class="pt-5"><span style={{ color: "#aa8038" }}>Which Products Are Exempt From The Excise Tax In The Uae?</span></h4>
                    <p>
                        <ul>
                            <li style={{ listStyle: "auto" }}>
                                Ready-to-drink beverages containing at least 75% milk or milk substitutes
                            </li>
                            <li style={{ listStyle: "auto" }}>
                                Baby formula or baby food
                            </li>
                            <li style={{ listStyle: "auto" }}>
                                Beverages consumed for special dietary needs, as determined under Standard 654 of the GCC Standardization Organization under the heading ‘General Requirements for Pre-packaged Foods for Special Dietary Use’ and any subsequent and relevant standards
                            </li>
                            <li style={{ listStyle: "auto" }}>
                                Beverages consumed for medical uses, as determined under Standard 1366 of the GCC Standardization Organisation under the heading ‘General Requirements for Handling of Foods for Special Medical Purposes’, and any subsequent and relevant standards
                            </li>
                        </ul>
                    </p>
                    <h4><span style={{ color: "#aa8038" }}>How To Register For Excise Tax In The Uae?</span></h4>
                    <p>
                        Extricate charge enlistment is obligatory for all associations overseeing separate products in the UAE.
                        The connection ought to conveniently be conceivable through the organizations fragment on the FTA site.
                        You ought to just sign in or get together with your nuances on FTA extricate charge entrance and comply with the direct rules they have there.
                    </p>
                    <h4 class="pt-5"><span style={{ color: "#aa8038" }}>Excise registration process in UAE is as below :-</span></h4>
                    <p>
                        <ul>
                            <li style={{ listStyle: "auto" }}>
                                The applicant should register online on the Federal tax authority website. www.tax.gov.ae
                            </li>
                            <li style={{ listStyle: "auto" }}>
                                Excise registration form would require specific business details which would be specified in the Executive regulations
                            </li>
                            <li style={{ listStyle: "auto" }}>
                                The various documents required are :-<br />
                                - 1. Emirates ID<br />
                                - 2. Authorized signatory<br />
                                - 3. Certificate of incorporation<br />
                                - 4. Articles of association<br />
                                - 5. Bank account details<br />
                            </li>
                        </ul>
                    </p>
                </div>
            </div>
        </>
    );
};

export default ExciseRegistration;