import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import moment from 'moment';
import API from '../../../middleware/API';
import { Cookies, useCookies } from "react-cookie";
import { FaCalendarDay, FaProjectDiagram, FaUserFriends, FaBlenderPhone, FaPaste, FaUserAlt } from "react-icons/fa";

const Dashboard = () => {
    const [cookie, removeCookie] = useCookies(['username']);
    const cookies = new Cookies();
    const role = cookies.get('role');
    const [Role, SetRole] = useState("");

    const [TotalUserCount, setTotalUserCount] = useState();
    const [TotalBlogCount, setTotalBlogCount] = useState();
    const [TotalProjectDetailCount, setTotalProjectDetailCount] = useState();
    const [TotalTodayProjectDetailCount, setTotalTodayProjectDetailCount] = useState();
    const [TotalCurrMonthProjectDetailCount, setTotalCurrMonthProjectDetailCount] = useState();
    const [TotalPrevMonthProjectDetailCount, setTotalPrevMonthProjectDetailCount] = useState();
    const [ProjectDetailPercentage, setProjectDetailPercentage] = useState();
    const [ProjectDetailPercentageType, setProjectDetailPercentageType] = useState();

    const [TotalDemoProjectDetailCount, setTotalDemoProjectDetailCount] = useState();
    const [TotalTodayDemoProjectDetailCount, setTotalTodayDemoProjectDetailCount] = useState();
    const [TotalCurrMonthDemoProjectDetailCount, setTotalCurrMonthDemoProjectDetailCount] = useState();
    const [TotalPrevMonthDemoProjectDetailCount, setTotalPrevMonthDemoProjectDetailCount] = useState();
    const [DemoProjectDetailPercentage, setDemoProjectDetailPercentage] = useState();
    const [DemoProjectDetailPercentageType, setDemoProjectDetailPercentageType] = useState();

    const [TotalCareerDetailCount, setTotalCareerDetailCount] = useState();
    const [TotalTodayCareerDetailCount, setTotalTodayCareerDetailCount] = useState();
    const [TotalCurrMonthCareerDetailCount, setTotalCurrMonthCareerDetailCount] = useState();
    const [TotalPrevMonthCareerDetailCount, setTotalPrevMonthCareerDetailCount] = useState();
    const [CareerDetailPercentage, setCareerDetailPercentage] = useState();
    const [CareerDetailPercentageType, setCareerDetailPercentageType] = useState();

    const [TotalContactDetailCount, setTotalContactDetailCount] = useState();
    const [TotalTodayContactDetailCount, setTotalTodayContactDetailCount] = useState();
    const [TotalCurrMonthContactDetailCount, setTotalCurrMonthContactDetailCount] = useState();
    const [TotalPrevMonthContactDetailCount, setTotalPrevMonthContactDetailCount] = useState();
    const [ContactDetailPercentage, setContactDetailPercentage] = useState();
    const [ContactDetailPercentageType, setContactDetailPercentageType] = useState();

    var date = moment().subtract(-1, "day").format('YYYY-MM-DD');
    var prevdate = moment().format('YYYY-MM-DD');
    var todayDate = moment().format('DD-MM-YYYY');
    var currMonthName = moment().format('MMMM');
    var currMonthFromDate = moment().startOf('month').format('YYYY-MM-DD');
    var currMonthToDate = moment().endOf('month').format('YYYY-MM-DD');

    var prevMonthName = moment().subtract(1, "month").format('MMMM');
    var prevMonthFromDate = moment().subtract(1, "month").startOf('month').format('YYYY-MM-DD');
    var prevMonthToDate = moment().subtract(1, "month").endOf('month').format('YYYY-MM-DD');

    useEffect(() => {

        if (role === "Super Admin") {
            SetRole(role);

            getUserCount();
            getBlogCount();

            getProjectDetailCount();
            getTodayProjectDetailCount();
            getCurrMonthProjectDetailCount();
            getPrevMonthProjectDetailCount();
            getProjectDetailPercentage();

            getDemoProjectDetailCount();
            getTodayDemoProjectDetailCount();
            getCurrMonthDemoProjectDetailCount();
            getPrevMonthDemoProjectDetailCount();
            getDemoProjectDetailPercentage();

            getCareerDetailCount();
            getTodayCareerDetailCount();
            getCurrMonthCareerDetailCount();
            getPrevMonthCareerDetailCount();
            getCareerDetailPercentage();

            getContactDetailCount();
            getTodayContactDetailCount();
            getCurrMonthContactDetailCount();
            getPrevMonthContactDetailCount();
            getContactDetailPercentage();
        }
    });

    const getUserCount = async () => {
        try {
            var GetUserCountAPI = API.getUsersCount;
            const response = await fetch(GetUserCountAPI);
            const jsonData = await response.json();
            setTotalUserCount(jsonData.recordsets[0][0].TotalRows);
        }
        catch (error) {
            console.log(error);
        }
    };

    const getBlogCount = async () => {
        try {
            var GetBlogCountAPI = API.getBlogsCount;
            const response = await fetch(GetBlogCountAPI);
            const jsonData = await response.json();
            setTotalBlogCount(jsonData.recordsets[0][0].TotalRows);
        }
        catch (error) {
            console.log(error);
        }
    };


    const getProjectDetailCount = async () => {
        try {
            var GetProjectDetailCountAPI = API.getProjectDetailCount;
            const response = await fetch(GetProjectDetailCountAPI);
            const jsonData = await response.json();
            setTotalProjectDetailCount(jsonData.recordsets[0][0].TotalRows);
        }
        catch (error) {
            console.log(error);
        }
    };
    const getTodayProjectDetailCount = async () => {
        try {
            var GetTodayProjectDetailCountAPI = API.getProjectDetailCountByMonth;
            GetTodayProjectDetailCountAPI = GetTodayProjectDetailCountAPI + "?fromDate='" + prevdate + "'&toDate='" + date + "'";
            const response = await fetch(GetTodayProjectDetailCountAPI);
            const jsonData = await response.json();
            setTotalTodayProjectDetailCount(jsonData.recordsets[0][0].TotalRows);
        }
        catch (error) {
            console.log(error);
        }
    };
    const getCurrMonthProjectDetailCount = async () => {
        try {
            var GetCurrMonthProjectDetailCountAPI = API.getProjectDetailCountByMonth;
            GetCurrMonthProjectDetailCountAPI = GetCurrMonthProjectDetailCountAPI + "?fromDate='" + currMonthFromDate + "'&toDate='" + currMonthToDate + "'";
            const response = await fetch(GetCurrMonthProjectDetailCountAPI);
            const jsonData = await response.json();
            setTotalCurrMonthProjectDetailCount(jsonData.recordsets[0][0].TotalRows);
        }
        catch (error) {
            console.log(error);
        }
    };
    const getPrevMonthProjectDetailCount = async () => {
        try {
            var GetPrevMonthProjectDetailCountAPI = API.getProjectDetailCountByMonth;
            GetPrevMonthProjectDetailCountAPI = GetPrevMonthProjectDetailCountAPI + "?fromDate='" + prevMonthFromDate + "'&toDate='" + prevMonthToDate + "'";
            const response = await fetch(GetPrevMonthProjectDetailCountAPI);
            const jsonData = await response.json();
            setTotalPrevMonthProjectDetailCount(jsonData.recordsets[0][0].TotalRows);
        }
        catch (error) {
            console.log(error);
        }
    };
    const getProjectDetailPercentage = async () => {
        try {
            if (TotalCurrMonthProjectDetailCount > TotalPrevMonthProjectDetailCount) {
                let res = TotalCurrMonthProjectDetailCount - TotalPrevMonthProjectDetailCount;
                let percent = res / TotalCurrMonthProjectDetailCount * 100;
                setProjectDetailPercentage(percent.toFixed(2));
                setProjectDetailPercentageType(1);
            }
            else {
                let res = TotalPrevMonthProjectDetailCount - TotalCurrMonthProjectDetailCount / TotalPrevMonthProjectDetailCount;
                let percent = res / TotalPrevMonthProjectDetailCount * 100;
                setProjectDetailPercentage(percent.toFixed(2));
                setProjectDetailPercentageType(0);
            }
        }
        catch (error) {
            console.log(error);
        }
    };


    const getDemoProjectDetailCount = async () => {
        try {
            var GetDemoProjectDetailCountAPI = API.getDemoProjectDetailCount;
            const response = await fetch(GetDemoProjectDetailCountAPI);
            const jsonData = await response.json();
            setTotalDemoProjectDetailCount(jsonData.recordsets[0][0].TotalRows);
        }
        catch (error) {
            console.log(error);
        }
    };
    const getTodayDemoProjectDetailCount = async () => {
        try {
            var GetTodayDemoProjectDetailCountAPI = API.getProjectDetailCountByMonth;
            GetTodayDemoProjectDetailCountAPI = GetTodayDemoProjectDetailCountAPI + "?fromDate='" + prevdate + "'&toDate='" + date + "'";
            const response = await fetch(GetTodayDemoProjectDetailCountAPI);
            const jsonData = await response.json();
            setTotalTodayDemoProjectDetailCount(jsonData.recordsets[0][0].TotalRows);
        }
        catch (error) {
            console.log(error);
        }
    };
    const getCurrMonthDemoProjectDetailCount = async () => {
        try {
            var GetCurrMonthDemoProjectDetailCountAPI = API.getDemoProjectDetailCountByMonth;
            GetCurrMonthDemoProjectDetailCountAPI = GetCurrMonthDemoProjectDetailCountAPI + "?fromDate='" + currMonthFromDate + "'&toDate='" + currMonthToDate + "'";
            const response = await fetch(GetCurrMonthDemoProjectDetailCountAPI);
            const jsonData = await response.json();
            setTotalCurrMonthDemoProjectDetailCount(jsonData.recordsets[0][0].TotalRows);
        }
        catch (error) {
            console.log(error);
        }
    };
    const getPrevMonthDemoProjectDetailCount = async () => {
        try {
            var GetPrevMonthDemoProjectDetailCountAPI = API.getDemoProjectDetailCountByMonth;
            GetPrevMonthDemoProjectDetailCountAPI = GetPrevMonthDemoProjectDetailCountAPI + "?fromDate='" + prevMonthFromDate + "'&toDate='" + prevMonthToDate + "'";
            const response = await fetch(GetPrevMonthDemoProjectDetailCountAPI);
            const jsonData = await response.json();
            setTotalPrevMonthDemoProjectDetailCount(jsonData.recordsets[0][0].TotalRows);
        }
        catch (error) {
            console.log(error);
        }
    };
    const getDemoProjectDetailPercentage = async () => {
        try {
            if (TotalCurrMonthDemoProjectDetailCount > TotalPrevMonthDemoProjectDetailCount) {
                let res = TotalCurrMonthDemoProjectDetailCount - TotalPrevMonthDemoProjectDetailCount;
                let percent = res / TotalCurrMonthDemoProjectDetailCount * 100;
                setDemoProjectDetailPercentage(percent.toFixed(2));
                setDemoProjectDetailPercentageType(1);
            }
            else {
                let res = TotalPrevMonthDemoProjectDetailCount - TotalCurrMonthDemoProjectDetailCount;
                let percent = res / TotalPrevMonthDemoProjectDetailCount * 100;
                setDemoProjectDetailPercentage(percent.toFixed(2));
                setDemoProjectDetailPercentageType(0);
            }
        }
        catch (error) {
            console.log(error);
        }
    };


    const getCareerDetailCount = async () => {
        try {
            var GetCareerDetailCountAPI = API.getCareerDetailCount;
            const response = await fetch(GetCareerDetailCountAPI);
            const jsonData = await response.json();
            setTotalCareerDetailCount(jsonData.recordsets[0][0].TotalRows);
        }
        catch (error) {
            console.log(error);
        }
    };
    const getTodayCareerDetailCount = async () => {
        try {
            var GetTodayCareerDetailCountAPI = API.getCareerDetailCountByMonth;
            GetTodayCareerDetailCountAPI = GetTodayCareerDetailCountAPI + "?fromDate='" + prevdate + "'&toDate='" + date + "'";
            const response = await fetch(GetTodayCareerDetailCountAPI);
            const jsonData = await response.json();
            setTotalTodayCareerDetailCount(jsonData.recordsets[0][0].TotalRows);
        }
        catch (error) {
            console.log(error);
        }
    };
    const getCurrMonthCareerDetailCount = async () => {
        try {
            var GetCurrMonthCareerDetailCountAPI = API.getCareerDetailCountByMonth;
            GetCurrMonthCareerDetailCountAPI = GetCurrMonthCareerDetailCountAPI + "?fromDate='" + currMonthFromDate + "'&toDate='" + currMonthToDate + "'";
            const response = await fetch(GetCurrMonthCareerDetailCountAPI);
            const jsonData = await response.json();
            setTotalCurrMonthCareerDetailCount(jsonData.recordsets[0][0].TotalRows);
        }
        catch (error) {
            console.log(error);
        }
    };
    const getPrevMonthCareerDetailCount = async () => {
        try {
            var GetPrevMonthCareerDetailCountAPI = API.getCareerDetailCountByMonth;
            GetPrevMonthCareerDetailCountAPI = GetPrevMonthCareerDetailCountAPI + "?fromDate='" + prevMonthFromDate + "'&toDate='" + prevMonthToDate + "'";
            const response = await fetch(GetPrevMonthCareerDetailCountAPI);
            const jsonData = await response.json();
            setTotalPrevMonthCareerDetailCount(jsonData.recordsets[0][0].TotalRows);
        }
        catch (error) {
            console.log(error);
        }
    };
    const getCareerDetailPercentage = async () => {
        try {
            if (TotalCurrMonthCareerDetailCount > TotalPrevMonthCareerDetailCount) {
                let res = TotalCurrMonthCareerDetailCount - TotalPrevMonthCareerDetailCount;
                let percent = res / TotalCurrMonthCareerDetailCount * 100;
                setCareerDetailPercentage(percent.toFixed(2));
                setCareerDetailPercentageType(1);
            }
            else {
                let res = TotalPrevMonthCareerDetailCount - TotalCurrMonthCareerDetailCount;
                let percent = res / TotalPrevMonthCareerDetailCount * 100;
                setCareerDetailPercentage(percent.toFixed(2));
                setCareerDetailPercentageType(0);
            }
        }
        catch (error) {
            console.log(error);
        }
    };


    const getContactDetailCount = async () => {
        try {
            var GetContactDetailCountAPI = API.getContactDetailCount;
            const response = await fetch(GetContactDetailCountAPI);
            const jsonData = await response.json();
            setTotalContactDetailCount(jsonData.recordsets[0][0].TotalRows);
        }
        catch (error) {
            console.log(error);
        }
    };
    const getTodayContactDetailCount = async () => {
        try {
            var GetTodayContactDetailCountAPI = API.getContactDetailCountByMonth;
            GetTodayContactDetailCountAPI = GetTodayContactDetailCountAPI + "?fromDate='" + prevdate + "'&toDate='" + date + "'";
            const response = await fetch(GetTodayContactDetailCountAPI);
            const jsonData = await response.json();
            setTotalTodayContactDetailCount(jsonData.recordsets[0][0].TotalRows);
        }
        catch (error) {
            console.log(error);
        }
    };
    const getCurrMonthContactDetailCount = async () => {
        try {
            var GetCurrMonthContactDetailCountAPI = API.getContactDetailCountByMonth;
            GetCurrMonthContactDetailCountAPI = GetCurrMonthContactDetailCountAPI + "?fromDate='" + currMonthFromDate + "'&toDate='" + currMonthToDate + "'";
            const response = await fetch(GetCurrMonthContactDetailCountAPI);
            const jsonData = await response.json();
            setTotalCurrMonthContactDetailCount(jsonData.recordsets[0][0].TotalRows);
        }
        catch (error) {
            console.log(error);
        }
    };
    const getPrevMonthContactDetailCount = async () => {
        try {
            var GetPrevMonthContactDetailCountAPI = API.getContactDetailCountByMonth;
            GetPrevMonthContactDetailCountAPI = GetPrevMonthContactDetailCountAPI + "?fromDate='" + prevMonthFromDate + "'&toDate='" + prevMonthToDate + "'";
            const response = await fetch(GetPrevMonthContactDetailCountAPI);
            const jsonData = await response.json();
            setTotalPrevMonthContactDetailCount(jsonData.recordsets[0][0].TotalRows);
        }
        catch (error) {
            console.log(error);
        }
    };
    const getContactDetailPercentage = async () => {
        try {
            if (TotalCurrMonthContactDetailCount > TotalPrevMonthContactDetailCount) {
                let res = TotalCurrMonthContactDetailCount - TotalPrevMonthContactDetailCount;
                let percent = res / TotalCurrMonthContactDetailCount * 100;
                setContactDetailPercentage(percent.toFixed(2));
                setContactDetailPercentageType(1);
            }
            else {
                let res = TotalPrevMonthContactDetailCount - TotalCurrMonthContactDetailCount;
                let percent = res / TotalPrevMonthContactDetailCount * 100;
                setContactDetailPercentage(percent.toFixed(2));
                setContactDetailPercentageType(0);
            }
        }
        catch (error) {
            console.log(error);
        }
    };


    return (
        <>
            <Helmet>
                <title>Dashboard</title>
            </Helmet>
            <div className="container-xxl">
                <div className="container-xxl flex-grow-1 container-p-y">
                    <div>{Role ? <div className="row">
                        <div className="col-lg-8 order-1 order-md-3 order-lg-2 mb-4">
                            <div className="card">
                                <div className="row row-bordered g-0">
                                    <div className="col-md-8">
                                        <h5 className="card-header m-0 pb-3">
                                            Today's
                                            <span className='text-muted mt-1' style={{ float: "right", fontSize: "medium" }}>
                                                <FaCalendarDay />&nbsp;&nbsp;{todayDate}
                                            </span>
                                        </h5>
                                        <div className="d-flex px-xxl-4 px-lg-2 p-3 gap-xxl-3 gap-lg-1 gap-3 justify-content-between">
                                            <div className="d-flex">
                                                <div className="avatar flex-shrink-0 me-2">
                                                    <Link to='/admin/project-details' className='text-decoration-none text-dark'>
                                                        <span className='avatar-initial rounded bg-label-success'>
                                                            <FaProjectDiagram className="fs-4" />
                                                        </span>
                                                    </Link>
                                                </div>
                                                <div className="d-flex flex-column">
                                                    <small>Project Leads</small>
                                                    <h6 className="mb-0">{TotalTodayProjectDetailCount}</h6>
                                                </div>
                                            </div>
                                            <div className="d-flex">
                                                <div className="avatar flex-shrink-0 me-2">
                                                    <Link to='/admin/demo-projects' className='text-decoration-none text-dark'>
                                                        <span className='avatar-initial rounded bg-label-info'>
                                                            <FaPaste className="fs-4" />
                                                        </span>
                                                    </Link>
                                                </div>
                                                <div className="d-flex flex-column">
                                                    <small>Demo Projects</small>
                                                    <h6 className="mb-0">{TotalTodayDemoProjectDetailCount}</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex px-xxl-4 px-lg-2 p-3 gap-xxl-3 gap-lg-1 gap-3 justify-content-between">
                                            <div className="d-flex">
                                                <div className="avatar flex-shrink-0 me-2">
                                                    <Link to='/admin/recruitments' className='text-decoration-none text-dark'>
                                                        <span className='avatar-initial rounded bg-label-danger'>
                                                            <FaUserFriends className="fs-4" />
                                                        </span>
                                                    </Link>
                                                </div>
                                                <div className="d-flex flex-column">
                                                    <small>Recruitments</small>
                                                    <h6 className="mb-0">{TotalTodayCareerDetailCount}</h6>
                                                </div>
                                            </div>
                                            <div className="d-flex">
                                                <div className="avatar flex-shrink-0 me-2">
                                                    <Link to='/admin/enquiries' className='text-decoration-none text-dark'>
                                                        <span className='avatar-initial rounded bg-label-primary'>
                                                            <FaBlenderPhone className="fs-4" />
                                                        </span>
                                                    </Link>
                                                </div>
                                                <div className="d-flex flex-column">
                                                    <small style={{ paddingRight: "2rem" }}>Enquiries</small>
                                                    <h6 className="mb-0">{TotalTodayContactDetailCount}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="card-body">
                                            <div className="text-center">
                                                <div className="dropdown">
                                                    <button
                                                        className="btn btn-sm btn-outline-primary dropdown-toggle"
                                                        type="button"
                                                        id="growthReportId"
                                                        data-bs-toggle="dropdown"
                                                        aria-haspopup="false"
                                                        disabled="true"
                                                    > 2023
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="growthChart"></div>
                                        <div className="text-center fw-semibold pt-3 mb-2">Users & Blogs</div>
                                        <div className="d-flex px-xxl-4 px-lg-2 p-4 gap-xxl-3 gap-lg-1 gap-3 justify-content-between">
                                            <div className="d-flex">
                                                <div className="avatar flex-shrink-0 me-2">
                                                    <Link to='/admin/users' className='text-decoration-none text-dark'>
                                                        <span className='avatar-initial rounded bg-label-secondary'>
                                                            <FaUserAlt className="fs-4" />
                                                        </span>
                                                    </Link>
                                                </div>
                                                <div className="d-flex flex-column">
                                                    <small>Users</small>
                                                    <h6 className="mb-0">{TotalUserCount}</h6>
                                                </div>
                                            </div>
                                            <div className="d-flex">
                                                <div className="avatar flex-shrink-0 me-2">
                                                    <Link to='/admin/blogs' className='text-decoration-none text-dark'>
                                                        <span className='avatar-initial rounded bg-label-warning'>
                                                            <FaProjectDiagram className="fs-4" />
                                                        </span>
                                                    </Link>
                                                </div>
                                                <div className="d-flex flex-column">
                                                    <small>Blogs</small>
                                                    <h6 className="mb-0">{TotalBlogCount}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 order-2">
                            <div className="row">
                                <div className="col-lg-6 col-md-12 col-6 mb-4">
                                    <div className="card">
                                        <div className="card-body">
                                            <Link to='/admin/project-details' className='text-decoration-none text-dark'>
                                                <div className="card-title d-flex align-items-start justify-content-between">
                                                    <div className="avatar flex-shrink-0 me-3">
                                                        <span className='avatar-initial rounded bg-label-success'>
                                                            <FaProjectDiagram className="fs-4" />
                                                        </span>
                                                    </div>
                                                    <span>{ProjectDetailPercentageType ? (<small className="text-success mt-2 fw-semibold"><i className="bx bx-up-arrow-alt"></i>+{ProjectDetailPercentage}%</small>) : (<small className="text-danger mt-2 fw-semibold"><i className="bx bx-down-arrow-alt"></i>-{ProjectDetailPercentage}%</small>)}</span>
                                                </div>
                                                <span className="fw-semibold d-block mb-1">Total Project Leads</span>
                                                <h3 className="card-title mb-2">{TotalProjectDetailCount}</h3>
                                                <ul className="p-0 m-0">
                                                    <li className="d-flex mt-1 pb-1">
                                                        <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                                            <div className="me-2">
                                                                <span className="text-muted">{currMonthName}</span>
                                                            </div>
                                                            <div className="user-progress d-flex align-items-center gap-1">
                                                                <span className="text-muted">{TotalCurrMonthProjectDetailCount}</span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="d-flex pb-1">
                                                        <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                                            <div className="me-2">
                                                                <span className="text-muted">{prevMonthName}</span>
                                                            </div>
                                                            <div className="user-progress d-flex align-items-center gap-1">
                                                                <span className="text-muted">{TotalPrevMonthProjectDetailCount}</span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 col-6 mb-4">
                                    <div className="card">
                                        <div className="card-body">
                                            <Link to='/admin/demo-projects' className='text-decoration-none text-dark'>
                                                <div className="card-title d-flex align-items-start justify-content-between">
                                                    <div className="avatar flex-shrink-0 me-3">
                                                        <span className='avatar-initial rounded bg-label-info'>
                                                            <FaPaste className="fs-4" />
                                                        </span>
                                                    </div>
                                                    <span>{DemoProjectDetailPercentageType ? (<small className="text-success mt-2 fw-semibold"><i className="bx bx-up-arrow-alt"></i>+{DemoProjectDetailPercentage}%</small>) : (<small className="text-danger mt-2 fw-semibold"><i className="bx bx-down-arrow-alt"></i>-{DemoProjectDetailPercentage}%</small>)}</span>
                                                </div>
                                                <span className="fw-semibold d-block mb-1">Total Demo Projects</span>
                                                <h3 className="card-title mb-2">{TotalDemoProjectDetailCount}</h3>
                                                <ul className="p-0 m-0">
                                                    <li className="d-flex mt-1 pb-1">
                                                        <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                                            <div className="me-2">
                                                                <span className="text-muted">{currMonthName}</span>
                                                            </div>
                                                            <div className="user-progress d-flex align-items-center gap-1">
                                                                <span className="text-muted">{TotalCurrMonthDemoProjectDetailCount}</span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="d-flex pb-1">
                                                        <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                                            <div className="me-2">
                                                                <span className="text-muted">{prevMonthName}</span>
                                                            </div>
                                                            <div className="user-progress d-flex align-items-center gap-1">
                                                                <span className="text-muted">{TotalPrevMonthDemoProjectDetailCount}</span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-8 order-2 order-md-3 order-lg-2 mb-4">
                        </div>
                        <div className="col-12 col-md-8 col-lg-4 order-3 order-md-2">
                            <div className="row">
                                <div className="col-6 mb-4">
                                    <div className="card">
                                        <div className="card-body">
                                            <Link to='/admin/recruitments' className='text-decoration-none text-dark'>
                                                <div className="card-title d-flex align-items-start justify-content-between">
                                                    <div className="avatar flex-shrink-0 me-3">
                                                        <span className='avatar-initial rounded bg-label-danger'>
                                                            <FaUserFriends className="fs-4" />
                                                        </span>
                                                    </div>
                                                    <span>{CareerDetailPercentageType ? (<small className="text-success mt-2 fw-semibold"><i className="bx bx-up-arrow-alt"></i>+{CareerDetailPercentage}%</small>) : (<small className="text-danger mt-2 fw-semibold"><i className="bx bx-down-arrow-alt"></i>-{CareerDetailPercentage}%</small>)}</span>
                                                </div>
                                                <span className="fw-semibold d-block mb-1">Total Recruitments</span>
                                                <h3 className="card-title mb-2">{TotalCareerDetailCount}</h3>
                                                <ul className="p-0 m-0">
                                                    <li className="d-flex mt-1 pb-1">
                                                        <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                                            <div className="me-2">
                                                                <span className="text-muted">{currMonthName}</span>
                                                            </div>
                                                            <div className="user-progress d-flex align-items-center gap-1">
                                                                <span className="text-muted">{TotalCurrMonthCareerDetailCount}</span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="d-flex pb-1">
                                                        <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                                            <div className="me-2">
                                                                <span className="text-muted">{prevMonthName}</span>
                                                            </div>
                                                            <div className="user-progress d-flex align-items-center gap-1">
                                                                <span className="text-muted">{TotalPrevMonthCareerDetailCount}</span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 mb-4">
                                    <div className="card">
                                        <div className="card-body">
                                            <Link to='/admin/enquiries' className='text-decoration-none text-dark'>
                                                <div className="card-title d-flex align-items-start justify-content-between">
                                                    <div className="avatar flex-shrink-0 me-3">
                                                        <span className='avatar-initial rounded bg-label-primary'>
                                                            <FaBlenderPhone className="fs-4" />
                                                        </span>
                                                    </div>
                                                    <span>{ContactDetailPercentageType ? (<small className="text-success mt-2 fw-semibold"><i className="bx bx-up-arrow-alt"></i>+{ContactDetailPercentage}%</small>) : (<small className="text-danger mt-2 fw-semibold"><i className="bx bx-down-arrow-alt"></i>-{ContactDetailPercentage}%</small>)}</span>
                                                </div>
                                                <span className="fw-semibold d-block mb-1">Total Enquiries</span>
                                                <h3 className="card-title mb-2">{TotalContactDetailCount}</h3>
                                                <ul className="p-0 m-0">
                                                    <li className="d-flex mt-1 pb-1">
                                                        <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                                            <div className="me-2">
                                                                <span className="text-muted">{currMonthName}</span>
                                                            </div>
                                                            <div className="user-progress d-flex align-items-center gap-1">
                                                                <span className="text-muted">{TotalCurrMonthContactDetailCount}</span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="d-flex pb-1">
                                                        <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                                            <div className="me-2">
                                                                <span className="text-muted">{prevMonthName}</span>
                                                            </div>
                                                            <div className="user-progress d-flex align-items-center gap-1">
                                                                <span className="text-muted">{TotalPrevMonthContactDetailCount}</span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : null}</div>

                </div>
            </div>
        </>
    );
};

export default Dashboard;