import React from 'react';
import { Link } from 'react-router-dom';
import { scrollToTop } from "../utils/utilityFunctions";

// ================================|| Footer ||================================ //

const handleNavLinkClick = () => {
    scrollToTop()
    const navbarToggle = document.querySelector('.navbar-toggler');
    if (navbarToggle && window.getComputedStyle(navbarToggle).display !== 'none' && (navbarToggle.getAttribute('aria-expanded') == "true")) {
        navbarToggle.click();
    }
}

const Footer = () => {
    return (
        <>
            <footer className="footer-99382">
                <div className='row w-100 m-0'>
                    <div className="footer-mobile col-lg-3 col-md-6 pt-3 mb-1" style={{ backgroundColor: "#fbf2de" }}>
                        <h3 className='text-center'><u>Quick Links</u></h3>
                        <ul className="list-unstyled nav-links" style={{ display: "block", marginLeft: "auto", marginRight: "auto", width: "50%" }}>
                            <li><Link to="/overview" onClick={handleNavLinkClick} className='text-decoration-none'>About Us</Link></li>
                            <li><Link to="/career" onClick={handleNavLinkClick} className='text-decoration-none'>Career</Link></li>
                            <li><Link to="/contact-us" onClick={handleNavLinkClick} className='text-decoration-none'>Contact Us</Link></li>
                            <li><Link to="/blogs" onClick={handleNavLinkClick} className='text-decoration-none'>Blog</Link></li>
                            <li><Link to="/faq" onClick={handleNavLinkClick} className='text-decoration-none'>FAQ</Link></li>
                            <li><Link to="/privacy-policy" onClick={handleNavLinkClick} className='text-decoration-none'>Privacy Policy</Link></li>
                            <li><Link to="/terms-conditions" onClick={handleNavLinkClick} className='text-decoration-none'>Terms & Conditions</Link></li>
                        </ul>
                    </div>
                    <div className="footer-mobile border-left col-lg-3 col-md-6 pt-3 mb-1" style={{ backgroundColor: "#fbf2de" }}>
                        <h3 className='text-center' style={{ paddingLeft: "2rem" }}><u>Connect With Us</u></h3>
                        <ul className="list-unstyled nav-links" style={{ paddingLeft: "2rem" }}>
                            <li><img src='https://res.cloudinary.com/ddnekoeii/image/upload/v1711001985/images/airplane-gold.png' height={30} width={30} alt="location" title="Location" /><a href='https://maps.app.goo.gl/GfEoon4nsKxeDUj59' className='text-decoration-none' style={{ marginLeft: "5px" }}><span className='fw-bold' style={{ fontSize: "16px", color: "#aa8038" }}>Registered office</span><br />1901-Al Fahidi Heights - Exit 4 - <br />Near Sharaf DG Metro Station<br /> Bur Dubai - Dubai 122002</a></li>
                        </ul>
                        <ul className="list-unstyled nav-links" style={{ paddingLeft: "2rem" }}>
                            <li><img src='https://res.cloudinary.com/ddnekoeii/image/upload/v1711002043/images/location.png' height={30} width={30} alt="location" title="Location" /><span className='text-decoration-none' style={{ marginLeft: "5px" }}><span className='fw-bold' style={{ fontSize: "16px", color: "#aa8038" }}>Sales office</span><br />502-Iris Bay<br />Opposite JW Marriot Hotel<br />Business Bay - Dubai UAE 242986</span></li>
                        </ul>
                    </div>
                    <div className="footer-mobile border-left col-lg-3 col-md-6 pt-3 mb-1" style={{ backgroundColor: "#fbf2de" }}>
                        <h3 className='text-center' style={{ paddingLeft: "2rem" }}><u>Get In Touch</u></h3>
                        <ul className="list-unstyled nav-links" style={{ paddingLeft: "2rem" }}>
                            <li><img src='https://res.cloudinary.com/ddnekoeii/image/upload/v1711002015/images/email-gold.png' height={30} width={30} alt="email" title="Email" /><a href='mailto:sales@devotionbusiness.com' className='text-decoration-none' style={{ marginLeft: "5px" }}>sales@devotionbusiness.com</a></li>
                            <li><img src='https://res.cloudinary.com/ddnekoeii/image/upload/v1711002055/images/phone-gold.png' height={30} width={30} alt="phone" title="Phone" /><a href='tel:+97144488538' className='text-decoration-none' style={{ marginLeft: "5px" }}>+971 44488538</a></li>
                            <li><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1704114953/website/footer_p/icons8-whatsapp.png' height={30} width={30} alt="whatsapp" title="WhatsApp" /><a href='tel:+971585956669' className='text-decoration-none' style={{ marginLeft: "5px" }}>+971 585956669</a></li>
                        </ul>
                    </div>
                    <div className="footer-mobile border-left col-lg-3 col-md-6 pt-3 mb-1" style={{ backgroundColor: "#fbf2de" }}>
                        <Link to="/" onClick={handleNavLinkClick}><img src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002044/images/logo.png" className="pt-2 pb-4" style={{ display: "block", marginLeft: "auto", marginRight: "auto", width: "50%" }} width={200} alt="logo" title="Logo" /> </Link>
                        <div style={{ paddingLeft: "2rem" }}>
                            <a style={{ color: "black" }}>Start Your Business in the UAE. Devotion is one of the leading 360 Degree Business Services Providers with a portfolio of more than 1500 Companies in the UAE.</a><br /><br />
                            <a href="https://www.instagram.com/devotioncorp/" target={'_blank'}><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1704114953/website/footer_p/icons8-instagram.png' className='rounded-2' alt="instagram" title="Instagram" /></a>
                            <a href="https://www.linkedin.com/company/dibn-business-servicess/" target={'_blank'}><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1704114953/website/footer_p/icons8-linkedin.png' className='rounded-2' alt="linked-in" title="Linked In" /></a>
                            <a href="https://wa.me/message/FKVORBGCTZW3I1" target={'_blank'}><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1704114953/website/footer_p/icons8-whatsapp.png' className='rounded-2' alt="whatsapp" title="Whatsapp" /></a>
                            <a href="https://www.facebook.com/devotioncorp" target={'_blank'}><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1704114953/website/footer_p/icons8-facebook.png' className='rounded-2' alt="facebook" title="Facebook" /></a>
                            <a href="https://twitter.com/devotioncorp" target={'_blank'}><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1704114953/website/footer_p/icons8-twitterx.png' className='rounded-2' alt="twitter-x" title="Twitter X" /></a>
                        </div>
                    </div>
                    <div className="row align-items-center mx-auto">
                        <div className="col-12 copyright mt-3 text-center">
                            <span style={{verticalAlign:"-webkit-baseline-middle"}}><small>&copy; 2024 DEVOTION CORPORATE SERVICES L.L.C. All Rights Reserved.</small></span>
                        </div>
                    </div>
                </div>
            </footer >
        </>
    );
};

export default Footer;