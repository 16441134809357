import { useState, useEffect } from "react";
import { MdDelete } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import { FaFile, FaEye } from "react-icons/fa";
import { Helmet } from "react-helmet";
import moment from 'moment';
import API from '../../../middleware/API';
import DataTable from 'react-data-table-component';

// ================================|| ContactList ||================================ //

function ContactList() {
    const navigate = useNavigate();
    const [Page, setPage] = useState(1);
    const [Contactdata, setContactData] = useState();
    const [TotalContactdata, setTotalContactdata] = useState();
    const [loading, setLoading] = useState(false);

    const rowsperPage = "5";

    useEffect(() => {
        getContactDetailList();
    }, [Page]);

    const getContactDetailList = async () => {
        try {
            setLoading(true);
            var GetContactDetailAPI = API.getContactDetail;
            GetContactDetailAPI = GetContactDetailAPI + '?pageNumber=' + Page + '&rowsperPage=' + rowsperPage;
            const response = await fetch(GetContactDetailAPI);
            const jsonData = await response.json();
            setContactData(jsonData.recordsets[0]);
            setTotalContactdata(jsonData.recordsets[1][0].TotalRows);
            setLoading(false);
        }
        catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    function viewContactDetailById(id) {
        navigate('/admin/contact-detail?Id=' + id);
    }

    function deleteContactDetailById(id) {
        var result = window.confirm('Are you sure you want to delete this record?');
        if (result) {
            try {
                var DeleteContactDetailByIdAPI = API.deleteContactDetailById;
                DeleteContactDetailByIdAPI = DeleteContactDetailByIdAPI + "?id=" + id;
                const response = fetch(DeleteContactDetailByIdAPI).then(() => {
                    getContactDetailList(Page);
                });
            }
            catch (error) {
                console.log(error);
            }
        }
    }

    const columns = [
        {
            name: 'Id',
            selector: row => row.Id,
            sortable: true,
            width: "4rem"
        },
        {
            name: 'Name',
            selector: row => row.Name,
            sortable: true
        },
        {
            name: 'Email',
            selector: row => row.Email,
            sortable: true
        },
        {
            name: 'Mobile No',
            selector: row => row.MobileNo,
            sortable: true
        },
        {
            name: 'Service',
            selector: row => row.Service,
            sortable: true
        },
        {
            name: 'Created Date',
            selector: row => moment(row.CreatedOn).format('DD-MM-YYYY'),
            sortable: true
        },
        {
            name: 'Action',
            cell: (props) => <div>
                <a><FaEye size={30} className="m-1 p-1 cl-button -primary -small" onClick={() => viewContactDetailById(props.Id)} style={{ background: "#aa8038", cursor: "pointer" }} /></a>
                <a><MdDelete size={30} className="m-1 p-1 cl-button -primary -small" onClick={() => deleteContactDetailById(props.Id)} style={{ background: "#aa8038", cursor: "pointer" }} /></a>
            </div>,
            sortable: false
        },
    ];

    return (
        <>
            <Helmet>
                <title>Contact</title>
            </Helmet>
            <div className="container-fluid">
                <div className="container pt-5">
                    <h3 className="card-header px-3 pb-3">Contact Details</h3>
                </div>

                <div className="container mx-auto pt-3">
                    <div className="px-3 pb-3">
                        <div className="col-sm-12 table-responsive text-nowrap shadow p-3 mb-5 bg-body rounded">
                            <DataTable
                                columns={columns}
                                data={Contactdata}
                                pagination
                                paginationServer
                                progressPending={loading}
                                paginationTotalRows={TotalContactdata}
                                paginationPerPage={rowsperPage}
                                paginationComponentOptions={{
                                    noRowsPerPage: true
                                }}
                                onChangePage={page => setPage(page)}
                            ></DataTable>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ContactList;