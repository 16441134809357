import { useState, useEffect } from "react";
import { Link, useSearchParams } from 'react-router-dom';
import { Helmet } from "react-helmet";
import moment from 'moment';
import API from '../../../middleware/API';

// ================================|| User ||================================ //

function User() {
    const [params] = useSearchParams();
    const [Userdata, setUserData] = useState();

    useEffect(() => {
        getUserById();
    }, []);

    const getUserById = async () => {
        try {
            var GetUserByIdAPI = API.getUserById;
            GetUserByIdAPI = GetUserByIdAPI + "?id=" + params.get("Id");
            const response = await fetch(GetUserByIdAPI);
            const jsonData = await response.json();
            setUserData(jsonData.recordsets[0]);
        }
        catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <Helmet>
                <title>User</title>
            </Helmet>
            <div className="container-fluid">
                <div className="container row pt-5">
                    <div className="col">
                        <h3 className="card-header px-3 pb-3">User Detail</h3>
                    </div>
                    <div className="col" style={{ textAlign: "end" }}>
                        <Link to='/admin/users' className="btn -primary text-white" style={{ background: "#aa8038" }}>Back to List</Link>
                    </div>
                </div>
                
                <div className="container-fluid mx-auto ">
                    <div className="px-3 pb-3">
                        <div className="table-responsive text-nowrap shadow p-3 mb-5 bg-body rounded">
                            <div className="container">
                                <div className="col-md-8 mx-auto">
                                    <div className="border rounded-3 p-2">
                                        <table className="row">
                                            <div className="col-md-3">
                                                <thead>
                                                    <tr>
                                                        <div className="col pb-2 fs-6 fw-bold">Index :- </div>
                                                        <div className="col pb-2 fs-6 fw-bold">Role Name :- </div>
                                                        <div className="col pb-2 fs-6 fw-bold">Full Name :- </div>
                                                        <div className="col pb-2 fs-6 fw-bold">UserName :- </div>
                                                        <div className="col pb-2 fs-6 fw-bold">Password :- </div>
                                                        <div className="col pb-2 fs-6 fw-bold">Email Id :- </div>
                                                        <div className="col pb-2 fs-6 fw-bold">Mobile No :- </div>
                                                        <div className="col pb-2 fw-bold">Address :- </div>
                                                        <div className="col pb-2 fs-6 fw-bold">Created On :- </div>
                                                        <div className="col pb-2 fs-6 fw-bold">Created By :- </div>
                                                    </tr>
                                                </thead>
                                            </div>
                                            <div className="col-md-9">
                                                <tbody className="pb-4">
                                                    {Userdata &&
                                                        Userdata.map(({ Id, Role, FullName, UserName, Password, EmailId, MobileNo, Address, CreatedOn, CreatedBy }) => (
                                                            <tr key={Id}>
                                                                <div className='col table-data pb-2'>{Id}</div>
                                                                <div className='col table-data pb-2'>{Role ? <span>{Role}</span> : "N/A"}</div>
                                                                <div className='col table-data pb-2'>{FullName}</div>
                                                                <div className='col table-data pb-2'>{UserName}</div>
                                                                <div className='col table-data pb-2'>{Password}</div>
                                                                <div className='col table-data pb-2'><a className="" href='mailto:{EmailId}' style={{ cursor: "pointer" }}>{EmailId}</a></div>
                                                                <div className='col table-data pb-2'>+91 {MobileNo}</div>
                                                                <div className='col table-data pb-2' style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}>{Address}</div>
                                                                <div className='col table-data pb-2'>{moment(CreatedOn).format('DD-MM-YYYY')}</div>
                                                                <div className='col table-data pb-2'>{CreatedBy}</div>
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </div>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default User;