import React, { useState } from 'react';
import { Button } from './ScrollButtonStyle';
import './navbar.css';

const ScrollButton = () => {

    const [visible, setVisible] = useState(false)

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 100) {
            setVisible(true)
        }
        else if (scrolled <= 100) {
            setVisible(false)
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour 
               in place of 'smooth' */
        });
    };

    window.addEventListener('scroll', toggleVisible);

    return (
        <div>
            <Button>
                <a href='https://wa.me/message/FKVORBGCTZW3I1' target='_blank'><img src='https://res.cloudinary.com/ddnekoeii/image/upload/v1711002087/images/whatsapp.png' className='whatsapp'
                    style={{ display: 'block' }} alt="whatsapp" title="Whatsapp" /></a>
            </Button>
            <img src='https://res.cloudinary.com/ddnekoeii/image/upload/v1711002078/images/top-arrow.png' className='backtotop' onClick={scrollToTop}
                style={{ display: visible ? 'block' : 'none' }} alt="backtotop" title="Back to Top" />
        </div>
    );
}

export default ScrollButton;