import React from 'react';
import { Helmet } from "react-helmet";

// ================================|| VATDeregistration ||================================ //

const VATDeregistration = () => {

    return (
        <>
            <Helmet>
                <title>VAT Deregistration Services in Dubai and the UAE</title>
            </Helmet>
            <div class="chairman-message">
                <div class="chairman-img">
                    <div class="pos-message-box">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-7 aos-init" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2 className='alignment'>VAT Deregistration</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img class="d-lg-block d-none" style={{ width: "-webkit-fill-available" }} src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001997/images/Contact-us.png" alt="vat-deregistration-banner" title="Vat Deregistration Banner" />
            </div>
            <div className="container">
                <div className="row pt-5 pb-5">
                    <h1><span style={{ color: "#aa8038" }}>VAT Deregistration Services in UAE</span></h1>
                    <div className='col-12'>
                        <p>
                            Generally, the end or withdrawal of VAT selection is called VAT Deregistration.
                            As per the law portrayed under the rules of FTA, the following capability is ought to apply for VAT Deregistration in UAE.
                        </p>
                    </div>
                    <div className='col-md-7'>
                        <h6 className='pt-3'>1. Mandatory VAT Deregistration</h6>
                        <p>
                            A Registrant will apply to the Authority for Tax Deregistration in any of the going with cases:
                        </p>
                        <p>
                            <ul>
                                <li style={{ listStyle: "unset" }}>
                                    Expecting he stops making Taxable Supplies.
                                </li>
                                <li style={{ listStyle: "unset" }}>
                                    If the value of the Taxable Supplies made throughout (12) progressive months isn't the very Voluntary Registration Threshold (for instance AED 187,500) and he expects that he will not show up at the stubborn enlistment limit in the next 30 days.
                                </li>
                            </ul>
                        </p>
                        <h6 className='pt-3'>2. Voluntary VAT Deregistration</h6>
                        <p>
                            A Registrant could apply to the Authority for Tax Deregistration if:-
                        </p>
                        <p>
                            <ul>
                                <li style={{ listStyle: "unset" }}>
                                    If the value of his Taxable Supplies during the past year was not the very Mandatory Registration Threshold (for instance AED 375,000) and
                                </li>
                                <li style={{ listStyle: "unset" }}>
                                    12 timetable months have passed since the substance was selected with FTA under persistent enrollment.
                                </li>
                            </ul>
                        </p>
                    </div>
                    <div className='col-md-5'>
                        <img class="card-img-top" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002082/images/vat-deregistration.jpg" alt="vat-deregistration" title="Vat Deregistration" />
                    </div>
                    <h2 style={{ color: "#aa8038" }}>HOW TO APPLY FOR VAT DEREGISTRATION ?</h2>
                    <p>
                        Deregistration applications can be made through FTA Online Portal. After login in, through VAT deregistration structure UAE you can give the nuances close by the assistance behind the VAT deregistration.
                        Several information will eventually be populated into a structure from the past selection data.
                    </p>
                    <p>
                        The decision of FTA to embrace the application for deregistration of VAT depends on the offered reason as well as the opportunity of all:
                    </p>
                    <div class="row pb-5">
                        <div class="col-lg-4 col-md-4 col-sm-4 float-left mt-2">
                            <div class="pl-4 pr-4 pb-1 pt-1" style={{ background: "#aa8038", borderRadius: "25px" }}>
                                <h5 class="text-center text-white">a. Outstanding liability</h5>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 float-left mt-2">
                            <div class="pl-4 pr-4 pb-1 pt-1" style={{ background: "#aa8038", borderRadius: "25px" }}>
                                <h5 class="text-center text-white">b. Returns being filed</h5>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 float-left mt-2">
                            <div class="pl-4 pr-4 pb-1 pt-1" style={{ background: "#aa8038", borderRadius: "25px" }}>
                                <h5 class="text-center text-white">c. Any Administrative Penalties</h5>
                            </div>
                        </div>
                    </div>
                    <h2 style={{ color: "#aa8038" }}>DEREGISTRATION PENALTY</h2>
                    <p>
                        The penalty for failing to apply for a VAT deregistration with specific timeline i.e. within 20 business days, is AED. 10,000.
                    </p>
                </div>
            </div>
        </>
    );
};

export default VATDeregistration;