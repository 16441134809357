import React from 'react';
import ModalDialog from './UmmDialogEnquiry';
import ModalDownloadDialog from './UmmDialogDownload';
import { Helmet } from "react-helmet";

// ================================|| UmmAlQuwainFreeZones ||================================ //

const UmmAlQuwainFreeZones = () => {

    return (
        <>
            <Helmet>
                <title>Umm Al Quwain Free Zone Business Setup | Company Registration in UAQ</title>
            </Helmet>
            <div class="chairman-message">
                <div class="chairman-img">
                    <div class="pos-message-freezone-box">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-7 aos-init" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2>Umm Al Quwain Free Zone</h2>
                                    <p>
                                        UAQ Streamlined commerce Zone is situated in the littlest Emirate of the UAE yet offers every one of the offices that bigger free zones are proposing.
                                    </p>
                                    <p>
                                        Umm Al Quwain Free Zone has drawn in organizations that are searching for reasonable permits to operate, workplaces and stockrooms.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img class="d-lg-block chairman-desktop-umm-img d-none" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001991/images/Chairmans-Message_desktop.jpg" alt="umm-al-quwain-banner" title="UMM Al Quwain Banner" />
                <img class="d-lg-none d-block" style={{ width: "-webkit-fill-available" }} src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001991/images/Chairmans-Message_mobile.jpg" alt="umm-al-quwain-banner" title="UMM Al Quwain Banner" />
            </div>
            <div class="pt-5 pb-5">
                <div class="container">
                    <div class="row">
                        <h1><span style={{ color: "#aa8038" }}>Business Setup in UAQ Freezone</span></h1>
                        <div class="col-lg-3 col-md-4 col-sm-6 mt-2 float-left allowHover">
                            <div class="card border-brown business-broucher-blocks busniess-block-shadow">
                                <div class="card-body">
                                    <span>
                                        <img class="img-fluid mx-auto d-block busniess-img-shadow" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002079/images/umm-al-quwain-freezones_1.jpg" alt="umm-al-quwain" title="UMM Al Quwain" />
                                    </span>
                                    <h5 class="text-brown text-break text-center mt-4">
                                        Umm Al Quwain Free Zone Trade (UAQFTZ)
                                    </h5>
                                    <p id='Umm Al Quwain Free Zone Trade (UAQFTZ)' class="text-center mt-4 freezoneblock-enquiry">
                                        <ModalDialog />
                                    </p>
                                    <p id='Umm Al Quwain Free Zone Trade (UAQFTZ)' class="text-center mt-3 freezoneblock-download">
                                        <ModalDownloadDialog />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UmmAlQuwainFreeZones;