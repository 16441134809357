import React from 'react';
import { Helmet } from "react-helmet";

// ================================|| TrademarkRegistration ||================================ //

const TrademarkRegistration = () => {

    return (
        <>
            <Helmet>
                <title>Trademark Registration Service in Dubai and the UAE</title>
            </Helmet>
            <div class="chairman-message">
                <div class="chairman-img">
                    <div class="pos-message-box">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-7 aos-init" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2 className='alignment'>Trademark Registration</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img class="d-lg-block d-none" style={{ width: "-webkit-fill-available" }} src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001997/images/Contact-us.png" alt="trademark-banner" title="Trademark Banner" />
            </div>
            <div className="container">
                <div className="row pt-5 pb-5">
                    <div className='col-md-7'>
                        <h1><span style={{ color: "#aa8038" }}>Trademark Registration Service in UAE</span></h1>
                        <p>
                            Brand names are Logos, names or short trademarks that assist to isolate a 'decent' or 'administration' from different labor and products in a specific geographic region.
                            The regulations plan to shield the interests of the two organizations and purchasers in the country The Federal Laws of the United Arab Emirates give assurance to enlisted brand names.
                            The UAE Trademarks Laws cover the accompanying primary regions:
                        </p>
                        <ul>
                            <li style={{ listStyle: "unset" }}>
                                Clarity & Defination of trademarks
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Signs that can and cannot be registered as trademarks
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Registration and cancellation procedures for trademarks
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Transfer of ownership of trademarks
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Licensing others to use trademarks
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Penalties for trademark law infringement and general and transitory provisions.
                            </li>
                        </ul>
                    </div>
                    <div className='col-md-5'>
                        <img class="card-img-top" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002080/images/trademark-registration.jpg" alt="trademark" title="Trademark" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default TrademarkRegistration;