import React from 'react';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css';
import Header from './navbar/Header';
import Navbar from './navbar/Navmenu';
import Footer from './navbar/Footer';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Dashboard from './pages/dashboard/Dashboard';
import MyProfile from './navbar/navigation/MyProfile';
import SaveBlog from './pages/blog/SaveBlog';
import BlogList from './pages/blog/BlogList';
import Blog from './pages/blog/Blog';
import EditBlog from './pages/blog/EditBlog';
import ScheduleBlogList from './pages/blog/schedule-blog/ScheduleBlogList';
import TrashBlog from './pages/blog/trash-blog/TrashBlog';
import TrashBlogList from './pages/blog/trash-blog/TrashBlogList';
import CommentBlogList from './pages/blog/comment-blog/CommentBlogList';
import CommentBlog from './pages/blog/comment-blog/CommentBlog';
import CategoryList from './pages/blog/category/CategoryList';
import Category from './pages/blog/category/Category';
import SaveCategory from './pages/blog/category/SaveCategory';
import EditCategory from './pages/blog/category/EditCategory';
import RequestAQuoteList from './pages/request-a-quote/RequestAQuoteList';
import RequestAQuote from './pages/request-a-quote/RequestAQuote';
import ContactList from './pages/contact/ContactList';
import Contact from './pages/contact/Contact';
import ProjectDetailList from './pages/project-detail/ProjectDetailList';
import ProjectDetail from './pages/project-detail/ProjectDetail';
import RecruitmentList from './pages/recruitment/RecruitmentList';
import Recruitment from './pages/recruitment/Recruitment';
import Replied from './pages/recruitment/Replied';
import SaveUser from './pages/user/SaveUser';
import UserList from './pages/user/UserList';
import User from './pages/user/User';
import EditUser from './pages/user/EditUser';
import SaveRole from './pages/role/SaveRole';
import RoleList from './pages/role/RoleList';
import Role from './pages/role/Role';
import EditRole from './pages/role/EditRole';
import RolePermission from './pages/role-permission/RolePermission';
import SaveCurrentOpening from './pages/current-opening/SaveCurrentOpening';
import CurrentOpeningList from './pages/current-opening/CurrentOpeningList';
import EditCurrentOpening from './pages/current-opening/EditCurrentOpening';
import { useEffect } from 'react';


function Admin() {
	useEffect(()=>{
		setTimeout(()=>{
			if (window.location.pathname === "/admin/dashboard") {
				document.getElementById('chatIconTbc').classList.add('d-none')
			}
		}, 50)
	},[])

	return (
		<div className="layout-wrapper layout-content-navbar">
			<div className="layout-container">
				<Router>
					<Navbar />
					<div className="layout-page" style={{backgroundColor: "#f5f5f9"}}>
						<Header />
						<div className="content-wrapper">
							<Routes>
								<Route path='/admin/dashboard' element={<Dashboard />} />
								<Route path='/admin/my-profile' element={<MyProfile />} />
								<Route path='/admin/save-blog' element={<SaveBlog />} />
								<Route path='/admin/blogs' element={<BlogList />} />
								<Route path='/admin/blog' element={<Blog />} />
								<Route path='/admin/edit-blog' element={<EditBlog />} />
								<Route path='/admin/schedule-blogs' element={<ScheduleBlogList />} />
								<Route path='/admin/trash-blog' element={<TrashBlog />} />
								<Route path='/admin/trash-blogs' element={<TrashBlogList />} />
								<Route path='/admin/comments-blog' element={<CommentBlogList />} />
								<Route path='/admin/comment-blog' element={<CommentBlog />} />
								<Route path='/admin/categories' element={<CategoryList />} />
								<Route path='/admin/category' element={<Category />} />
								<Route path='/admin/save-category' element={<SaveCategory />} />
								<Route path='/admin/edit-category' element={<EditCategory />} />
								<Route path='/admin/request-a-quotes' element={<RequestAQuoteList />} />
								<Route path='/admin/request-a-quote' element={<RequestAQuote />} />
								<Route path='/admin/contact-details' element={<ContactList />} />
								<Route path='/admin/contact-detail' element={<Contact />} />
								<Route path='/admin/project-details' element={<ProjectDetailList />} />
								<Route path='/admin/project-detail' element={<ProjectDetail />} />
								<Route path='/admin/recruitments' element={<RecruitmentList />} />
								<Route path='/admin/recruitment' element={<Recruitment />} />
								<Route path='/admin/replied' element={<Replied />} />
								<Route path='/admin/save-user' element={<SaveUser />} />
								<Route path='/admin/users' element={<UserList />} />
								<Route path='/admin/user' element={<User />} />
								<Route path='/admin/edit-user' element={<EditUser />} />
								<Route path='/admin/save-role' element={<SaveRole />} />
								<Route path='/admin/roles' element={<RoleList />} />
								<Route path='/admin/role' element={<Role />} />
								<Route path='/admin/edit-role' element={<EditRole />} />
								<Route path='/admin/role-permission' element={<RolePermission />} />
								<Route path='/admin/save-current-opening' element={<SaveCurrentOpening />} />
								<Route path='/admin/current-openings' element={<CurrentOpeningList />} />
								<Route path='/admin/edit-current-opening' element={<EditCurrentOpening />} />
							</Routes>
							<Footer />
							<div className="content-backdrop fade"></div>
						</div>
					</div>
				</Router>
			</div>
			<div className="layout-overlay layout-menu-toggle"></div>
		</div>
	);
}

export default Admin;
