import React, { useState } from 'react';
import Faq from "react-faq-component";
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { FaAngleRight } from 'react-icons/fa';
// import './carousel';


// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Card from 'react-bootstrap/Card';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

// ================================|| Home ||================================ //

const Home = () => {

	const [showFisrtText, setFisrtText] = useState(false);
	const [showSecondText, setSecondText] = useState(false);
	const [showThirdText, setThiredText] = useState(false);
	const [showFourthText, setFourthText] = useState(false);
	const [showFifthText, setFifthText] = useState(false);

	const handleShowText = event => {
		if (event.currentTarget.id === "1") {
			setFisrtText(!showFisrtText)
			setSecondText(false)
			setThiredText(false)
			setFourthText(false)
			setFifthText(false)
		}
		else if (event.currentTarget.id === "2") {
			setFisrtText(false)
			setSecondText(!showSecondText)
			setThiredText(false)
			setFourthText(false)
			setFifthText(false)
		}
		else if (event.currentTarget.id === "3") {
			setFisrtText(false)
			setSecondText(false)
			setThiredText(!showThirdText)
			setFourthText(false)
			setFifthText(false)
		}
		else if (event.currentTarget.id === "4") {
			setFisrtText(false)
			setSecondText(false)
			setThiredText(false)
			setFourthText(!showFourthText)
			setFifthText(false)
		}
		else if (event.currentTarget.id === "5") {
			setFisrtText(false)
			setSecondText(false)
			setThiredText(false)
			setFourthText(false)
			setFifthText(!showFifthText)
		}
		else {
			setFisrtText(false)
			setSecondText(false)
			setThiredText(false)
			setFourthText(false)
			setFifthText(false)
		}
	}

	const responsive1 = {
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 1,
			slidesToSlide: 1 // optional, default to 1.
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 1,
			slidesToSlide: 1 // optional, default to 1.
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1,
			slidesToSlide: 1 // optional, default to 1.
		}
	};

	const responsive = {
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 3,
			slidesToSlide: 1 // optional, default to 1.
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 3,
			slidesToSlide: 1 // optional, default to 1.
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1,
			slidesToSlide: 1 // optional, default to 1.
		}
	};

	const mainland = {
		title: "",
		rows: [
			{
				title: "Can a business setup in Dubai mainland operate without a sponsor?",
				content: `Businesses in Dubai mainland can now operate without a local 
				sponsor, allowing 100% foreign ownership as per the updated UAE Commercial 
				Companies Law. This move is aimed at boosting foreign investment and offering 
				entrepreneurs greater control and flexibility in their operations.`,
			},
			{
				title: "Can I have 100% ownership in my business setup in Dubai mainland?",
				content: `Yes, you can now fully own your business for certain business activities. You don’t need an Emirati 
				partner to have most of the ownership. But some activities still need a local sponsor.`,
			},
			{
				title: "Are there any restrictions on the number of visas for a mainland business setup in Dubai?",
				content: `In Dubai’s mainland, businesses have no visa limits. They can get visas based on their office space. 
				This helps firms grow and hire diverse staff in Dubai.`,
			},
			{
				title: "Can I set up a mainland company without a physical office space?",
				content: `In Dubai, setting up a mainland company usually requires leasing a physical office 
				space, but professional activities may offer some exemptions. Detailed guidance, including options like virtual 
				offices, is best sought through business setup advisory services.`,
			},
		],
	};

	const freezone = {
		title: "",
		rows: [
			{
				title: "What is a Free Zone Company Setup?",
				content: `The UAE has special economic zones. These zones give perks like full ownership, no taxes, and 
				simplified processes. A company set in a free zone is called a freezone company setup.`,
			},
			{
				title: "Can I own a free zone company alone?",
				content: `Yes, you can own a Free Zone company in Dubai alone as a Free Zone Establishment 
				(FZE), which is a business entity with a single shareholder. This allows for 100% foreign ownership without 
				requiring a local sponsor, offering benefits like tax exemptions and complete repatriation of profits and capital.`,
			},
			{
				title: "How long does the Dubai freezone company setup process take?",
				content: `The Dubai freezone company setup can take a few weeks. It can change based on the specific free 
				zone, how complicated your business is, and whether your paperwork is complete.`,
			},
		],
	};

	const accounting = {
		title: "",
		rows: [
			{
				title: "What are accounts outsourcing bookkeeping?",
				content: `We provide specialized bookkeeping and accounting services; we meet all your accounting 
				requirements. Outsourcing is a very cost-effective way of reducing the accountants’ hiring and training 
				expenses in an organization.`,
			},
			{
				title: "Do I lose control if my accounts are handled by an external agency?",
				content: `No, you do not. But you will have more time to make informed decisions on all the financial aspects 
				once accounting and bookkeeping are completed. As an investor, you can focus on growing your 
				business instead of reacting to business events.`,
			},
			{
				title: "What’s included in accounting services by an external agency?",
				content: `The services offered by us can be tailored to include almost everything an in-house accounting 
				department would do or as little as you would want and provide useful financial information that can 
				benefit your company’s growth.`,
			},
			{
				title: "How many years company have to keep their book of account?",
				content: `Books of Accounts should be maintained for a period of 5 Years from the end of the financial year.`,
			},
			{
				title: "What is VAT in Dubai?",
				content: `VAT = Value Added Tax levied on the consumption or use of goods and services at each point of sale. 
				It is in existence in more than 180 countries around the world. The end-consumer ultimately bears the 
				cost.`,
			},
			{
				title: "How can I get VAT Certificate in UAE?",
				content: `In-order to obtain VAT Certificate from the Federal Tax Authority the applicant must consider various 
				aspects such as, whether to register as 
				<ul>
					<li style="list-style: unset">
						Voluntary Applicant 
					</li>
					<li style="list-style: unset">
						Mandatory Registrant
					</li>
					<li style="list-style: unset">
						Tax Group
					</li>
					<li style="list-style: unset">
						Standalone
					</li>
				</ul>`,
			},
			{
				title: "What is VAT Certificate in UAE?",
				content: `VAT Certificate is a Statutory Document issued by the Federal tax Authority confirming the 
				registration of the applicant mentioning the Tax Registration Number (TRN) & the period for which 
				the Tax Returns need to be filed.`,
			},
			{
				title: "What is the percentage of VAT in Dubai?",
				content: `In terms of Standard Rated Supplies, the Percentage of VAT is 5%. However, there are Exempt & Zero
				Rated Supplies as well.`,
			},
		],
	};

	const styles = {
		titleTextColor: "black",
		bgColor: "#fbf2de"
	};

	const config = {
		bgColor: "#fbf2de",
	};

	return (
		<>
			<script type="application/ld+json"
				dangerouslySetInnerHTML={{
					__html:
						`{
							  "@context": "https://schema.org",
							  "@type": "FAQPage",
							  "mainEntity": [{
								"@type": "Question",
								"name": "Can a business setup in Dubai mainland operate without a sponsor?",
								"acceptedAnswer": {
								  "@type": "Answer",
								  "text": "Businesses in Dubai mainland can now operate without a local sponsor, allowing 100% foreign ownership as per the updated UAE Commercial Companies Law. This move is aimed at boosting foreign investment and offering entrepreneurs greater control and flexibility in their operations."
								}
							  },{
								"@type": "Question",
								"name": "Can I have 100% ownership in my business setup in Dubai mainland?",
								"acceptedAnswer": {
								  "@type": "Answer",
								  "text": "Yes, you can now fully own your business for certain business activities. You don’t need an Emirati partner to have most of the ownership. But some activities still need a local sponsor."
								}
							  },{
								"@type": "Question",
								"name": "Are there any restrictions on the number of visas for a mainland business setup in Dubai?",
								"acceptedAnswer": {
								  "@type": "Answer",
								  "text": "In Dubai’s mainland, businesses have no visa limits. They can get visas based on their office space. This helps firms grow and hire diverse staff in Dubai."
								}
							  },{
								"@type": "Question",
								"name": "Can I set up a mainland company without a physical office space?",
								"acceptedAnswer": {
								  "@type": "Answer",
								  "text": "In Dubai, setting up a mainland company usually requires leasing a physical office space, but professional activities may offer some exemptions. Detailed guidance, including options like virtual offices, is best sought through business setup advisory services."
								}
							  },{
								"@type": "Question",
								"name": "What is a Free Zone Company Setup?",
								"acceptedAnswer": {
								  "@type": "Answer",
								  "text": "The UAE has special economic zones. These zones give perks like full ownership, no taxes, and simplified processes. A company set in a free zone is called a freezone company setup."
								}
							  },{
								"@type": "Question",
								"name": "Can I own a free zone company alone?",
								"acceptedAnswer": {
								  "@type": "Answer",
								  "text": "Yes, you can own a Free Zone company in Dubai alone as a Free Zone Establishment (FZE), which is a business entity with a single shareholder. This allows for 100% foreign ownership without requiring a local sponsor, offering benefits like tax exemptions and complete repatriation of profits and capital."
								}
							  },{
								"@type": "Question",
								"name": "How long does the Dubai freezone company setup process take?",
								"acceptedAnswer": {
								  "@type": "Answer",
								  "text": "The Dubai freezone company setup can take a few weeks. It can change based on the specific free zone, how complicated your business is, and whether your paperwork is complete."
								}
							  },{
								"@type": "Question",
								"name": "What is licensing in international business?",
								"acceptedAnswer": {
								  "@type": "Answer",
								  "text": "Licensing in international business involves a company (licensor) granting another company (licensee) the right to use its intellectual property, like trademarks or technology, in exchange for fees or royalties. This allows the licensee to use and benefit from the intellectual property without developing it themselves."
								}
							  },{
								"@type": "Question",
								"name": "What are the benefits of an international business license?",
								"acceptedAnswer": {
								  "@type": "Answer",
								  "text": "Benefits of an international business license include:
							
							Market Expansion: Allows companies to enter new markets without significant upfront investment.
							
							Cost Savings: Licensees avoid the high costs of research, development, and marketing for intellectual property.
							
							Risk Mitigation: Reduces risks associated with entering new markets by leveraging the local knowledge and expertise of the licensee.
							
							Revenue Generation: Provides an additional income stream through licensing fees or royalties.
							
							Brand Exposure: Expands brand presence globally through the licensee's activities.
							
							Flexibility: Allows licensors to focus on core competencies while licensees handle local operations.
							
							Global Presence: Enables rapid international expansion without the need for substantial infrastructure investment."
								}
							  },{
								"@type": "Question",
								"name": "How to acquire an international business license?",
								"acceptedAnswer": {
								  "@type": "Answer",
								  "text": "To acquire an international business license:
							
							Identify Intellectual Property: Determine the intellectual property to license, such as trademarks, patents, or technology.
							
							Legal Consultation: Seek legal advice to understand licensing regulations and requirements in target markets.
							
							Due Diligence: Research potential licensees, considering their reputation, capabilities, and market presence.
							
							Negotiate Terms: Discuss and negotiate terms, including fees, royalties, duration, and quality control, with the chosen licensee.
							
							Draft Agreement: Develop a comprehensive licensing agreement detailing rights, obligations, and conditions.
							
							Legal Approval: Ensure legal compliance and seek approval from relevant authorities, if required.
							
							Sign Agreement: Finalize the agreement with signatures from both parties.
							
							Quality Control: Implement measures to maintain and monitor the quality of licensed products or services.
							
							Monitor and Enforce: Regularly monitor licensee activities and enforce terms to protect intellectual property rights."
								}
							  },{
								"@type": "Question",
								"name": "What are accounts outsourcing bookkeeping?",
								"acceptedAnswer": {
								  "@type": "Answer",
								  "text": "We provide specialized bookkeeping and accounting services; we meet all your accounting requirements. Outsourcing is a very cost-effective way of reducing the accountants’ hiring and training expenses in an organization."
								}
							  },{
								"@type": "Question",
								"name": "Do I lose control if my accounts are handled by an external agency?",
								"acceptedAnswer": {
								  "@type": "Answer",
								  "text": "No, you do not. But you will have more time to make informed decisions on all the financial aspects once accounting and bookkeeping are completed. As an investor, you can focus on growing your business instead of reacting to business events."
								}
							  },{
								"@type": "Question",
								"name": "What’s included in accounting services by an external agency?",
								"acceptedAnswer": {
								  "@type": "Answer",
								  "text": "The services offered by us can be tailored to include almost everything an in-house accounting department would do or as little as you would want and provide useful financial information that can benefit your company’s growth."
								}
							  },{
								"@type": "Question",
								"name": "How many years company have to keep their book of account?",
								"acceptedAnswer": {
								  "@type": "Answer",
								  "text": "Books of Accounts should be maintained for a period of 5 Years from the end of the financial year."
								}
							  },{
								"@type": "Question",
								"name": "What is VAT in Dubai?",
								"acceptedAnswer": {
								  "@type": "Answer",
								  "text": "VAT = Value Added Tax levied on the consumption or use of goods and services at each point of sale. It is in existence in more than 180 countries around the world. The end-consumer ultimately bears the cost."
								}
							  },{
								"@type": "Question",
								"name": "How can I get VAT Certificate in UAE?",
								"acceptedAnswer": {
								  "@type": "Answer",
								  "text": "In-order to obtain VAT Certificate from the Federal Tax Authority the applicant must consider various aspects such as, whether to register as
							Voluntary Applicant
							Mandatory Registrant
							Tax Group
							Standalone"
								}
							  },{
								"@type": "Question",
								"name": "What is VAT Certificate in UAE?",
								"acceptedAnswer": {
								  "@type": "Answer",
								  "text": "VAT Certificate is a Statutory Document issued by the Federal tax Authority confirming the registration of the applicant mentioning the Tax Registration Number (TRN) & the period for which the Tax Returns need to be filed."
								}
							  },{
								"@type": "Question",
								"name": "What is the percentage of VAT in Dubai?",
								"acceptedAnswer": {
								  "@type": "Answer",
								  "text": "In terms of Standard Rated Supplies, the Percentage of VAT is 5%. However, there are Exempt & Zero Rated Supplies as well."
								}
							  }]
						}`
				}}
			/>
			<Helmet>
				<title>Business Setup in Dubai | LLC Company Formation in the UAE</title>
			</Helmet>
			<Carousel
				swipeable={true}
				draggable={false}
				showDots={true}
				responsive={responsive1}
				ssr={true}
				arrows={false}
				// infinite={true}
				autoPlay={true}
				autoPlaySpeed={3000}
				keyBoardControl={true}
				customTransition="all .5"
				transitionDuration={500}
				containerClass="carousel-container p-0"
				// itemClass="thumbnail"
				dotListClass="custom-dot-list-style"
				height="600px"
			>
				<div className="mx-auto">
					<div className='container'>
						<div className="container align-self-center" style={{ position: "absolute" }}>
							<div className="container row text-black mx-auto flex-lg-row pt-2 pb-5 pt-lg-0">
								<div className='col-lg-8 col-md-8 col-sm-8'>
									<div><h1 className="main-contain mb-3" style={{ fontSize: "18px" }}><div class='squre squre-color'></div>Business Setup in Dubai</h1></div>
									<h2 className="h2-text fw-bold">Unlock Your Company <br />Formation in Dubai-UAE</h2>
									<div><h3 className="mt-2 mb-3" style={{ fontSize: "16px", fontWeight: "400" }}><div class='triangle'></div>Tailored Solutions for Seamless Business Setup</h3></div>
									<a className="banner-btn mt-4 mb-3 bg-black" href="https://devotionbusiness.com/InvestmentCostCalculator/index.html" target='_blank'>
										Investment Cost Calculator
									</a>
								</div>
								<div className="col-lg-3 col-md-3 col-sm-3 main-contain">
									<div className='border rounded-3 shadow-sm' style={{ width: "fit-content", padding: "1rem", backgroundColor: "rgb(255 255 255 / 40%)" }}>
										<p className='mb-0' style={{ fontSize: "17px" }}>12 Years of Experience <br /> 1500+ Companies Registered.</p>
										<a href='/#reviews' className='text-decoration-none'><p className='mb-0' style={{ fontSize: "17px" }}><img src='https://res.cloudinary.com/ddnekoeii/image/upload/v1711002060/images/ratings.png' width="200" height="50" style={{ marginLeft: "-12px" }} alt='rating' title='Rating' /><br /><span class="text-black">reviews from our customers</span></p></a>
									</div>
								</div>
							</div>
						</div>
					</div>
					<img className='slider-img' src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711027575/images/banner/slide-1.jpg" alt="Testimonials" title="Testimonials" />
				</div>
				<div className="mx-auto">
					<div className='container'>
						<div className="container align-self-center" style={{ position: "absolute" }}>
							<div className="container row text-black mx-auto flex-lg-row pt-2 pb-5 pt-lg-0">
								<div className='col-lg-8 col-md-8 col-sm-8'>
									<div><h2 className="main-contain mb-3" style={{ fontSize: "18px" }}><div class='squre squre-color'></div>Corporate Tax in Dubai-UAE</h2></div>
									<h2 className="h2-text fw-bold">Navigate the Complexities <br />of Corporate Tax in UAE</h2>
									<div><h3 className="mt-2 mb-3" style={{ fontSize: "16px", fontWeight: "400" }}><div class='triangle'></div>Expert Guidance to Optimize Tax Strategies</h3></div>
									<div><h3 className="mt-2 mb-3" style={{ fontSize: "16px", fontWeight: "400" }}><div class='triangle'></div>Calculate Your Tax Liabilities with Ease</h3></div>
									<a className="banner-btn mt-4 mb-3 bg-black" href="https://devotionbusiness.com/InvestmentCostCalculator/index.html" target='_blank'>
										Investment Cost Calculator
									</a>
								</div>
								<div className="col-lg-3 col-md-3 col-sm-3 main-contain">
									<div className='border rounded-3 shadow-sm' style={{ width: "fit-content", padding: "1rem", backgroundColor: "rgb(255 255 255 / 40%)" }}>
										<p className='mb-0' style={{ fontSize: "17px" }}>12 Years of Experience <br /> 1500+ Companies Registered.</p>
										<a href='/#reviews' className='text-decoration-none'><p className='mb-0' style={{ fontSize: "17px" }}><img src='https://res.cloudinary.com/ddnekoeii/image/upload/v1711002060/images/ratings.png' width="200" height="50" style={{ marginLeft: "-12px" }} alt='rating-image' title='Rating Image' /><br /><span class="text-black">reviews from our customers</span></p></a>
									</div>
								</div>
							</div>
						</div>
					</div>
					<img className='slider-img' src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711027579/images/banner/slide-2.jpg" alt="Testimonials" title="Testimonials" />
				</div>
				<div className="mx-auto">
					<div className='container'>
						<div className="container align-self-center" style={{ position: "absolute" }}>
							<div className="container row text-black mx-auto flex-lg-row pt-2 pb-5 pt-lg-0">
								<div className='col-lg-8 col-md-8 col-sm-8'>
									<div><h2 className="main-contain mb-3" style={{ fontSize: "18px" }}><div class='squre squre-color'></div>Accounting and Taxes</h2></div>
									<h2 className="h2-text fw-bold">Efficient Accounting <br />& Tax Solutions in Dubai-UAE</h2>
									<div><h3 className="mt-2 mb-3" style={{ fontSize: "16px", fontWeight: "400" }}><div class='triangle'></div>Stay Compliant, Stay Ahead with our Expert Services</h3></div>
									<div><h3 className="mt-2 mb-3" style={{ fontSize: "16px", fontWeight: "400" }}><div class='triangle'></div>Simplify Your Financial Processes Today</h3></div>
									<a className="banner-btn mt-4 mb-3 bg-black" href="https://devotionbusiness.com/InvestmentCostCalculator/index.html" target='_blank'>
										Investment Cost Calculator
									</a>
								</div>
								<div className="col-lg-3 col-md-3 col-sm-3 main-contain">
									<div className='border rounded-3 shadow-sm' style={{ width: "fit-content", padding: "1rem", backgroundColor: "rgb(255 255 255 / 40%)" }}>
										<p className='mb-0' style={{ fontSize: "17px" }}>12 Years of Experience <br /> 1500+ Companies Registered.</p>
										<a href='/#reviews' className='text-decoration-none'><p className='mb-0' style={{ fontSize: "17px" }}><img src='https://res.cloudinary.com/ddnekoeii/image/upload/v1711002060/images/ratings.png' width="200" height="50" style={{ marginLeft: "-12px" }} alt='rating-image' title='Rating Image' /><br /><span class="text-black">reviews from our customers</span></p></a>
									</div>
								</div>
							</div>
						</div>
					</div>
					<img className='slider-img' src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711027582/images/banner/slide-3.jpg" alt="Testimonials" title="Testimonials" />
				</div>
				<div className="mx-auto">
					<div className='container'>
						<div className="container align-self-center" style={{ position: "absolute" }}>
							<div className="container row text-black mx-auto flex-lg-row pt-2 pb-5 pt-lg-0">
								<div className='col-lg-8 col-md-8 col-sm-8'>
									<div><h2 className="main-contain mb-3" style={{ fontSize: "16px" }}><div class='squre squre-color'></div>PRO Services in Dubai-UAE</h2></div>
									<h2 className="h2-text fw-bold">Streamline Operations with <br />Professional PRO Services</h2>
									<div><h3 className="mt-2 mb-3" style={{ fontSize: "16px" }}><div class='triangle'></div>Effortless Visa, Licensing, and Documentation Processes</h3></div>
									<div><h3 className="mt-2 mb-3" style={{ fontSize: "16px" }}><div class='triangle'></div>Simplify Your Business Journey in UAE</h3></div>
									<a className="banner-btn mt-4 mb-3 bg-black" href="https://devotionbusiness.com/InvestmentCostCalculator/index.html" target='_blank'>
										Investment Cost Calculator
									</a>
								</div>
								<div className="col-lg-3 col-md-3 col-sm-3 main-contain">
									<div className='border rounded-3 shadow-sm' style={{ width: "fit-content", padding: "1rem", backgroundColor: "rgb(255 255 255 / 40%)" }}>
										<p className='mb-0' style={{ fontSize: "17px" }}>12 Years of Experience <br /> 1500+ Companies Registered.</p>
										<a href='/#reviews' className='text-decoration-none'><p className='mb-0' style={{ fontSize: "17px" }}><img src='https://res.cloudinary.com/ddnekoeii/image/upload/v1711002060/images/ratings.png' width="200" height="50" style={{ marginLeft: "-12px" }} alt='rating-image' title='Rating Image' /><br /><span class="text-black">reviews from our customers</span></p></a>
									</div>
								</div>
							</div>
						</div>
					</div>
					<img className='slider-img' src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711027589/images/banner/slide-5.jpg" alt="Testimonials" title="Testimonials" />
				</div>
			</Carousel>

			{/* 5 EASY STEPS For desktop */}
			<div id='steps-desktop' className="three-coloum-content" style={{ backgroundColor: "#fbf2de" }}>
				<div className="container">
					<div className="row">
						<h3 className='text-center fw-bold mt-5 mb-5 hr-line-center'>Make Your Business Setup in UAE with <span style={{ color: "#aa8038" }}>5 EASY STEPS</span></h3>
						<div className="col-md-4">
							<div className="cards card-effect">
								<div id='1' onMouseEnter={handleShowText} onMouseLeave={handleShowText}>
									<i>
										<img width="60" height="50" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002073/images/Step-1.png" alt="step-1" title="Step-1" />
									</i>
									<div className='text-center'>
										<i>
											<img width="50" height="50" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002022/images/free-consultation.svg" alt="Free Consultation" title="Free Consultation" />
										</i>
										<p className='fw-bold'>Free Consultation</p>
										<p className={`${showFisrtText ? '' : 'd-none'}`}>Devotion are free consultants providing collaborative solutions for establishing and running your business in the UAE.</p>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-4">
							<div className="cards card-effect">
								<div id='2' onMouseEnter={handleShowText} onMouseLeave={handleShowText}>
									<i>
										<img width="60" height="50" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002074/images/Step-2.png" alt="step-2" title="Step-2" />
									</i>
									<div className='text-center'>
										<i>
											<img width="50" height="50" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002063/images/registered-company-formation.svg" alt="Company Type" title="Company Type" />
										</i>
										<p className='fw-bold'>Company Type</p>
										<p className={`${showSecondText ? '' : 'd-none'}`}>Many Jurisdictions and Incorporation options are available in the UAE. Different type of Mainland, Free Zone and Offshore, & Branch Setup.</p>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-4">
							<div className="cards card-effect">
								<div id='3' onMouseEnter={handleShowText} onMouseLeave={handleShowText}>
									<i>
										<img width="60" height="50" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002074/images/Step-3.png" alt="step-3" title="Step-3" />
									</i>
									<div className='text-center'>
										<i>
											<img width="50" height="50" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002042/images/legal-type.svg" alt="Legal Type" title="Legal Type" />
										</i>
										<p className='fw-bold'>Legal Type</p>
										<p className={`${showThirdText ? '' : 'd-none'}`}>Create a type of companies like LLC, Joint Ventures, General Partnership, Private Shareholding, & Civil Company License in UAE.</p>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-2"></div>
						<div className="col-md-4">
							<div className="cards card-effect">
								<div id='4' onMouseEnter={handleShowText} onMouseLeave={handleShowText}>
									<i>
										<img width="60" height="50" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002075/images/Step-4.png" alt="step-4" title="Step-4" />
									</i>
									<div className='text-center'>
										<i>
											<img width="50" height="50" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002087/images/Visa-services.svg" alt="Visa Processing" title="Visa Processing" />
										</i>
										<p className='fw-bold'>Visa Processing</p>
										<p className={`${showFourthText ? '' : 'd-none'}`}>Visa Processing of Partner, Staff & Family visa. We will also provide immigration advice.</p>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-4">
							<div className="cards card-effect">
								<div id='5' onMouseEnter={handleShowText} onMouseLeave={handleShowText}>
									<i>
										<img width="60" height="50" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002076/images/Step-5.png" alt="step-5" title="Step-5" />
									</i>
									<div className='text-center'>
										<i>
											<img width="50" height="50" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001989/images/Bank-account-opening.svg" alt="Open Bank Account" title="Open Bank Account" />
										</i>
										<p className='fw-bold'>Open Bank Account</p>
										<p className={`${showFifthText ? '' : 'd-none'}`}>The UAE Banks Pose substantial number of advantages in comparison to most European banks. Open an Mainland / Free Zone & Offshore corporate account.</p>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-2"></div>
					</div>
				</div>
			</div>
			{/* 5 EASY STEPS For mobile */}
			<div id='steps-mobile' className="three-coloum-content" style={{ backgroundColor: "#fbf2de" }}>
				<div className="container">
					<div className="row">
						<h3 className='text-center fw-bold mt-5 mb-5 hr-line-center'>Make Your Business Setup in UAE with <span style={{ color: "#aa8038" }}>5 EASY STEPS</span></h3>
						<div className="row mx-auto">
							<Carousel
								swipeable={true}
								draggable={false}
								showDots={true}
								responsive={responsive}
								ssr={true}
								infinite={true}
								autoPlaySpeed={3000}
								keyBoardControl={true}
								customTransition="all .5"
								transitionDuration={500}
								containerClass="carousel-container p-0"
								dotListClass="custom-dot-list-style"
								height="600px"
							>
								<div className="cards">
									<div>
										<i>
											<img width="60" height="50" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002073/images/Step-1.png" alt="step-1" title="Step-1" />
										</i>
										<div className='text-center'>
											<i>
												<img width="40" height="40" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002022/images/free-consultation.svg" alt="Free Consultation" title="Free Consultation" />
											</i>
											<p className='fw-bold'>Free Consultation</p>
											<p>Devotion are free consultants providing collaborative solutions for establishing and running your business in the UAE.</p>
										</div>
									</div>
								</div>
								<div className="cards">
									<div>
										<i>
											<img width="60" height="50" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002074/images/Step-2.png" alt="step-2" title="Step-2" />
										</i>
										<div className='text-center'>
											<i>
												<img width="40" height="40" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002063/images/registered-company-formation.svg" alt="Company Type" title="Company Type" />
											</i>
											<p className='fw-bold'>Company Type</p>
											<p>Many Jurisdictions and Incorporation options are available in the UAE. Different type of Mainland, Free Zone and Offshore, & Branch Setup.</p>
										</div>
									</div>
								</div>
								<div className="cards">
									<div>
										<i>
											<img width="60" height="50" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002074/images/Step-3.png" alt="step-3" title="Step-3" />
										</i>
										<div className='text-center'>
											<i>
												<img width="40" height="40" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002042/images/legal-type.svg" alt="Legal Type" title="Legal Type" />
											</i>
											<p className='fw-bold'>Legal Type</p>
											<p>Create a type of companies like LLC, Joint Ventures, General Partnership, Private Shareholding, & Civil Company License in UAE.</p>
										</div>
									</div>
								</div>
								<div className="cards">
									<div>
										<i>
											<img width="60" height="50" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002075/images/Step-4.png" alt="step-4" title="Step-4" />
										</i>
										<div className='text-center'>
											<i>
												<img width="40" height="40" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002087/images/Visa-services.svg" alt="Visa Processing" title="Visa Processing" />
											</i>
											<p className='fw-bold'>Visa Processing</p>
											<p>Visa Processing of Partner, Staff & Family visa. We will also provide immigration advice.</p>
										</div>
									</div>
								</div>
								<div className="cards">
									<div>
										<i>
											<img width="60" height="50" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002076/images/Step-5.png" alt="step-5" title="Step-5" />
										</i>
										<div className='text-center'>
											<i>
												<img width="40" height="40" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001989/images/Bank-account-opening.svg" alt="Open Bank Account" title="Open Bank Account" />
											</i>
											<p className='fw-bold'>Open Bank Account</p>
											<p>The UAE Banks Pose substantial number of advantages in comparison to most European banks. Open an Mainland / Free Zone & Offshore corporate account.</p>
										</div>
									</div>
								</div>
							</Carousel>
						</div>
					</div>
				</div>
			</div>

			<div className="three-coloum-content mt-4 mb-4">
				<div className="container">
					<div className='row'>
						<h3 className='fw-bold mb-5 text-center hr-line-center'>Devotion Working With <span style={{ color: "#aa8038" }}>All Major Government</span></h3>
						<ul className="goverment-logo">
							<li><img className='gov-logo' width="100%" height="100%" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002024/images/government_1.png" alt="Government Icons" title="Government Icons" /></li>
							<li><img className='gov-logo' width="100%" height="100%" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002026/images/government_2.png" alt="Government Icons" title="Government Icons" /></li>
							<li><img className='gov-logo' width="100%" height="100%" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002026/images/government_3.png" alt="Government Icons" title="Government Icons" /></li>
							<li><img className='gov-logo' width="100%" height="100%" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002027/images/government_4.png" alt="Government Icons" title="Government Icons" /></li>
							<li><img className='gov-logo' width="100%" height="100%" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002027/images/government_5.png" alt="Government Icons" title="Government Icons" /></li>
							<li><img className='gov-logo' width="100%" height="100%" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002029/images/government_6.png" alt="Government Icons" title="Government Icons" /></li>
							<li><img className='gov-logo' width="100%" height="100%" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002029/images/government_7.png" alt="Government Icons" title="Government Icons" /></li>
							<li><img className='gov-logo' width="100%" height="100%" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002030/images/government_8.png" alt="Government Icons" title="Government Icons" /></li>
							<li><img className='gov-logo' width="100%" height="100%" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002030/images/government_9.png" alt="Government Icons" title="Government Icons" /></li>
							<li><img className='gov-logo' width="100%" height="100%" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002031/images/government_10.png" alt="Government Icons" title="Government Icons" /></li>
						</ul>
					</div>
				</div>
			</div>
			<div className="fund-section">
				<div className="container">
					<h3 className='text-center fw-bold mb-5 hr-line-center'>Devotion Key <span style={{ color: "#aa8038" }}>Corporate Services</span></h3>
					<p className="p-tag txt-center txt-width-50 grey">
						Devotion Corporate Services is one of the prominent Documents Centers in Dubai, UAE. We have more than 12 years of experience with organization arrangement and administrations.
						We are one of the leading 360-degree Business Service Providers with the portfolio of more than 1500 Companies Registered by us in the UAE.
						Devotion Group has experts who truly do organizations enlistment and other services like Company Registration, Trademark Registration, Immigration Services,
						Accounting and Bookkeeping, Company Liquidation, VAT Registration, Excise Tax Registration, Business Banking, Import Export Solution, Forex Broker Solution,
						UAE Mainland Company, FreeZone company setup, Offshore Company Setup.
					</p>
				</div>
				<div className="container mt-4 mb-5">
					<div className="row mx-auto">
						<Carousel
							swipeable={true}
							draggable={false}
							showDots={true}
							responsive={responsive}
							ssr={true}
							infinite={true}
							// autoPlay={true}
							autoPlaySpeed={3000}
							keyBoardControl={true}
							customTransition="all .5"
							transitionDuration={500}
							containerClass="carousel-container p-0"
							itemClass="thumbnail"
							dotListClass="custom-dot-list-style"
							height="600px"
						>
							<div className="p-2 mb-4 mx-auto">
								<Card style={{ maxWidth: "fit-content", marginInline: "auto" }}>
									<a href='https://www.devotionbusiness.com/immigration-pro-services'>
										<img src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002063/images/service-1.jpg" height={350} alt="immigration-pro-services" title="Immigration Pro Services" />
									</a>
								</Card>
							</div>
							<div className="p-2 mb-4 mx-auto">
								<Card style={{ maxWidth: "fit-content", marginInline: "auto" }}>
									<a href='https://www.devotionbusiness.com/auditing'>
										<img src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002064/images/service-2.jpg" height={350} alt="auditing" title="Auditing" />
									</a>
								</Card>
							</div>
							<div className="p-2 mb-4 mx-auto">
								<Card style={{ maxWidth: "fit-content", marginInline: "auto" }}>
									<a href='https://www.devotionbusiness.com/business-consulting-advisory'>
										<img src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002064/images/service-3.jpg" height={350} alt="business-consulting-advisory" title="Business Consulting Advisory" />
									</a>
								</Card>
							</div>
							<div className="p-2 mb-4 mx-auto">
								<Card style={{ maxWidth: "fit-content", marginInline: "auto" }}>
									<a href='https://www.devotionbusiness.com/immigration-pro-services'>
										<img src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002064/images/service-4.jpg" height={350} alt="immigration-pro-services" title="Immigration Pro Services" />
									</a>
								</Card>
							</div>
							<div className="p-2 mb-4 mx-auto">
								<Card style={{ maxWidth: "fit-content", marginInline: "auto" }}>
									<a href='https://www.devotionbusiness.com/accounting-bookkeeping'>
										<img src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002065/images/service-5.jpg" height={350} alt="accounting-bookkeeping" title="Accounting Bookkeeping" />
									</a>
								</Card>
							</div>
						</Carousel>
					</div>
				</div >
			</div >
			<div className="footer-99382 bg-white p-0">
				<div className="container">
					<div className="row">
						{/* Footer links For desktop */}
						<div id='links-desktop' className="row">
							<div className="col-md-3 col-sm-6 col-lg-3">
								<h3>UAE Freezone Company Setup</h3>
								<ul className="list-unstyled nav-links">
									<li><FaAngleRight size={'1.5rem'} style={{ color: "#aa8038" }} /><Link to="/dubai-free-zones" className='text-decoration-none'>Dubai Freezone</Link></li>
									<li><FaAngleRight size={'1.5rem'} style={{ color: "#aa8038" }} /><Link to="/sharjah-free-zones" className='text-decoration-none'>Sharjah Freezone</Link></li>
									<li><FaAngleRight size={'1.5rem'} style={{ color: "#aa8038" }} /><Link to="/abu-dhabi-free-zones" className='text-decoration-none'>Abu Dhabi Freezone</Link></li>
									<li><FaAngleRight size={'1.5rem'} style={{ color: "#aa8038" }} /><Link to="/ajman-free-zones" className='text-decoration-none'>Ajman Freezone</Link></li>
									<li><FaAngleRight size={'1.5rem'} style={{ color: "#aa8038" }} /><Link to="/ras-al-khaimah-free-zones" className='text-decoration-none'>Ras Al Khaimah Freezone</Link></li>
									<li><FaAngleRight size={'1.5rem'} style={{ color: "#aa8038" }} /><Link to="/fujairah-free-zones" className='text-decoration-none'>Fujairah Freezone</Link></li>
									<li><FaAngleRight size={'1.5rem'} style={{ color: "#aa8038" }} /><Link to="/umm-al-quwain-free-zones" className='text-decoration-none'>UMM Al Quwain Freezone</Link></li>
								</ul>
							</div>
							<div className="col-md-3 col-sm-6 col-lg-3">
								<h3>UAE Offshore Company Setup</h3>
								<ul className="list-unstyled nav-links">
									<li><FaAngleRight size={'1.5rem'} style={{ color: "#aa8038" }} /><Link to="/jabel-ali-offshore" className='text-decoration-none'>Jabel Ali Offshore Freezone</Link></li>
									<li><FaAngleRight size={'1.5rem'} style={{ color: "#aa8038" }} /><Link to="/ajman-offshore" className='text-decoration-none'>Ajman Offshore Freezone</Link></li>
									<li><FaAngleRight size={'1.5rem'} style={{ color: "#aa8038" }} /><Link to="/ras-al-khaimah-offshore" className='text-decoration-none'>Ras Al Khaimah Offshore</Link></li>
								</ul>
							</div>
							<div className="col-md-3 col-sm-6 col-lg-3">
								<h3>Services</h3>
								<ul className="list-unstyled nav-links">
									<li><FaAngleRight size={'1.5rem'} style={{ color: "#aa8038" }} /><Link to="/immigration-pro-services" className='text-decoration-none'>Immigration Pro Services</Link></li>
									<li><FaAngleRight size={'1.5rem'} style={{ color: "#aa8038" }} /><Link to="/business-consulting-advisory" className='text-decoration-none'>Business Consulting/Advisory</Link></li>
									<li><FaAngleRight size={'1.5rem'} style={{ color: "#aa8038" }} /><Link to="/business-banking" className='text-decoration-none'>Business Banking</Link></li>
									<li><FaAngleRight size={'1.5rem'} style={{ color: "#aa8038" }} /><Link to="/import-export-solutions" className='text-decoration-none'>Import & Export Solutions</Link></li>
									<li><FaAngleRight size={'1.5rem'} style={{ color: "#aa8038" }} /><Link to="/trademark-registration" className='text-decoration-none'>Trademark Registration</Link></li>
									<li><FaAngleRight size={'1.5rem'} style={{ color: "#aa8038" }} /><Link to="/company-liquidation" className='text-decoration-none'>Company Liquidation</Link></li>
								</ul>
							</div>
							<div className="col-md-3 col-sm-6 col-lg-3">
								<h3>Accounting & VAT Consultancy</h3>
								<ul className="list-unstyled nav-links">
									<li><FaAngleRight size={'1.5rem'} style={{ color: "#aa8038" }} /><Link to="/accounting-bookkeeping" className='text-decoration-none'>Accounting & Bookkeeping</Link></li>
									<li><FaAngleRight size={'1.5rem'} style={{ color: "#aa8038" }} /><Link to="/auditing" className='text-decoration-none'>Auditing</Link></li>
									<li><FaAngleRight size={'1.5rem'} style={{ color: "#aa8038" }} /><Link to="/vat-registration" className='text-decoration-none'>VAT Registration</Link></li>
									<li><FaAngleRight size={'1.5rem'} style={{ color: "#aa8038" }} /><Link to="/vat-return-filing" className='text-decoration-none'>VAT Return Filling</Link></li>
									<li><FaAngleRight size={'1.5rem'} style={{ color: "#aa8038" }} /><Link to="/vat-compliance" className='text-decoration-none'>VAT Compliance</Link></li>
									<li><FaAngleRight size={'1.5rem'} style={{ color: "#aa8038" }} /><Link to="/vat-advisory-services" className='text-decoration-none'>VAT Advisory</Link></li>
									<li><FaAngleRight size={'1.5rem'} style={{ color: "#aa8038" }} /><Link to="/vat-deregistration" className='text-decoration-none'>VAT Deregistration</Link></li>
								</ul>
							</div>
						</div>
						{/* Footer links For mobile */}
						<div id='links-mobile' className="container">
							<div className="row mx-auto">
								<Carousel
									swipeable={true}
									draggable={false}
									showDots={true}
									responsive={responsive}
									ssr={true}
									infinite={true}
									autoPlaySpeed={3000}
									keyBoardControl={true}
									customTransition="all .5"
									transitionDuration={500}
									containerClass="carousel-container p-0"
									dotListClass="custom-dot-list-style"
									height="600px"
								>
									<div id="wrap">
										<div id="content">
											<h3>UAE Freezone Company Setup</h3>
											<ul className="list-unstyled nav-links">
												<li><FaAngleRight size={'1.5rem'} style={{ color: "#aa8038" }} /><Link to="/dubai-free-zones" className='text-decoration-none'>Dubai Freezone</Link></li>
												<li><FaAngleRight size={'1.5rem'} style={{ color: "#aa8038" }} /><Link to="/sharjah-free-zones" className='text-decoration-none'>Sharjah Freezone</Link></li>
												<li><FaAngleRight size={'1.5rem'} style={{ color: "#aa8038" }} /><Link to="/abu-dhabi-free-zones" className='text-decoration-none'>Abu Dhabi Freezone</Link></li>
												<li><FaAngleRight size={'1.5rem'} style={{ color: "#aa8038" }} /><Link to="/ajman-free-zones" className='text-decoration-none'>Ajman Freezone</Link></li>
												<li><FaAngleRight size={'1.5rem'} style={{ color: "#aa8038" }} /><Link to="/ras-al-khaimah-free-zones" className='text-decoration-none'>Ras Al Khaimah Freezone</Link></li>
												<li><FaAngleRight size={'1.5rem'} style={{ color: "#aa8038" }} /><Link to="/fujairah-free-zones" className='text-decoration-none'>Fujairah Freezone</Link></li>
												<li><FaAngleRight size={'1.5rem'} style={{ color: "#aa8038" }} /><Link to="/umm-al-quwain-free-zones" className='text-decoration-none'>UMM Al Quwain Freezone</Link></li>
											</ul>
										</div>
									</div>
									<div id="wrap">
										<div id="content">
											<h3>UAE Offshore Company Setup</h3>
											<ul className="list-unstyled nav-links">
												<li><FaAngleRight size={'1.5rem'} style={{ color: "#aa8038" }} /><Link to="/jabel-ali-offshore" className='text-decoration-none'>Jabel Ali Offshore Freezone</Link></li>
												<li><FaAngleRight size={'1.5rem'} style={{ color: "#aa8038" }} /><Link to="/ajman-offshore" className='text-decoration-none'>Ajman Offshore Freezone</Link></li>
												<li><FaAngleRight size={'1.5rem'} style={{ color: "#aa8038" }} /><Link to="/ras-al-khaimah-offshore" className='text-decoration-none'>Ras Al Khaimah Offshore</Link></li>
											</ul>
										</div>
									</div>
									<div id="wrap">
										<div id="content">
											<h3>Services</h3>
											<ul className="list-unstyled nav-links">
												<li><FaAngleRight size={'1.5rem'} style={{ color: "#aa8038" }} /><Link to="/immigration-pro-services" className='text-decoration-none'>Immigration Pro Services</Link></li>
												<li><FaAngleRight size={'1.5rem'} style={{ color: "#aa8038" }} /><Link to="/business-consulting-advisory" className='text-decoration-none'>Business Consulting/Advisory</Link></li>
												<li><FaAngleRight size={'1.5rem'} style={{ color: "#aa8038" }} /><Link to="/business-banking" className='text-decoration-none'>Business Banking</Link></li>
												<li><FaAngleRight size={'1.5rem'} style={{ color: "#aa8038" }} /><Link to="/import-export-solutions" className='text-decoration-none'>Import & Export Solutions</Link></li>
												<li><FaAngleRight size={'1.5rem'} style={{ color: "#aa8038" }} /><Link to="/trademark-registration" className='text-decoration-none'>Trademark Registration</Link></li>
												<li><FaAngleRight size={'1.5rem'} style={{ color: "#aa8038" }} /><Link to="/company-liquidation" className='text-decoration-none'>Company Liquidation</Link></li>
											</ul>
										</div>
									</div>
									<div id="wrap">
										<div id="content">
											<h3>Accounting & VAT Consultancy</h3>
											<ul className="list-unstyled nav-links">
												<li><FaAngleRight size={'1.5rem'} style={{ color: "#aa8038" }} /><Link to="/accounting-bookkeeping" className='text-decoration-none'>Accounting & Bookkeeping</Link></li>
												<li><FaAngleRight size={'1.5rem'} style={{ color: "#aa8038" }} /><Link to="/auditing" className='text-decoration-none'>Auditing</Link></li>
												<li><FaAngleRight size={'1.5rem'} style={{ color: "#aa8038" }} /><Link to="/vat-registration" className='text-decoration-none'>VAT Registration</Link></li>
												<li><FaAngleRight size={'1.5rem'} style={{ color: "#aa8038" }} /><Link to="/vat-return-filing" className='text-decoration-none'>VAT Return Filling</Link></li>
												<li><FaAngleRight size={'1.5rem'} style={{ color: "#aa8038" }} /><Link to="/vat-compliance" className='text-decoration-none'>VAT Compliance</Link></li>
												<li><FaAngleRight size={'1.5rem'} style={{ color: "#aa8038" }} /><Link to="/vat-advisory-services" className='text-decoration-none'>VAT Advisory</Link></li>
												<li><FaAngleRight size={'1.5rem'} style={{ color: "#aa8038" }} /><Link to="/vat-deregistration" className='text-decoration-none'>VAT Deregistration</Link></li>
											</ul>
										</div>
									</div>
								</Carousel>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="three-coloum-content mt-4 mb-1" style={{ backgroundColor: "#fbf2de" }}>
				<div className="container faqsection">
					<h3 className='text-center fw-bold pt-5 pb-5 hr-line-center' style={{ color: "#aa8038" }}>FAQ</h3>
					<div className="row">
						<Tab.Container id="left-tabs-example" defaultActiveKey="first" className="mt-5">
							<Row>
								<Nav id='faq-mobile' variant="pills" className="d-flex faq-main text-center" fill="true">
									<Nav.Item style={{ flex: "none" }}>
										<Nav.Link eventKey="first" className='fw-bold text-black'>Mainland</Nav.Link>
									</Nav.Item>
									<Nav.Item style={{ flex: "none" }}>
										<Nav.Link eventKey="second" className='fw-bold text-black'>Freezone</Nav.Link>
									</Nav.Item>
									<Nav.Item style={{ flex: "none" }}>
										<Nav.Link eventKey="four" className='fw-bold text-black'>Accounting & VAT</Nav.Link>
									</Nav.Item>
								</Nav>
								<Nav id='faq-desktop' variant="pills" className="d-flex faq-main" fill="true">
									<Nav.Item style={{ flex: "none" }}>
										<Nav.Link eventKey="first" className='fw-bold text-black'>Mainland</Nav.Link>
									</Nav.Item>
									<Nav.Item style={{ flex: "none" }}>
										<Nav.Link eventKey="second" className='fw-bold text-black'>Freezone</Nav.Link>
									</Nav.Item>
									<Nav.Item style={{ flex: "none" }}>
										<Nav.Link eventKey="four" className='fw-bold text-black'>Accounting & VAT</Nav.Link>
									</Nav.Item>
								</Nav>
								<Tab.Content className='conta'>
									<Tab.Pane eventKey="first" className=''>
										<Faq
											data={mainland}
											styles={styles}
											config={config}
										/>
									</Tab.Pane>
									<Tab.Pane eventKey="second">
										<Faq
											data={freezone}
											styles={styles}
											config={config}
										/>
									</Tab.Pane>
									<Tab.Pane eventKey="four">
										<Faq
											data={accounting}
											styles={styles}
											config={config}
										/>
									</Tab.Pane>
								</Tab.Content>
							</Row>
						</Tab.Container>
						{/* <div className='col-md-5 faq-section p-5'>
							<p className="fw-bold" style={{ color: "#aa8038" }}>Popular Questions</p>
							<h3 className="fw-bold">FAQs for your company<br /> formation in the UAE</h3>
							<a className="main-btn w-button mt-2 mb-3" href="#">
								Get In Touch
							</a>
						</div>
						<div className='col-md-7' style={{ paddingLeft: "3rem" }}>
							<Faq id="1"
								data={data}
								styles={styles}
								config={config}
							/>
							<Faq id="2"
								data={data}
								styles={styles}
								config={config}
							/>
							<Faq id="3"
								data={data}
								styles={styles}
								config={config}
							/>
							<Faq id="4"
								data={data}
								styles={styles}
								config={config}
							/>
						</div> */}
					</div>
				</div>
			</div>
			<div id='google-review' className="container">
				<div className="row">
					<div id='reviews' class="commonninja_component pid-f55e784f-c561-4756-9eab-60ec832dd40d"></div>
				</div>
			</div>
		</>
	);
};

export default Home;