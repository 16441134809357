import React from 'react';
import { Helmet } from "react-helmet";

// ================================|| CEOMessage ||================================ //

const CEOMessage = () => {


    return (
        <>
            <Helmet>
                <title>Devotion CEO Message: Our Vision for Your Success</title>
            </Helmet>
            <div class="chairman-message">
                <div class="chairman-img">
                    <div class="pos-message-box">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-7 aos-init" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h1>CEO Message</h1>
                                    <p className='fw-bold'>
                                        Goodness is imperative for growth.<br /><br /></p>
                                    <p>
                                        The Devotion Corporate Services existence and emergence have been an integral part of Many Small Companies and Business in Dubai,
                                        who has come to make their dream live.<br />
                                    </p>
                                    <p className='fw-bold'>
                                        "Our journey as a company has been synchronous with building national assets and also building an institution that gives back to society.
                                        My personal belief is that every day is a journey of learning and caring about all those who help in realizing the larger dream."
                                    </p>
                                    <p>
                                        We must ensure that our immediate communities settled around our business sites are empowered by our presence and become self-reliant in the shadow of our expansion.
                                        We have a strong heritage of taking responsibility for our all clients, there employees, the environment and society.<br />
                                        At Devotion Corporate Services, we prioritize creating the best work environment for our all Clients, fostering a culture that promotes collaboration, growth, and well-being.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img class="d-lg-block ceo-desktop-img d-none" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001991/images/Chairmans-Message_desktop.jpg" alt="ceo-banner" title="CEO Banner" />
                <img class="d-lg-none d-block" style={{ width: "-webkit-fill-available" }} src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001991/images/Chairmans-Message_mobile.jpg" alt="ceo-banner" title="CEO Banner" />
            </div>
        </>
    );
};

export default CEOMessage;