import React from 'react';
import { Helmet } from "react-helmet";

// ================================|| Mission ||================================ //

const Mission = () => {


    return (
        <>
            <Helmet>
                <title>Devotion: Your Trusted Business Services Partner</title>
            </Helmet>
            <div class="chairman-message">
                <div class="chairman-img">
                    <div class="pos-message-box">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-7 aos-init" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h1>Our Mission</h1>
                                    <p>
                                        Our Mission is to Develop Collaboration with the Government Agency and our Client for there Business need and requirement.
                                        Being Business setup Consultants in Dubai we set up Guidelines and Support with integrity to cut down the maximum risk and cost.<br /><br />
                                        <p className='fw-bold'>
                                            "We aim at expanding your business in the UAE markets for which, it is important to recognize the scope of the organization."
                                        </p>
                                        We also help our client to create a better everyday Business Setup services, Company restructures solution and Proper oversea Business growth Guidance.
                                        We are always available to our client 24*7 for all the year to help them in each way for making there Business more profitable and expandable.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img class="d-lg-block mission-desktop-img d-none" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001991/images/Chairmans-Message_desktop.jpg" alt="mission-banner" title="Mission Banner" />
                <img class="d-lg-none d-block" style={{ width: "-webkit-fill-available" }} src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001991/images/Chairmans-Message_mobile.jpg" alt="mission-banner" title="Mission Banner" />
            </div>
        </>
    );
};

export default Mission;