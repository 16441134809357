import React from 'react';
import { Helmet } from "react-helmet";

// ================================|| EBook ||================================ //

const EBook = () => {


    return (
        <>
            <Helmet>
                <title>E-Book - Devotion Business</title>
            </Helmet>

            <div class="chairman-message">
                <div class="chairman-img">
                    <div class="pos-message-box">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-7 aos-init" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2 className='alignment-contact'>E-Book</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img class="d-lg-block d-none" style={{ width: "-webkit-fill-available" }} src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001997/images/Contact-us.png" alt="ebook-banner" title="EBook Banner" />
            </div>
            <div class="pt-5 pb-5">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-3 col-md-4 col-sm-6 mt-2 float-left allowHover">
                            <div class="card border-brown business-broucher-blocks busniess-block-shadow">
                                <div class="card-body" style={{ height: "auto" }}>
                                    <span>
                                        <a href='https://devotionbusiness.com/documents/E_book.pdf'>
                                            <img class="img-fluid mx-auto d-block busniess-img-shadow" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002015/images/e-book.png" alt="e-book" title="E-book" />
                                        </a>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EBook;