import React from 'react';

// ================================|| Footer ||================================ //

const Footer = () => {
	return (
		<>
			<footer className="content-footer footer bg-footer-theme">
				<div className="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column" style={{ padding: "1.5rem 1.5rem" }}>
					<div className="mb-2 mb-md-0">
						©
						<script>
							document.write(new Date().getFullYear());
						</script>
						Devotion Business Services. All rights reserved.
					</div>
				</div>
			</footer>
		</>
	);
};

export default Footer;