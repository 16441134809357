import React from 'react';
import ModalDialog from './DubaiDialogEnquiry';
import ModalDownloadDialog from './DubaiDialogDownload';
import { Helmet } from "react-helmet";

// ================================|| DubaiFreeZones ||================================ //

const DubaiFreeZones = () => {

    return (
        <>
            <Helmet>
                <title>Business Setup in Dubai Free Zone | UAE Free Zone Company Formation</title>
            </Helmet>
            <div class="chairman-message">
                <div class="chairman-img">
                    <div class="pos-message-freezone-box">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-7 aos-init" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2>Dubai Free Zone</h2>
                                    <p>
                                        The UAE has more than 40 diverse free zones where foreign and expatriate investors can fully own their Businesses.
                                        These areas stand out for their extremely effective infrastructure and unique offerings that support efficient workflows and save organizations a great deal of time and effort.
                                        Devotion helps their clients to setup their Business, Company, Industries, Professional and Commercial Services, with all kind of Licenses in Dubai Free Zone.
                                        We also handle any kind of Company Registration process in Dubai Free Zones.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img class="d-lg-block chairman-desktop-dubai-img d-none" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001991/images/Chairmans-Message_desktop.jpg" alt="dubai-banner" title="Dubai Banner" />
                <img class="d-lg-none d-block" style={{ width: "-webkit-fill-available" }} src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001991/images/Chairmans-Message_mobile.jpg" alt="dubai-banner" title="Dubai Banner" />
            </div>
            <div class="pt-5 pb-5">
                <div class="container">
                    <div class="row">
                        <h1><span style={{ color: "#aa8038" }}>Business Setup in Dubai Freezone</span></h1>
                        <div class="col-lg-3 col-md-4 col-sm-6 mt-2 float-left allowHover">
                            <div class="card border-brown business-broucher-blocks busniess-block-shadow">
                                <div class="card-body">
                                    <span>
                                        <img class="img-fluid mx-auto d-block busniess-img-shadow" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001999/images/dubai-free-zones_1.jpg" alt="dubai" title="Dubai" />
                                    </span>
                                    <h5 class="text-brown text-break text-center mt-4">
                                        Dubai Multi Commodity Center (DMCC)
                                    </h5>
                                    <p class="description mt-4 font-size-17 font-weight-7 text-break text-center freezoneblock-aed">
                                        <b>AED 34000 *</b>
                                    </p>
                                    <p class="description font-size-17 font-weight-7 text-break text-center freezoneblock-usd">
                                        <b>USD 9500 *</b>
                                    </p>
                                    <p id='Dubai Multi Commodity Center (DMCC)' class="text-center mt-4 freezoneblock-enquiry">
                                        <ModalDialog />
                                    </p>
                                    <p id='Dubai Multi Commodity Center (DMCC)' class="text-center mt-3 freezoneblock-download">
                                        <ModalDownloadDialog />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 mt-2 float-left allowHover">
                            <div class="card border-brown business-broucher-blocks busniess-block-shadow">
                                <div class="card-body">
                                    <span>
                                        <img class="img-fluid mx-auto d-block busniess-img-shadow" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001999/images/dubai-free-zones_2.jpg" alt="dubai" title="Dubai" />
                                    </span>
                                    <h5 class="text-brown text-break text-center mt-4">
                                        Dubai Airport Free Zone (DAFZA)
                                    </h5>
                                    <p class="description mt-4 font-size-17 font-weight-7 text-break text-center freezoneblock-aed">
                                        <b>AED 23000 *</b>
                                    </p>
                                    <p class="description font-size-17 font-weight-7 text-break text-center freezoneblock-usd">
                                        <b>USD 6500 *</b>
                                    </p>
                                    <p id='Dubai Airport Free Zone (DAFZA)' class="text-center mt-4 freezoneblock-enquiry">
                                        <ModalDialog />
                                    </p>
                                    <p id='Dubai Airport Free Zone (DAFZA)' class="text-center mt-3 freezoneblock-download">
                                        <ModalDownloadDialog />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 mt-2 float-left allowHover">
                            <div class="card border-brown business-broucher-blocks busniess-block-shadow">
                                <div class="card-body">
                                    <span>
                                        <img class="img-fluid mx-auto d-block busniess-img-shadow" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002001/images/dubai-free-zones_3.jpg" alt="dubai" title="Dubai" />
                                    </span>
                                    <h5 class="text-brown text-break text-center mt-4">
                                        Dubai Word Central (DWC)
                                    </h5>
                                    <p class="description mt-4 font-size-17 font-weight-7 text-break text-center freezoneblock-aed">
                                        <b>AED 21000 *</b>
                                    </p>
                                    <p class="description font-size-17 font-weight-7 text-break text-center freezoneblock-usd">
                                        <b>USD 5800 *</b>
                                    </p>
                                    <p id='Dubai Word Central (DWC)' class="text-center mt-4 freezoneblock-enquiry">
                                        <ModalDialog />
                                    </p>
                                    <p id='Dubai Word Central (DWC)' class="text-center mt-3 freezoneblock-download">
                                        <ModalDownloadDialog />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 mt-2 float-left allowHover">
                            <div class="card border-brown business-broucher-blocks busniess-block-shadow">
                                <div class="card-body">
                                    <span>
                                        <img class="img-fluid mx-auto d-block busniess-img-shadow" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002002/images/dubai-free-zones_4.jpg" alt="dubai" title="Dubai" />
                                    </span>
                                    <h5 class="text-brown text-break text-center mt-4">
                                        Jebel Ali Free Zone (JAFZA)
                                    </h5>
                                    <p class="description mt-4 font-size-17 font-weight-7 text-break text-center freezoneblock-aed">
                                        <b>AED 15500 *</b>
                                    </p>
                                    <p class="description font-size-17 font-weight-7 text-break text-center freezoneblock-usd">
                                        <b>USD 4300 *</b>
                                    </p>
                                    <p id='Jebel Ali Free Zone (JAFZA)' class="text-center mt-4 freezoneblock-enquiry">
                                        <ModalDialog />
                                    </p>
                                    <p id='Jebel Ali Free Zone (JAFZA)' class="text-center mt-3 freezoneblock-download">
                                        <ModalDownloadDialog />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 mt-2 float-left allowHover">
                            <div class="card border-brown business-broucher-blocks busniess-block-shadow">
                                <div class="card-body">
                                    <span>
                                        <img class="img-fluid mx-auto d-block busniess-img-shadow" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002002/images/dubai-free-zones_5.jpg" alt="dubai" title="Dubai" />
                                    </span>
                                    <h5 class="text-brown text-break text-center mt-4">
                                        Dubai Silicon Oasis (DSOA)
                                    </h5>
                                    <p class="description mt-4 font-size-17 font-weight-7 text-break text-center freezoneblock-aed">
                                        <b>AED 13900 *</b>
                                    </p>
                                    <p class="description font-size-17 font-weight-7 text-break text-center freezoneblock-usd">
                                        <b>USD 3700 *</b>
                                    </p>
                                    <p id='Dubai Silicon Oasis (DSOA)' class="text-center mt-4 freezoneblock-enquiry">
                                        <ModalDialog />
                                    </p>
                                    <p id='Dubai Silicon Oasis (DSOA)' class="text-center mt-3 freezoneblock-download">
                                        <ModalDownloadDialog />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 mt-2 float-left allowHover">
                            <div class="card border-brown business-broucher-blocks busniess-block-shadow">
                                <div class="card-body">
                                    <span>
                                        <img class="img-fluid mx-auto d-block busniess-img-shadow" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002003/images/dubai-free-zones_6.jpg" alt="dubai" title="Dubai" />
                                    </span>
                                    <h5 class="text-brown text-break text-center mt-4">
                                        Dubai Media City
                                    </h5>
                                    <p class="description mt-4 font-size-17 font-weight-7 text-break text-center freezoneblock-aed">
                                        <b>AED 25000 *</b>
                                    </p>
                                    <p class="description font-size-17 font-weight-7 text-break text-center freezoneblock-usd">
                                        <b>USD 6900 *</b>
                                    </p>
                                    <p id='Dubai Media City' class="text-center mt-4 freezoneblock-enquiry">
                                        <ModalDialog />
                                    </p>
                                    <p id='Dubai Media City' class="text-center mt-3 freezoneblock-download">
                                        <ModalDownloadDialog />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 mt-2 float-left allowHover">
                            <div class="card border-brown business-broucher-blocks busniess-block-shadow">
                                <div class="card-body">
                                    <span>
                                        <img class="img-fluid mx-auto d-block busniess-img-shadow" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002005/images/dubai-free-zones_7.jpg" alt="dubai" title="Dubai" />
                                    </span>
                                    <h5 class="text-brown text-break text-center mt-4">
                                        Dubai Internet City
                                    </h5>
                                    <p class="description mt-4 font-size-17 font-weight-7 text-break text-center freezoneblock-aed">
                                        <b>AED 48000 *</b>
                                    </p>
                                    <p class="description font-size-17 font-weight-7 text-break text-center freezoneblock-usd">
                                        <b>USD 13100 *</b>
                                    </p>
                                    <p id='Dubai Internet City' class="text-center mt-4 freezoneblock-enquiry">
                                        <ModalDialog />
                                    </p>
                                    <p id='Dubai Internet City' class="text-center mt-3 freezoneblock-download">
                                        <ModalDownloadDialog />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 mt-2 float-left allowHover">
                            <div class="card border-brown business-broucher-blocks busniess-block-shadow">
                                <div class="card-body">
                                    <span>
                                        <img class="img-fluid mx-auto d-block busniess-img-shadow" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002005/images/dubai-free-zones_8.jpg" alt="dubai" title="Dubai" />
                                    </span>
                                    <h5 class="text-brown text-break text-center mt-4">
                                        Meydan Free Zone
                                    </h5>
                                    <p class="description mt-4 font-size-17 font-weight-7 text-break text-center freezoneblock-aed">
                                        <b>AED 16350 *</b>
                                    </p>
                                    <p class="description font-size-17 font-weight-7 text-break text-center freezoneblock-usd">
                                        <b>USD 4500 *</b>
                                    </p>
                                    <p id='Meydan Free Zone' class="text-center mt-4 freezoneblock-enquiry">
                                        <ModalDialog />
                                    </p>
                                    <p id='Meydan Free Zone' class="text-center mt-3 freezoneblock-download">
                                        <ModalDownloadDialog />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 mt-2 float-left allowHover">
                            <div class="card border-brown business-broucher-blocks busniess-block-shadow">
                                <div class="card-body">
                                    <span>
                                        <img class="img-fluid mx-auto d-block busniess-img-shadow" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002006/images/dubai-free-zones_9.jpg" alt="dubai" title="Dubai" />
                                    </span>
                                    <h5 class="text-brown text-break text-center mt-4">
                                        Dubai Knowledge Village
                                    </h5>
                                    <p class="description mt-4 font-size-17 font-weight-7 text-break text-center freezoneblock-aed">
                                        <b>AED 32500 *</b>
                                    </p>
                                    <p class="description font-size-17 font-weight-7 text-break text-center freezoneblock-usd">
                                        <b>USD 8890 *</b>
                                    </p>
                                    <p id='Dubai Knowledge Village' class="text-center mt-4 freezoneblock-enquiry">
                                        <ModalDialog />
                                    </p>
                                    <p id='Dubai Knowledge Village' class="text-center mt-3 freezoneblock-download">
                                        <ModalDownloadDialog />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 mt-2 float-left allowHover">
                            <div class="card border-brown business-broucher-blocks busniess-block-shadow">
                                <div class="card-body">
                                    <span>
                                        <img class="img-fluid mx-auto d-block busniess-img-shadow" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002007/images/dubai-free-zones_10.jpg" alt="dubai" title="Dubai" />
                                    </span>
                                    <h5 class="text-brown text-break text-center mt-4">
                                        Dubai Maritime City
                                    </h5>
                                    <p class="description mt-4 font-size-17 font-weight-7 text-break text-center freezoneblock-aed">
                                        <b>AED 28725 *</b>
                                    </p>
                                    <p class="description font-size-17 font-weight-7 text-break text-center freezoneblock-usd">
                                        <b>USD 7875 *</b>
                                    </p>
                                    <p id='Dubai Maritime City' class="text-center mt-4 freezoneblock-enquiry">
                                        <ModalDialog />
                                    </p>
                                    <p id='Dubai Maritime City' class="text-center mt-3 freezoneblock-download">
                                        <ModalDownloadDialog />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 mt-2 float-left allowHover">
                            <div class="card border-brown business-broucher-blocks busniess-block-shadow">
                                <div class="card-body">
                                    <span>
                                        <img class="img-fluid mx-auto d-block busniess-img-shadow" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002008/images/dubai-free-zones_11.jpg" alt="dubai" title="Dubai" />
                                    </span>
                                    <h5 class="text-brown text-break text-center mt-4">
                                        Dubai Gold & Diamond Park
                                    </h5>
                                    <p class="description mt-4 font-size-17 font-weight-7 text-break text-center freezoneblock-aed">
                                        <b>AED 21000 *</b>
                                    </p>
                                    <p class="description font-size-17 font-weight-7 text-break text-center freezoneblock-usd">
                                        <b>USD 5800 *</b>
                                    </p>
                                    <p id='Dubai Gold & Diamond Park' class="text-center mt-4 freezoneblock-enquiry">
                                        <ModalDialog />
                                    </p>
                                    <p id='Dubai Gold & Diamond Park' class="text-center mt-3 freezoneblock-download">
                                        <ModalDownloadDialog />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 mt-2 float-left allowHover">
                            <div class="card border-brown business-broucher-blocks busniess-block-shadow">
                                <div class="card-body">
                                    <span>
                                        <img class="img-fluid mx-auto d-block busniess-img-shadow" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002008/images/dubai-free-zones_12.jpg" alt="dubai" title="Dubai" />
                                    </span>
                                    <h5 class="text-brown text-break text-center mt-4">
                                        International Freezone (IFZA)
                                    </h5>
                                    <p class="description mt-4 font-size-17 font-weight-7 text-break text-center freezoneblock-aed">
                                        <b>AED 13900 *</b>
                                    </p>
                                    <p class="description font-size-17 font-weight-7 text-break text-center freezoneblock-usd">
                                        <b>USD 3800 *</b>
                                    </p>
                                    <p id='International Freezone (IFZA)' class="text-center mt-4 freezoneblock-enquiry">
                                        <ModalDialog />
                                    </p>
                                    <p id='International Freezone (IFZA)' class="text-center mt-3 freezoneblock-download">
                                        <ModalDownloadDialog />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 mt-2 float-left allowHover">
                            <div class="card border-brown business-broucher-blocks busniess-block-shadow">
                                <div class="card-body">
                                    <span>
                                        <img class="img-fluid mx-auto d-block busniess-img-shadow" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002009/images/dubai-free-zones_13.png" alt="dubai" title="Dubai" />
                                    </span>
                                    <h5 class="text-brown text-break text-center mt-4">
                                        Dubai Prodcution city
                                    </h5>
                                    <p class="description mt-4 font-size-17 font-weight-7 text-break text-center freezoneblock-aed">
                                        <b>AED 28800 *</b>
                                    </p>
                                    <p class="description font-size-17 font-weight-7 text-break text-center freezoneblock-usd">
                                        <b>USD 7870 *</b>
                                    </p>
                                    <p id='Dubai Prodcution city' class="text-center mt-4 freezoneblock-enquiry">
                                        <ModalDialog />
                                    </p>
                                    <p id='Dubai Prodcution city' class="text-center mt-3 freezoneblock-download">
                                        <ModalDownloadDialog />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 mt-2 float-left allowHover">
                            <div class="card border-brown business-broucher-blocks busniess-block-shadow">
                                <div class="card-body">
                                    <span>
                                        <img class="img-fluid mx-auto d-block busniess-img-shadow" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002010/images/dubai-free-zones_14.jpg" alt="dubai" title="Dubai" />
                                    </span>
                                    <h5 class="text-brown text-break text-center mt-4">
                                        Dubai International Financial Center (DIFC)
                                    </h5>
                                    <p id='Dubai International Financial Center (DIFC)' class="text-center mt-4 freezoneblock-enquiry">
                                        <ModalDialog />
                                    </p>
                                    <p id='Dubai International Financial Center (DIFC)' class="text-center mt-3 freezoneblock-download">
                                        <ModalDownloadDialog />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 mt-2 float-left allowHover">
                            <div class="card border-brown business-broucher-blocks busniess-block-shadow">
                                <div class="card-body">
                                    <span>
                                        <img class="img-fluid mx-auto d-block busniess-img-shadow" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002011/images/dubai-free-zones_15.jpg" alt="dubai" title="Dubai" />
                                    </span>
                                    <h5 class="text-brown text-break text-center mt-4">
                                        Dubai Academic Free Zone
                                    </h5>
                                    <p id='Dubai Academic Free Zone' class="text-center mt-4 freezoneblock-enquiry">
                                        <ModalDialog />
                                    </p>
                                    <p id='Dubai Academic Free Zone' class="text-center mt-3 freezoneblock-download">
                                        <ModalDownloadDialog />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 mt-2 float-left allowHover">
                            <div class="card border-brown business-broucher-blocks busniess-block-shadow">
                                <div class="card-body">
                                    <span>
                                        <img class="img-fluid mx-auto d-block busniess-img-shadow" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002011/images/dubai-free-zones_16.jpg" alt="dubai" title="Dubai" />
                                    </span>
                                    <h5 class="text-brown text-break text-center mt-4">
                                        Dubai Healthcare City
                                    </h5>
                                    <p id='Dubai Healthcare City' class="text-center mt-4 freezoneblock-enquiry">
                                        <ModalDialog />
                                    </p>
                                    <p id='Dubai Healthcare City' class="text-center mt-3 freezoneblock-download">
                                        <ModalDownloadDialog />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 mt-2 float-left allowHover">
                            <div class="card border-brown business-broucher-blocks busniess-block-shadow">
                                <div class="card-body">
                                    <span>
                                        <img class="img-fluid mx-auto d-block busniess-img-shadow" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002012/images/dubai-free-zones_17.jpg" alt="dubai" title="Dubai" />
                                    </span>
                                    <h5 class="text-brown text-break text-center mt-4">
                                        Dubai Logistic City
                                    </h5>
                                    <p id='Dubai Logistic City' class="text-center mt-4 freezoneblock-enquiry">
                                        <ModalDialog />
                                    </p>
                                    <p id='Dubai Logistic City' class="text-center mt-3 freezoneblock-download">
                                        <ModalDownloadDialog />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 mt-2 float-left allowHover">
                            <div class="card border-brown business-broucher-blocks busniess-block-shadow">
                                <div class="card-body">
                                    <span>
                                        <img class="img-fluid mx-auto d-block busniess-img-shadow" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002013/images/dubai-free-zones_18.jpg" alt="dubai" title="Dubai" />
                                    </span>
                                    <h5 class="text-brown text-break text-center mt-4">
                                        Dubai Techno Park
                                    </h5>
                                    <p id='Dubai Techno Park' class="text-center mt-4 freezoneblock-enquiry">
                                        <ModalDialog />
                                    </p>
                                    <p id='Dubai Techno Park' class="text-center mt-3 freezoneblock-download">
                                        <ModalDownloadDialog />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DubaiFreeZones;