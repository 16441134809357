import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { Cookies, useCookies } from "react-cookie";
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import JoditEditor from "jodit-react";
import API from '../../../middleware/API';

// ================================|| EditCurrentOpening ||================================ //

const copyStringToClipboard = function (str) {
  var el = document.createElement("textarea");
  el.value = str;
  el.setAttribute("readonly", "");
  el.style = { position: "absolute", left: "-9999px" };
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
};

const facilityMergeFields = [
  "FacilityNumber",
  "FacilityName",
  "Address",
  "MapCategory",
  "Latitude",
  "Longitude",
  "ReceivingPlant",
  "TrunkLine",
  "SiteElevation"
];
const inspectionMergeFields = [
  "InspectionCompleteDate",
  "InspectionEventType"
];
const createOptionGroupElement = (mergeFields, optionGrouplabel) => {
  let optionGroupElement = document.createElement("optgroup");
  optionGroupElement.setAttribute("label", optionGrouplabel);
  for (let index = 0; index < mergeFields.length; index++) {
    let optionElement = document.createElement("option");
    optionElement.setAttribute("class", "merge-field-select-option");
    optionElement.setAttribute("value", mergeFields[index]);
    optionElement.text = mergeFields[index];
    optionGroupElement.appendChild(optionElement);
  }
  return optionGroupElement;
}
const buttons = [
  "undo",
  "redo",
  "|",
  "bold",
  "strikethrough",
  "underline",
  "italic",
  "|",
  "superscript",
  "subscript",
  "|",
  "align",
  "|",
  "ul",
  "ol",
  "outdent",
  "indent",
  "|",
  "font",
  "fontsize",
  "brush",
  "paragraph",
  "|",
  "image",
  "link",
  "table",
  "|",
  "hr",
  "eraser",
  "copyformat",
  "|",
  "fullsize",
  "selectall",
  "print",
  "|",
  "source",
  "|",
  {
    name: "insertMergeField",
    tooltip: "Insert Merge Field",
    iconURL: "images/merge.png",
    popup: (editor, current, self, close) => {
      function onSelected(e) {
        let mergeField = e.target.value;
        if (mergeField) {
          editor.selection.insertNode(
            editor.create.inside.fromHTML("{{" + mergeField + "}}")
          );
        }
      }
      let divElement = editor.create.div("merge-field-popup");

      let labelElement = document.createElement("label");
      labelElement.setAttribute("class", "merge-field-label");
      labelElement.text = 'Merge field: ';
      divElement.appendChild(labelElement);

      let selectElement = document.createElement("select");
      selectElement.setAttribute("class", "merge-field-select");
      selectElement.appendChild(createOptionGroupElement(facilityMergeFields, "Facility"));
      selectElement.appendChild(createOptionGroupElement(inspectionMergeFields, "Inspection"));
      selectElement.onchange = onSelected;
      divElement.appendChild(selectElement);
      return divElement;
    }
  },
  {
    name: "copyContent",
    tooltip: "Copy HTML to Clipboard",
    iconURL: "images/copy.png",
    exec: function (editor) {
      let html = editor.value;
      copyStringToClipboard(html);
    }
  }
];

const editorConfig = {
  readonly: false,
  toolbar: true,
  spellcheck: true,
  language: "en",
  toolbarButtonSize: "medium",
  toolbarAdaptive: false,
  showCharsCounter: true,
  showWordsCounter: true,
  showXPathInStatusbar: false,
  askBeforePasteHTML: true,
  askBeforePasteFromWord: true,
  //defaultActionOnPaste: "insert_clear_html",
  buttons: buttons,
  uploader: {
    insertImageAsBase64URI: true
  }
};

function EditCurrentOpening() {
  var base64String = "";
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const [CurrentOpeningdata, setCurrentOpeningData] = useState();
  const [Title, SetTitle] = useState("");                            // store the input values
  const [JobDescription, SetJobDescription] = useState("");          // store the input values
  const [IsActive, SetIsActive] = useState("");                      // store the input values 
  const [cookie, SetCookie] = useCookies(['username']);
  const cookies = new Cookies();
  const user = cookies.get('id');

  useEffect(() => {
    getCurrentOpeningById();
  }, []);

  let currentopeningdata = "";

  const getCurrentOpeningById = async () => {
    try {
      var GetCurrentOpeningByIdAPI = API.getCurrentOpeningById;
      GetCurrentOpeningByIdAPI = GetCurrentOpeningByIdAPI + "?id=" + params.get("Id");
      const response = await fetch(GetCurrentOpeningByIdAPI);
      const jsonData = await response.json();
      setCurrentOpeningData(jsonData.recordset);
      currentopeningdata = jsonData.recordset[0];
      SetTitle(currentopeningdata.Title);
      SetJobDescription(currentopeningdata.JobDescription);
      SetIsActive(currentopeningdata.IsActive);
    }
    catch (error) {
      console.log(error);
    }
  };

  let submitHandler = async (e) => {
    e.preventDefault();

    var checked = false;
    if (document.querySelector('#IsActive:checked')) {
      checked = true;
    }

    try {
      var UpdateCurrentOpeningAPI = API.updateCurrentOpening;
      UpdateCurrentOpeningAPI = UpdateCurrentOpeningAPI + "?id=" + params.get("Id");
      let res = await fetch(UpdateCurrentOpeningAPI, {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
        },
        json: true,
        body: JSON.stringify({
          title: Title,
          jobDescription: JobDescription,
          modifiedBy: user,
          isActive: checked
        }),
      });
      let resJson = await res.json();
      if (resJson.status === 200) {
        SetTitle("");
        SetJobDescription("");
        SetIsActive("");
        SetCookie("");
      }
      else
        navigate('/admin/current-openings');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Helmet>
        <title>Edit Current Opening</title>
      </Helmet>
      <div className="container p-0">
        <div className="container row pt-5">
          <div className="col">
            <h3 className="card-header px-3 pb-3">Edit Current Opening Details</h3>
          </div>
          <div className="col" style={{ textAlign: "end" }}>
            <Link to='/admin/current-openings' className="btn btn-info cl-button -primary">Back To List</Link>
          </div>
        </div>
        <div className='container mx-auto'>
          <div className="px-3 pb-3">
            <form className="pt-4" id='myform' onSubmit={submitHandler}>
              <div className="form-item pb-4">
                <input type="text" name="Title" id="Title" value={Title} onChange={(e) => SetTitle(e.target.value)} required />
                <label className='labe' >Title</label>
              </div>

              <div className="form-item pb-4">
                <JoditEditor
                  value={JobDescription}
                  config={editorConfig}
                  onChange={value => SetJobDescription(value)}
                />
              </div>

              <div className="form-check pb-4">
                <input className="form-check-input" type="checkbox" name="IsActive" id="IsActive" onChange={() => SetIsActive(!IsActive)} checked={IsActive} />
                <label className="form-check-label ms-1" for="flexCheckDefault">
                  Is-Active
                </label>
              </div>
              <input type="submit" value="Submit" className="cl-button -primary -small rounded-2 mt-2" />
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditCurrentOpening;
