import React from 'react';
import { Helmet } from "react-helmet";

// ================================|| VATReturnFiling ||================================ //

const VATReturnFiling = () => {

    return (
        <>
            <Helmet>
                <title>VAT Return Filing Services in Dubai and the UAE</title>
            </Helmet>
            <div class="chairman-message">
                <div class="chairman-img">
                    <div class="pos-message-box">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-7 aos-init" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2 className='alignment'>VAT Return Filing</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img class="d-lg-block d-none" style={{ width: "-webkit-fill-available" }} src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001997/images/Contact-us.png" alt="vat-return-banner" title="Vat Return Banner" />
            </div>
            <div className="container">
                <div className="row pt-5 pb-5">
                    <h1><span style={{ color: "#aa8038" }}>VAT Return Filing Services in UAE</span></h1>
                    <div className='col-12'>
                        <p>
                            VAT return is a power report to be introduced by each selected person to the Federal Tax Authority ("FTA") at conventional stretches determining any outcome charge due and input charge recoverable and including anything that different information that is supposed to be given.
                            Normally, VAT returns are filed quarterly and once in a while reliably. All VAT Returns should be submitted web using the FTA entrance. If there is no arrangement explicitly period, moreover enrolled individual requirements to record NIL return before the due date.
                        </p>
                    </div>
                    <h4><span style={{ color: "#aa8038" }}>Penalty For Noncompliance</span></h4>
                    <h6>Penalty for Non-Submission or Non-payment of VAT</h6>
                    <table class="table table-hover table-responsive table-striped table-bordered py-2">
                        <thead>
                            <tr>
                                <th class="text-white font-size-16 font-weight-6 bg-blue p-2" style={{ backgroundColor: "#aa8038" }}>
                                    Non Submission of return on time
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="font-size-15">
                                    AED 1000 for the first time delay
                                </td>
                            </tr>
                            <tr>
                                <td class="font-size-15">
                                    AED 2000 in case of repetative non-compliance with 24 months
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="table table-hover table-responsive table-striped table-bordered py-2">
                        <thead>
                            <tr>
                                <th class="text-white font-size-16 font-weight-6 bg-blue p-2" style={{ backgroundColor: "#aa8038" }}>
                                    Nonpayment of VAT on time
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="font-size-15">
                                    2% of the unpaid tax is due immediately once the payment of Payable Tax is late
                                </td>
                            </tr>
                            <tr>
                                <td class="font-size-15">
                                    4% of the unpaid tax is due on the seventh day following the deadline for payment, on the amount of tax which is still unpaid
                                </td>
                            </tr>
                            <tr>
                                <td class="font-size-15">
                                    1% of the unpaid tax daily penalty charged on any amount that is still unpaid one calendar month following the deadline for payment with upper ceiling of (300%)
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className='col-md-7'>
                        <h2 className='pt-3'><span style={{ color: "#aa8038" }}>How Devotion Can Help:-</span></h2>
                        <p>
                            We have a gathering of cost-trained professionals and subject matter experts and their master advice helps associations in concurring with the rules not entirely settled by government charge authority.
                            Our serious expert gathering for VAT return reporting constantly screens the still up in the air by the FTA and records the return recording inside the predefined time, along these lines, ensuring smooth consistency of the law, and related rules.
                        </p>
                    </div>
                    <div className='col-md-5'>
                        <img class="card-img-top" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002084/images/vat-return-filing.jpg" alt="vat-return" title="Vat Return" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default VATReturnFiling;