import React, { useState } from 'react';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import API from '../../../middleware/API';
import { Helmet } from "react-helmet";
import { Link, useNavigate } from 'react-router-dom';

// ================================|| ContactUs ||================================ //

const ContactUs = () => {
	const navigate = useNavigate();
	const [Name, setName] = useState("");
	const [Email, setEmail] = useState("");
	const [MobileNo, setMobileNo] = useState();
	const [Service, setService] = useState("");
	const [EmailError, setEmailError] = useState("");
	const [Description, setDescription] = useState("");

	let submitHandler = async (e) => {
		e.preventDefault();
		try {
			let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

			if (!re.test(Email)) {
				setEmailError("please enter valid email address");
			}
			else {
				var SendContactDetailEmailAPI = API.sendContactDetailEmail;
				let result = await fetch(SendContactDetailEmailAPI, {
					method: "POST",
					headers: {
						'Accept': 'application/json',
						'Content-type': 'application/json',
					},
					json: true,
					body: JSON.stringify({
						name: Name,
						email: Email,
						mobileNo: MobileNo,
						service: Service,
						description: Description
					}),
				});
				let resJsons = await result.json();
				if (resJsons.status === "SUCCESS") {
					var SaveContactDetailAPI = API.saveContactDetail;
					let res = await fetch(SaveContactDetailAPI, {
						method: "POST",
						headers: {
							'Accept': 'application/json',
							'Content-type': 'application/json',
						},
						json: true,
						body: JSON.stringify({
							name: Name,
							email: Email,
							mobileNo: MobileNo,
							service: Service,
							description: Description,
						}),
					});

					setName('');
					setEmail('');
					setMobileNo('');
					setService('');
					setDescription('');
					setEmailError('');
					navigate('/contact-us/thank-you');
				}
			}
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<>
			<Helmet>
				<title>Contact Us - Devotion Business</title>
            </Helmet>
			<div class="chairman-message">
				<div class="chairman-img">
					<div class="pos-message-box">
						<div class="container">
							<div class="row">
								<div class="col-lg-7 aos-init" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
									<h1 className='alignment-contact'>Contact Us</h1>
								</div>
							</div>
						</div>
					</div>
				</div>
				<img class="d-lg-block d-none" style={{ width: "-webkit-fill-available" }} src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001997/images/Contact-us.png" alt="contact-us-banner" title="Contact-us Banner" />
			</div>
			<div class="three-coloum-content mt-5 mb-5">
				<div class="container">
					<div class="row">
						<div class="col-lg-6 col-md-6">
							<h3 class="text-center" style={{ color: "rgb(170, 128, 56)" }}>Get In Touch With Us</h3>
							<ul class="list-unstyled nav-links mt-4">
								<li><img src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002043/images/location.png" height="30" width="30" alt="location" title="Location" /><a><span class="text-center fw-bold" style={{ fontSize: "18px", color: "rgb(170, 128, 56)", marginLeft: "1rem" }}>Registered Office: </span></a></li>
								<li><a href="https://maps.app.goo.gl/GSBC1ocsnESoer8u7" class="text-decoration-none text-black" style={{ marginLeft: "3rem" }}>1901-Al Fahidi Height, Nr Sharaf DG Metro Station, Bur - Dubai UAE.</a></li>
							</ul>
							<ul class="list-unstyled nav-links">
								<li><img src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002043/images/location.png" height="30" width="30" alt="location" title="Location" /><a><span class="text-center fw-bold" style={{ fontSize: "18px", color: "rgb(170, 128, 56)", marginLeft: "1rem" }}>Sales Office: </span></a></li>
								<li><a href="https://goo.gl/maps/MJ59aNDVHUyTaVLM8" class="text-decoration-none text-black" style={{ marginLeft: "3rem" }}>502-Iris Bay, Opposite JW Marriot Hotel, Business Bay - Dubai UAE.</a></li>
							</ul>
							<ul class="list-unstyled nav-links">
								<li><img src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002017/images/enquiry.png" height="30" width="30" alt="email" title="Email" /><a><span class="text-center fw-bold" style={{ fontSize: "18px", color: "rgb(170, 128, 56)", marginLeft: "1rem" }}>General Enquiries: </span></a></li>
								<li><span class="fw-bold" style={{ marginLeft: "3rem" }}>Email: </span><a href="mailto:sales@devotionbusiness.com" class="text-decoration-none text-black">sales@devotionbusiness.com</a></li>
								<li><span class="fw-bold" style={{ marginLeft: "3rem" }}>Tel: </span><a href="tel:+97144488538" class="text-decoration-none text-black">+971 44488538</a></li>
							</ul>
						</div>
						<div class="col-lg-6 col-md-6" style={{ border: "8px solid rgb(170, 128, 56" }}>
							<form class="mt-5 mb-5" id="inquiry-form" onSubmit={submitHandler}>
								<div class="row">
									<div class="col-md-12 form-group py-2">
										<input type="text" class="form-control bg-light" name="name" id="name" placeholder="Your Name *" value={Name} onChange={(e) => setName(e.target.value)} required />
									</div>
								</div>
								<div class="row">
									<div class="col-md-12 form-group py-2">
										<input type="email" class="form-control bg-light" name="email" id="email" placeholder="Your Email *" value={Email} onChange={(e) => setEmail(e.target.value)} required />
										<div class="error-message">{EmailError ? <p>{EmailError}</p> : null}</div>
									</div>
								</div>
								<div class="row">
									<div class="col-md-12 form-group py-2">
										<PhoneInput
											defaultCountry="AE"
											placeholder="Your Mobile No *"
											value={MobileNo}
											onChange={setMobileNo} required />
									</div>
								</div>
								<div class="row">
									<div class="col-md-12 form-group py-2">
										<select class="form-select" id="select" onChange={(e) => setService(e.target.value)} value={Service} required>
											<option value="">--Select a Service--</option>
											<option value="Immigration PRO Services">Immigration PRO Services</option>
											<option value="Business Consulting Advisory">Business Consulting Advisory</option>
											<option value="Business Banking">Business Banking</option>
											<option value="Forex Brokers Solution">Forex Brokers Solution</option>
											<option value="Import And Export Solution">Import And Export Solution</option>
											<option value="Company Set Up">Company Set Up</option>
											<option value="Trademark Registration">Trademark Registration</option>
											<option value="Company Liquidation">Company Liquidation</option>
											<option value="Accounting And Vat">Accounting And Vat</option>
											<option value="Others">Others</option>
										</select>
									</div>
								</div>
								<div class="row">
									<div class="col-md-12 form-group py-2">
										<textarea class="" name="message" id="message" placeholder="Description" value={Description} onChange={(e) => setDescription(e.target.value)}></textarea>
									</div>
								</div>
								<div class="row">
									<div class="col-md-12 py-1 d-block">
										<input type="submit" class="cl-button -primary -small rounded-2 py-2" value="Send Email" style={{ background: "rgb(170, 128, 56)" }} />
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ContactUs;