import React from 'react';
import '../../../thankyou.css';
import { Link } from 'react-router-dom';

// ================================|| Thankyou ||================================ //

const Thankyou1 = () => {

    return (
        <>
            <div class="content">
                <div class="wrapper-1">
                    <div class="wrapper-2">
                        <h1 class="main-text">Thank you !</h1>
                        <p>Your response has been submitted successfully.  </p>
                        <p>we'll contact you soon.  </p>
                        <button class="go-home">
                            <Link to="/" class='text-decoration-none text-white'>
                                Back to site
                            </Link>
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Thankyou1;