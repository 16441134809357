import React from 'react';
import { Helmet } from "react-helmet";

// ================================|| Auditing ||================================ //

const Auditing = () => {

    return (
        <>
            <Helmet>
                <title>Internal & External Audit Services in Dubai and the UAE</title>
            </Helmet>
            <div class="chairman-message">
                <div class="chairman-img">
                    <div class="pos-message-box">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-7 aos-init" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2 className='alignment'>Auditing</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img class="d-lg-block d-none" style={{ width: "-webkit-fill-available" }} src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001997/images/Contact-us.png" alt="auditing-banner" title="Auditing Banner" />
            </div>
            <div className="container">
                <div className="row pt-5 pb-5">
                    <div className='col-md-7'>
                        <h1><span style={{ color: "#aa8038" }}>Internal & External Audit Services in UAE</span></h1>
                        <p>
                            Typical investigating and backing of records have a more unmistakable responsibility towards the successful running of a business.
                            Every business needs the organization of masterfully qualified and experienced external evaluators in UAE to research their accounting issues.
                            Outside looking at is the strategy engaged with ensuring that the financial records and errands of a business firm are careful by using the organizations of an external inspector.
                        </p>
                        <h2><span style={{ color: "#aa8038" }}>Benefits of Outside Audit :-</span></h2>
                        <ul>
                            <li style={{ listStyle: "auto" }}>
                                Ensuring consistency with unofficial laws
                            </li>
                            <li style={{ listStyle: "auto" }}>
                                Works on financial trustworthiness
                            </li>
                            <li style={{ listStyle: "auto" }}>
                                Ensuring the accuracy of financial information
                            </li>
                            <li style={{ listStyle: "auto" }}>
                                Evasion of distortion and bad conduct
                            </li>
                        </ul>
                    </div>
                    <div className='col-md-5'>
                        <img class="card-img-top" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001988/images/auditing.jpg" alt="auditing" title="Auditing" />
                    </div>
                    <h2><span style={{ color: "#aa8038" }}>Internal Audit :-</span></h2>
                    <p>
                        Internal audits evaluate an association's inside controls, including its corporate organization and accounting processes.
                        These audits ensure consistency with guidelines and rules and help with staying aware of careful and advantageous moneyrelated uncovering and data collection.
                        Inside audits in like manner give the board the devices vital to accomplish practical capability by recognizing issues and helping slips before they are tracked down in an external survey.
                    </p>
                    <p>
                        We have accomplices assessing firms arranged in the United Arab Emirates (U.A.E) which are centered around offering capable kinds of the help of the best quality to its clients.
                    </p>
                    <h2><span style={{ color: "#aa8038" }}>Benefits of internal audit :-</span></h2>
                    <p>
                        <ul>
                            <li style={{ listStyle: "auto" }}>
                                Strengthens Internal Controls.
                            </li>
                            <li style={{ listStyle: "auto" }}>
                                Enhances Efficiency.
                            </li>
                            <li style={{ listStyle: "auto" }}>
                                Improves IT Security.
                            </li>
                            <li style={{ listStyle: "auto" }}>
                                Evasion of distortion and bad conduct.
                            </li>
                            <li style={{ listStyle: "auto" }}>
                                Helps Minimize Risk.
                            </li>
                            <li style={{ listStyle: "auto" }}>
                                Ensures Compliance.
                            </li>
                        </ul>
                    </p>
                </div>
            </div>

        </>
    );
};

export default Auditing;