import React from 'react';
import { Helmet } from "react-helmet";

// ================================|| Overview ||================================ //

const Overview = () => {


    return (
        <>
            <Helmet>
				<title>Who We Are & What We Do Devotion Business</title>
			</Helmet>
            <div class="chairman-message">
                <div class="chairman-img">
                    <div class="pos-message-box">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-7 aos-init" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h1>About Us</h1>
                                    <p>
                                        <b>DEVOTION CORPORATE SERVICES</b> is one of the prominent Document Processing centres in Dubai-UAE. We have more than <b>12 years of experience</b> with the involvement of organization arrangements and administrations. Devotion Corporate Services has industry best experts who truly do organization enlistment and are enthusiastic about transforming the fantasies of hopeful Business people and SMEs into the real world. The point of the organization is to help your Business Setup in Dubai as well as all over UAE.<br /><br />
                                        <p>
                                            Our mastery is to get the best rates for every one of the administrations like Document leeway, Business arrangement, Accounting and Bookkeeping, Composing and Interpretation administrations, Staff work, and Private migration purposes in Dubai and all through the UAE. We are generally cognizant of the nature of our administration by persistently updating ourselves with the most noteworthy expert principles. That is how we can convey top-notch proficient support to our clients at a reasonable expense.<br />
                                        </p>
                                        The fundamental focal point of our organization is our clients. We fulfil our client requirement in accordance with their exact prerequisites. We would simply prefer not to find lasting success yet we wish to affect what we deal with our clients.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img class="d-lg-block ceo-desktop-img d-none" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001991/images/Chairmans-Message_desktop.jpg" alt="overview-banner" title="Overview Banner" />
                <img class="d-lg-none d-block" style={{ width: "-webkit-fill-available" }} src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001991/images/Chairmans-Message_mobile.jpg" alt="overview-banner" title="Overview Banner" />
            </div>
        </>
    );
};

export default Overview;