import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { FaFileAlt } from 'react-icons/fa';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import API from '../../../middleware/API';

// ================================|| SharjahDialogEnquiry ||================================ //

function SharjahDialogEnquiry() {
    const [Name, setName] = useState("");
    const [Email, setEmail] = useState("");
    const [MobileNo, setMobileNo] = useState();
    const [Service, setService] = useState("");
    const [message, setMessage] = useState("");
    const [EmailError, setEmailError] = useState("");
    const [Description, setDescription] = useState("");
    const [Show, setShow] = useState(false);
    const handleClose = () => setShow(false);               // Open model close on click on a close button.
    const handleShow = (e) => {
        setService(e.target.offsetParent.id);
        setShow(true);                 // On button click Open the model.
    }


    let submitHandler = async (e) => {
        e.preventDefault();
        try {
            let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            if (!re.test(Email)) {
                setEmailError("please enter valid email address");
            }
            else {
                var SendFreezoneEnquiryEmailAPI = API.sendFreezoneEnquiryEmail;
                let result = await fetch(SendFreezoneEnquiryEmailAPI, {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-type': 'application/json',
                    },
                    json: true,
                    body: JSON.stringify({
                        name: Name,
                        email: Email,
                        mobileNo: MobileNo,
                        service: Service,
                        description: Description,
                    }),
                });
                setName('');
                setEmail('');
                setMobileNo('');
                setService('');
                setDescription('');
                setEmailError('');
                setMessage("Your response has been submitted sucessfully.");
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <button class="btn p-2">
                <a className="banner-btn w-button mt-2" onClick={handleShow}>
                    <span class="text-break"><FaFileAlt /> Enquiry</span>
                </a>
            </button>

            <Modal show={Show}>
                <Modal.Header closeButton onHide={handleClose}>
                    <Modal.Title>Enquiry Form</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form id="inquiry-form" onSubmit={submitHandler}>
                        <div class="row">
                            <div class="col-md-12 form-group py-2">
                                <h6 style={{ color: "#aa8038" }}>Enquiry For : </h6>
                                <input type="text" class="form-control bg-light" value={Service} disabled />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 form-group py-2">
                                <h6 style={{ color: "#aa8038" }}>Name : </h6>
                                <input type="text" class="form-control bg-light" name="name" id="name" placeholder="Your Name *" value={Name} onChange={(e) => setName(e.target.value)} required />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 form-group py-2">
                                <h6 style={{ color: "#aa8038" }}>Email : </h6>
                                <input type="email" class="form-control bg-light" name="email" id="email" placeholder="Your Email *" value={Email} onChange={(e) => setEmail(e.target.value)} required />
                                <div class="error-message">{EmailError ? <p>{EmailError}</p> : null}</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 form-group py-2">
                                <h6 style={{ color: "#aa8038" }}>Mobile No : </h6>
                                <PhoneInput
                                    defaultCountry="AE"
                                    placeholder="Your Mobile No *"
                                    value={MobileNo}
                                    onChange={setMobileNo} required />
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12 form-group py-2">
                                <h6 style={{ color: "#aa8038" }}>Description : </h6>
                                <textarea class="" name="message" id="message" placeholder="Description" value={Description} onChange={(e) => setDescription(e.target.value)}></textarea>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 py-1 d-block">
                                <input type="submit" class="cl-button -primary -small rounded-2 py-2" value="Submit" style={{ background: "rgb(170, 128, 56)" }} />
                            </div>
                        </div>
                        <div class="message">{message ? <p>{message}</p> : null}</div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default SharjahDialogEnquiry;