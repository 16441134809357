import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from 'react-router-dom';
import { Cookies, useCookies } from "react-cookie";
import API from '../../../middleware/API';

// ================================|| SaveUser ||================================ //

function SaveUser() {
  const navigate = useNavigate();
  const [Roledata, setRoleData] = useState();
  const [RoleId, SetRoleId] = useState("");                          // store the input values
  const [FullName, SetFullName] = useState("");                      // store the input values
  const [UserName, SetUserName] = useState("");                      // store the input values
  const [Password, SetPassword] = useState("");                      // store the input values
  const [Email, SetEmail] = useState("");                            // store the input values
  const [MobileNumber, SetMobileNumber] = useState("");              // store the input values
  const [Image, SetImage] = useState("");                            // store the input values
  const [Address, SetAddress] = useState("");                        // store the input values
  const [IsActive, SetIsActive] = useState("");                      // store the input values
  const [ErrorMessage, SetErrorMessage] = useState("");
  const [cookie, SetCookie] = useCookies(['username']);
  const cookies = new Cookies();
  const user = cookies.get('id');

  useEffect(() => {
    getRoles();
  }, []);

  const getRoles = async () => {
    try {
      var GetRolesAPI = API.getActiveRoles;
      const response = await fetch(GetRolesAPI);
      const jsonData = await response.json();
      setRoleData(jsonData.recordset);
    }
    catch (error) {
      console.log(error);
    }
  };

  let submitHandler = async (e) => {
    e.preventDefault();

    var checked = false;
    if (document.querySelector('#IsActive:checked')) {
      checked = true;
    }

    try {
      var re = /(?:\.([^.]+))?$/;
      var ext = re.exec(Image);

      if (ext[0] === ".jpg" || ext[0] === ".png") {
        var SignuploadformAPI = API.signuploadform;
        const signResponse = await fetch(SignuploadformAPI);
        const signData = await signResponse.json();
        const url = "https://api.cloudinary.com/v1_1/" + signData.cloudname + "/auto/upload";
        const files = document.querySelector("[type=file]").files;
        let file = files[0];
        const formData = new FormData();

        formData.append("file", file);
        formData.append("api_key", signData.apikey);
        formData.append("timestamp", signData.timestamp);
        formData.append("signature", signData.signature);
        formData.append("eager", "c_pad,h_300,w_400|c_crop,h_200,w_260");
        formData.append("folder", "images");

        const fileData = await fetch(url, {
          method: "POST",
          body: formData
        })
        const fileJson = await fileData.json();

        var SaveUserAPI = API.saveUser;
        let res = await fetch(SaveUserAPI, {
          method: "POST",
          headers: {
            'Accept': 'application/json',
            'Content-type': 'application/json',
          },
          json: true,
          body: JSON.stringify({
            roleId: RoleId,
            fullName: FullName,
            username: UserName,
            password: Password,
            email: Email,
            mobileNo: MobileNumber,
            image: fileJson.secure_url,
            address: Address,
            createdBy: user,
            isActive: checked
          }),
        });
        let resJson = await res.json();
        if (resJson.status === 200) {
          SetRoleId("");
          SetFullName("");
          SetUserName("");
          SetPassword("");
          SetEmail("");
          SetMobileNumber("");
          SetImage("");
          SetAddress("");
          SetErrorMessage("");
        }
        else
          navigate('/admin/users');
      }
      else {
        SetErrorMessage("Upload only jpg & png format Image");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Helmet>
        <title>Save User</title>
      </Helmet>
      <div className="container p-0">
        <div className="container row pt-5">
          <div className="col">
            <h3 className="card-header px-3 pb-3">Enter User Details</h3>
          </div>
          <div className="col" style={{ textAlign: "end" }}>
            <Link to='/admin/users' className="btn -primary text-white" style={{ background: "#aa8038" }}>Back To List</Link>
          </div>
        </div>
        <div className="container-fluid mx-auto ">
          <div className="px-3 pb-3">
            <form id='myform' onSubmit={submitHandler}>
              <div className="form-item pb-4">
                <select class="form-select form-select-lg mb-3" id='select' onChange={(e) => SetRoleId(e.target.selectedIndex)} required>
                  <option key="0" value="0">-- Select Role --</option>
                  {Roledata && Roledata.map((Roledata) => (
                    <option key={RoleId.Id} value={RoleId.Id}>
                      {Roledata.Name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-item pb-4">
                <input type="text" name="FullName" id="FullName" onChange={(e) => SetFullName(e.target.value)} required />
                <label className='labe' >FullName</label>
              </div>
              <div className="form-item pb-4">
                <input type="text" name="UserName" id="UserName" onChange={(e) => SetUserName(e.target.value)} required />
                <label className='labe' >UserName</label>
              </div>

              <div className="form-item pb-4">
                <input type="password" name="Password" id="Password" onChange={(e) => SetPassword(e.target.value)} required />
                <label className='labe' >Password</label>
              </div>

              <div className="form-item pb-4">
                <input type="email" name="Email" id="Email" onChange={(e) => SetEmail(e.target.value)} required />
                <label className='labe' >Email</label>
              </div>

              <div className="form-item pb-4">
                <input type="number" name="MobileNumber" id="MobileNumber" onChange={(e) => SetMobileNumber(e.target.value)} required />
                <label className='labe' >MobileNumber</label>
              </div>

              <div className="form-item">
                <input type="file" name='file' className="bg-body text-dark form-control-file" value={Image} onChange={(e) => SetImage(e.target.value)} required />
              </div>
              <div className="error-message pb-4">{ErrorMessage ? <p>{ErrorMessage}</p> : null}</div>

              <div className="form-item pb-4">
                <textarea name="address" value={Address} onChange={(e) => SetAddress(e.target.value)} ></textarea>
                <label className='labe'>Address</label>
              </div>

              <div className="form-check pb-4">
                <input className="form-check-input" type="checkbox" name="IsActive" id="IsActive" value={IsActive} onChange={(e) => SetIsActive(e.target.value)} />
                <label className="form-check-label ms-1" for="flexCheckDefault">
                  Is-Active
                </label>
              </div>
              <div className="col px-5" style={{ textAlign: "center" }}>
                <input type="submit" value="Submit" className="btn -primary text-white" style={{ background: "#aa8038" }} />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default SaveUser;