import React from 'react';
import Faq from "react-faq-component";
import { Helmet } from "react-helmet";

// ================================|| ImportExportSolutions ||================================ //

const ImportExportSolutions = () => {

    const exports = {
        title: "",
        rows: [
            {
                title: "Procedure for Export:",
                content: `<b>The exporter needs to go through the following steps.</b><br/><br/>
                The exporter needs to submit documents required at the Customs Office. Pay the export declaration registration fees at the Customs office. Then collect the Customs declaration certificate.`,
            },
            {
                title: "Documents required:",
                content: `Instructions of the Declaration of Goods Application (IDG) or an approved export declaration certificate in the name of a licensed company by a licensing agency in the country for airport customs points of entry. 
                An export permit from the competent agencies if the goods exporting are restricted. Sale invoice showing total quantity, description and total and detailed value of each individual item from a licensed company in the country and should be addressed to a company outside the country. Declaration for clearing the goods.`,
            },
        ],
    };

    const imports = {
        title: "",
        rows: [
            {
                title: "The Process of importing goods in Dubai:",
                content: `When the vessel arrives, the importer must approach the shipping agent in order to submit the original Bill of Lading to the shipping agent and pay dues (if any). After this, the shipping agent will issue a Delivery Order. 
                The Importer must ensure that all cargo clearance formalities are completed before the Delivery Order expiry date. So keeping this in context the shipping agent will issue the Delivery order 3-4 days prior to the vessel arrival. 
                For Dubai Customs clearance the importer should then login to Dubai Trade to create and submit the Import Declaration application. The importer should make payments for customs duties and other fees online using the CDR account or E-payment. 
                The importer should get special permissions in case of restricted goods or duty exempted cargo before filling the Customs Import Declaration. Dubai Customs will do the verification and evaluation of the documentation provided, 
                in order to collect the customs duties and approve import. Dubai Customs may command inspection of the goods by a proficient authority before giving the possession of cargo to the importer. 
                The importer can check the vessel arrival status online by logging into Vessel Enquiry e-service and by using the Container Enquiry e-service, check the container discharge status. 
                After the container is discharged, the importer can then appoint a transport company to receive delivery of the cargo/ container from DP World on importer’s behalf using the hauler Nomination service on Dubai Trade. 
                Equipment Interchange Receipt (EIR) by DP World will be given to the hauler for each container taken out from the Jebel Ali port. As per the Customs Import Declaration if any inspection is required then the hauler must take the container to the inspection area of the competent authority.
                Only after getting the inspection clearance, the hauler will be allowed to exit the JAFZA by showing a copy of the EIR.`,
            },
            {
                title: "Documents required importing goods outside Dubai:",
                content: `Commercial Invoice: from the exporter addressed to the importer detailing the total quantity, goods description and total value of each item Certificate of origin (stating the origin of goods): 
                approved by the country of origin’s Chamber of Commerce Detailed Packing List: as per weight, the method of packing and HS code for each individual article contained in the shipment Import Permit: from the competent agencies in the event of importing restricted goods or duty exempted goods.`,
            },
        ],
    };

    const styles = {
        titleTextColor: "black",
        rowTitleColor: "#aa8038"
    };

    const config = {
        rowTitleColor: "#aa8038",
    };

    return (
        <>
            <Helmet>
                <title>Import & Export Solution in Dubai and the UAE</title>
            </Helmet>
            <div class="chairman-message">
                <div class="chairman-img">
                    <div class="pos-message-box">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-7 aos-init" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2 className='alignment'>Import & Export Solutions</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img class="d-lg-block d-none" style={{ width: "-webkit-fill-available" }} src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001997/images/Contact-us.png" alt="import-export-banner" title="Import Export Banner" />
            </div>
            <div className="container">
                <div className="row pt-5 pb-5">
                    <div className='col-md-7'>
                        <h1><span style={{ color: "#aa8038" }}>Dubai Import And Export Business License</span></h1>
                        <p>
                            Depending upon the degree of activities these assumptions explain that controlled UAE Forex delegates go through a lot of examination, guaranteeing they can offer genuine kinds of help to their clients.
                            Offshore vendors are happily gotten, but they need to have a local representative in the country, and, regularly, they need to show their liquidity. A Forex vendor without sufficient cash nearby is a disaster in progress.
                        </p>
                        <p>
                            Dubai's fundamental region has been filling in as an advantage for the import-exchange associations Dubai. For quite a while now Dubai has been the middle point for continuing with work and one among those critical associations is the import-convey business in Dubai.
                            Being the point of convergence in Asia the UAE has been the partner road for trade between the European nations and the East Asian nations. The import and item associations have been in the most gainful spot of their business in the past several years.
                            Dubai government has the course of action e-Services for the import and item associations in Dubai to make their working even more straightforward and capable.
                        </p>
                    </div>
                    <div className='col-md-5'>
                        <img class="card-img-top" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002039/images/import-export-solutions.png" alt="import-export" title="Import Export" />
                    </div>
                    <p>
                        Anyone overseeing in import and ware business in Dubai needs to enlist with Dubai Customs. Dubai Customs has become on the web. Mirsal 2 is the web-based explanation and getting structure liberated from Dubai Customs.
                        Mirsal's online door gives client counsel for selection and how in the first place import-convey business in Dubai. Enrollment at the Dubai Customs ought to be conceivable on the web, yet all of the records ought to be given to the Customs official eye to eye.
                        All items imported to and conveyed from Dubai need to go through the Customs Office and will be checked whether they fall under the denied summary of the Customs office or not.
                    </p>
                    <p>
                        Dubai Customs worked with the DP World and JAFZA (Jebel Ali Free Zone) to make a web-based entryway for Dubai trade, which gives all the import-ware and trading techniques under a lone roof.
                        The global coalition with Dubai and various countries have restricted the obligation cost charged on stock imported and conveyed from those countries.
                    </p>
                </div>
            </div>
            <div className="three-coloum-content pt-5 pb-5" style={{ backgroundColor: "#EDEDED" }}>
                <div className="container">
                    <div className="row">
                        <h3 style={{ color: "#aa8038" }}>Dubai Export</h3>
                        <p>
                            Export means producing goods in one country but trading them in another country in a legal way. Export plays an important part in the International trade of a country.
                            If the goods are exported any of the Free zones of the UAE they are not subject to pay any customs duty. Although the Free Trade Zone is considered an integral part of the UAE province but doesn’t come under the UAE Customs territory.
                        </p>
                        <Faq
                            data={exports}
                            styles={styles}
                            config={config}
                        />
                    </div>
                </div>
            </div>
            <div className="three-coloum-content pt-5 pb-5">
                <div className="container">
                    <div className="row">
                        <h3 style={{ color: "#aa8038" }}>Dubai Import</h3>
                        <p>
                            Importing involves purchasing or getting goods from outside the borders of the UAE or from a foreign country or from any other province of Emirates by meeting all the legal procedures, documentation, permissions, charges and terms of licensing.
                            By availing the trade license from the UAE license issuing authority anyone can become an importer and will be registered with the Customs Department.
                        </p>
                        <Faq
                            data={imports}
                            styles={styles}
                            config={config}
                        />
                    </div>
                    <h3 className='pt-5' style={{ color: "#aa8038" }}>Dubai Import And Export License</h3>
                    <p>
                        If you are looking to set up a business in Dubai in the import-export sector then you need to have the import-export license in Dubai.
                        Obtaining the import-export license in Dubai has been simplified by Dubai customs and can be acquired online.
                        But if you are exporting or importing special or restricted goods then you need to get a special import or export license in Dubai from the Customs Office.
                    </p>
                    <p>
                        The information provided in the above regarding the import-export business in Dubai will be able to help you in your venture.
                        If you are willing to set up a business in Dubai which involves import and export in Dubai you can avail the services of Commitbiz.com and we will take care of the rest.
                        Our team of professionals will guide through any business related quires and help you in company formation in Dubai and other locations in the UAE.
                    </p>
                </div>
            </div>
        </>
    );
};

export default ImportExportSolutions;