import React from 'react';
import { Helmet } from "react-helmet";

// ================================|| BusinessBanking ||================================ //

const BusinessBanking = () => {

    return (
        <>
            <Helmet>
                <title>Business Banking Services in Dubai and the UAE</title>
            </Helmet>
            <div class="chairman-message">
                <div class="chairman-img">
                    <div class="pos-message-box">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-7 aos-init" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2 className='alignment'>Business Banking</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img class="d-lg-block d-none" style={{ width: "-webkit-fill-available" }} src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001997/images/Contact-us.png" alt="business-banking-banner" title="Business Banking Banner" />
            </div>
            <div className="container">
                <div className="row pt-5 pb-5">
                    <div className='col-md-7'>
                        <h1><span style={{ color: "#aa8038" }}>Business Banking Services in UAE</span></h1>
                        <p>
                            Opening a record is an essential perspective ensuing to getting the grant for any association.
                            Permit us to help you with opening a record or help you with offshore banking in Dubai.
                            Our relationship with various banks grants us to help both Freezone and focal region associations open a record in the UAE.
                        </p>
                        <p>
                            Banks have changing essentials concerning reports, least balance need, and specialization in unambiguous regions among others.
                            The strategy can change depending upon the case. Considering the necessities and tendencies of our clients, we can outfit them with a once-over of sensible and strong banks.
                            Despite the fundamental chronicles to open record, in unambiguous cases supporting reports also might be required like requesting, reference letters, etc.
                            If the association is a branch, records about the parent association will be normal close by bank decrees.
                        </p>
                        <p>
                            By far most of the banks in the UAE request keeping a base of harmony.
                            Contemplating the possibility of your business, we propose the banks which would suit your necessities.
                        </p>
                    </div>
                    <div className='col-md-5'>
                        <img class="card-img-top" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001990/images/business-banking.jpg" alt="business-banking" title="Business Banking" />
                    </div>
                    <p>
                        If the essential is to open an Islamic record we propose Emirates Islamic, RAK Islamic record. If the essential is for driving hypothesis or new trade, we suggest Al Masraf.
                        If you are looking for a customary kind of record, there are various decisions like ENBD, Mashreq Bank, First Abu Dhabi Bank, National Bank of Fujairah, Union National bank, ADCB, etc.
                    </p>
                    <p>
                        The period to open the record relies upon various variables like the nature of business, home of movement (Free Zone/Offshore or Mainland), and nationality of the signatory.
                        For instance, in the focal region, General Trading activity requires consistent support before you can open a record.
                    </p>
                    <p>
                        Hence it requires more prominent speculation to open the monetary equilibrium.
                    </p>
                    <p>
                        A blend of these different components can impact the period. For a common trading association the focal region with all indispensable reports set up, it usually requires 10 working days to open a record.
                    </p>
                </div>
            </div>
        </>
    );
};

export default BusinessBanking;