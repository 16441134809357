import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Cookies, useCookies } from "react-cookie";
import API from '../../../middleware/API';

// ================================|| EditUser ||================================ //

function EditUser() {
    const navigate = useNavigate();
    const [params] = useSearchParams();
    const [Userdata, setUserData] = useState();
    const [Roledata, setRoleData] = useState();
    const [RoleId, SetRoleId] = useState("");                          // store the input values
    const [FullName, SetFullName] = useState("");                      // store the input values
    const [UserName, SetUserName] = useState("");                      // store the input values
    const [Password, SetPassword] = useState("");                      // store the input values
    const [Email, SetEmail] = useState("");                            // store the input values
    const [MobileNumber, SetMobileNumber] = useState("");              // store the input values
    const [Image, SetImage] = useState("");                            // store the input values
    const [Address, SetAddress] = useState("");                        // store the input values
    const [IsActive, SetIsActive] = useState("");                      // store the input values
    const [ErrorMessage, SetErrorMessage] = useState("");
    const [cookie, SetCookie] = useCookies(['username']);
    const cookies = new Cookies();
    const user = cookies.get('id');

    useEffect(() => {
        getUserByID();
        getRoles();
    }, []);

    let userdata = "";

    const getUserByID = async () => {
        try {
            var GetUserByIdAPI = API.getUserById;
            GetUserByIdAPI = GetUserByIdAPI + "?id=" + params.get("Id");
            const response = await fetch(GetUserByIdAPI);
            const jsonData = await response.json();
            setUserData(jsonData.recordset);
            userdata = jsonData.recordset[0];
            SetRoleId(userdata.Role_Id);
            SetFullName(userdata.FullName);
            SetUserName(userdata.UserName);
            SetPassword(userdata.Password);
            SetEmail(userdata.EmailId);
            SetMobileNumber(userdata.MobileNo);
            SetImage(userdata.Image);
            SetAddress(userdata.Address);
            SetIsActive(userdata.IsActive);
        }
        catch (error) {
            console.log(error);
        }
    };

    const getRoles = async () => {
        try {
            var GetRolesAPI = API.getActiveRoles;
            const response = await fetch(GetRolesAPI);
            const jsonData = await response.json();
            setRoleData(jsonData.recordset);
        }
        catch (error) {
            console.log(error);
        }
    };

    let submitHandler = async (e) => {
        e.preventDefault();

        var checked = false;
        if (document.querySelector('#IsActive:checked')) {
            checked = true;
        }

        try {
            var re = /(?:\.([^.]+))?$/;
            var ext = re.exec(Image);
            var text = Image;

            if (text.match("https://res.cloudinary.com/")) {
                var UpdateUserAPI = API.updateUser;
                UpdateUserAPI = UpdateUserAPI + "?id=" + params.get("Id");
                let res = await fetch(UpdateUserAPI, {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-type': 'application/json',
                    },
                    json: true,
                    body: JSON.stringify({
                        roleId: RoleId,
                        fullName: FullName,
                        username: UserName,
                        password: Password,
                        email: Email,
                        mobileNo: MobileNumber,
                        image: Image,
                        address: Address,
                        modifiedBy: user,
                        isActive: checked
                    }),
                });
                let resJson = await res.json();
                if (resJson.status === 200) {
                    SetFullName("");
                    SetUserName("");
                    SetPassword("");
                    SetEmail("");
                    SetMobileNumber("");
                    SetImage("");
                    SetAddress("");
                    SetErrorMessage("");
                }
                else
                    navigate('/admin/users');
            }
            else if (ext[0] === ".jpg" || ext[0] === ".png") {

                var SignuploadformAPI = API.signuploadform;
                const signResponse = await fetch(SignuploadformAPI);
                const signData = await signResponse.json();
                const url = "https://api.cloudinary.com/v1_1/" + signData.cloudname + "/auto/upload";
                const files = document.querySelector("[type=file]").files;
                let file = files[0];
                const formData = new FormData();

                formData.append("file", file);
                formData.append("api_key", signData.apikey);
                formData.append("timestamp", signData.timestamp);
                formData.append("signature", signData.signature);
                formData.append("eager", "c_pad,h_300,w_400|c_crop,h_200,w_260");
                formData.append("folder", "images");

                const fileData = await fetch(url, {
                    method: "POST",
                    body: formData
                })
                const fileJson = await fileData.json();

                var UpdateUserAPI = API.updateUser;
                UpdateUserAPI = UpdateUserAPI + "?id=" + params.get("Id");
                let res = await fetch(UpdateUserAPI, {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-type': 'application/json',
                    },
                    json: true,
                    body: JSON.stringify({
                        roleId: RoleId,
                        fullName: FullName,
                        username: UserName,
                        password: Password,
                        email: Email,
                        mobileNo: MobileNumber,
                        image: fileJson.secure_url,
                        address: Address,
                        modifiedBy: user,
                        isActive: checked
                    }),
                });
                let resJson = await res.json();
                if (resJson.status === 200) {
                    SetRoleId("");
                    SetFullName("");
                    SetUserName("");
                    SetPassword("");
                    SetEmail("");
                    SetMobileNumber("");
                    SetImage("");
                    SetAddress("");
                    SetErrorMessage("");
                }
                else
                    navigate('/admin/users');
            }
            else {
                SetErrorMessage("Upload only jpg & png format Image");
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <Helmet>
                <title>Edit User</title>
            </Helmet>
            <div className="container p-0">
                <div className="container row pt-5">
                    <div className="col">
                        <h3 className="card-header px-3 pb-3">Edit User Details</h3>
                    </div>
                    <div className="col" style={{ textAlign: "end" }}>
                        <Link to='/admin/users' className="btn -primary text-white" style={{ background: "#aa8038" }}>Back To List</Link>
                    </div>
                </div>
                <div className='container'>
                    <form id='myform' onSubmit={submitHandler}>
                        <div className="form-item pb-4">
                            <select class="form-select form-select-lg mb-3" id='select' value={RoleId} onChange={(e) => SetRoleId(e.target.value)} required>
                                <option key="0" value="0">-- Select Role --</option>
                                {Roledata && Roledata.map((Roledata) => (
                                    <option key={Roledata.Id} value={Roledata.Id}>
                                        {Roledata.Name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="form-item pb-4">
                            <input type="text" name="FullName" id="FullName" value={FullName} onChange={(e) => SetFullName(e.target.value)} required />
                            <label className='labe' >FullName</label>
                        </div>

                        <div className="form-item pb-4">
                            <input type="text" name="UserName" id="UserName" value={UserName} onChange={(e) => SetUserName(e.target.value)} required />
                            <label className='labe' >UserName</label>
                        </div>

                        <div className="form-item pb-4">
                            <input type="password" name="Password" id="Password" value={Password} onChange={(e) => SetPassword(e.target.value)} required />
                            <label className='labe' >Password</label>
                        </div>

                        <div className="form-item pb-4">
                            <input type="email" name="Email" id="Email" value={Email} onChange={(e) => SetEmail(e.target.value)} required />
                            <label className='labe' >Email</label>
                        </div>

                        <div className="form-item pb-4">
                            <input type="number" name="MobileNumber" id="MobileNumber" value={MobileNumber} onChange={(e) => SetMobileNumber(e.target.value)} required />
                            <label className='labe' >MobileNumber</label>
                        </div>

                        <div className="row form-item pb-4">
                            <label className=''>Current Image:- </label>
                            <img src={Image} className="col-md-1 thumbnail" alt="user" title="User" />
                        </div>

                        <div className="form-item">
                            <input type="file" name='file' className="bg-body text-dark form-control-file" onChange={(e) => SetImage(e.target.value)} />
                        </div>
                        <div className="error-message pb-4">{ErrorMessage ? <p>{ErrorMessage}</p> : null}</div>

                        <div className="form-item pb-4">
                            <textarea name="address" value={Address} onChange={(e) => SetAddress(e.target.value)} ></textarea>
                            <label className='labe'>Address</label>
                        </div>

                        <div className="form-check pb-4">
                            <input className="form-check-input" type="checkbox" name="IsActive" id="IsActive" onChange={() => SetIsActive(!IsActive)} checked={IsActive} />
                            <label className="form-check-label ms-1" for="flexCheckDefault">
                                Is-Active
                            </label>
                        </div>
                        <div className="col px-5" style={{ textAlign: "center" }}>
                            <input type="submit" value="Submit" className="btn -primary text-white" style={{ background: "#aa8038" }} />
                        </div>
                    </form>

                </div>
            </div>
        </>
    );
};

export default EditUser;