import React from 'react';
import { Helmet } from "react-helmet";

// ================================|| CompanyLiquidation ||================================ //

const CompanyLiquidation = () => {

    return (
        <>
            <Helmet>
                <title>Company Liquidation Service in Dubai and the UAE</title>
            </Helmet>
            <div class="chairman-message">
                <div class="chairman-img">
                    <div class="pos-message-box">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-7 aos-init" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2 className='alignment'>Company Liquidation</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img class="d-lg-block d-none" style={{ width: "-webkit-fill-available" }} src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001997/images/Contact-us.png" alt="company-liquidation-banner" title="Company Liquidation Banner" />
            </div>
            <div className="container">
                <div className="row pt-5 pb-5">
                    <h1><span style={{ color: "#aa8038" }}>Company Liquidation Service in UAE</span></h1>
                    <div className='col-12'>
                        <p>
                            Liquidation is the technique by which an LLC organization (or a piece of an organization), sole foundation, Dubai free zone organization, or establishment shuts its activity,
                            and hence the resources and property of the corporation or foundation is circulated to banks and investors (proprietors) of the UAE enlisted Company.
                            Liquidation is moreover referred to or noted as twisting up or disintegration, however disintegration hypothetically alludes to definitive strides of the liquidation interaction.
                            The liquidation of an organization in Dubai, UAE becomes unavoidable when a partnership doesn't have fluid assets to hang on to the everyday exchange of the organization,
                            leaders aren't being paid or organization commits some serious offense and an administrative power or office in UAE of Free Zone Authority responsible for the important area documents a suit for the influencing the tasks of the corporate.
                        </p>
                    </div>
                    <div className='col-md-7'>
                        <p>
                            There are cycle contrasts between Freezone organization liquidation and Dubai fundamental land as Freezone organizations need to pass the goal from Board for selling the corporate and arrangement of outlet for the interaction though in Dubai central area organizations expected to pass and legally approve Board Resolution to exchange/drop/wrap up the activity of LLC organization and name vendors.
                            In the free zone, every one of the contributions needs to clear and follow the cycle properly assigned by the Freezone with 45 days of paper promotion for any complaint in some freezone. Dubai's primary land needs to get a letter from the reviewer to settle on exchange the organization additionally the clearances from govt division like DEWA, ETISALAT/DU, RTA, Bank, Department of Labor and
                            Immigration and so forth) and a similar like some free zones closer promotion in neighborhood papers with 45 days holding up period to get NOC and submit to the Department of monetary turn of events. The entire cycle takes a likely 70 to 80 days of interaction.
                        </p>
                    </div>
                    <div className='col-md-5'>
                        <img class="card-img-top" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001994/images/company-liquidation.jpg" alt="company-liquidation" title="Company Liquidation" />
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row pb-5">
                    <div class="col-md-12">
                        <table class="table table-hover table-responsive table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th class="text-white font-size-16 font-weight-6 bg-blue p-2" style={{ backgroundColor: "#aa8038" }} width="70%">
                                        DESCRIPTIONS
                                    </th>
                                    <th class="text-white font-size-16 font-weight-6 bg-blue p-2" style={{ backgroundColor: "#aa8038" }} width="30%">
                                        FEE in AED
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="font-size-15">
                                        Liquidation Audit Report &amp; Confirmation of Appointment Liquidator
                                    </td>
                                    <td class="font-size-15">
                                        AED 3,000
                                    </td>
                                </tr>
                                <tr>
                                    <td class="font-size-15">
                                        Company Liquidation Certificate - Initial Approval (DED)
                                    </td>
                                    <td class="font-size-15">
                                        AED 2,020
                                    </td>
                                </tr>
                                <tr>
                                    <td class="font-size-15">
                                        Final Approval (DED)
                                    </td>
                                    <td class="font-size-15">
                                        AED 3,030
                                    </td>
                                </tr>
                                <tr>
                                    <td class="font-size-15">
                                        Arabic and English Newspaper Publication - (45 days)
                                    </td>
                                    <td class="font-size-15">
                                        AED 1,575
                                    </td>
                                </tr>
                                <tr>
                                    <td class="font-size-15">
                                        Aurion Professional Inclusive 5% UAE VAT
                                    </td>
                                    <td class="font-size-15">
                                        AED 5,250
                                    </td>
                                </tr>
                                <tr>
                                    <td class="font-size-15">
                                        TOTAL LLC Company Liquidation Cost
                                    </td>
                                    <td class="font-size-15">
                                        AED 14,875
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CompanyLiquidation;