import React from 'react';
import ModalDialog from './AbuDhabiDialogEnquiry';
import ModalDownloadDialog from './AbuDhabiDialogDownload';
import { Helmet } from "react-helmet";

// ================================|| AbuDhabiFreeZones ||================================ //

const AbuDhabiFreeZones = () => {

    return (
        <>
            <Helmet>
                <title>Business Setup in Abu Dhabi Free Zone | Abu Dhabi Company Formation</title>
            </Helmet>
            <div class="chairman-message">
                <div class="chairman-img">
                    <div class="pos-message-freezone-box">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-7 aos-init" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2>Abu Dhabi Free Zone</h2>
                                    <p>
                                        Establishing a company in one of the free zones in Abu Dhabi UAE has many benefits for Businesses and Company looking to grow internationally.
                                        Starting a free zone Business in Abu Dhabi could be difficult, as it needs a lot of paperwork.
                                        We at Devotion Corporate Services streamline this procedure and assist you, in quickly establishing a Business Setup in the Abu Dhabi Free Zones.
                                        You can even register a business online with Devotion Corporate Services in the free zone of Abu Dhabi.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img class="d-lg-block chairman-desktop-abudhabi-img d-none" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001991/images/Chairmans-Message_desktop.jpg" alt="abudhabi-banner" title="AbuDhabi Banner" />
                <img class="d-lg-none d-block" style={{ width: "-webkit-fill-available" }} src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001991/images/Chairmans-Message_mobile.jpg" alt="abudhabi-banner" title="AbuDhabi Banner" />
            </div>
            <div class="pt-5 pb-5">
                <div class="container">
                    <div class="row">
                        <h1><span style={{ color: "#aa8038" }}>Business Setup in Abu Dhabi Freezone</span></h1>
                        <div class="col-lg-3 col-md-4 col-sm-6 mt-2 float-left allowHover">
                            <div class="card border-brown business-broucher-blocks busniess-block-shadow">
                                <div class="card-body">
                                    <span>
                                        <img class="img-fluid mx-auto d-block busniess-img-shadow" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001977/images/abuDhabi-free-zones_1.jpg" alt="abudhabi-image" title="AbuDhabi" />
                                    </span>
                                    <h5 class="text-brown text-break text-center mt-4">
                                        Abu Dhabi Airport Free Zone (ADAFZ)
                                    </h5>
                                    <p id='Abu Dhabi Airport Free Zone (ADAFZ)' class="text-center mt-4 freezoneblock-enquiry">
                                        <ModalDialog />
                                    </p>
                                    <p id='Abu Dhabi Airport Free Zone (ADAFZ)' class="text-center mt-3 freezoneblock-download">
                                        <ModalDownloadDialog />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 mt-2 float-left allowHover">
                            <div class="card border-brown business-broucher-blocks busniess-block-shadow">
                                <div class="card-body">
                                    <span>
                                        <img class="img-fluid mx-auto d-block busniess-img-shadow" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001978/images/abuDhabi-free-zones_2.jpg" alt="abudhabi-image" title="AbuDhabi" />
                                    </span>
                                    <h5 class="text-brown text-break text-center mt-4">
                                        Masdar City Free Zone
                                    </h5>
                                    <p id='Masdar City Free Zone' class="text-center mt-4 freezoneblock-enquiry">
                                        <ModalDialog />
                                    </p>
                                    <p id='Masdar City Free Zone' class="text-center mt-3 freezoneblock-download">
                                        <ModalDownloadDialog />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 mt-2 float-left allowHover">
                            <div class="card border-brown business-broucher-blocks busniess-block-shadow">
                                <div class="card-body">
                                    <span>
                                        <img class="img-fluid mx-auto d-block busniess-img-shadow" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001978/images/abuDhabi-free-zones_3.jpg" alt="abudhabi-image" title="AbuDhabi" />
                                    </span>
                                    <h5 class="text-brown text-break text-center mt-4">
                                        Twofour54 Abu Dhabi (Media Free Zone Authority)
                                    </h5>
                                    <p id='Twofour54 Abu Dhabi (Media Free Zone Authority)' class="text-center mt-4 freezoneblock-enquiry">
                                        <ModalDialog />
                                    </p>
                                    <p id='Twofour54 Abu Dhabi (Media Free Zone Authority)' class="text-center mt-3 freezoneblock-download">
                                        <ModalDownloadDialog />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 mt-2 float-left allowHover">
                            <div class="card border-brown business-broucher-blocks busniess-block-shadow">
                                <div class="card-body">
                                    <span>
                                        <img class="img-fluid mx-auto d-block busniess-img-shadow" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001980/images/abuDhabi-free-zones_4.jpg" alt="abudhabi-image" title="AbuDhabi" />
                                    </span>
                                    <h5 class="text-brown text-break text-center mt-4">
                                        Khalifa Industrial Zone Abu Dhabi (KIZAD)
                                    </h5>
                                    <p id='Khalifa Industrial Zone Abu Dhabi (KIZAD)' class="text-center mt-4 freezoneblock-enquiry">
                                        <ModalDialog />
                                    </p>
                                    <p id='Khalifa Industrial Zone Abu Dhabi (KIZAD)' class="text-center mt-3 freezoneblock-download">
                                        <ModalDownloadDialog />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 mt-2 float-left allowHover">
                            <div class="card border-brown business-broucher-blocks busniess-block-shadow">
                                <div class="card-body">
                                    <span>
                                        <img class="img-fluid mx-auto d-block busniess-img-shadow" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001981/images/abuDhabi-free-zones_5.jpg" alt="abudhabi-image" title="AbuDhabi" />
                                    </span>
                                    <h5 class="text-brown text-break text-center mt-4">
                                        Industrial City of Abu Dhabi (ICAD)
                                    </h5>
                                    <p id='Industrial City of Abu Dhabi (ICAD)' class="text-center mt-4 freezoneblock-enquiry">
                                        <ModalDialog />
                                    </p>
                                    <p id='Industrial City of Abu Dhabi (ICAD)' class="text-center mt-3 freezoneblock-download">
                                        <ModalDownloadDialog />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 mt-2 float-left allowHover">
                            <div class="card border-brown business-broucher-blocks busniess-block-shadow">
                                <div class="card-body">
                                    <span>
                                        <img class="img-fluid mx-auto d-block busniess-img-shadow" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001981/images/abuDhabi-free-zones_6.jpg" alt="abudhabi-image" title="AbuDhabi" />
                                    </span>
                                    <h5 class="text-brown text-break text-center mt-4">
                                        Abu Dhabi Global Market (ADGM)
                                    </h5>
                                    <p id='Abu Dhabi Global Market (ADGM)' class="text-center mt-4 freezoneblock-enquiry">
                                        <ModalDialog />
                                    </p>
                                    <p id='Abu Dhabi Global Market (ADGM)' class="text-center mt-3 freezoneblock-download">
                                        <ModalDownloadDialog />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 mt-2 float-left allowHover">
                            <div class="card border-brown business-broucher-blocks busniess-block-shadow">
                                <div class="card-body">
                                    <span>
                                        <img class="img-fluid mx-auto d-block busniess-img-shadow" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001982/images/abuDhabi-free-zones_7.jpg" alt="abudhabi-image" title="AbuDhabi" />
                                    </span>
                                    <h5 class="text-brown text-break text-center mt-4">
                                        Higher Corporation for Specialized Economic Zones (ZonesCorp)
                                    </h5>
                                    <p id='Higher Corporation for Specialized Economic Zones (ZonesCorp)' class="text-center mt-4 freezoneblock-enquiry">
                                        <ModalDialog />
                                    </p>
                                    <p id='Higher Corporation for Specialized Economic Zones (ZonesCorp)' class="text-center mt-3 freezoneblock-download">
                                        <ModalDownloadDialog />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 mt-2 float-left allowHover">
                            <div class="card border-brown business-broucher-blocks busniess-block-shadow">
                                <div class="card-body">
                                    <span>
                                        <img class="img-fluid mx-auto d-block busniess-img-shadow" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001984/images/abuDhabi-free-zones_8.jpg" alt="abudhabi-image" title="AbuDhabi" />
                                    </span>
                                    <h5 class="text-brown text-break text-center mt-4">
                                        Khalifa Port and Industrial Zone (KPIZ)
                                    </h5>
                                    <p id='Khalifa Port and Industrial Zone (KPIZ)' class="text-center mt-4 freezoneblock-enquiry">
                                        <ModalDialog />
                                    </p>
                                    <p id='Khalifa Port and Industrial Zone (KPIZ)' class="text-center mt-3 freezoneblock-download">
                                        <ModalDownloadDialog />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AbuDhabiFreeZones;