import React from 'react';
import { Helmet } from "react-helmet";

// ================================|| ProductRegistrationWithFTA ||================================ //

const ProductRegistrationWithFTA = () => {

    return (
        <>
            <Helmet>
                <title>Product Registration Services in Dubai and the UAE</title>
            </Helmet>
            <div class="chairman-message">
                <div class="chairman-img">
                    <div class="pos-message-box">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-7 aos-init" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2 className='alignment'>Product Registration With FTA</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img class="d-lg-block d-none" style={{ width: "-webkit-fill-available" }} src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001997/images/Contact-us.png" alt="product-registration-banner" title="Product Registration Banner" />
            </div>
            <div className="container">
                <div className="row pt-5 pb-5">
                    <h1><span style={{ color: "#aa8038" }}>Product Registration Services in UAE</span></h1>
                    <div className='col-12'>
                        <p>
                            Any people who have requirements to import an excisable thing in UAE need to enroll their thing with FTA before getting to UAE.
                            The necessities consolidate things nuances, trimmings, advancing information including pictures and accounts, lab tests on occasion,
                            and the retail cost of the thing established on the UAE retailers or in the significant country if it isn't sold in the UAE.
                        </p>
                        <p>
                            We have a gathering of experts who assists associations in enrolling thing with FTA, so our client’s items can be imported effortlessly.
                        </p>
                    </div>
                    <div className='col-md-7'>
                        <h5 style={{ color: "#aa8038" }}>Requirement For Product Registration:-</h5>
                        <ul>
                            <li style={{ listStyle: "unset" }}>
                                Product Images
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Product Nutrition Details
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Product Ingredient Details
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Product Marketing Images & Videos
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Lab Reports, If Required By FTA
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Five Sample Invoice Along With Declaration For Existing Products
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                FLRA Registration From Municipality & Declaration For New Products
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Product Retail Price & Shelf Price Information
                            </li>
                        </ul>
                    </div>
                    <div className='col-md-5'>
                        <img class="card-img-top" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002055/images/product-registration-with-fta.jpg" alt="product-registration" title="Product Registration" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProductRegistrationWithFTA;