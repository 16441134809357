import React from 'react';
import Faq from "react-faq-component";
import { Helmet } from "react-helmet";

// ================================|| AjmanMainland ||================================ //

const AjmanMainland = () => {

    const data = {
        title: "",
        rows: [
            {
                title: "+ Select Business Activity",
                content: `Select the business activity types from Trading/commercial, Consultancy, Professional or industrial. You can choose several activities within a one group.`,
            },
            {
                title: "+ Select Trade Name",
                content: `It is advisable to suggest three tradename options in order to preferences and name Should be clear. Trade Names that cannot be reserved: Name of country, Name of Continent, Famous regions and Towns, Color (blue, red etc.). Foreign words and short words will be charged extra fee of Dh2000 per year. At this stage it is required to submit all the documents of all partners (passport Visa / EID / NOC of UAE / Resident / Residential address).`,
            },
            {
                title: "+ Apply for Initial Approval in DED",
                content: `Once the trade name confirms then the company can be formed and the company name is approved.`,
            },
            {
                title: "+ Prepare MOA & LSA agreement",
                content: `DED will prepare the Memorandum of Association and Local Service agreement based on the information provided on the above stages.`,
            },
            {
                title: "+ Business Location",
                content: `To complete the registration, process a Ejari (Lease contract) is required in main land. This refers to either business center/warehouse/office/showroom. It may be rented or a purchase.`,
            },
            {
                title: "+ Get Approvals",
                content: `Some business activities require an additional / external approval from the other Government authorities such as a Civil Defense, Police, SIRA, MOH etc.`,
            },
            {
                title: "+ Collect Business License",
                content: `After the LSA and MOA are signed, all the payments and approval are made. License will be issued.`,
            },
        ],
    };

    const styles = {
        titleTextColor: "black",
    };

    const config = {
        rowTitleColor: "#aa8038",
    };

    return (
        <>
            <Helmet>
                <title>Business Setup in Ajman Mainland | Company Formation in Ajman Mainland</title>
            </Helmet>
            <div class="chairman-message">
                <div class="chairman-img">
                    <div class="pos-message-box">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-7 aos-init" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2 className='alignment'>Ajman Mainland</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img class="d-lg-block d-none" style={{ width: "-webkit-fill-available" }} src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001997/images/Contact-us.png" alt="ajman-banner" title="Ajman Banner" />
            </div>
            <div className="container">
                <div className="row pt-5 pb-5">
                    <h1><span style={{ color: "#aa8038" }}>Business Setup in Ajman Mainland</span></h1>
                    <div className='col-12'>
                        <p>
                            Ajman land is one in every of the foremost developed economies because it is ready up directly by following the legal structures. Business setup in Ajman land doesn't need a native UAE Sponsor. The UAE government has recently adopted an associate change to the business corporations Law permitting one hundred pc foreign possession of corporations.
                        </p>
                        <p>
                            Ajman free port, a distinguished producing hub in UAE, was established in 1988. The free port is strategically situated which provides easy convenience to airports and seaports. Ajman offshore and land offers a good variety of opportunities to investors from various fields.
                        </p>
                        <p>
                            The major industries in Ajman are manufacturing; construction; wholesale and retail; land and business services and transport, storage, and communications. Ajman port and free port contribute mostly to the economy of the emirate.
                        </p>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h2 class="text-center" style={{ color: "#aa8038" }}>
                            Business Entities In Ajman Mainland
                        </h2>
                    </div>
                </div>
                <div class="row pt-3 pb-3">

                    <div class="col-md-1">
                    </div>
                    <div class="col-md-2 text-white float-left business-blocks ml-1 mt-2" style={{ background: "#aa8038", marginLeft: "0.25rem" }}>
                        <p class="text-center font-weight-5 font-size-15">Branch office</p>
                    </div>
                    <div class="col-md-2 text-dark float-left business-blocks ml-1 mt-2" style={{ background: "#EDEDED", marginLeft: "0.25rem" }}>
                        <p class="text-center font-weight-5 font-size-15">Representative Office</p>
                    </div>
                    <div class="col-md-2 text-white float-left business-blocks ml-1 mt-2" style={{ background: "#aa8038", marginLeft: "0.25rem" }}>
                        <p class="text-center font-weight-5 font-size-15">Limited Liability Company</p>
                    </div>
                    <div class="col-md-2 text-dark float-left business-blocks ml-1 mt-2" style={{ background: "#EDEDED", marginLeft: "0.25rem" }}>
                        <p class="text-center font-weight-5 font-size-15">Professional License</p>
                    </div>
                    <div class="col-md-2 text-white float-left business-blocks ml-1 mt-2" style={{ background: "#aa8038", marginLeft: "0.25rem" }}>
                        <p class="text-center font-weight-5 font-size-15">Public Shareholding company</p>
                    </div>
                    <div class="col-md-1">
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row pt-3 pb-3">
                    <div class="col-md-12">
                        <h2 class="text-center" style={{ color: "#aa8038" }}>
                            Licenses For Business Setup In Ajman Mainland Offering
                        </h2>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="business-license-blocks col-md-3" style={{ background: "#aa8038" }}>
                        <h3 class="text-white mt-5">
                            Trading license
                        </h3>
                        <p class="text-justify text-white font-size-15">
                            The trading license in Ajman is easiest to obtain compared to other business licenses.
                            It is for companies dealing with general trading or specialized trading activity.
                        </p>
                    </div>
                    <div class="business-license-blocks col-md-3" style={{ backgroundColor: "#EDEDED" }}>
                        <h3 class="text-blue mt-5">
                            Professional License
                        </h3>
                        <p class="text-justify text-dark-grey font-size-15">
                            For Service oriented companies life artisans and professionals needs to apply for this license.
                        </p>
                    </div>
                    <div class="business-license-blocks col-md-3" style={{ background: "#aa8038" }}>
                        <h3 class="text-white mt-5">
                            Industrial License
                        </h3>
                        <p class="text-justify text-white font-size-15">
                            Companies engaged in manufacturing, producing, and fabricating needs to apply for this license.
                        </p>
                    </div>
                    <div class="business-license-blocks col-md-3" style={{ backgroundColor: "#EDEDED" }}>
                        <h3 class="text-blue mt-5">
                            E-commerce License
                        </h3>
                        <p class="text-justify text-dark-grey font-size-15">
                            E-commerce license is for conducting business related to electronics trading or an online business. It also provides marketing &amp; technical support to investor.
                        </p>
                    </div>
                </div>
            </div>
            <div class="container pt-5 pb-5">
                <h3 class="pb-4" style={{ color: "#aa8038" }}>How To Register Dubai Mainland Company In UAE?</h3>
                <Faq
                    data={data}
                    styles={styles}
                    config={config}
                />
            </div>
        </>
    );
};

export default AjmanMainland;