import React from 'react';
import { Helmet } from "react-helmet";

// ================================|| RasAlKhaimahMainland ||================================ //

const RasAlKhaimahMainland = () => {

    return (
        <>
            <Helmet>
                <title>Business Setup in RAK Mainland | RAK Mainland Company Formation</title>
            </Helmet>
            <div class="chairman-message">
                <div class="chairman-img">
                    <div class="pos-message-box">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-7 aos-init" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2 className='alignment'>Ras Al Khaimah Mainland</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img class="d-lg-block d-none" style={{ width: "-webkit-fill-available" }} src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001997/images/Contact-us.png" alt="ras-al-banner" title="Ras Al Banner" />
            </div>
            <div className="container">
                <div className="row pt-5 pb-5">
                    <h1><span style={{ color: "#aa8038" }}>Business Setup in RAK Mainland</span></h1>
                    <div className='col-12'>
                        <p>
                            In the UAE, the Ras Al Khaimah central area is the favored objective for some financial backers for organization arrangement.
                        </p>
                        <p>
                            The purpose of the prominence of Ras Al Khaimah central area is the opportunity to carry on with work anyplace in UAE. There is a finished method for central area organization development.
                        </p>
                        <p>
                            For this reason, intrigued people need to achieve a few prerequisites for central area organization arrangement in UAE.
                        </p>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row">
                    <h2 style={{ color: "#aa8038" }}>Benefits Of Mainland Company Registration:</h2>
                    <div class="col-md-6">
                        <p>
                            <ul>
                                <li style={{ listStyle: "unset" }}>
                                    Mainland company enjoys 100% corporate tax exemption
                                </li>
                                <li style={{ listStyle: "unset" }}>
                                    You can operate your company from any part of UAE
                                </li>
                                <li style={{ listStyle: "unset" }}>
                                    No capital required for a local mainland license
                                </li>
                                <li style={{ listStyle: "unset" }}>
                                    Able to carry out business in all emirates mainland
                                </li>
                                <li style={{ listStyle: "unset" }}>
                                    No currency restrictions
                                </li>
                            </ul>
                        </p>
                    </div>
                    <div class="col-md-6">
                        <p>
                            <ul>
                                <li style={{ listStyle: "unset" }}>
                                    Easy processing of employment visas
                                </li>
                                <li style={{ listStyle: "unset" }}>
                                    Mainland company registration will provide 100% repatriation of capital and profits
                                </li>
                                <li style={{ listStyle: "unset" }}>
                                    Hassle free legal procedures to get the license and registration
                                </li>
                                <li style={{ listStyle: "unset" }}>
                                    Simple incorporation process
                                </li>
                                <li style={{ listStyle: "unset" }}>
                                    No audit requirement
                                </li>
                            </ul>
                        </p>
                    </div>
                </div>
            </div>
            <div className="three-coloum-content pt-4" style={{ backgroundColor: "#EDEDED" }}>
                <div className="container">
                    <div className="row pt-5 pb-5">
                        <div className='col-md-12'>
                            <h3 style={{ color: "#aa8038" }}>Types Of Business Set Up RAK</h3>
                            <p>
                                The enlistment and issuance of licenses to work to non-tenant relationships in Ras Al Khaimah are administered by the Free Zone Authorities and other embracing techniques are overseen by the single Emirates and public government.
                                There are 3 relationships of business system in RAK:
                            </p>
                            <p>
                                <b>RAK Free Zone : </b>RAK free zones are inclined toward battles for connection strategy considering their cost probability, charge absolved status, and liberal government moves close. The public power body answerable for picking and giving honors to work to non-inhabitant or new affiliations is the RAK Free Zone Authority.
                                Understanding that monetary partner necessities for work areas, stockrooms, and land working conditions are contrasting, stand-separated Free Zone Parks have been approached by the Ras Al Khaimah Free Trade Zone Authority. These free zones are technique at different locales across the Emirate of Ras Al Khaimah and combine Technology Park,
                                Business Park, Industrial Park, and Al Ghail Park. Each RAK free zone deals with specific cash-related matters and is ready to meet the monetary help's requirements. Major free zones for business game-plan in RAK are Ras Al Khaimah Free Trade Zone (RAK FTZ), RAK Investment Authority (RAKIA), and RAK Media Free Zone (RMFZ).
                            </p>
                            <p>
                                <b>RAK Offshore : </b>RAK Offshore has adaptable norms and it is an ideal region for an expansive grouping of business undertakings. If you are looking at a reasonable strategy for overseeing setting up an ocean relationship at an unimportant cost then RAK Offshore in Ras Al Khaimah is an ideal spot for you as it gives many benefits,
                                such as 0% corporate and individual cost, 100% new having a spot, 100% bringing back of capital and advantages, no key for the base proposal capital, and business-obliging standards kept by depiction.
                            </p>
                            <p>
                                <b>Focal district Company : </b>Mainland affiliations are overseen by the UAE's valid course of action and government outlines and are coordinated inside the need of cutting-edge geological locales falling under the space of the Emirates government.
                                To spread out a focal region connection, monetary help can pick one among Limited Liability Company (LLC), Representative Office, Public Shareholding Company, Branch of Foreign Company, and Professional Firm.
                            </p>
                        </div>
                    </div>
                </div>
            </div >
            <div class="container">
                <div class="row pt-5 pb-5">
                    <div class="col-md-12">
                        <h2 style={{ color: "#aa8038" }}>How Does The Process Work?</h2>
                        <p>To set up a business in RAK, the following steps need to be carried out:</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 float-left">
                        <ul class="list-unstyled list-icon mb-5">
                            <li class="text-justify mb-3">
                                <div class="d-flex justify-content-start p-3" style={{ background: "#EDEDED", borderRadius: "22px" }}>
                                    <div class="ml-2">
                                        <span class="font-size-15 font-weight-4 text-break">Find a Local Sponsor</span>
                                    </div>
                                </div>
                            </li>
                            <li class="text-justify mb-3">
                                <div class="d-flex justify-content-start p-3" style={{ background: "#EDEDED", borderRadius: "22px" }}>
                                    <div class="ml-2">
                                        <span class="font-size-15 font-weight-4 text-break">
                                            Determine the type of economic activity and legal form your business will take
                                        </span>
                                    </div>
                                </div>
                            </li>
                            <li class="text-justify mb-3">
                                <div class="d-flex justify-content-start p-3" style={{ background: "#EDEDED", borderRadius: "22px" }}>
                                    <div class="ml-2">
                                        <span class="font-size-15 font-weight-4 text-break">
                                            Submit application for company registration
                                        </span>
                                    </div>
                                </div>
                            </li>
                            <li class="text-justify mb-3">
                                <div class="d-flex justify-content-start p-3" style={{ background: "#EDEDED", borderRadius: "22px" }}>
                                    <div class="ml-2">
                                        <span class="font-size-15 font-weight-4 text-break">
                                            Submit proposed company name to the Department of Economic Development (DED)
                                        </span>
                                    </div>
                                </div>
                            </li>
                            <li class="text-justify mb-3">
                                <div class="d-flex justify-content-start p-3" style={{ background: "#EDEDED", borderRadius: "22px" }}>
                                    <div class="ml-2">
                                        <span class="font-size-15 font-weight-4 text-break">
                                            Submit the company’s documents to DED and Chamber of Commerce
                                        </span>
                                    </div>
                                </div>
                            </li>
                            <li class="text-justify mb-3">
                                <div class="d-flex justify-content-start p-3" style={{ background: "#EDEDED", borderRadius: "22px" }}>
                                    <div class="ml-2">
                                        <span class="font-size-15 font-weight-4 text-break">
                                            Industry to obtain a trade licence
                                        </span>
                                    </div>
                                </div>
                            </li>
                            <li class="text-justify mb-3">
                                <div class="d-flex justify-content-start p-3" style={{ background: "#EDEDED", borderRadius: "22px" }}>
                                    <div class="ml-2">
                                        <span class="font-size-15 font-weight-4 text-break">
                                            Get the company’s Memorandum of Association (MOA) notarized
                                        </span>
                                    </div>
                                </div>
                            </li>
                            <li class="text-justify mb-3">
                                <div class="d-flex justify-content-start p-3" style={{ background: "#EDEDED", borderRadius: "22px" }}>
                                    <div class="ml-2">
                                        <span class="font-size-15 font-weight-4 text-break">
                                            Register trade name
                                        </span>
                                    </div>
                                </div>
                            </li>
                            <li class="text-justify mb-3">
                                <div class="d-flex justify-content-start p-3" style={{ background: "#EDEDED", borderRadius: "22px" }}>
                                    <div class="ml-2">
                                        <span class="font-size-15 font-weight-4 text-break">
                                            Obtain initial approval
                                        </span>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="col-md-6 float-left">
                        <ul class="list-unstyled list-icon mb-5">
                            <li class="text-justify mb-3">
                                <div class="d-flex justify-content-start p-3" style={{ background: "#EDEDED", borderRadius: "22px" }}>
                                    <div class="ml-2">
                                        <span class="font-size-15 font-weight-4 text-break">Get special approvals</span>
                                    </div>
                                </div>
                            </li>
                            <li class="text-justify mb-3">
                                <div class="d-flex justify-content-start p-3" style={{ background: "#EDEDED", borderRadius: "22px" }}>
                                    <div class="ml-2">
                                        <span class="font-size-15 font-weight-4 text-break">
                                            Sign the lease agreement along with MOA in public notary
                                        </span>
                                    </div>
                                </div>
                            </li>
                            <li class="text-justify mb-3">
                                <div class="d-flex justify-content-start p-3" style={{ background: "#EDEDED", borderRadius: "22px" }}>
                                    <div class="ml-2">
                                        <span class="font-size-15 font-weight-4 text-break">
                                            Obtain municipality’s approval for location and inspection
                                        </span>
                                    </div>
                                </div>
                            </li>
                            <li class="text-justify mb-3">
                                <div class="d-flex justify-content-start p-3" style={{ background: "#EDEDED", borderRadius: "22px" }}>
                                    <div class="ml-2">
                                        <span class="font-size-15 font-weight-4 text-break">
                                            Apply establishment card
                                        </span>
                                    </div>
                                </div>
                            </li>
                            <li class="text-justify mb-3">
                                <div class="d-flex justify-content-start p-3" style={{ background: "#EDEDED", borderRadius: "22px" }}>
                                    <div class="ml-2">
                                        <span class="font-size-15 font-weight-4 text-break">
                                            Submit the company’s documents to DED and Chamber of Commerce
                                        </span>
                                    </div>
                                </div>
                            </li>
                            <li class="text-justify mb-3">
                                <div class="d-flex justify-content-start p-3" style={{ background: "#EDEDED", borderRadius: "22px" }}>
                                    <div class="ml-2">
                                        <span class="font-size-15 font-weight-4 text-break">
                                            Fulfil the licensing requirements
                                        </span>
                                    </div>
                                </div>
                            </li>
                            <li class="text-justify mb-3">
                                <div class="d-flex justify-content-start p-3" style={{ background: "#EDEDED", borderRadius: "22px" }}>
                                    <div class="ml-2">
                                        <span class="font-size-15 font-weight-4 text-break">
                                            Get trade license issued by paying the license fees
                                        </span>
                                    </div>
                                </div>
                            </li>
                            <li class="text-justify mb-3">
                                <div class="d-flex justify-content-start p-3" style={{ background: "#EDEDED", borderRadius: "22px" }}>
                                    <div class="ml-2">
                                        <span class="font-size-15 font-weight-4 text-break">
                                            Get approval from Name Board
                                        </span>
                                    </div>
                                </div>
                            </li>
                            <li class="text-justify mb-3">
                                <div class="d-flex justify-content-start p-3" style={{ background: "#EDEDED", borderRadius: "22px" }}>
                                    <div class="ml-2">
                                        <span class="font-size-15 font-weight-4 text-break">
                                            Get approval for the employees from Ministry of Labour
                                        </span>
                                    </div>
                                </div>
                            </li>
                            <li class="text-justify mb-3">
                                <div class="d-flex justify-content-start p-3" style={{ background: "#EDEDED", borderRadius: "22px" }}>
                                    <div class="ml-2">
                                        <span class="font-size-15 font-weight-4 text-break">
                                            Get stamp on Visa from Immigration Department
                                        </span>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row pt-5 pb-5">
                    <div class="col-md-12">
                        <h2 class="text-center" style={{ color: "#aa8038" }}>
                            Mainland Company Formation In RAK :
                        </h2>
                    </div>
                    <div class="col-md-12">
                        <div class="col-md-6 float-left d-flex">
                            <div class="col-md-12 mt-2" style={{ background: "#EDEDED", padding: "1rem" }}>
                                <h4 style={{ color: "#aa8038" }}>Advantages :</h4>
                                <p>
                                    RAK is conclusively organized, close to the stream of Hormuz, and just minutes from the point of convergence of Dubai. Such a region permits over 1.2 billion customers in the Middle East and Africa.
                                </p>
                                <p>
                                    An association in RAK is an unprecedented way to deal with truly book overall advantages without settling close by organization charge. Also, there are no capital increments or individual yearly costs.
                                </p>
                                <p>
                                    100% new belonging is permitted to a monetary patron if one chooses to set up an association in RAK Free Trade Zone. RAK Free Trade Zone is astoundingly coordinated and has a sweeping bunch of workplaces available.
                                </p>
                                <p>
                                    There is no restriction on the best number of financial backers and corporate financial backers for an association in RAK. In like manner, there is no register of financial backers or bosses for associations in RAK.
                                </p>
                            </div>
                            <div class="col-md-12 mt-2" style={{ background: "#aa8038", padding: "1rem" }}>
                                <h4 class="text-white">Disadvantages :</h4>
                                <p class="text-white">
                                    An offshore association in RAK isn't allowed to trade things or deal with kinds of help inside the UAE, and can't rent area premises or open a corporate record in the UAE.
                                </p>
                                <p class="text-white">
                                    A limited liability association (LLC) expects no less than 51% shareholding by UAE citizen(s).
                                </p>
                                <p class="text-white">
                                    Most substances are supposed to submit yearly inspected records to the public power.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RasAlKhaimahMainland;