import React from 'react';
import ModalDialog from './AjmanDialogEnquiry';
import ModalDownloadDialog from './AjmanDialogDownload';
import { Helmet } from "react-helmet";

// ================================|| AjmanFreeZones ||================================ //

const AjmanFreeZones = () => {

    return (
        <>
            <Helmet>
                <title>Business Setup in Ajman | Ajman Free Zone Company Setup Cost</title>
            </Helmet>
            <div class="chairman-message">
                <div class="chairman-img">
                    <div class="pos-message-freezone-box">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-7 aos-init" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2>Ajman Free Zone</h2>
                                    <p>
                                        The Emirate of Ajman, situated on the western bank of Joined Bedouin Emirates was framed by the Al Nuaim clan, notable for their dauntlessness, boldness and insight who relocated to this area from Oman around 1775.
                                        The economy of Ajman in the past was primarily founded on serious areas of strength, for its practice - fishing and exchange. However, the savvy Rulers generally energized different other partnered exercises, for example, Shipbuilding, Pearl Plunging and Exchanging.
                                        Its dhow building yard is the biggest in the Emirates and keeps on working notwithstanding being predominated by current improvements like the significant dockyard. Devotion helps their clients to Setup their Business and Company, with all kind of Licenses in Ajman Free Zone.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img class="d-lg-block chairman-desktop-ajman-img d-none" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001991/images/Chairmans-Message_desktop.jpg" alt="ajman-banner" title="Ajman Banner" />
                <img class="d-lg-none d-block" style={{ width: "-webkit-fill-available" }} src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001991/images/Chairmans-Message_mobile.jpg" alt="ajman-banner" title="Ajman Banner" />
            </div>
            <div class="pt-5 pb-5">
                <div class="container">
                    <div class="row">
                        <h1><span style={{ color: "#aa8038" }}>Business Setup in Ajman Freezone</span></h1>
                        <div class="col-lg-3 col-md-4 col-sm-6 mt-2 float-left allowHover">
                            <div class="card border-brown business-broucher-blocks busniess-block-shadow">
                                <div class="card-body">
                                    <span>
                                        <img class="img-fluid mx-auto d-block busniess-img-shadow" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001985/images/ajman-free-zones_1.jpg" alt="ajman" title="Ajman" />
                                    </span>
                                    <h5 class="text-brown text-break text-center mt-4">
                                        Ajman Free Zone (AFZA)
                                    </h5>
                                    <p class="description mt-4 font-size-17 font-weight-7 text-break text-center freezoneblock-aed">
                                        <b>AED 13535 *</b>
                                    </p>
                                    <p class="description font-size-17 font-weight-7 text-break text-center freezoneblock-usd">
                                        <b>USD 3700 *</b>
                                    </p>
                                    <p id='Ajman Free Zone (AFZA)' class="text-center mt-4 freezoneblock-enquiry">
                                        <ModalDialog />
                                    </p>
                                    <p id='Ajman Free Zone (AFZA)' class="text-center mt-3 freezoneblock-download">
                                        <ModalDownloadDialog />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 mt-2 float-left allowHover">
                            <div class="card border-brown business-broucher-blocks busniess-block-shadow">
                                <div class="card-body">
                                    <span>
                                        <img class="img-fluid mx-auto d-block busniess-img-shadow" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001987/images/ajman-free-zones_2.jpg" alt="ajman" title="Ajman" />
                                    </span>
                                    <h5 class="text-brown text-break text-center mt-4">
                                        Ajman Media City Free Zone (AMCF)
                                    </h5>
                                    <p class="description mt-4 font-size-17 font-weight-7 text-break text-center freezoneblock-aed">
                                        <b>AED 8500 *</b>
                                    </p>
                                    <p class="description font-size-17 font-weight-7 text-break text-center freezoneblock-usd">
                                        <b>USD 2300 *</b>
                                    </p>
                                    <p id='Ajman Media City Free Zone (AMCF)' class="text-center mt-4 freezoneblock-enquiry">
                                        <ModalDialog />
                                    </p>
                                    <p id='Ajman Media City Free Zone (AMCF)' class="text-center mt-3 freezoneblock-download">
                                        <ModalDownloadDialog />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AjmanFreeZones;