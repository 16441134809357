import React, { useState, useEffect } from 'react';
import API from '../../../middleware/API';
import ReactPaginate from 'react-paginate';
import Card from 'react-bootstrap/Card';
import moment from 'moment';
import 'react-multi-carousel/lib/styles.css';
import '../../scss/Spinner.scss';
import { useCookies } from "react-cookie";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from 'react-router-dom';

// ================================|| Blogs ||================================ //

const Blogs = () => {
	const navigate = useNavigate();
	const [cookies, setCookie] = useCookies(['username']);
	const [BlogData, setBlogData] = useState();
	const [TotalBlog, setTotalBlog] = useState();
	const [Categorydata, setCategoryData] = useState();
	const [CategoryId, SetCategoryId] = useState("");
	const [loading, setLoading] = useState(false);

	const pageNumber = "1";
	const rowsperPage = "6";
	var totalPageCount = 0;

	var GetAllBlogsAPI = API.getAllBlogs;
	var GetBlogsByCategoryIdAPI = API.getBlogsByCategoryId;

	useEffect(() => {
		getAllBlogList();
		getCategories();
	}, []);

	const getAllBlogList = async () => {
		try {
			setLoading(true);
			GetAllBlogsAPI = GetAllBlogsAPI + "?pageNumber=" + pageNumber + "&rowsperPage=" + rowsperPage;
			if (GetAllBlogsAPI == 'http://185.196.21.59:459/api/GetBlogs?pageNumber=1&rowsperPage=6') {
				document.getElementById("fail").style.display = "grid";
			}
			else {
				const response = await fetch(GetAllBlogsAPI);
				const jsonData = await response.json();
				setBlogData(jsonData.recordsets[0]);
				setTotalBlog(jsonData.recordsets[1][0].TotalRows);
				document.getElementById("success").style.display = "grid";
			}
			setLoading(false);
		}
		catch (error) {
			console.log(error);
			setLoading(false);
		}
	};

	const getCategories = async () => {
		try {
			var GetCategoriesAPI = API.getActiveCategories;
			const response = await fetch(GetCategoriesAPI);
			const jsonData = await response.json();
			setCategoryData(jsonData.recordset);
		}
		catch (error) {
			console.log(error);
		}
	};

	totalPageCount = Math.floor(TotalBlog / rowsperPage) + 1;

	const handlePageClick = async (event) => {
		var currentPageNo = event.selected + 1;
		try {
			GetAllBlogsAPI = GetAllBlogsAPI + "?pageNumber=" + currentPageNo + "&rowsperPage=" + rowsperPage;
			const response = await fetch(GetAllBlogsAPI);
			const jsonData = await response.json();
			setBlogData(jsonData.recordsets[0]);
			setTotalBlog(jsonData.recordsets[1][0].TotalRows);
		}
		catch (error) {
			console.log(error);
		}
		totalPageCount = Math.floor(TotalBlog / rowsperPage) + 1;
	};

	const handleChange = async (event) => {
		var categoryId = event.target.value;
		if (categoryId === "0")
			getAllBlogList();
		else {
			var currentPageNo = 1;
			if (event.selected)
				currentPageNo = event.selected + 1;
			else
				currentPageNo = 1;
			try {
				GetBlogsByCategoryIdAPI = GetBlogsByCategoryIdAPI + "?categoryId=" + categoryId + "&pageNumber=" + currentPageNo + "&rowsperPage=" + rowsperPage;
				const response = await fetch(GetBlogsByCategoryIdAPI);
				const jsonData = await response.json();
				setBlogData(jsonData.recordsets[0]);
				setTotalBlog(jsonData.recordsets[1][0].TotalRows);
			}
			catch (error) {
				console.log(error);
			}
			totalPageCount = Math.floor(TotalBlog / rowsperPage) + 1;
		}
	};

	function viewBlog(SeoTitle, BlogSlug, Meta_Description) {
		setCookie('Title', SeoTitle);
		setCookie('Slug', BlogSlug);
		setCookie('Description', Meta_Description);
		navigate('/blog/' + BlogSlug);
	}

	return (
		<>
			<Helmet>
				<title>Blogs - Devotion Business</title>
            </Helmet>
			<div class="three-coloum-content mt-5 mb-5">
				<div class="container">
					<div class="row">
						{loading ?
							<>
								<div className="spinner">
									<div className="lds-roller">
										<div></div>
										<div></div>
										<div></div>
										<div></div>
										<div></div>
										<div></div>
										<div></div>
										<div></div>
									</div>

									<div>Loading...</div>
								</div>
							</> :
							<>
								<div className="container py-5 p-0 mx-auto">
									<div className="blog">
										{BlogData &&
											BlogData.map(({ Id, BlogTitle, SeoTitle, BlogSlug, Meta_Description, BlogImage, CreatedOn }) => (
												<Card className="m-2">
													<a onClick={() => viewBlog(SeoTitle, BlogSlug, Meta_Description)} className="text-decoration-none text-dark" style={{ cursor: "pointer" }} >
														<Card.Body className="align-middle d-flex" style={{ height: "auto" }}><img src={BlogImage} className="img-fluid" alt="blog-image" title="Blog Image" /></Card.Body>
														<Card.Body className="pt-0 pb-0 text-gray " style={{ height: "auto", textAlign: "end" }}><p>{moment(CreatedOn).format('DD-MM-YYYY')}</p></Card.Body>
														<Card.Body className="pb-3 text-gray" style={{ height: "auto" }}><a className="h4">{BlogTitle}</a></Card.Body>
													</a>
												</Card>
											))}
									</div>
									<div class="row">
										<div class="col-lg-12 d-flex justify-content-center">
											<div class="paginationDiv">
												<ReactPaginate
													nextLabel=">"
													onPageChange={handlePageClick}
													pageRangeDisplayed={3}
													marginPagesDisplayed={1}
													pageCount={totalPageCount}
													previousLabel="<"
													pageClassName="page-item"
													pageLinkClassName="page-link"
													previousClassName="page-item"
													previousLinkClassName="page-link"
													nextClassName="page-item"
													nextLinkClassName="page-link"
													breakLabel="..."
													breakClassName="page-item"
													breakLinkClassName="page-link"
													containerClassName="pagination"
													activeClassName="active"
													renderOnZeroPageCount={null}
												/>
											</div>
										</div>
									</div>
								</div>
							</>}
					</div>
				</div>
			</div >
		</>
	);
};

export default Blogs;