import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Cookies, useCookies } from "react-cookie";
import $ from 'jquery';
import API from '../../../middleware/API';

// ================================|| RolePermission ||================================ //

function RolePermission() {
    const [Roledata, setRoleData] = useState();
    const [Moduledata, setModuledate] = useState();
    const [RoleId, SetRoleId] = useState("");
    const [RolePermissiondata, setRolePermissionData] = useState();
    const [ErrorMessage, setErrorMessage] = useState("");
    const [SuccessMessage, setSuccessMessage] = useState("");
    const [cookie, SetCookie] = useCookies(['username']);
    const cookies = new Cookies();
    const user = cookies.get('id');

    useEffect(() => {
        getRoles();
        getModules();
    }, []);

    const getRoles = async () => {
        try {
            var GetRolesAPI = API.getActiveRoles;
            const response = await fetch(GetRolesAPI);
            const jsonData = await response.json();
            setRoleData(jsonData.recordset);
        }
        catch (error) {
            console.log(error);
        }
    };

    const getModules = async () => {
        try {
            var GetModulesAPI = API.getActiveModules;
            const response = await fetch(GetModulesAPI);
            const jsonData = await response.json();
            setModuledate(jsonData.recordset);
        }
        catch (error) {
            console.log(error);
        }
    };

    const handleChange = async (event) => {
        var roleId = event.target.value;
        SetRoleId(roleId);
        setSuccessMessage("");
        setErrorMessage("");
        setRolePermissionData("");
        if (roleId === "0")
            setRolePermissionData("");
        else {
            try {
                var GetRolePermissionByRoleIdAPI = API.getRolePermissionByRoleId + "?roleId=" + roleId;
                const response = await fetch(GetRolePermissionByRoleIdAPI);
                const jsonData = await response.json();
                setRolePermissionData(jsonData.recordsets[0]);
            }
            catch (error) {
                console.log(error);
            }
        }
    };

    let submitHandler = async (e) => {
        e.preventDefault();
        if (RoleId === "0") {
            setErrorMessage("Please select any Role First.");
        }
        else {
            var isAllowed = false;
            var isActive = true;
            var isDeleted = false;
            var isAllow = $('.allow_Insert input:checkbox:checked').map(function () {
                return $(this).val();
            }).get();

            var isNotAllow = $('.allow_Insert input:checkbox:not(:checked)').map(function () {
                return $(this).val();
            }).get();

            for (var i = 0; i <= isAllow.length; i++) {
                if (isAllow[i]) {
                    isAllowed = true;
                    let res = await fetch(API.rolePermission, {
                        method: "POST",
                        headers: {
                            'Accept': 'application/json',
                            'Content-type': 'application/json',
                        },
                        json: true,
                        body: JSON.stringify({
                            roleId: RoleId,
                            moduleId: isAllow[i],
                            isAllowed: isAllowed,
                            createdBy: user,
                            modifiedBy: user,
                            isActive: isActive,
                            isDeleted: isDeleted
                        })
                    });
                }
            }

            for (var j = 0; j <= isNotAllow.length; j++) {
                if (isNotAllow[j]) {
                    isAllowed = false;
                    let res = await fetch(API.rolePermission, {
                        method: "POST",
                        headers: {
                            'Accept': 'application/json',
                            'Content-type': 'application/json',
                        },
                        json: true,
                        body: JSON.stringify({
                            roleId: RoleId,
                            moduleId: isNotAllow[j],
                            isAllowed: isAllowed,
                            createdBy: user,
                            modifiedBy: user,
                            isActive: isActive,
                            isDeleted: isDeleted
                        })
                    });
                }
            }

            setErrorMessage("");
            setSuccessMessage("Role Permission Saved Successfully.");

            setTimeout(() => { document.location.reload(); }, 1000);
        }
    };

    return (
        <>
            <Helmet>
                <title>Role Permission</title>
            </Helmet>
            <div className="container-fluid">
                <div className="container row pt-5">
                    <div className="col">
                        <h3 className="card-header px-3 pb-3">Role Permission Detail</h3>
                    </div>
                </div>

                <div className="container-fluid mx-auto ">
                    <div className="px-3 pb-3">
                        <div className="table-responsive text-nowrap shadow p-3 mb-5 bg-body rounded">
                            <div className="container">
                                <div className="col-md-11 mx-auto">
                                    <form className="pt-3" id='myform' onSubmit={submitHandler}>
                                        <div className="d-flex">
                                            <div className="col-md-3">
                                                <select class="form-select form-select-sm mb-3" id='select' onChange={handleChange}>
                                                    <option key="0" value="0">-- Select Role --</option>
                                                    {Roledata && Roledata.map((Roledata) => (
                                                        <option key={Roledata.Id} value={Roledata.Id}>
                                                            {Roledata.Name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="error-message" style={{fontSize: "1.2em", textAlign: "center"}}>{ErrorMessage ? <p>{ErrorMessage}</p> : null}</div>
                                                <div className="message">{SuccessMessage ? <p>{SuccessMessage}</p> : null}</div>
                                            </div>
                                            <div className="col" style={{ textAlign: "end" }}>
                                                <button type="submit" value="Save" className="btn btn-info cl-button -primary">save</button>
                                            </div>
                                        </div>
                                        <div>{RolePermissiondata ? <div className="border rounded-1 p-2">
                                            {RolePermissiondata &&
                                                RolePermissiondata.map(({ Id, Role_Id, Module_Id, Module, IsAllowed }) => (
                                                    <div className="container d-flex">
                                                        <div className="col-md-4">
                                                            <div className='col table-data pb-2'>{Module}</div>
                                                        </div>
                                                        <div className="col-md-8">
                                                            <span className="text-center allow_Insert">
                                                                <input className="" style={{ margin: "4px 5px 4px", lineHeight: "normal", width: "20px", height: "22px" }} type="checkbox" defaultChecked={IsAllowed} value={Module_Id} name={Module_Id} />
                                                            </span>
                                                        </div>
                                                    </div>
                                                ))}
                                        </div> : <div className="border rounded-1 p-2">
                                            <div className="container d-flex pt-2 pb-2">
                                                <div className="col-md-4">
                                                    <h4 className="card-header pb-4">Modules</h4>
                                                    {Moduledata &&
                                                        Moduledata.map(({ Id, Name }) => (
                                                            <tr key={Id}>
                                                                <div className='col table-data pb-2'>{Name}</div>
                                                            </tr>
                                                        ))}
                                                </div>
                                                <div className="col-md-8">
                                                    <h4 className="card-header pb-4">Permission</h4>
                                                    {Moduledata &&
                                                        Moduledata.map(({ Id }) => (
                                                            <tr key={Id}>
                                                                <span className="text-center allow_Insert">
                                                                    <input className="" style={{ margin: "4px 5px 4px", lineHeight: "normal", width: "20px", height: "22px" }} type="checkbox" />
                                                                </span>
                                                            </tr>
                                                        ))}
                                                </div>
                                            </div>
                                        </div>}</div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RolePermission;