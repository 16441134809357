import React from 'react';
import { Helmet } from "react-helmet";

// ================================|| ExciseDeregistration ||================================ //

const ExciseDeregistration = () => {

    return (
        <>
            <Helmet>
                <title>Excise Deregistration Services in Dubai and the UAE</title>
            </Helmet>
            <div class="chairman-message">
                <div class="chairman-img">
                    <div class="pos-message-box">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-7 aos-init" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2 className='alignment'>Excise Deregistration</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img class="d-lg-block d-none" style={{ width: "-webkit-fill-available" }} src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001997/images/Contact-us.png" alt="excise-deregistration-banner" title="Excise Deregistration Banner" />
            </div>
            <div className="container">
                <div className="row pt-5 pb-5">
                    <div className='col-md-7'>
                        <h1><span style={{ color: "#aa8038" }}>Excise Deregistration Services in UAE</span></h1>
                        <p>
                            Accessible substance who has been selected previously and by and by is finished overseeing separate items can apply for Excise Tax deregistration.
                        </p>
                        <h5><span style={{ color: "#aa8038" }}>Who Need to Apply Excise Deregistration ?</span></h5>
                        <p>
                            Right when the accessible individual stops the accessible activities like import, creation, or collecting of concentrate items in the UAE.
                            The Taxable individual can De-register by completing the De-Register application structure through a web-based entryway.
                        </p>
                        <p>
                            The decision of FTA to embrace the application for deregistration of concentrate depends upon the offered reason as well as the slack of all:
                        </p>
                        <ul>
                            <li style={{ listStyle: "lower-alpha" }}>
                                Momentous obligation
                            </li>
                            <li style={{ listStyle: "lower-alpha" }}>
                                Returns being recorded.
                            </li>
                            <li style={{ listStyle: "lower-alpha" }}>
                                Any Administrative Penalties
                            </li>
                        </ul>
                        <h5><span style={{ color: "#aa8038" }}>Deregistration Penalty</span></h5>
                        <p>
                            The discipline for failing to apply for VAT deregistration with the express course of occasions is AED. 10,000.
                        </p>
                    </div>
                    <div className='col-md-5'>
                        <img class="card-img-top" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002020/images/excise-deregistration.jpg" alt="excise-deregistration-banner" title="Excise Deregistration Banner" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ExciseDeregistration;