import React from 'react';
import { Helmet } from "react-helmet";

// ================================|| ImmigrationProServices ||================================ //

const ImmigrationProServices = () => {

    return (
        <>
            <Helmet>
                <title>PRO & Immigration Services in Dubai and the UAE</title>
            </Helmet>
            <div class="chairman-message">
                <div class="chairman-img">
                    <div class="pos-message-box">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-7 aos-init" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2 className='alignment'>Immigration Pro Services</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img class="d-lg-block d-none" style={{ width: "-webkit-fill-available" }} src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001997/images/Contact-us.png" alt="immigration-banner" title="Immigration Banner" />
            </div>
            <div className="container">
                <div className="row pt-5 pb-5">
                    <h1><span style={{ color: "#aa8038" }}>PRO & Immigration Services in UAE</span></h1>
                    <div className='col-12'>
                        <p>
                            Promoting work is an important and the most principal development of any association in UAE right from the Business Setup, and association improvement frameworks.
                            Our factual reviewing, assessment, and client study showed that a lot of time is committed and irritates expected to keep a record, follow up of reclamations and expiry dates,
                            invigorating the standards of the Labor, Immigration, Chamber of Commerce, and other Government foundations, a lot of man-hours, work supply, cost towards pay, transportation, comfort, tip, insurance, etc of laborers are involved close by setting up associations.
                            Devotion goes probably as a singular window reply for all PR activities of the Company, giving strong and master help.
                        </p>
                    </div>
                    <div className='col-md-7'>
                        <h5 style={{ color: "#aa8038" }}>We Assist Investors In The Following Work :</h5>
                        <ul>
                            <li style={{ listStyle: "unset" }}>
                                Approvals from Ministry & Government Departments.
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Assistance for all Government & Semi Government work.
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Company Registrations.
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Annual License Renewal of company.
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Chamber of Commerce / Foreign Affairs / Ministry of Justice Notary.
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Document Clearance / Document Writing / Document Processing in Labour / Immigration / Economic & other Gov. Department.
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Documents clearing in government and semi government departments such as Department of Economic Development, Ministry of Labour, Department of Naturalization & Residency Dubai, etc.
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Visa assistance for Investor visa, employment visa and family member’s visa.
                            </li>
                        </ul>
                    </div>
                    <div className='col-md-5'>
                        <img class="card-img-top" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002039/images/immigration-pro-services.jpg" alt="immigration" title="Immigration" />
                    </div>
                    <div className='col-md-12'>
                        <h5 style={{ color: "#aa8038" }}>Benefits Of Outsourcing Your PRO Services To Devotion Corporate Services.</h5>
                        <ul>
                            <li style={{ listStyle: "unset" }}>
                                <b>Money Saving: </b>We Guarantee a considerable reduction in cost of your Document Processing and Clearing.
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                <b>Time Saving: </b> Enabling you to devote more time to your core wealth creation activities.
                                Usually it takes 7 working days to register company with DED – Dubai. But sometimes it might take more if same approvals are needed foe same specific business activates.
                            </li><li style={{ listStyle: "unset" }}>
                                <b>Renewal Reminder Service: </b>Our Computerized client database system stores all essential information including all renewal dates for your license, visas, registrations. We act for you on tine and avoid you incurring any penalties.
                            </li><li style={{ listStyle: "unset" }}>
                                <b>Document Pick & Drop: </b>We make it hassle free for you and come to you office to pick and drop your documents.
                            </li><li style={{ listStyle: "unset" }}>
                                <b>Transparency: </b>We provide you with all the receipts and letters issued from Government Departments and ensure full transparency.
                            </li><li style={{ listStyle: "unset" }}>
                                <b>Free Updates: </b>With us your are always up to date with all latest Government initiatives, rules and regulations.
                            </li><li style={{ listStyle: "unset" }}>
                                <b>Transparent Service: </b>With us your are always up to date with all latest Government initiatives, rules and regulations.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ImmigrationProServices;