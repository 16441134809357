import React from 'react';
import ModalDialog from './SharjahDialogEnquiry';
import ModalDownloadDialog from './SharjahDialogDownload';
import { Helmet } from "react-helmet";

// ================================|| SharjahFreeZones ||================================ //

const SharjahFreeZones = () => {

    return (
        <>
            <Helmet>
                <title>Business Setup in Sharjah | Sharjah Free Zone Company Formation</title>
            </Helmet>
            <div class="chairman-message">
                <div class="chairman-img">
                    <div class="pos-message-freezone-box">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-7 aos-init" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2>Sharjah Free Zone</h2>
                                    <p>
                                        Companies established in Sharjah's free zones provide benefits to small and medium-sized businesses as well as inexpensive land and labour.
                                        Sharjah free zones offer 24-hour licensing services and highly built infrastructure to facilitate speedy company establishment.
                                        The region is renowned for its inexpensive rentals and simple company creation options.
                                        Devotion helps their clients to Setup their Business, Company, Industries, Professional and Commercial Services, with all kind of Licenses in Sharjah Free Zone.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img class="d-lg-block chairman-desktop-sharjah-img d-none" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001991/images/Chairmans-Message_desktop.jpg" alt="sharjah-banner" title="Sharjah Banner" />
                <img class="d-lg-none d-block" style={{ width: "-webkit-fill-available" }} src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001991/images/Chairmans-Message_mobile.jpg" alt="sharjah-banner" title="Sharjah Banner" />
            </div>
            <div class="pt-5 pb-5">
                <div class="container">
                    <div class="row">
                        <h1><span style={{ color: "#aa8038" }}>Business Setup in Sharjah Freezone</span></h1>
                        <div class="col-lg-3 col-md-4 col-sm-6 mt-2 float-left allowHover">
                            <div class="card border-brown business-broucher-blocks busniess-block-shadow">
                                <div class="card-body">
                                    <span>
                                        <img class="img-fluid mx-auto d-block busniess-img-shadow" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002067/images/sharjah-free-zones_1.jpg" alt="sharjah" title="Sharjah" />
                                    </span>
                                    <h5 class="text-brown text-break text-center mt-4">
                                        Sharjah International Free Zone (SAIF)
                                    </h5>
                                    <p class="description mt-4 font-size-17 font-weight-7 text-break text-center freezoneblock-aed">
                                        <b>AED 22500 *</b>
                                    </p>
                                    <p class="description font-size-17 font-weight-7 text-break text-center freezoneblock-usd">
                                        <b>USD 6100 *</b>
                                    </p>
                                    <p id='Sharjah International Free Zone (SAIF)' class="text-center mt-4 freezoneblock-enquiry">
                                        <ModalDialog />
                                    </p>
                                    <p id='Sharjah International Free Zone (SAIF)' class="text-center mt-3 freezoneblock-download">
                                        <ModalDownloadDialog />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 mt-2 float-left allowHover">
                            <div class="card border-brown business-broucher-blocks busniess-block-shadow">
                                <div class="card-body">
                                    <span>
                                        <img class="img-fluid mx-auto d-block busniess-img-shadow" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002068/images/sharjah-free-zones_2.jpg" alt="sharjah" title="Sharjah" />
                                    </span>
                                    <h5 class="text-brown text-break text-center mt-4">
                                        Sharjah Media City Free Zone (SHAMS)
                                    </h5>
                                    <p class="description mt-4 font-size-17 font-weight-7 text-break text-center freezoneblock-aed">
                                        <b>AED 8050 *</b>
                                    </p>
                                    <p class="description font-size-17 font-weight-7 text-break text-center freezoneblock-usd">
                                        <b>USD 2200 *</b>
                                    </p>
                                    <p id='Sharjah Media City Free Zone (SHAMS)' class="text-center mt-4 freezoneblock-enquiry">
                                        <ModalDialog />
                                    </p>
                                    <p id='Sharjah Media City Free Zone (SHAMS)' class="text-center mt-3 freezoneblock-download">
                                        <ModalDownloadDialog />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 mt-2 float-left allowHover">
                            <div class="card border-brown business-broucher-blocks busniess-block-shadow">
                                <div class="card-body">
                                    <span>
                                        <img class="img-fluid mx-auto d-block busniess-img-shadow" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002068/images/sharjah-free-zones_3.jpg" alt="sharjah" title="Sharjah" />
                                    </span>
                                    <h5 class="text-brown text-break text-center mt-4">
                                        Hamriyah Free Zone (HAFZA)
                                    </h5>
                                    <p id='Hamriyah Free Zone (HAFZA)' class="text-center mt-4 freezoneblock-enquiry">
                                        <ModalDialog />
                                    </p>
                                    <p id='Hamriyah Free Zone (HAFZA)' class="text-center mt-3 freezoneblock-download">
                                        <ModalDownloadDialog />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SharjahFreeZones;