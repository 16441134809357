import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { scrollToTop } from "../utils/utilityFunctions";
import { FaAngleRight } from "react-icons/fa";
import './navbar.css';

<head>
	<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css" />
</head>

// ================================|| Header ||================================ //

const Header = () => {
	const [offset, setOffset] = useState(0);
	const [showNavbar, setShowNavbar] = useState(false);
	const [showLoginItems, setLoginItems] = useState(false);
	const [showAboutItems, setAboutItems] = useState(false);
	const [showBusinessItems, setBusinessItems] = useState(false);
	const [showMainlandItems, setMainlandItems] = useState(false);
	const [showFreezoneItems, setFreezoneItems] = useState(false);
	const [showOffshoreItems, setOffshoreItems] = useState(false);
	const [showAccountItems, setAccountItems] = useState(false);
	const [showAccountingItems, setAccountingItems] = useState(false);
	const [showVatItems, setVatItems] = useState(false);
	const [showExiseItems, setExiseItems] = useState(false);
	const [showLicencesItems, setLicencesItems] = useState(false);
	const [showServicesItems, setServicesItems] = useState(false);
	const [showResourcesItems, setResourcesItems] = useState(false);
	const [showContactItems, setContactItems] = useState(false);


	useEffect(() => {
		const onScroll = () => setOffset(window.pageYOffset);
		document.getElementById("navbar").classList.remove("sticky-header");
		return () => window.removeEventListener('scroll', onScroll);
	}, []);

	const handleShowNavbar = () => {
		setShowNavbar(!showNavbar)
	}

	const handleShowDropdown = event => {
		if (event.currentTarget.id === "login" || event.currentTarget.id === "log-in") {
			setLoginItems(!showLoginItems)
		}
		if (event.currentTarget.id === "topnavbar") {
			setLoginItems(false)
		}
		if (event.currentTarget.id === "about") {
			setAboutItems(!showAboutItems)
			setBusinessItems(false)
			setMainlandItems(false)
			setFreezoneItems(false)
			setOffshoreItems(false)
			setAccountItems(false)
			setAccountingItems(false)
			setVatItems(false)
			setExiseItems(false)
			setLicencesItems(false)
			setServicesItems(false)
			setResourcesItems(false)
			setContactItems(false)
		}
		else if (event.currentTarget.id === "business") {
			setAboutItems(false)
			setBusinessItems(!showBusinessItems)
			setMainlandItems(false)
			setFreezoneItems(false)
			setOffshoreItems(false)
			setAccountItems(false)
			setAccountingItems(false)
			setVatItems(false)
			setExiseItems(false)
			setLicencesItems(false)
			setServicesItems(false)
			setResourcesItems(false)
			setContactItems(false)
		}
		else if (event.currentTarget.id === "mainland") {
			setAboutItems(false)
			setMainlandItems(!showMainlandItems)
			setFreezoneItems(false)
			setOffshoreItems(false)
			setAccountItems(false)
			setAccountingItems(false)
			setVatItems(false)
			setExiseItems(false)
			setLicencesItems(false)
			setServicesItems(false)
			setResourcesItems(false)
			setContactItems(false)
		}
		else if (event.currentTarget.id === "freezone") {
			setAboutItems(false)
			setMainlandItems(false)
			setFreezoneItems(!showFreezoneItems)
			setOffshoreItems(false)
			setAccountItems(false)
			setAccountingItems(false)
			setVatItems(false)
			setExiseItems(false)
			setLicencesItems(false)
			setServicesItems(false)
			setResourcesItems(false)
			setContactItems(false)
		}
		else if (event.currentTarget.id === "offshore") {
			setAboutItems(false)
			setMainlandItems(false)
			setFreezoneItems(false)
			setOffshoreItems(!showOffshoreItems)
			setAccountItems(false)
			setAccountingItems(false)
			setVatItems(false)
			setExiseItems(false)
			setLicencesItems(false)
			setServicesItems(false)
			setResourcesItems(false)
			setContactItems(false)
		}
		else if (event.currentTarget.id === "account") {
			setAboutItems(false)
			setBusinessItems(false)
			setMainlandItems(false)
			setFreezoneItems(false)
			setOffshoreItems(false)
			setAccountItems(!showAccountItems)
			setAccountingItems(false)
			setVatItems(false)
			setExiseItems(false)
			setLicencesItems(false)
			setServicesItems(false)
			setResourcesItems(false)
			setContactItems(false)
		}
		else if (event.currentTarget.id === "accounting") {
			setAboutItems(false)
			setBusinessItems(false)
			setMainlandItems(false)
			setFreezoneItems(false)
			setOffshoreItems(false)
			setAccountingItems(!showAccountingItems)
			setVatItems(false)
			setExiseItems(false)
			setLicencesItems(false)
			setServicesItems(false)
			setResourcesItems(false)
			setContactItems(false)
		}
		else if (event.currentTarget.id === "vatt") {
			setAboutItems(false)
			setBusinessItems(false)
			setAccountingItems(false)
			setVatItems(false)
			setExiseItems(false)
			setLicencesItems(false)
			setServicesItems(false)
			setResourcesItems(false)
			setContactItems(false)
		}
		else if (event.currentTarget.id === "vat") {
			setAboutItems(false)
			setBusinessItems(false)
			setMainlandItems(false)
			setFreezoneItems(false)
			setOffshoreItems(false)
			setAccountingItems(false)
			setVatItems(!showVatItems)
			setExiseItems(false)
			setLicencesItems(false)
			setServicesItems(false)
			setResourcesItems(false)
			setContactItems(false)
		}
		else if (event.currentTarget.id === "excise") {
			setAboutItems(false)
			setBusinessItems(false)
			setMainlandItems(false)
			setFreezoneItems(false)
			setOffshoreItems(false)
			setAccountingItems(false)
			setVatItems(false)
			setExiseItems(!showExiseItems)
			setLicencesItems(false)
			setServicesItems(false)
			setResourcesItems(false)
			setContactItems(false)
		}
		else if (event.currentTarget.id === "taxx") {
			setAboutItems(false)
			setBusinessItems(false)
			setMainlandItems(false)
			setFreezoneItems(false)
			setOffshoreItems(false)
			setAccountingItems(false)
			setVatItems(false)
			setExiseItems(false)
			setLicencesItems(false)
			setServicesItems(false)
			setResourcesItems(false)
			setContactItems(false)
		}
		else if (event.currentTarget.id === "services") {
			setAboutItems(false)
			setBusinessItems(false)
			setMainlandItems(false)
			setFreezoneItems(false)
			setOffshoreItems(false)
			setAccountItems(false)
			setAccountingItems(false)
			setVatItems(false)
			setExiseItems(false)
			setLicencesItems(false)
			setServicesItems(!showServicesItems)
			setResourcesItems(false)
			setContactItems(false)
		}
		else if (event.currentTarget.id === "resources") {
			setAboutItems(false)
			setBusinessItems(false)
			setMainlandItems(false)
			setFreezoneItems(false)
			setOffshoreItems(false)
			setAccountItems(false)
			setAccountingItems(false)
			setVatItems(false)
			setExiseItems(false)
			setLicencesItems(false)
			setServicesItems(false)
			setResourcesItems(!showResourcesItems)
			setContactItems(false)
		}
		else if (event.currentTarget.id === "contact") {
			setAboutItems(false)
			setBusinessItems(false)
			setMainlandItems(false)
			setFreezoneItems(false)
			setOffshoreItems(false)
			setAccountItems(false)
			setAccountingItems(false)
			setVatItems(false)
			setExiseItems(false)
			setLicencesItems(false)
			setServicesItems(false)
			setResourcesItems(false)
			setContactItems(!showContactItems)
		}
		else {
			setAboutItems(false)
			setBusinessItems(false)
			setMainlandItems(false)
			setFreezoneItems(false)
			setOffshoreItems(false)
			setAccountItems(false)
			setAccountingItems(false)
			setVatItems(false)
			setExiseItems(false)
			setLicencesItems(false)
			setServicesItems(false)
			setResourcesItems(false)
			setContactItems(false)
		}
	}

	window.onscroll = function () { scrollFunction() };
	const scrollFunction = () => {
		if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
			document.getElementById("navbar").style.top = "";
			document.getElementById("navbar").classList.add("sticky-header");
		}
		else {
			document.getElementById("navbar").style.top = "";
			document.getElementById("navbar").classList.remove("sticky-header");
		}
	}

	const handleNavLinkClick = () => {
		setShowNavbar(false)
		scrollToTop()
	}

	return (
		<>
			<div class="w-container">
				<div class="header-top-social header-bg" id="topnavbar-mobile">
					<a href="mailto:sales@devotionbusiness.com" class="email-top">Email Us</a>
					<span class="h-buttons text-decoration-none float-end">
						<a href='tel:+97144488538' class="call-top"> +971 44488538 | </a>
						<a href='tel:+971585956669' class="mobile-top">+971 585956669</a>
					</span>

				</div>
				<div class="header-top-social header-bg p-1" id="topnavbar-desktop">
					<div class="header-top-social space" onMouseLeave={handleShowDropdown}>
						<a href="mailto:sales@devotionbusiness.com" class="email-top">sales@devotionbusiness.com</a> |
						<a href='tel:+97144488538' class="call-top">+971 44488538</a> |
						<a href='tel:+971585956669' class="mobile-top">+971 585956669</a> |
						<a class="nav-item dropdown login-top" id="log-in" onClick={handleShowDropdown} style={{ cursor: "pointer" }}>Login</a>
						<ul className={`${showLoginItems ? '' : 'd-none'}`} style={{ listStyle: "none", position: "absolute", padding: "10px 0 0 0", backgroundColor: "#fbf2de", margin: "160px 0 0 0", right: "10px", zIndex: 1050 }}>
							<li className="nav-item mx-3 my-auto py-1"><Link to="https://portal.devotionbusiness.com/" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black p-0'><FaAngleRight />Member Login</Link></li>
							<li className="nav-item mx-3 my-auto py-1"><Link to="/login" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black p-0'><FaAngleRight />Channel Partner Login</Link></li>
							<li className="nav-item mx-3 my-auto py-1"><Link to="https://portal.devotionbusiness.com/" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black p-0'><FaAngleRight />E-Services</Link></li>
						</ul>
					</div>
				</div>
				<div class="header-top-sec">
					<div class="header-top-inner">
						<div className="menu-icon">
							<img src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002050/images/nav-icon.png" onClick={handleShowNavbar} height={30} width={30} alt="logo" title="Logo" />
						</div>
						<div className="logo" style={{ padding: "inherit" }}>
							<Link to="/" onClick={handleNavLinkClick} ><img src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711002044/images/logo.png" height={55} width={200} alt="logo" title="Logo" /> </Link>
						</div>
						<div class="header-top-social" id="login" onClick={handleShowDropdown} style={{ position: "absolute", right: 0, paddingRight: "10px" }}>
							<a class="nav-item dropdown login-top">Login</a>
							<ul className={`${showLoginItems ? '' : 'd-none'}`} style={{ listStyle: "none", position: "absolute", padding: "10px 0 0 0", backgroundColor: "#fbf2de", margin: "8px 0 0 -98px", zIndex: "5" }}>
								<li className="nav-item mx-3 my-auto py-1"><Link to="https://portal.devotionbusiness.com/" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black p-0'><FaAngleRight />Member Login</Link></li>
								<li className="nav-item mx-3 my-auto py-1"><Link to="/login" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black p-0'><FaAngleRight />Channel Partner Login</Link></li>
								<li className="nav-item mx-3 my-auto py-1"><Link to="https://portal.devotionbusiness.com/" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black p-0'><FaAngleRight />E-Services</Link></li>
							</ul>
						</div>
						<div className={`header nav-elements  ${showNavbar && 'active'}`} id="navbarSupportedContent">
							<span id="" class="float-end fw-bold p-2" onClick={handleShowNavbar} aria-label="menu" role="button" tabindex="0" aria-controls="w-nav-overlay-0" aria-haspopup="menu" aria-expanded="true">✕</span>
							<ul className="mt-3 mt-md-0 nav-links navbar-nav justify-content-center">
								<li className="nav-item mx-3 my-auto py-1" activeStyle>
									<Link to="/" onClick={handleNavLinkClick} className='text-decoration-none text-black mx-xl-2 py-1'>Home</Link>
								</li>
								<li id="about" className="nav-item dropdown my-auto py-1" onClick={handleShowDropdown}>
									<a className="nav-link text-black dropdown-toggle mx-3 py-0">
										<span>About Us</span>
									</a>
									<ul className={`${showAboutItems ? '' : 'd-none'}`}>
										<li className="nav-item mx-3 my-auto py-1"><Link to="/overview" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Overview</Link></li>
										<li className="nav-item mx-3 my-auto py-1"><Link to="/ceo-message" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>CEO Message</Link></li>
										<li className="nav-item mx-3 my-auto py-1"><Link to="/mission" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Mission</Link></li>
									</ul>
								</li>
								<li className="nav-item dropdown my-auto py-1">
									<a id="business" className="nav-link text-black dropdown-toggle mx-3 py-0" onClick={handleShowDropdown}>
										<span>Business Setup</span>
									</a>
									<ul className={`${showBusinessItems ? '' : 'd-none'}`}>
										<li id="mainland" className="nav-item dropdown my-auto py-1" onClick={handleShowDropdown}>
											<a className="nav-link text-black dropdown-toggle dropdown-sub-toggle mx-3 py-0">
												<span>UAE Mainland</span>
											</a>
											<ul className={`${showMainlandItems ? '' : 'd-none'}`}>
												<li className="nav-item mx-3 my-auto py-1"><Link to="/dubai-mainland" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Dubai</Link></li>
												<li className="nav-item mx-3 my-auto py-1"><Link to="/sharjah-mainland" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Sharjah</Link></li>
												<li className="nav-item mx-3 my-auto py-1"><Link to="/abu-dhabi-mainland" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Abu Dhabi</Link></li>
												<li className="nav-item mx-3 my-auto py-1"><Link to="/ajman-mainland" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Ajman</Link></li>
												<li className="nav-item mx-3 my-auto py-1"><Link to="/ras-al-khaimah-mainland" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Ras Al Khaimah</Link></li>
												<li className="nav-item mx-3 my-auto py-1"><Link to="/fujairah-mainland" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Fujairah</Link></li>
												<li className="nav-item mx-3 my-auto py-1"><Link to="/umm-al-quwain-mainland" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Umm Al Quwain</Link></li>
											</ul>
										</li>
										<li id="freezone" className="nav-item dropdown my-auto py-1" onClick={handleShowDropdown}>
											<a className="nav-link text-black dropdown-toggle dropdown-sub-toggle mx-3 py-0">
												<span>UAE Freezone</span>
											</a>
											<ul className={`${showFreezoneItems ? '' : 'd-none'}`}>
												<li className="nav-item mx-3 my-auto py-1"><Link to="/dubai-free-zones" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Dubai</Link></li>
												<li className="nav-item mx-3 my-auto py-1"><Link to="/sharjah-free-zones" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Sharjah</Link></li>
												<li className="nav-item mx-3 my-auto py-1"><Link to="/abu-dhabi-free-zones" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Abu Dhabi</Link></li>
												<li className="nav-item mx-3 my-auto py-1"><Link to="/ajman-free-zones" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Ajman</Link></li>
												<li className="nav-item mx-3 my-auto py-1"><Link to="/ras-al-khaimah-free-zones" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Ras Al Khaimah</Link></li>
												<li className="nav-item mx-3 my-auto py-1"><Link to="/fujairah-free-zones" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Fujairah</Link></li>
												<li className="nav-item mx-3 my-auto py-1"><Link to="/umm-al-quwain-free-zones" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Umm Al Quwain</Link></li>
											</ul>
										</li>
										<li id="offshore" className="nav-item dropdown my-auto py-1" onClick={handleShowDropdown}>
											<a className="nav-link text-black dropdown-toggle dropdown-sub-toggle mx-3 py-0">
												<span>UAE Offshore</span>
											</a>
											<ul className={`${showOffshoreItems ? '' : 'd-none'}`}>
												<li className="nav-item mx-3 my-auto py-1"><Link to="/jabel-ali-offshore" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Jabeli Ali</Link></li>
												<li className="nav-item mx-3 my-auto py-1"><Link to="/ajman-offshore" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Ajman</Link></li>
												<li className="nav-item mx-3 my-auto py-1"><Link to="/ras-al-khaimah-offshore" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Ras Ali Khaimah</Link></li>
											</ul>
										</li>
									</ul>
								</li>
								<li className="nav-item dropdown my-auto py-1">
									<a id="account" className="nav-link text-black dropdown-toggle mx-3 py-0" onClick={handleShowDropdown}>
										<span>Accounting & Taxes</span>
									</a>
									<ul className={`${showAccountItems ? '' : 'd-none'}`}>
										<li id="accounting" className="nav-item dropdown my-auto py-1" onClick={handleShowDropdown}>
											<a className="nav-link text-black dropdown-toggle dropdown-sub-toggle mx-3 py-0">
												<span>Accounting</span>
											</a>
											<ul className={`${showAccountingItems ? '' : 'd-none'}`}>
												<li className="nav-item mx-3 my-auto py-1"><Link to="/accounting-bookkeeping" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Accounting & Bookkeeping</Link></li>
												<li className="nav-item mx-3 my-auto py-1"><Link to="/auditing" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Auditing</Link></li>
											</ul>
										</li>
										{/* <li id="vatt" className="nav-item mx-3 my-auto py-1" onClick={handleShowDropdown}>
											<Link to="/" onClick={handleNavLinkClick} className='text-decoration-none text-black mx-xl-2 py-1'>Vat & Excise</Link>
										</li> */}
										<li id="vat" className="nav-item dropdown my-auto py-1" onClick={handleShowDropdown}>
											<a className="nav-link text-black dropdown-toggle dropdown-sub-toggle mx-3 py-0">
												<span>VAT Consultancy</span>
											</a>
											<ul className={`${showVatItems ? '' : 'd-none'}`}>
												<li className="nav-item mx-3 my-auto py-1"><Link to="/vat-registration" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>VAT Registration</Link></li>
												<li className="nav-item mx-3 my-auto py-1"><Link to="/vat-return-filing" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>VAT Return Filling</Link></li>
												<li className="nav-item mx-3 my-auto py-1"><Link to="/vat-compliance" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>VAT Compliance</Link></li>
												<li className="nav-item mx-3 my-auto py-1"><Link to="/vat-advisory-services" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>VAT Advisory</Link></li>
												<li className="nav-item mx-3 my-auto py-1"><Link to="/vat-deregistration" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>VAT Deregistration</Link></li>
											</ul>
										</li>
										<li id="excise" className="nav-item dropdown my-auto py-1" onClick={handleShowDropdown}>
											<a className="nav-link text-black dropdown-toggle dropdown-sub-toggle mx-3 py-0">
												<span>Excise Consultancy</span>
											</a>
											<ul className={`${showExiseItems ? '' : 'd-none'}`}>
												<li className="nav-item mx-3 my-auto py-1"><Link to="/excise-registration" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Excise Registration</Link></li>
												<li className="nav-item mx-3 my-auto py-1"><Link to="/excise-return-filing" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Excise Return Filling</Link></li>
												<li className="nav-item mx-3 my-auto py-1"><Link to="/excise-compliance" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Excise Compliance</Link></li>
												<li className="nav-item mx-3 my-auto py-1"><Link to="/excise-advisory" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Excise Advisory</Link></li>
												<li className="nav-item mx-3 my-auto py-1"><Link to="/excise-deregistration" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Excise Deregistration</Link></li>
												<li className="nav-item mx-3 my-auto py-1"><Link to="/product-registration-with-fta" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Product Registration with FTA</Link></li>
											</ul>
										</li>
										<li id="taxx" className="nav-item mx-3 my-auto py-1" onClick={handleShowDropdown}>
											<Link to="/tax-residency-certificate" onClick={handleNavLinkClick} className='text-decoration-none text-black mx-xl-2 py-1'>TAX Residency Certificate (TRC)</Link>
										</li>
									</ul>
								</li>
								<li className="nav-item mx-3 my-auto py-1">
									<Link to="/corporate-tax" onClick={handleNavLinkClick} className='text-decoration-none text-black mx-xl-2 py-1'>Corporate Tax</Link>
								</li>
								<li id="services" className="nav-item dropdown my-auto py-1" onClick={handleShowDropdown}>
									<a className="nav-link text-black dropdown-toggle mx-3 py-0">
										<span>Services</span>
									</a>
									<ul className={`${showServicesItems ? '' : 'd-none'}`}>
										<li className="nav-item mx-3 my-auto py-1"><Link to="/immigration-pro-services" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Immigration (PRO Services)</Link></li>
										<li className="nav-item mx-3 my-auto py-1"><Link to="/business-consulting-advisory" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Business Consultancy/Advisory</Link></li>
										<li className="nav-item mx-3 my-auto py-1"><Link to="/business-banking" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Business Banking</Link></li>
										<li className="nav-item mx-3 my-auto py-1"><Link to="/import-export-solutions" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Import & Export Solutions</Link></li>
										<li className="nav-item mx-3 my-auto py-1"><Link to="/trademark-registration" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Trademark Registration</Link></li>
										<li className="nav-item mx-3 my-auto py-1"><Link to="/company-liquidation" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Company Liquidation</Link></li>
									</ul>
								</li>
								<li id="resources" className="nav-item dropdown my-auto py-1" onClick={handleShowDropdown}>
									<a className="nav-link text-black dropdown-toggle mx-3 py-0">
										<span>Resources</span>
									</a>
									<ul className={`${showResourcesItems ? '' : 'd-none'}`}>
										<li className="nav-item mx-3 my-auto py-1"><a href="https://devotionbusiness.com/InvestmentCostCalculator/index.html" target='_blank' onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Investment Cost Calculator</a></li>
										<li className="nav-item mx-3 my-auto py-1"><Link to="/blogs" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Blogs</Link></li>
										{/* <li className="nav-item mx-3 my-auto py-1"><Link to="/e-book" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>eBook</Link></li> */}
										{/* <li className="nav-item mx-3 my-auto py-1"><Link to="/" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Events</Link></li>
										<li className="nav-item mx-3 my-auto py-1"><Link to="/" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Press Release</Link></li> */}
										<li className="nav-item mx-3 my-auto py-1"><Link to="/faq" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>FAQ</Link></li>
									</ul>
								</li>
								<li className="nav-item mx-3 my-auto py-1">
									<Link to="/contact-us" onClick={handleNavLinkClick} className='text-decoration-none text-black mx-xl-2 py-1'>Contact Us</Link>
								</li>
							</ul>
						</div>
						<div class="header-top-social" id="topnavbar" onMouseLeave={handleShowDropdown}>
							<a href="https://calendly.com/devotioncorporatesales/devotion-sales" target="_blank" class="header-button">Book Consultation</a>
							<Link to="/request-a-quote" class="header-button">Request A Quote</Link>
						</div>
					</div>
				</div>
			</div>
			<div className="header-navbar-sec" id="navbar">
				<nav className="container-fluid navbar navbar-expand-lg">
					<div className="container mt-1" style={{ display: "block" }}>
						<div className={`header nav-elements  ${showNavbar && 'active'}`} id="navbarContent">
							<ul className="mt-3 mt-md-0 nav-links navbar-nav justify-content-center">
								<li className="nav-item mx-1 my-auto py-1" activeStyle>
									<Link to="/" onClick={handleNavLinkClick} className='text-decoration-none mx-xl-2 py-1'>Home</Link>
								</li>
								<li id="about" className="nav-item dropdown my-auto py-1" onMouseEnter={handleShowDropdown} onMouseLeave={handleShowDropdown}>
									<a className="nav-link dropdown-toggle mx-1 py-0">
										<span>About Us</span>
									</a>
									<ul className={`dropdown-menu ${showAboutItems ? '' : 'd-none'}`}>
										<li><Link to="/overview" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Overview</Link></li>
										<li><Link to="/ceo-message" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>CEO Message</Link></li>
										<li><Link to="/mission" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Mission</Link></li>
									</ul>
								</li>
								<li id="business" className="nav-item dropdown my-auto py-1" onMouseEnter={handleShowDropdown} onMouseLeave={handleShowDropdown}>
									<a className="nav-link dropdown-toggle mx-1 py-0">
										<span>Business Setup</span>
									</a>
									<ul className={`dropdown-menu ${showBusinessItems ? '' : 'd-none'}`}>
										<li id="mainland" className="nav-item dropdown my-auto py-1" onMouseEnter={handleShowDropdown}>
											<a className="nav-link text-black dropdown-toggle dropdown-sub-toggle mx-1 py-0">
												<span>UAE Mainland</span>
											</a>
											<ul className={`dropdown-sub-menu ${showMainlandItems ? '' : 'd-none'}`}>
												<li><Link to="/dubai-mainland" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Dubai</Link></li>
												<li><Link to="/sharjah-mainland" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Sharjah</Link></li>
												<li><Link to="/abu-dhabi-mainland" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Abu Dhabi</Link></li>
												<li><Link to="/ajman-mainland" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Ajman</Link></li>
												<li><Link to="/ras-al-khaimah-mainland" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Ras Al Khaimah</Link></li>
												<li><Link to="/fujairah-mainland" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Fujairah</Link></li>
												<li><Link to="/umm-al-quwain-mainland" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Umm Al Quwain</Link></li>
											</ul>
										</li>
										<li id="freezone" className="nav-item dropdown my-auto py-1" onMouseEnter={handleShowDropdown}>
											<a className="nav-link text-black dropdown-toggle dropdown-sub-toggle mx-1 py-0">
												<span>UAE Freezone</span>
											</a>
											<ul className={`dropdown-sub-menu ${showFreezoneItems ? '' : 'd-none'}`}>
												<li><Link to="/dubai-free-zones" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Dubai</Link></li>
												<li><Link to="/sharjah-free-zones" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Sharjah</Link></li>
												<li><Link to="/abu-dhabi-free-zones" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Abu Dhabi</Link></li>
												<li><Link to="/ajman-free-zones" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Ajman</Link></li>
												<li><Link to="/ras-al-khaimah-free-zones" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Ras Al Khaimah</Link></li>
												<li><Link to="/fujairah-free-zones" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Fujairah</Link></li>
												<li><Link to="/umm-al-quwain-free-zones" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Umm Al Quwain</Link></li>
											</ul>
										</li>
										<li id="offshore" className="nav-item dropdown my-auto py-1" onMouseEnter={handleShowDropdown}>
											<a className="nav-link text-black dropdown-toggle dropdown-sub-toggle mx-1 py-0">
												<span>UAE Offshore</span>
											</a>
											<ul className={`dropdown-sub-menu ${showOffshoreItems ? '' : 'd-none'}`}>
												<li><Link to="/jabel-ali-offshore" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Jabeli Ali</Link></li>
												<li><Link to="/ajman-offshore" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Ajman</Link></li>
												<li><Link to="/ras-al-khaimah-offshore" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Ras Ali Khaimah</Link></li>
											</ul>
										</li>
									</ul>
								</li>
								<li id="account" className="nav-item dropdown my-auto py-1" onMouseEnter={handleShowDropdown} onMouseLeave={handleShowDropdown}>
									<a className="nav-link dropdown-toggle mx-1 py-0">
										<span>Accounting & Taxes</span>
									</a>
									<ul className={`dropdown-menu ${showAccountItems ? '' : 'd-none'}`}>
										<li id="accounting" className="nav-item dropdown my-auto py-1" onMouseEnter={handleShowDropdown}>
											<a className="nav-link text-black dropdown-toggle dropdown-sub-toggle mx-1 py-0">
												<span>Accounting</span>
											</a>
											<ul className={`dropdown-sub-menu ${showAccountingItems ? '' : 'd-none'}`}>
												<li><Link to="/accounting-bookkeeping" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Accounting & Bookkeeping</Link></li>
												<li><Link to="/auditing" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Auditing</Link></li>
											</ul>
										</li>
										{/* <li id="vatt" className="nav-item mx-1 my-auto py-1" onMouseEnter={handleShowDropdown}>
											<Link to="/" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none mx-xl-2 py-1 text-black' style={{ width: "auto", padding: "0" }}>Vat & Excise</Link>
										</li> */}
										<li id="vat" className="nav-item dropdown my-auto py-1" onMouseEnter={handleShowDropdown}>
											<a className="nav-link text-black dropdown-toggle dropdown-sub-toggle mx-1 py-0">
												<span>VAT Consultancy</span>
											</a>
											<ul className={`dropdown-sub-menu ${showVatItems ? '' : 'd-none'}`}>
												<li><Link to="/vat-registration" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>VAT Registration</Link></li>
												<li><Link to="/vat-return-filing" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>VAT Return Filling</Link></li>
												<li><Link to="/vat-compliance" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>VAT Compliance</Link></li>
												<li><Link to="/vat-advisory-services" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>VAT Advisory</Link></li>
												<li><Link to="/vat-deregistration" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>VAT Deregistration</Link></li>
											</ul>
										</li>
										<li id="excise" className="nav-item dropdown my-auto py-1" onMouseEnter={handleShowDropdown}>
											<a className="nav-link text-black dropdown-toggle dropdown-sub-toggle mx-1 py-0">
												<span>Excise Consultancy</span>
											</a>
											<ul className={`dropdown-sub-menu ${showExiseItems ? '' : 'd-none'}`}>
												<li><Link to="/excise-registration" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Excise Registration</Link></li>
												<li><Link to="/excise-return-filing" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Excise Return Filling</Link></li>
												<li><Link to="/excise-compliance" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Excise Compliance</Link></li>
												<li><Link to="/excise-advisory" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Excise Advisory</Link></li>
												<li><Link to="/excise-deregistration" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Excise Deregistration</Link></li>
												<li><Link to="/product-registration-with-fta" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Product Registration with FTA</Link></li>
											</ul>
										</li>
										<li id="taxx" className="nav-item mx-1 my-auto py-1" onMouseEnter={handleShowDropdown}>
											<Link to="/tax-residency-certificate" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none mx-xl-2 py-1 text-black' style={{ width: "auto", padding: "0" }}>TAX Residency Certificate (TRC)</Link>
										</li>
									</ul>
								</li>
								<li className="nav-item mx-1 my-auto py-1">
									<Link to="/corporate-tax" onClick={handleNavLinkClick} className='text-decoration-none mx-xl-2 py-1'>Corporate Tax</Link>
								</li>
								<li id="services" className="nav-item dropdown my-auto py-1" onMouseEnter={handleShowDropdown} onMouseLeave={handleShowDropdown}>
									<a className="nav-link dropdown-toggle mx-1 py-0">
										<span>Services</span>
									</a>
									<ul className={`dropdown-menu ${showServicesItems ? '' : 'd-none'}`}>
										<li><Link to="/immigration-pro-services" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Immigration (PRO Services)</Link></li>
										<li><Link to="/business-consulting-advisory" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Business Consultancy/Advisory</Link></li>
										<li><Link to="/business-banking" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Business Banking</Link></li>
										<li><Link to="/import-export-solutions" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Import & Export Solutions</Link></li>
										<li><Link to="/trademark-registration" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Trademark Registration</Link></li>
										<li><Link to="/company-liquidation" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Company Liquidation</Link></li>
									</ul>
								</li>
								<li id="resources" className="nav-item dropdown my-auto py-1" onMouseEnter={handleShowDropdown} onMouseLeave={handleShowDropdown}>
									<a className="nav-link dropdown-toggle mx-1 py-0">
										<span>Resources</span>
									</a>
									<ul className={`dropdown-menu ${showResourcesItems ? '' : 'd-none'}`}>
										<li><a href="https://devotionbusiness.com/InvestmentCostCalculator/index.html" target='_blank' onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Investment Cost Calculator</a></li>
										<li><Link to="/blogs" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Blogs</Link></li>
										{/* <li><Link to="/e-book" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>eBook</Link></li> */}
										{/* <li><Link to="/" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Events</Link></li>
										<li><Link to="/" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>Press Release</Link></li> */}
										<li><Link to="/faq" onClick={handleNavLinkClick} className='dropdown-item text-decoration-none text-black'>FAQ</Link></li>
									</ul>
								</li>
								<li className="nav-item mx-1 my-auto py-1">
									<Link to="/contact-us" onClick={handleNavLinkClick} className='text-decoration-none mx-xl-2 py-1'>Contact Us</Link>
								</li>
							</ul>
						</div>
					</div>
				</nav>
			</div>
		</>
	);
};

export default Header;