import { useState, useEffect } from "react";
import { Cookies, useCookies } from "react-cookie";
import { Link, useNavigate } from 'react-router-dom';
import API from '../../../middleware/API';

// ================================|| MyProfile ||================================ //

function MyProfile() {
    const navigate = useNavigate();
    const [Userdata, setUserData] = useState();
    const [FullName, SetFullName] = useState("");                      // store the input values
    const [UserName, SetUserName] = useState("");                      // store the input values
    const [Password, SetPassword] = useState("");                      // store the input values
    const [Email, SetEmail] = useState("");                            // store the input values
    const [MobileNumber, SetMobileNumber] = useState("");              // store the input values
    const [Image, SetImage] = useState("");                            // store the input values
    const [Address, SetAddress] = useState("");                        // store the input values
    const [IsActive, SetIsActive] = useState("");                      // store the input values
    const [ErrorMessage, SetErrorMessage] = useState("");
    const [SucessMessage, SetSucessMessage] = useState("");
    const [cookie, SetCookie] = useCookies(['username']);
    const cookies = new Cookies();
    const user = cookies.get('id');

    useEffect(() => {
        getUserByID();
    }, [])

    let userdata = "";

    const getUserByID = async () => {
        try {
            var GetUserByIdAPI = API.getUserById;
            GetUserByIdAPI = GetUserByIdAPI + "?id=" + user;
            const response = await fetch(GetUserByIdAPI);
            const jsonData = await response.json();
            setUserData(jsonData.recordset);
            userdata = jsonData.recordset[0];
            SetFullName(userdata.FullName);
            SetUserName(userdata.UserName);
            SetPassword(userdata.Password);
            SetEmail(userdata.EmailId);
            SetMobileNumber(userdata.MobileNo);
            SetImage(userdata.Image);
            SetAddress(userdata.Address);
            SetIsActive(userdata.IsActive);
        }
        catch (error) {
            console.log(error);
        }
    };

    let submitHandler = async (e) => {
        e.preventDefault();
        var checked = false;
        if (IsActive === true) {
            checked = true;
        }

        try {
            var re = /(?:\.([^.]+))?$/;
            var ext = re.exec(Image);
            var text = Image;

            if (text.match("https://res.cloudinary.com/")) {
                var UpdateUserAPI = API.updateUser;
                UpdateUserAPI = UpdateUserAPI + "?id=" + user;
                let res = await fetch(UpdateUserAPI, {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-type': 'application/json',
                    },
                    json: true,
                    body: JSON.stringify({
                        fullName: FullName,
                        username: UserName,
                        password: Password,
                        email: Email,
                        mobileNo: MobileNumber,
                        image: Image,
                        address: Address,
                        modifiedBy: user,
                        isActive: checked
                    }),
                });
                let resJson = await res.json();
                if (resJson.status === 200) {
                    SetFullName("");
                    SetUserName("");
                    SetPassword("");
                    SetEmail("");
                    SetMobileNumber("");
                    SetImage("");
                    SetAddress("");
                    SetErrorMessage("");
                }
                else{
                    SetSucessMessage("Profile Updated Sucessfully.");
                    getUserByID();
                }
            }
            else if (ext[0] === ".jpg" || ext[0] === ".png") {

                var SignuploadformAPI = API.signuploadform;
                const signResponse = await fetch(SignuploadformAPI);
                const signData = await signResponse.json();
                const url = "https://api.cloudinary.com/v1_1/" + signData.cloudname + "/auto/upload";
                const files = document.querySelector("[type=file]").files;
                let file = files[0];
                const formData = new FormData();

                formData.append("file", file);
                formData.append("api_key", signData.apikey);
                formData.append("timestamp", signData.timestamp);
                formData.append("signature", signData.signature);
                formData.append("eager", "c_pad,h_300,w_400|c_crop,h_200,w_260");
                formData.append("folder", "images");

                const fileData = await fetch(url, {
                    method: "POST",
                    body: formData
                })
                const fileJson = await fileData.json();

                var UpdateUserAPI = API.updateUser;
                UpdateUserAPI = UpdateUserAPI + "?id=" + user;
                let res = await fetch(UpdateUserAPI, {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-type': 'application/json',
                    },
                    json: true,
                    body: JSON.stringify({
                        fullName: FullName,
                        username: UserName,
                        password: Password,
                        email: Email,
                        mobileNo: MobileNumber,
                        image: fileJson.secure_url,
                        address: Address,
                        modifiedBy: user,
                        isActive: checked
                    }),
                });
                let resJson = await res.json();
                if (resJson.status === 200) {
                    SetFullName("");
                    SetUserName("");
                    SetPassword("");
                    SetEmail("");
                    SetMobileNumber("");
                    SetImage("");
                    SetAddress("");
                    SetErrorMessage("");
                }
                else{
                    SetSucessMessage("Profile Updated Sucessfully.");
                    getUserByID();
                }
            }
            else {
                SetErrorMessage("Upload only jpg & png format Image");
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <div class="container-xxl content-wrapper">
                <div class="container-xxl flex-grow-1 container-p-y">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card mb-4">
                                <h5 class="card-header">Profile Details</h5>
                                <form id='myform' onSubmit={submitHandler}>
                                    <div class="card-body" style={{ height: "auto" }}>
                                        <div class="d-flex align-items-start align-items-sm-center gap-4">
                                            <div className="row form-item pb-4">
                                                <img src={Image} height="100" width="100"className="d-block rounded thumbnail" alt="profile" title="Profile" />
                                            </div>
                                            <div class="button-wrapper">
                                                <label for="upload" class="btn btn-primary me-2 mb-4" tabindex="0">
                                                    <span class="d-none d-sm-block">Upload new photo</span>
                                                    <i class="bx bx-upload d-block d-sm-none"></i>
                                                    <input
                                                        type="file"
                                                        id="upload"
                                                        class="account-file-input"
                                                        hidden
                                                        accept="image/png, image/jpeg"
                                                        onChange={(e) => SetImage(e.target.value)}
                                                    />
                                                </label>
                                                <button type="button" class="btn btn-outline-secondary account-image-reset mb-4">
                                                    <i class="bx bx-reset d-block d-sm-none"></i>
                                                    <span class="d-none d-sm-block">Reset</span>
                                                </button>
                                                <div className="error-message">{ErrorMessage ? <p>{ErrorMessage}</p> : null}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr class="my-0" />
                                    <div class="card-body" style={{ height: "auto" }}>
                                        <div class="row">
                                            <div class="mb-3 col-md-6">
                                                <label for="fullname" class="form-label">Full Name</label>
                                                <input
                                                    class="form-control"
                                                    type="text"
                                                    id="fullname"
                                                    name="fullname"
                                                    value={FullName}
                                                    onChange={(e) => SetFullName(e.target.value)}
                                                    required
                                                    autofocus
                                                />
                                            </div>
                                            <div class="mb-3 col-md-6">
                                                <label for="email" class="form-label">E-mail</label>
                                                <input
                                                    class="form-control"
                                                    type="text"
                                                    id="email"
                                                    name="email"
                                                    value={Email}
                                                    onChange={(e) => SetEmail(e.target.value)}
                                                    required
                                                />
                                            </div>
                                            <div class="mb-3 col-md-6">
                                                <label for="firstName" class="form-label">User Name</label>
                                                <input
                                                    class="form-control"
                                                    type="text"
                                                    id="username"
                                                    name="username"
                                                    value={UserName}
                                                    onChange={(e) => SetUserName(e.target.value)}
                                                    required
                                                    autofocus
                                                />
                                            </div>
                                            <div class="mb-3 col-md-6">
                                                <label for="email" class="form-label">Password</label>
                                                <input
                                                    class="form-control"
                                                    type="password"
                                                    id="password"
                                                    name="password"
                                                    value={Password}
                                                    onChange={(e) => SetPassword(e.target.value)}
                                                    required
                                                />
                                            </div>
                                            <div class="mb-3 col-md-6">
                                                <label for="organization" class="form-label">Organization</label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    id="organization"
                                                    name="organization"
                                                    value="Devotion Business"
                                                    disabled="true"
                                                />
                                            </div>
                                            <div class="mb-3 col-md-6">
                                                <label class="form-label" for="phoneNumber">Phone Number</label>
                                                <div class="input-group input-group-merge">
                                                    <span class="input-group-text">IND (+91)</span>
                                                    <input
                                                        type="text"
                                                        id="phoneNumber"
                                                        name="phoneNumber"
                                                        class="form-control"
                                                        value={MobileNumber}
                                                        onChange={(e) => SetMobileNumber(e.target.value)}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div class="mb-3 col-md-12">
                                                <label for="address" class="form-label">Address</label>
                                                <textarea id="address" name="address" placeholder="Address" value={Address} onChange={(e) => SetAddress(e.target.value)} />
                                            </div>
                                        </div>
                                        <div class="mt-2">
                                            <button type="submit" class="btn btn-primary me-2">Save changes</button>
                                            <button type="reset" class="btn btn-outline-secondary">Cancel</button>
                                        </div>
                                        <div className="message" style={{textAlign:"left"}}>{SucessMessage ? <p>{SucessMessage}</p> : null}</div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MyProfile;