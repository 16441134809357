import React from 'react';
import { Helmet } from "react-helmet";

// ================================|| AjmanOffshore ||================================ //

const AjmanOffshore = () => {

    return (
        <>
            <Helmet>
                <title>Business Setup in Ajman Offshore | Ajman Offshore Company Setup Cost</title>
            </Helmet>
            <div class="chairman-message">
                <div class="chairman-img">
                    <div class="pos-message-box">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-7 aos-init" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2 className='alignment'>Ajman Offshore</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img class="d-lg-block d-none" style={{ width: "-webkit-fill-available" }} src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001997/images/Contact-us.png" alt="ajman-banner" title="Ajman Banner" />
            </div>
            <div className="container">
                <div className="row pt-5 pb-5">
                    <h1><span style={{ color: "#aa8038" }}>Business Setup in Ajman Offshore Freezone</span></h1>
                    <div className='col-12'>
                        <p>
                            Ajman free zone was spread out in 1988 and was doled out irreverently in 1996 under the standards of Humaid Bin Rashid Al Naumi, the Ruler of Ajman.
                            This toward the ocean place fills in as an endeavor system for associations and provides them with the upsides toward the ocean association, which consolidates zero expense assortment structure and mystery.
                            It furthermore gives the monetary benefactors going with components:
                        </p>
                        <ul>
                            <li style={{ listStyle: "unset" }}>
                                No working environments/current premises are required.
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                No trading inside the UAE.
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                No Chamber of Commerce enlistment is required.
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                No home visa for originators and bosses.
                                Financial backers or possibly bosses are not normally able to get a UAE home visa from the offshore association opened.
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Not essential for Submission of Annual Audit Report.
                            </li>
                        </ul>
                        <p>
                            Offshore association in Ajman can be used as a Holding association to guarantee participation in various associations enrolled wherever in the world.
                            An offshore association can be used as a Special Power Vehicle (SPV) to execute any errand. The standard strategy to structure a business is to enroll toward the ocean association and this toward the ocean association will hold 100% bits of a Freezone Company in UAE.
                            A conclusive beneficiary of Freezone association will be the owner of Offshore association anyway his name isn't seen as the Shareholder or Director of Freezone association.
                        </p>
                        <p>
                            Ajman Offshore being another toward the ocean substance has a ton speedier association improvement process stood out from other toward the ocean networks.
                            The association can be united in just 1 working day after association name support and complete required reports are submitted.
                            Furthermore, financial backers need not come to the UAE for the association's improvement.
                        </p>
                    </div>
                    <div className='col-md-7'>
                        <h5 style={{ color: "#aa8038" }}>The Documents Required For Ajman Offshore Company Incorporation Are</h5>
                        <ul>
                            <li style={{ listStyle: "unset" }}>
                                Passport copy of the shareholder(s)
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Original proof of residence of the shareholder/s (can be recent telephone/electricity bill or any Bank Statement showing the address)
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Duly signed application form and Memorandum of Association
                            </li>
                            <li style={{ listStyle: "unset" }}>
                                Incorporation Fees of AED 7,500 (all inclusive)
                            </li>
                        </ul>
                    </div>
                    <div className='col-md-5'>
                        <img class="card-img-top" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001988/images/ajman-offshore-freezone.jpg" alt="ajman" title="Ajman" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default AjmanOffshore;