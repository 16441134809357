import { useState, useEffect } from "react";
import { MdDelete } from "react-icons/md";
import { Link, useNavigate } from 'react-router-dom';
import { FaCheck, FaTimes, FaFilePdf, FaLinkedin, FaGithub, FaFileArchive, FaEye } from "react-icons/fa";
import { Helmet } from "react-helmet";
import moment from 'moment';
import API from '../../../middleware/API';
import DataTable from 'react-data-table-component';

// ================================|| Replied ||================================ //

function Replied() {
    const navigate = useNavigate();
    const [Page, setPage] = useState(1);
    const [RepliedCareerdata, setRepliedCareerData] = useState();
    const [TotalRepliedCareer, setTotalRepliedCareer] = useState();
    const [loading, setLoading] = useState(false);

    const rowsperPage = "5";

    useEffect(() => {
        getRepliedCareerList();
    }, [Page]);

    const getRepliedCareerList = async () => {
        try {
            setLoading(true);
            var GetRepliedCareerDetails = API.getRepliedCareerDetails;
            GetRepliedCareerDetails = GetRepliedCareerDetails + '?pageNumber=' + Page + '&rowsperPage=' + rowsperPage;
            const response = await fetch(GetRepliedCareerDetails);
            const jsonData = await response.json();
            setRepliedCareerData(jsonData.recordsets[0]);
            setTotalRepliedCareer(jsonData.recordsets[1][0].TotalRows);
            setLoading(false);
        }
        catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    function viewCareerById(id) {
        navigate('/admin/recruitment?Id=' + id);
    }

    function deleteCareerById(id) {
        var result = window.confirm('Are you sure you want to delete this record?');
        if (result) {
            try {
                var DeleteCareerDetailByIdAPI = API.deleteCareerDetailById;
                DeleteCareerDetailByIdAPI = DeleteCareerDetailByIdAPI + "?id=" + id;
                const response = fetch(DeleteCareerDetailByIdAPI);
                getRepliedCareerList();
            }
            catch (error) {
                console.log(error);
            }
        }
    }

    const columns = [
        {
            name: 'Id',
            selector: row => row.Id,
            sortable: true,
            width: "4rem"
        },
        {
            name: 'First Name',
            selector: row => row.FirstName,
            sortable: true,
            width: "7rem"
        },
        {
            name: 'Last Name',
            selector: row => row.LastName,
            sortable: true,
            width: "7rem"
        },
        {
            name: 'Email',
            selector: row => row.Email,
            sortable: true,
            width: "14rem"
        },
        {
            name: 'Last Company',
            selector: row => row.LastCompany,
            sortable: true,
            width: "8rem"
        },
        {
            name: 'CV',
            cell: (props) =>
                <div><a href={props.CV} target="_blank"><FaFilePdf size={'1.5rem'} /></a></div>,
            sortable: false,
            width: "4rem"
        },
        {
            name: 'Linked In',
            cell: (props) =>
                <div><a href={props.LinkedIn} target="_blank"><FaLinkedin size={'1.5rem'} /></a></div>,
            sortable: false,
            width: "6rem"
        },
        {
            name: 'GitHub',
            cell: (props) =>
                <div><a href={props.GitHub} target="_blank"><FaGithub size={'1.5rem'} /></a></div>,
            sortable: false,
            width: "5rem"
        },
        {
            name: 'Portfolio',
            cell: (props) =>
                <div><a href={props.PortFolio} target="_blank"><FaFileArchive size={'1.5rem'} /></a></div>,
            sortable: false,
            width: "5rem"
        },
        {
            name: 'Created Date',
            selector: row => moment(row.CreatedOn).format('DD-MM-YYYY'),
            sortable: true,
            width: "8rem"
        },
        {
            name: 'Status',
            cell: (row) => <a>{row.Status ? (<FaCheck color="green" className="fa-2x"/>) : (<FaTimes color="red" className="fa-2x" />)}</a>,
            sortable: true,
            width: "5rem"
        },
        {
            name: 'Action',
            cell: (props) => <div>
                <a><FaEye size={30} className="m-1 p-1 cl-button -primary -small" onClick={() => viewCareerById(props.Id)} style={{ cursor: "pointer" }} /></a>
                <a><MdDelete size={30} className="m-1 p-1 cl-button -primary -small" onClick={() => deleteCareerById(props.Id)} style={{ cursor: "pointer" }} /></a>
            </div>,
            sortable: false
        },
    ];

    return (
        <>
            <Helmet>
                <title>Replied Recruitment</title>
            </Helmet>
            <div className="container-fluid">
                <div className="container d-flex pt-5 pb-3">
                    <div className="col">
                        <h3 className="card-header px-3 ">Recruitment Replied Details (Career)</h3>
                    </div>
                    <div className="col " style={{ textAlign: "end" }}>
                        <Link to='/admin/recruitments' className="btn btn-info cl-button -primary mx-1 my-1">Back to List</Link>
                    </div>
                </div>
                <div className="container mx-auto pt-3">
                    <div className="px-3 pb-3">
                        <div className="col-sm-12 table-responsive text-nowrap shadow p-3 mb-5 bg-body rounded">
                            <DataTable
                                columns={columns}
                                data={RepliedCareerdata}
                                pagination
                                paginationServer
                                progressPending={loading}
                                paginationTotalRows={TotalRepliedCareer}
                                paginationPerPage={rowsperPage}
                                paginationComponentOptions={{
                                    noRowsPerPage: true
                                }}
                                onChangePage={page => setPage(page)}
                            ></DataTable>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Replied;