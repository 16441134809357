import React, { useState } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css';
import './site/navbar/app-1.scss';
import { Routes, Route, useLocation } from 'react-router-dom';
import { Cookies, useCookies } from "react-cookie";
import Login from './site/pages/Login';
import Header from './site/navbar/Header';
import Footer from './site/navbar/Footer';
import Home from './site/pages/Index';
import Overview from './site/pages/Overview';
import CEOMessage from './site/pages/CEOMessage';
import UmmAlQuwainMainland from './site/pages/mainland/UmmAlQuwainMainland';
import FujairahMainland from './site/pages/mainland/FujairahMainland';
import SharjahMainland from './site/pages/mainland/SharjahMainland';
import AbuDhabiMainland from './site/pages/mainland/AbuDhabiMainland';
import RasAlKhaimahMainland from './site/pages/mainland/RasAlKhaimahMainland';
import DubaiMainland from './site/pages/mainland/DubaiMainland';
import AjmanMainland from './site/pages/mainland/AjmanMainland';
import DubaiFreeZones from './site/pages/freezone/DubaiFreeZones';
import SharjahFreeZones from './site/pages/freezone/SharjahFreeZones';
import AbuDhabiFreeZones from './site/pages/freezone/AbuDhabiFreeZones';
import AjmanFreeZones from './site/pages/freezone/AjmanFreeZones';
import RasAlKhaimahFreeZones from './site/pages/freezone/RasAlKhaimahFreeZones';
import FujairahFreeZones from './site/pages/freezone/FujairahFreeZones';
import UmmAlQuwainFreeZones from './site/pages/freezone/UmmAlQuwainFreeZones';
import JabelAliOffshore from './site/pages/offshore/JabelAliOffshore';
import AjmanOffshore from './site/pages/offshore/AjmanOffshore';
import RasAlKhaimahOffshore from './site/pages/offshore/RasAlKhaimahOffshore';
import AccountingBookkeeping from './site/pages/accounting/AccountingBookkeeping';
import Auditing from './site/pages/accounting/Auditing';
import VATAdvisoryServices from './site/pages/vat-consultancy/VATAdvisoryServices';
import VATReturnFiling from './site/pages/vat-consultancy/VATReturnFiling';
import VATRegistration from './site/pages/vat-consultancy/VATRegistration';
import VATDeregistration from './site/pages/vat-consultancy/VATDeregistration';
import VATCompliance from './site/pages/vat-consultancy/VATCompliance';
import ProductRegistrationWithFTA from './site/pages/excise-consultancy/ProductRegistrationWithFTA';
import ExciseDeregistration from './site/pages/excise-consultancy/ExciseDeregistration';
import ExciseAdvisory from './site/pages/excise-consultancy/ExciseAdvisory';
import ExciseCompliance from './site/pages/excise-consultancy/ExciseCompliance';
import ExciseReturnFiling from './site/pages/excise-consultancy/ExciseReturnFiling';
import ExciseRegistration from './site/pages/excise-consultancy/ExciseRegistration';
import TAXResidencyCertificate from './site/pages/TAXResidencyCertificate';
import CorporateTax from './site/pages/CorporateTax';
import ImmigrationProServices from './site/pages/services/ImmigrationProServices';
import BusinessConsultingAdvisory from './site/pages/services/BusinessConsultingAdvisory';
import BusinessBanking from './site/pages/services/BusinessBanking';
import ImportExportSolutions from './site/pages/services/ImportExportSolutions';
import TrademarkRegistration from './site/pages/services/TrademarkRegistration';
import CompanyLiquidation from './site/pages/services/CompanyLiquidation';
import Mission from './site/pages/Mission';
import Blogs from './site/pages/blog/Blogs';
import BlogDetail from './site/pages/blog/BlogDetail';
import AccountingAndBookkeepingServicesInDubai from './site/pages/blogs/AccountingAndBookkeepingServicesInDubai';
import ConsultancyBusinessSetupInDubai from './site/pages/blogs/ConsultancyBusinessSetupInDubai';
import DentalClinicBusinessInDubaiUAE from './site/pages/blogs/DentalClinicBusinessInDubaiUAE';
import ExportAndImportBusinessInDubai from './site/pages/blogs/ExportAndImportBusinessInDubai';
import RealEstateBusinessSetupInDubai from './site/pages/blogs/RealEstateBusinessSetupInDubai';
import RegisterYourITCompanyInDubai from './site/pages/blogs/RegisterYourITCompanyInDubai';
import StartRestaurantBusinessInDubaiUAE from './site/pages/blogs/StartRestaurantBusinessInDubaiUAE';
import EBook from './site/pages/EBook';
import FAQ from './site/pages/FAQ';
import Career from './site/pages/Career';
import ContactUs from './site/pages/contact-us/ContactUs';
import Thankyou1 from './site/pages/contact-us/Thankyou';
import RequestAQuote from './site/pages/request-a-quote/RequestAQuote';
import Thankyou2 from './site/pages/request-a-quote/Thankyou';
import PrivacyPolicy from './site/pages/PrivacyPolicy';
import TermsConditions from './site/pages/TermsConditions';
import "../src/site/scss/demo.scss";
import ScrollButton from './site/navbar/ScrollButton';
import CookieConsent from "react-cookie-consent";
import Page404 from './site/pages/extra-pages/Page404';


function App() {

	const [isAuth, setIsAuth] = useState(localStorage.getItem("isAuth"));
	const location = useLocation();

	const cookies = new Cookies();
	const BlogSlug = cookies.get('Slug');

	const data = {
		isAuth,
		setIsAuth,
	};
	console.log(data);

	return (
		<>
			{location.pathname == "/login" ? null : <Header />}{" "}
			{location.pathname == "/login" ? null : <ScrollButton />}{" "}
			<Routes>
				<Route path='/' element={<Home />} />
				<Route path='/login' element={<Login />} />
				<Route path='/overview' element={<Overview />} />
				<Route path='/mission' element={<Mission />} />
				<Route path='/ceo-message' element={<CEOMessage />} />
				<Route path='/umm-al-quwain-mainland' element={<UmmAlQuwainMainland />} />
				<Route path='/fujairah-mainland' element={<FujairahMainland />} />
				<Route path='/sharjah-mainland' element={<SharjahMainland />} />
				<Route path='/abu-dhabi-mainland' element={<AbuDhabiMainland />} />
				<Route path='/ras-al-khaimah-mainland' element={<RasAlKhaimahMainland />} />
				<Route path='/dubai-mainland' element={<DubaiMainland />} />
				<Route path='/ajman-mainland' element={<AjmanMainland />} />
				<Route path='/dubai-free-zones' element={<DubaiFreeZones />} />
				<Route path='/sharjah-free-zones' element={<SharjahFreeZones />} />
				<Route path='/abu-dhabi-free-zones' element={<AbuDhabiFreeZones />} />
				<Route path='/ajman-free-zones' element={<AjmanFreeZones />} />
				<Route path='/ras-al-khaimah-free-zones' element={<RasAlKhaimahFreeZones />} />
				<Route path='/fujairah-free-zones' element={<FujairahFreeZones />} />
				<Route path='/umm-al-quwain-free-zones' element={<UmmAlQuwainFreeZones />} />
				<Route path='/jabel-ali-offshore' element={<JabelAliOffshore />} />
				<Route path='/ajman-offshore' element={<AjmanOffshore />} />
				<Route path='/ras-al-khaimah-offshore' element={<RasAlKhaimahOffshore />} />
				<Route path='/accounting-bookkeeping' element={<AccountingBookkeeping />} />
				<Route path='/auditing' element={<Auditing />} />
				<Route path='/vat-advisory-services' element={<VATAdvisoryServices />} />
				<Route path='/vat-return-filing' element={<VATReturnFiling />} />
				<Route path='/vat-registration' element={<VATRegistration />} />
				<Route path='/vat-deregistration' element={<VATDeregistration />} />
				<Route path='/vat-compliance' element={<VATCompliance />} />
				<Route path='/product-registration-with-fta' element={<ProductRegistrationWithFTA />} />
				<Route path='/excise-deregistration' element={<ExciseDeregistration />} />
				<Route path='/excise-advisory' element={<ExciseAdvisory />} />
				<Route path='/excise-compliance' element={<ExciseCompliance />} />
				<Route path='/excise-return-filing' element={<ExciseReturnFiling />} />
				<Route path='/excise-registration' element={<ExciseRegistration />} />
				<Route path='/tax-residency-certificate' element={<TAXResidencyCertificate />} />
				<Route path='/corporate-tax' element={<CorporateTax />} />
				<Route path='/immigration-pro-services' element={<ImmigrationProServices />} />
				<Route path='/business-consulting-advisory' element={<BusinessConsultingAdvisory />} />
				<Route path='/business-banking' element={<BusinessBanking />} />
				<Route path='/import-export-solutions' element={<ImportExportSolutions />} />
				<Route path='/trademark-registration' element={<TrademarkRegistration />} />
				<Route path='/company-liquidation' element={<CompanyLiquidation />} />
				<Route path='/blogs' element={<Blogs />} />
				<Route path='/blog/:BlogSlug' element={<BlogDetail />} />
				<Route path='/accounting-and-bookkeeping-services-in-dubai' element={<AccountingAndBookkeepingServicesInDubai />} />
				<Route path='/consultancy-business-setup-in-dubai' element={<ConsultancyBusinessSetupInDubai />} />
				<Route path='/dental-clinic-business-in-dubai-uae' element={<DentalClinicBusinessInDubaiUAE />} />
				<Route path='/export-and-import-business-in-dubai' element={<ExportAndImportBusinessInDubai />} />
				<Route path='/real-estate-business-setup-in-dubai' element={<RealEstateBusinessSetupInDubai />} />
				<Route path='/register-your-itcompany-in-dubai' element={<RegisterYourITCompanyInDubai />} />
				<Route path='/start-restaurant-business-in-dubai-uae' element={<StartRestaurantBusinessInDubaiUAE />} />
				<Route path='/e-book' element={<EBook />} />
				<Route path='/faq' element={<FAQ />} />
				<Route path='/career' element={<Career />} />
				<Route path='/contact-us' element={<ContactUs />} />
				<Route path='/contact-us/thank-you' element={<Thankyou1 />} />
				<Route path='/request-a-quote' element={<RequestAQuote />} />
				<Route path='/request-a-quote/thank-you' element={<Thankyou2 />} />
				<Route path='/privacy-policy' element={<PrivacyPolicy />} />
				<Route path='/terms-conditions' element={<TermsConditions />} />
				<Route component={<Page404 />} />
			</Routes>
			{location.pathname == "/login" ? null :
				<CookieConsent enableDeclineButton
					location="bottom"
					buttonText="Accept"
					cookieName="cookies"
					declineButtonText="Reject"
					declineButtonStyle={{ background: "#666666", color: "#fff" }}
					style={{ background: "#EDEDED", color: "#000", textAlign: "center", justifyContent: "center", zIndex: "1002" }}
					buttonStyle={{ background: "#aa8038", color: "#fff" }}
					expires={1}
				>
					This website uses cookies to improve your experience.{" "}
				</CookieConsent>
			}{" "}
			{location.pathname == "/login" ? null : <Footer />}{" "}
		</>
	);
}

export default App;