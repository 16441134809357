import React from 'react';
import Faq from "react-faq-component";
import { Helmet } from "react-helmet";

// ================================|| CorporateTax ||================================ //

const CorporateTax = () => {

    const data = {
        title: "",
        rows: [
            {
                title: "1. What is corporate tax in UAE?",
                content: `Corporate tax is a form of direct tax levied on the net income or profit of corporations and other business entities. It is also commonly known as 'Corporate Income Tax' or Business Profits Tax.'<br/><br/>
                In simple words, it is a tax levied on the net profit made by the businesses. It requires companies to pay a certain percentage of profit as tax.`,
            },
            {
                title: "2. Who should pay corporate tax in the UAE?",
                content: `All the businesses whose taxable profit (net) is more than 375,000 AED fall under the purview of corporate tax and are required to pay a certain percentage of net profit as corporate tax.`,
            },
            {
                title: "3. What is the rate of corporate tax in the UAE?",
                content: `The corporate tax rate is at 9% of the net profit made by the businesses. In order extent support to small businesses and start-ups, the corporate tax rate will be '0' % if the net profit is up to 3,75,000 AED.`,
            },
            {
                title: "4. What is the date of implementing the federal corporate tax in UAE?",
                content: `The date of implementing the corporate tax is effective from the financial year starting on or after 1st June, 2023.<br/>
                Corporate Tax will become effective in fiscal years beginning on or after June 1, 2023. Businesses adopting a fiscal year ending May 31, 2024, will be subject to corporate tax. The first tax returns will be filed in 2024. Corporations with fiscal years starting on January 1, 2023, and ending on December 31, 2023, will be subject to corporate tax.
                For Example, Corporations with fiscal years starting on June 1, 2023, and ending on May 30, 2024, will be subject to corporate tax, likewise:<br/><br/>
                <table class="table table-hover table-responsive table-striped table-bordered">
                    <thead>
                        <tr>
                            <th class="text-white font-size-16 font-weight-6 bg-blue p-2" style="background-color: #aa8038" width="70%">
                                Financial year-end
                            </th>
                            <th class="text-white font-size-16 font-weight-6 bg-blue p-2" style="background-color: #aa8038" width="30%">
                                First Tax period
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="font-size-15">
                                30 June
                            </td>
                            <td class="font-size-15">
                                1st July 2023 - 30th June 2024
                            </td>
                        </tr>
                        <tr>
                            <td class="font-size-15">
                                31 December
                            </td>
                            <td class="font-size-15">
                                1st January 2024 - 31st December 2024
                            </td>
                        </tr>
                    </tbody>
                </table>`,
            },
            {
                title: "5. Registration and deregistration",
                content: `
                <ul>
                    <li style="list-style: unset">
                        The FTA requires that all businesses subject to CT register with the FTA within the prescribed period and obtain a Tax Registration Number. 
                        In addition, if the company does not voluntarily register for CT purposes, the FTA can automatically do so.
                    </li>
                    <li style="list-style: unset">
                        The FTA must be notified within three months of the cessation of a business' CT registration (e.g., due to liquidation).
                    </li>
                    <li style="list-style: unset">
                        For FTA to deregister an individual, the individual must have filed CT returns and paid all CT obligations and penalties until discontinuation.
                    </li>
                    <li style="list-style: unset">
                        The application for deregistration is not made within the deadlines, and the payment and filing requirements are not met.
                    </li>
                </ul>
                <h5 style="color: #aa8038">Filing, payment, and refund</h5>
                <ul>
                    <li style="list-style: unset">
                        Businesses will only need to prepare one tax return and other related supporting schedules with the FTA for each tax period. 
                        A company will not have to file a provisional CT return or pay the CT in advance.
                    </li>
                    <li style="list-style: unset">
                        The FTA must receive each tax return and related supporting schedule within nine months of each tax period's end.
                    </li>
                    <li style="list-style: unset">
                        CT payments for a Tax Period must be made within nine months after the Tax Period. 
                        The taxpayer can request a CT refund from the FTA if they can demonstrate that such a refund is due.
                    </li>
                </ul>
                <h5 style="color: #aa8038">Documentation requirements</h5>
                <ul>
                    <li style="list-style: unset">
                        Businesses will be required to maintain financial and other records to comply with the CT return and other documents submitted to the FTA. 
                        Additionally, specific exempted individuals will also be required to keep records to allow the FTA to verify their exemption status.
                    </li>
                    <li style="list-style: unset">
                        It will be determined by applicable laws and regulations whether the financial statements need to be independently audited by an accredited firm. 
                        However, Free Zone Persons must have audited financial statements to qualify for the UAE CT regime.
                    </li>
                </ul>`,
            },
            {
                title: "6. What are the businesses or incomes that are outside the scope of corporate tax?",
                content: `Given the profit threshold of 3,75,000 AED, all businesses that exceed the threshold have to pay the corporate tax. However, certain types of business or income are exempt from corporate tax. Below is the list of companies or income exempt from corporate tax:<br/><br/>
                <ul>
                    <li style="list-style: unset">
                        Individuals will not be subject to corporate tax. As a result, any income from employment, real estate, investments in shares, and other personal income unrelated to a trade or business in the UAE will be exempt from corporate tax.
                    </li>
                    <li style="list-style: unset">
                        Not applicable to foreign investors who do not carry on business in UAE.
                    </li>
                    <li style="list-style: unset">
                        Corporate tax incentives are currently being offered to free zone businesses that comply with all regulatory requirements will continue.
                    </li>
                    <li style="list-style: unset">
                        Capital gains and dividends received by UAE businesses from its qualifying shareholdings are exempt from corporate tax.
                    </li>
                    <li style="list-style: unset">
                    Not applicable on qualifying intragroup transactions and restructurings.
                    </li>
                </ul>`,
            },
            {
                title: "7. How is corporate tax in UAE calculated?",
                content: `
                <ul>
                    <li style="list-style: unset">
                        Corporate tax in UAE is calculated at 9% of the net profit shown in the company's financial statements. 
                        The 9 % corporate tax will be levied only if the taxable net profit exceeds 375,000 AED. In other words, the net profit up to 3,75,000 AED is taxed at 0%.
                    </li>
                    <li style="list-style: unset">
                        For example, If the net profit is 4,75,000 AED, the corporate tax will be 9,000 AED (4,75,000-3,75,000 X 9/100).
                    </li>
                </ul>`,
            },
            {
                title: "8. What are the Untaxed Income?",
                content: `Corporate tax will not apply to the following:<br/><br/>
                <ul>
                    <li style="list-style: unset">
                        An employee's salary or income will not have to comply with the new Corporate Tax. 
                        The individual, however, will be liable for company tax if the payment is derived from activities performed under a freelancing license or permission.
                    </li>
                    <li style="list-style: unset">
                        Owning shares or other assets results in dividends, capital gains, and other income earned in a personal capacity.
                    </li>
                    <li style="list-style: unset">
                        Investing in real estate is legal in the UAE, as long as the investor holds no business license.
                    </li>
                    <li style="list-style: unset">
                        Individuals can earn interest and other revenue through bank accounts or savings plans through bank accounts or savings plans.
                    </li>
                    <li style="list-style: unset">
                        Qualifying intra-group transactions and reorganizations will not be subject to UAE CT provided the necessary conditions are met.
                    </li>
                    <li style="list-style: unset">
                        UAE CT will generally not be levied on a foreign investor’s income from dividends, capital gains, interest, royalties, and other investment returns, unless the income is generated through a business/commercial activity.
                    </li>
                </ul>`,
            },
            {
                title: "9. When will the Corporate Tax regime become effective in the UAE?",
                content: `The Law provides the implementation of a Federal Corporate Tax (Corporate Tax) in the UAE and all businesses will become subject to it from the beginning of their first financial year that starts on or after 1 June 2023.`,
            },
            {
                title: "10. What are the steps that businesses operating in the UAE need to take before CT is implemented?",
                content: `Use the available information from the website of the Ministry of Finance and the Federal Tax Authority to determine whether your business will be subject to Corporate Tax and if so, from what date. Understand the requirements for your business under the Corporate Tax Law, including, for example –<br/><br/>
                <ul>
                    <li style="list-style: unset">
                        Whether and by when your business needs to register for Corporate Tax.
                    </li>
                    <li style="list-style: unset">
                        Existing status of your books of accounts.
                    </li>
                    <li style="list-style: unset">
                        Impact of Corporate Taxation on your business operations & cash flows.
                    </li>
                    <li style="list-style: unset">
                        What is the financial year of your business?
                    </li>
                    <li style="list-style: unset">
                        By when your business would need to file a Corporate Tax Return.
                    </li>
                    <li style="list-style: unset">
                        What applications your business may or should make for Corporate Tax purposes.
                    </li>
                    <li style="list-style: unset">
                        What financial information and records your business will need to keep for Corporate Tax Purposes.
                    </li>
                </ul>`,
            },
            {
                title: "11. When does the financial year start for most companies?",
                content: `The financial year for most companies in the UAE starts on January 1st, 2024.`,
            },
            {
                title: "12. Who is considered a non-resident for UAE Corporate Tax purposes?",
                content: `A legal entity incorporated and effectively managed and controlled outside of the UAE is considered a non-resident under the Corporate Tax Law. In addition, if a natural person is not engaged in taxable business or business activities in the UAE, they are considered to be a non-resident.`,
            },
            {
                title: "13. Will an individual’s employment income be subject to UAE Corporate Tax?",
                content: `Salaries and other employment income (whether from the public or private sector) are not subject to UAE corporate Tax. An individual’s employment may include a continuing service relationship in which the individual derives most or all of their income from one person. The service income is remuneration for the natural person’s labor.`,
            },
            {
                title: "14. Will self-employed persons (e.g., freelancers) be subject to UAE Corporate Tax?",
                content: `In accordance with the Cabinet Decision that will be issued in due course, self-employed persons will be subject to UAE Corporate Tax only if their activities are considered taxable businesses or business activities. Nonetheless, in spite of the fact that the self-employed individual may be regarded as conducting a taxable business or business activity, Corporate Tax is due on any profit/income in excess to the AED 375000. The Small Business Relief is the 0% charge in the first AED 375000.`,
            },
        ],
    };

    const styles = {
        titleTextColor: "black",
        rowTitleColor: "#aa8038"
    };

    const config = {
        rowTitleColor: "#aa8038",
    };

    return (
        <>
            <script type="application/ld+json"
                dangerouslySetInnerHTML={{
                    __html:
                        `{
                            "@context": "https://schema.org",
                            "@type": "FAQPage",
                            "mainEntity": [{
                                "@type": "Question",
                                "name": "1. What is corporate tax in UAE?",
                                "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Corporate tax is a form of direct tax levied on the net income or profit of corporations and other business entities. It is also commonly known as 'Corporate Income Tax' or Business Profits Tax.'
                            In simple words, it is a tax levied on the net profit made by the businesses. It requires companies to pay a certain percentage of profit as tax."
                                }
                            },{
                                "@type": "Question",
                                "name": "2. Who should pay corporate tax in the UAE?",
                                "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "All the businesses whose taxable profit (net) is more than 375,000 AED fall under the purview of corporate tax and are required to pay a certain percentage of net profit as corporate tax."
                                }
                            },{
                                "@type": "Question",
                                "name": "3. What is the rate of corporate tax in the UAE?",
                                "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "The corporate tax rate is at 9% of the net profit made by the businesses. In order extent support to small businesses and start-ups, the corporate tax rate will be '0' % if the net profit is up to 3,75,000 AED."
                                }
                            },{
                                "@type": "Question",
                                "name": "4. What is the date of implementing the federal corporate tax in UAE?",
                                "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "The date of implementing the corporate tax is effective from the financial year starting on or after 1st June, 2023.
                            Corporate Tax will become effective in fiscal years beginning on or after June 1, 2023. Businesses adopting a fiscal year ending May 31, 2024, will be subject to corporate tax. The first tax returns will be filed in 2024. Corporations with fiscal years starting on January 1, 2023, and ending on December 31, 2023, will be subject to corporate tax. For Example, Corporations with fiscal years starting on June 1, 2023, and ending on May 30, 2024, will be subject to corporate tax, likewise:
                            FINANCIAL YEAR-END	FIRST TAX PERIOD
                            30 June	1st July 2023 - 30th June 2024
                            31 December	1st January 2024 - 31st December 2024"
                                }
                            },{
                                "@type": "Question",
                                "name": "5. Registration and deregistration",
                                "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "The FTA requires that all businesses subject to CT register with the FTA within the prescribed period and obtain a Tax Registration Number. In addition, if the company does not voluntarily register for CT purposes, the FTA can automatically do so.

                            The FTA must be notified within three months of the cessation of a business' CT registration (e.g., due to liquidation).
                            For FTA to deregister an individual, the individual must have filed CT returns and paid all CT obligations and penalties until discontinuation.
                            The application for deregistration is not made within the deadlines, and the payment and filing requirements are not met.

                            Filing, payment, and refund
                            Businesses will only need to prepare one tax return and other related supporting schedules with the FTA for each tax period. A company will not have to file a provisional CT return or pay the CT in advance.
                            The FTA must receive each tax return and related supporting schedule within nine months of each tax period's end.

                            CT payments for a Tax Period must be made within nine months after the Tax Period. The taxpayer can request a CT refund from the FTA if they can demonstrate that such a refund is due.
                            Documentation requirements
                            Businesses will be required to maintain financial and other records to comply with the CT return and other documents submitted to the FTA. Additionally, specific exempted individuals will also be required to keep records to allow the FTA to verify their exemption status.
                            It will be determined by applicable laws and regulations whether the financial statements need to be independently audited by an accredited firm. However, Free Zone Persons must have audited financial statements to qualify for the UAE CT regime."
                                }
                            },{
                                "@type": "Question",
                                "name": "6. What are the businesses or incomes that are outside the scope of corporate tax?",
                                "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Given the profit threshold of 3,75,000 AED, all businesses that exceed the threshold have to pay the corporate tax. However, certain types of business or income are exempt from corporate tax. Below is the list of companies or income exempt from corporate tax:

                            Individuals will not be subject to corporate tax. As a result, any income from employment, real estate, investments in shares, and other personal income unrelated to a trade or business in the UAE will be exempt from corporate tax.
                            Not applicable to foreign investors who do not carry on business in UAE.
                            Corporate tax incentives are currently being offered to free zone businesses that comply with all regulatory requirements will continue.
                            Capital gains and dividends received by UAE businesses from its qualifying shareholdings are exempt from corporate tax.
                            Not applicable on qualifying intragroup transactions and restructurings."
                                }
                            },{
                                "@type": "Question",
                                "name": "7. How is corporate tax in UAE calculated?",
                                "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Corporate tax in UAE is calculated at 9% of the net profit shown in the company's financial statements. The 9 % corporate tax will be levied only if the taxable net profit exceeds 375,000 AED. In other words, the net profit up to 3,75,000 AED is taxed at 0%.
                            For example, If the net profit is 4,75,000 AED, the corporate tax will be 9,000 AED (4,75,000-3,75,000 X 9/100)."
                                }
                            },{
                                "@type": "Question",
                                "name": "8. What are the Untaxed Income?",
                                "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Corporate tax will not apply to the following:

                            An employee's salary or income will not have to comply with the new Corporate Tax. The individual, however, will be liable for company tax if the payment is derived from activities performed under a freelancing license or permission.
                            Owning shares or other assets results in dividends, capital gains, and other income earned in a personal capacity.
                            Investing in real estate is legal in the UAE, as long as the investor holds no business license.
                            Individuals can earn interest and other revenue through bank accounts or savings plans through bank accounts or savings plans.
                            Qualifying intra-group transactions and reorganizations will not be subject to UAE CT provided the necessary conditions are met.
                            UAE CT will generally not be levied on a foreign investor’s income from dividends, capital gains, interest, royalties, and other investment returns, unless the income is generated through a business/commercial activity."
                                }
                            },{
                                "@type": "Question",
                                "name": "9. When will the Corporate Tax regime become effective in the UAE?",
                                "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "The Law provides the implementation of a Federal Corporate Tax (Corporate Tax) in the UAE and all businesses will become subject to it from the beginning of their first financial year that starts on or after 1 June 2023."
                                }
                            },{
                                "@type": "Question",
                                "name": "10. What are the steps that businesses operating in the UAE need to take before CT is implemented?",
                                "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Use the available information from the website of the Ministry of Finance and the Federal Tax Authority to determine whether your business will be subject to Corporate Tax and if so, from what date. Understand the requirements for your business under the Corporate Tax Law, including, for example –

                            Whether and by when your business needs to register for Corporate Tax.
                            Existing status of your books of accounts.
                            Impact of Corporate Taxation on your business operations & cash flows.
                            What is the financial year of your business?
                            By when your business would need to file a Corporate Tax Return.
                            What applications your business may or should make for Corporate Tax purposes.
                            What financial information and records your business will need to keep for Corporate Tax Purposes."
                                }
                            },{
                                "@type": "Question",
                                "name": "11. When does the financial year start for most companies?",
                                "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "The financial year for most companies in the UAE starts on January 1st, 2024."
                                }
                            },{
                                "@type": "Question",
                                "name": "12. Who is considered a non-resident for UAE Corporate Tax purposes?",
                                "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "A legal entity incorporated and effectively managed and controlled outside of the UAE is considered a non-resident under the Corporate Tax Law. In addition, if a natural person is not engaged in taxable business or business activities in the UAE, they are considered to be a non-resident."
                                }
                            },{
                                "@type": "Question",
                                "name": "13. Will an individual’s employment income be subject to UAE Corporate Tax?",
                                "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Salaries and other employment income (whether from the public or private sector) are not subject to UAE corporate Tax. An individual’s employment may include a continuing service relationship in which the individual derives most or all of their income from one person. The service income is remuneration for the natural person’s labor."
                                }
                            },{
                                "@type": "Question",
                                "name": "14. Will self-employed persons (e.g., freelancers) be subject to UAE Corporate Tax?",
                                "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "In accordance with the Cabinet Decision that will be issued in due course, self-employed persons will be subject to UAE Corporate Tax only if their activities are considered taxable businesses or business activities. Nonetheless, in spite of the fact that the self-employed individual may be regarded as conducting a taxable business or business activity, Corporate Tax is due on any profit/income in excess to the AED 375000. The Small Business Relief is the 0% charge in the first AED 375000."
                                }
                            }]
                        }`
                }}
            />
            <Helmet>
                <title>Corporate Tax Service in Dubai and the UAE</title>
            </Helmet>
            <div class="chairman-message">
                <div class="chairman-img">
                    <div class="pos-message-box">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-7 aos-init" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2 className='alignment'>Corporate Tax</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img class="d-lg-block d-none" style={{ width: "-webkit-fill-available" }} src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001997/images/Contact-us.png" alt="corporate-tax-banner" title="Corporate Tax Banner" />
            </div>
            <div className="container">
                <div className="row pt-5 pb-5">
                    <h1><span style={{ color: "#aa8038" }}>Corporate Tax Service in UAE</span></h1>
                    <p>
                        The authorities in UAE have announced the introduction of corporate tax from 1st June,23. The law stipulates that a taxable person or
                        business will be subjected to a 9% corporate tax from the start of their first financial year commencing on or after June 1, 2023.
                    </p>
                    <p>
                        Since its announcement, it has created a buzz among businesses and tax professionals. With this announcement,
                        UAE is all set to become 4th among the GCC countries to introduce a federal corporate tax.
                    </p>
                    <p>
                        The introduction of federal corporate tax in UAE aims to further strengthen the country's
                        position as a world-leading hub for businesses and investment and accelerate the strategic
                        objective towards development and transformation. Also, the corporate tax concept helps meet
                        international standards for tax transparency and avoids harmful tax practices.
                    </p>
                    <p>
                        Given that corporate tax is new in UAE, it is essential for businesses to understand the concept.
                        We have tried to put across some common questions you might have on corporate tax.
                    </p>
                    <p>
                        Alight! Let's get started.
                    </p>
                    <Faq
                        data={data}
                        styles={styles}
                        config={config}
                    />
                    <h5 className='pt-5' style={{ color: "#aa8038" }}>Free Trade Zone</h5>
                    <p>
                        For now, the UAE government plans to maintain its commitment to enterprises registered in Free Trade Zones by providing exemplary tax benefits if they meet the conditions levied.
                        Further, a Corporate Tax return must also be submitted by each free zone annually.
                    </p>
                    <p>
                        Further business units headquartered in a free zone with mainland operations will be subject to corporate tax.
                    </p>
                </div>
            </div>
        </>
    );
};

export default CorporateTax;