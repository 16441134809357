import React from 'react';
import { Helmet } from "react-helmet";

// ================================|| BusinessConsultingAdvisory ||================================ //

const BusinessConsultingAdvisory = () => {

    return (
        <>
            <Helmet>
                <title>Business Consulting & Advisory Services in Dubai and the UAE</title>
            </Helmet>
            <div class="chairman-message">
                <div class="chairman-img">
                    <div class="pos-message-box">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-7 aos-init" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                    <h2 className='alignment'>Business Consulting Advisory</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img class="d-lg-block d-none" style={{ width: "-webkit-fill-available" }} src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001997/images/Contact-us.png" alt="business-consulting-banner" title="Business Consulting Banner" />
            </div>
            <div className="container">
                <div className="row pt-5 pb-5">
                    <div className='col-md-7'>
                        <h1><span style={{ color: "#aa8038" }}>Business Consulting & Advisory Services in UAE</span></h1>
                        <p>
                            Unquestionably, the UAE market is enticing and suitable yet starting a business in any new country is by and large a test! The best method for bartering is with Devotion Corporate Services in the UAE.
                        </p>
                        <p>
                            Our business game plan consultants give ace revamping direction and courses of action that will safeguard and stay aware of control of your social event's corporate presence through the United Arab Emirates and the greater GCC area.
                            Our lord gathering of association improvement specialists offers an extent of corporate getting sorted out and guiding organizations that are fit to your business development and expansion plans.
                            There are a couple of lawful bodies, regulative divisions, administrations, and departments expected during this cycle.
                        </p>
                        <p>
                            Devotion solidly confides in bestowing a getting-through relationship to our clients, collaborating with the public power specialists and regulatory subject matter experts, and giving you a hard and fast start-to-finish workplace.
                            To acknowledge more locations our guiding subject matter experts - as they have answers to each odd business situation.
                        </p>
                    </div>
                    <div className='col-md-5'>
                        <img class="card-img-top" src="https://res.cloudinary.com/ddnekoeii/image/upload/v1711001996/images/consultancy.png" alt="business-consulting" title="Business Consulting" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default BusinessConsultingAdvisory;